define('frodo/pods/index/system/batch/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/system/system-model'], function (exports, _baseModel, _systemModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = exports.jobSchedule = exports.check = exports.fetch = undefined;
  exports.default = _systemModel.default.extend({
    statusColor: Ember.computed('status', function () {
      switch (this.get('status')) {
        case 'FAILED':
          return 'text-danger';
        case 'STARTED':
          return 'text-warning';
        case 'STARTING':
          return 'text-warning';
        case 'STOPPING':
          return 'text-warning';
        case 'ABANDONED':
          return 'text-warning';
        case 'UNKNOWN':
          return 'text-warning';
        case 'STOPPED':
          return 'text-warning';
        case 'COMPLETED':
          return 'text-success';
        default:
          return '';
      }
    })
  });


  function fetch(owner) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/batch/jobs');
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/batch/job/validate', { data: data });
  }

  function jobSchedule(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/batch/job/' + data.jobName + '/schedule');
  }

  function add(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/batch/job/schedule', { data: data });
  }

  exports.fetch = fetch;
  exports.check = check;
  exports.jobSchedule = jobSchedule;
  exports.add = add;
});
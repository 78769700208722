define('frodo/pods/index/operator/privilege/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/operator/operator-model'], function (exports, _baseModel, _operatorModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.edit = exports.check = exports.fetch = exports.add = undefined;
  exports.default = _operatorModel.default.extend({});


  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/privilege', { data: data });
  }

  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/privileges', p);
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/privilege/validate', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/privilege/' + data.id, { data: data });
  }

  exports.add = add;
  exports.fetch = fetch;
  exports.check = check;
  exports.edit = edit;
});
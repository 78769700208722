define('frodo/mixins/authorization', ['exports', 'frodo/models/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    bootstrap: Ember.inject.service(),

    currentMenu: Ember.computed('bootstrap.menu', function () {
      // for sub page without menu
      var routeName = this.get('controller.parentRoot') || this._parse(this.get('routeName'));
      var menus = _.map(this.get('bootstrap.menu'), 'subs');

      var flatten = _.flatten(menus);
      return _.find(flatten, function (s) {
        return s.name === routeName;
      });
    }),

    isMenuRO: function isMenuRO(menu) {
      var menus = this.getReadableMenus();
      return !!(_.find(menus, function (s) {
        return s.name === menu;
      }) || {}).readable;
    },
    getReadableMenus: function getReadableMenus() {
      var menus = _.map(this.get('bootstrap.menu'), 'subs');
      var flatten = _.flatten(menus);
      var filters = _.filter(flatten, function (m) {
        return m.readable;
      });
      return filters;
    },


    /**
     * privilege
     */
    hasRO: Ember.computed('currentMenu', function () {
      var currentMenu = this.get('currentMenu');
      return currentMenu ? currentMenu.readable : false;
    }),

    hasRW: Ember.computed('currentMenu', function () {
      var currentMenu = this.get('currentMenu');
      return currentMenu ? currentMenu.readable && currentMenu.writable : false;
    }),

    /**
     * role
     */
    operatorType: Ember.computed('bootstrap.profile', function () {
      return this.get('bootstrap.profile.type');
    }),

    isSuper: Ember.computed('operatorType', function () {
      return this.get('operatorType') === _enums.AdminOperatorType.ROOT;
    }),

    isAdmin: Ember.computed('operatorType', function () {
      return this.get('operatorType') === _enums.AdminOperatorType.ADMIN;
    }),

    isManager: Ember.computed('operatorType', function () {
      return this.get('operatorType') === _enums.AdminOperatorType.MANAGER;
    }),

    isAdministrator: Ember.computed('isSuper', 'isAdmin', function () {
      return this.get('isSuper') || this.get('isAdmin');
    }),

    _parse: function _parse(routeName) {
      if (routeName) {
        var array = routeName.split('.');
        array.shift();
        return array.join('.');
      }
    }
  });
});
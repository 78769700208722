define('frodo/pods/index/market/lp-account/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/market/market-model'], function (exports, _baseModel, _marketModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.remove = exports.edit = exports.check = exports.fetch = exports.add = undefined;
  exports.default = _marketModel.default.extend({});


  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/' + data.lpId + '/account', { data: data });
  }

  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/' + data.lpId + '/accounts');
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/' + data.lpId + '/account/validate', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/' + data.lpId + '/account/' + data.id, { data: data });
  }

  function remove(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/' + data.lpId + '/account/' + data.id + '/' + data.version);
  }

  exports.add = add;
  exports.fetch = fetch;
  exports.check = check;
  exports.edit = edit;
  exports.remove = remove;
});
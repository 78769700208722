define('frodo/pods/index/market/broker-symbol/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    brokerId: [(0, _validators.validatePresence)(true)],
    symbolId: [(0, _validators.validatePresence)(true)],
    tradingPriceScale: [(0, _validators.validatePresence)(true)],
    tradingVolumeScale: [(0, _validators.validatePresence)(true)],
    tradingMinVolume: [(0, _validators.validatePresence)(true)],
    tradingMaxVolume: [(0, _validators.validatePresence)(true)],
    pricingFrequency: [(0, _validators.validatePresence)(true)]
  };
});
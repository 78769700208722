define('frodo/services/realtime', ['exports', 'frodo/config/environment', 'frodo/models/enums'], function (exports, _environment, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STATE = undefined;
  var STATE = exports.STATE = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
    READY: -1
  };

  exports.default = Ember.Service.extend(Ember.Evented, {
    ws: null,
    url: 'rest/realtime',
    retry: 10,
    interval: 3000,
    heartbeat: 10000,
    tryingClose: false,
    lastUpdate: 0,
    token: "",
    router: Ember.inject.service(),
    utility: Ember.inject.service(),
    websockets: Ember.inject.service(),
    resumed: false,

    isConnected: Ember.computed('state', function () {
      return this.get('state') === STATE.OPEN;
    }).volatile(),

    isDev: Ember.computed('config.environment', function () {
      return _environment.default.environment === _enums.EnvType.DEV;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('queue', []);
      this.set('subscribed', []);
      this.set('uniqueNumber', new UniqueNumber(true));
    },


    state: Ember.computed(function () {
      var ws = this.get('ws');
      var readyState = ws && ws.socket ? ws.socket.readyState : -1;
      switch (readyState) {
        case 0:
          return STATE.CONNECTING;
        case 1:
          return STATE.OPEN;
        case 2:
          return STATE.CLOSING;
        case 3:
          return STATE.CLOSED;
        default:
          return STATE.READY;
      }
    }).volatile(),

    willDestroy: function willDestroy() {
      var ws = this.get('ws');
      ws.off('open', this.openHandler);
      ws.off('close', this.closeHandler);
      ws.off('error', this.errorHandler);
      ws.off('message', this.messageHandler);
    },
    build_ws_json: function build_ws_json(action, event) {
      return { id: this.get('uniqueNumber').generate().toString(), action: action, event: event };
    },


    /**
     *
     */
    subscribe: function subscribe(event, payload) {
      if (this.get('isDev')) {
        var payload_str = JSON.stringify(payload);console.log('subscribe :' + event + ' :' + payload_str);
      }
      var ws_msg = Object.assign(this.build_ws_json(_enums.WebSocketAction.SUBSCRIBE, event), payload);
      if (STATE.OPEN === this.get('state')) {
        this.send(ws_msg, true);
        this.get('subscribed').push(ws_msg);
      } else {
        this.get('queue').push(ws_msg);
      }
      return this;
    },
    unsubscribe: function unsubscribe(event, payload) {
      if (this.get('isDev')) {
        var payload_str = JSON.stringify(payload);console.log('unsubscribe :' + event + ' :' + payload_str);
      }
      var ws_msg = Object.assign(this.build_ws_json(_enums.WebSocketAction.UNSUBSCRIBE, event), payload);
      var subscribed = this.get('subscribed');
      subscribed.forEach(function (s, i) {
        if (s.event === event && s.action === _enums.WebSocketAction.SUBSCRIBE) {
          subscribed.splice(i, 1);
        }
      });
      if (STATE.OPEN === this.get('state')) {
        this.send(ws_msg, true);
      } else {
        this.get('queue').push(ws_msg);
      }
      return this;
    },


    //
    send: function send(json) {
      var jsonStringify = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      this.get('ws').send(json, jsonStringify);
    },
    connect: function connect() {
      var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'rest/realtime';
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : { retry: this.get('retry'), interval: this.get('interval'), heartbeat: this.get('heartbeat') };

      if (STATE.OPEN !== this.get('state')) {
        this.set('url', url);
        this.set('retry', options ? options.retry : this.get('retry'));
        this.set('interval', options ? options.interval : this.get('interval'));
        this.set('heartbeat', options ? options.heartbeat : this.get('heartbeat'));
        //
        var ws = this.get('websockets').socketFor(this._concatUrl());
        ws.on('open', this.openHandler, this);
        ws.on('close', this.closeHandler, this);
        ws.on('error', this.errorHandler, this);
        ws.on('message', this.messageHandler, this);

        this.set('ws', ws);
      }
      return this;
    },
    disconnect: function disconnect() {
      if (this.get('isDev')) console.log('disconnect call, set retry to 0');
      this.set('retry', 0);
      this.set('tryingClose', true);
      this.get('websockets').closeSocketFor(this._concatUrl());
    },


    /**
     * open handler
     */
    openHandler: function openHandler() /*e*/{
      var _this = this;

      this.trigger('open');
      this.set('tryingClose', false);
      if (this.get('resumed')) {
        this.trigger('reopen');
      }
      var queue = this.get('queue');
      if (queue.length > 0) {
        _.each(queue, function () /*q*/{
          _this.send(queue.shift(), true);
        });
      }
      this.ping();
    },
    ping: function ping() {
      var _this2 = this;

      Ember.run.debounce(function () {
        if (STATE.OPEN === _this2.get('state')) {
          _this2.get('ws').send(_this2.build_ws_json(_enums.WebSocketAction.PING, ''), true);
          _this2.set('lastUpdate', Date.now());
          _this2.trigger('ping');
          _this2.ping();
        }
      }, this.get('heartbeat'));
    },


    /**
     * close handler
     */
    closeHandler: function closeHandler(e) {
      var _this3 = this;

      this.trigger('close');
      // server side close the connection, shouldn't reconnect.
      if (_enums.WebSocketCloseStatus.NORMAL === e.code) {
        this.closeWin();return;
      }
      // if (WebSocketCloseStatus.NO_CLOSE_FRAME === e.code) { this.stop(); return;}
      if (_enums.WebSocketCloseStatus.ABNORMAL_CODE === e.code) {
        this.closeWin();return;
      }
      if (_enums.WebSocketCloseStatus.SERVER_ERROR === e.code) {
        this.stop();return;
      }
      //
      if (!this.get('tryingClose')) {
        var retry = this.get('retry');
        if (retry > 0) {
          Ember.run.later(this, function () {
            if (_this3.get('retry') > 0) {
              _this3.decrementProperty('retry');
              _this3.get('ws').reconnect();
              _this3.set('resumed', true);
              if (_this3.get('isDev')) {
                console.warn('reconnecting...');
              }
            }
          }, this.get('interval'));
        } else {
          // out of retry
          this.set('resumed', false);
          if (_enums.WebSocketCloseStatus.NO_CLOSE_FRAME === e.code) {
            this.stop();return;
          }
        }
      } else {
        this.closeWin();
      }
    },
    closeWin: function closeWin() {
      this.get('utility').closeWindows();
    },
    stop: function stop() {
      this.get('utility').stop();
    },


    /**
     * error handler
     */
    errorHandler: function errorHandler(e) {
      console.error('on error', e);
      this.trigger('error', e);
    },


    /**
     * message handler
     */
    messageHandler: function messageHandler(e) {
      var r = JSON.parse(e.data);
      if (r.data) this.trigger(r.event, r.data); // data
      else this.trigger(r.event + '_SUBSCRIBE_RESPONSE', r); // response
    },
    _concatUrl: function _concatUrl() {
      var url = this.get('url');
      var protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
      return protocol + '//' + location.host + _environment.default.rootURL + url;
    }
  });
});
define('frodo/pods/index/operator/menu/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/operator/operator-model'], function (exports, _baseModel, _operatorModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.privileges = exports.remove = exports.edit = exports.check = exports.sort = exports.fetch = exports.add = undefined;
  exports.default = _operatorModel.default.extend({});


  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/menu', { data: data });
  }

  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/menus', { data: p });
  }

  function sort(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/menu/sort', { data: data });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/menu/validate', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/menu/' + data.id, { data: data });
  }

  function remove(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/menu/' + data.id + '/' + data.version);
  }

  function privileges(owner) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/menu/privileges');
  }

  exports.add = add;
  exports.fetch = fetch;
  exports.sort = sort;
  exports.check = check;
  exports.edit = edit;
  exports.remove = remove;
  exports.privileges = privileges;
});
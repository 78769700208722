define('frodo/mixins/popup-window', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    popupWindow: function popupWindow(_ref) {
      var queryPath = _ref.queryPath,
          windowParams = _ref.windowParams,
          _ref$width = _ref.width,
          width = _ref$width === undefined ? 400 : _ref$width,
          _ref$height = _ref.height,
          height = _ref$height === undefined ? 500 : _ref$height,
          _ref$resizable = _ref.resizable,
          resizable = _ref$resizable === undefined ? 'no' : _ref$resizable;

      var coord = windowParams.coord;
      var left = (window.outerWidth - width) / 2 + coord.x + (window.screenX || 0);
      var top = (window.screenY || 0) + (window.outerHeight - window.innerHeight) + coord.y;
      var openParams = 'left=' + left + ',top=' + top + ', toolbar=no, location=no, status=no, scrollbars=no, resizable=' + resizable + ', width=' + width + ', height=' + height;
      var winId = ++Ember.$.guid;
      var win = window.open('' + queryPath, 'popup-' + winId, openParams);

      return this.initPopWindow(win, winId, windowParams).then(function () {
        if (window.windowsSet) window.windowsSet.add(win);
        return win;
      });
    },


    /**
     * to async becauseOf IE blocked after execute window.open
     */
    initPopWindow: function initPopWindow(win, winId, windowParams) {
      function mark() {
        win.id = winId;
        win.isPopWindow = true;
        win.windowParams = windowParams;
      }

      var loop = this._executeLoop(mark, function () {
        return win.isPopWindow === true;
      });
      var ready = new Ember.RSVP.Promise(function (resolve) {
        Ember.$(win).ready(function () {
          resolve(win);
        });
      });

      return Ember.RSVP.Promise.all([loop, ready]).then(function () {
        return win;
      });
    },
    _executeLoop: function _executeLoop(fn, stopFn) {
      var delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;

      return new Ember.RSVP.Promise(function (resolve) {
        fn();
        var timer = setInterval(function () {
          if (stopFn()) {
            clearInterval(timer);
            return resolve();
          }
          fn();
        }, delay);
      });
    },


    actions: {
      popupWindow: function popupWindow() {
        this.popupWindow.apply(this, arguments);
      }
    }

  });
});
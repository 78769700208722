define('frodo/services/navtabs', ['exports', 'frodo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    //
    i18n: Ember.inject.service(),
    router: Ember.inject.service(),
    bootstrap: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    //
    openTabs: Ember.A(),
    on: _environment.default.navtabs.on,
    maxTab: _environment.default.navtabs.maxTab,

    init: function init() {
      this._super.apply(this, arguments);
    },

    isEmpty: Ember.computed('openTabs.[]', function () {
      var tabs = this.get('openTabs');
      return tabs && tabs.length === 0;
    }),
    notFull: Ember.computed('openTabs.[]', function () {
      var tabs = this.get('openTabs');
      return tabs && tabs.length < this.get('maxTab');
    }),
    isFull: Ember.computed('openTabs.[]', function () {
      var tabs = this.get('openTabs');
      return tabs && tabs.length >= this.get('maxTab');
    }),
    outFull: Ember.computed('openTabs.[]', function () {
      var tabs = this.get('openTabs');
      return tabs && tabs.length > this.get('maxTab');
    }),
    isLastOne: Ember.computed('openTabs.[]', function () {
      var tabs = this.get('openTabs');
      return tabs && tabs.length === 1;
    }),
    fistOne: Ember.computed('openTabs.[]', function () {
      var tabs = this.get('openTabs');
      return _.head(tabs);
    }),

    createTabFromUrl: function createTabFromUrl(url, isDirty) {
      var route = url.params[0];
      (false && !(route) && Ember.assert('url not have a route', route));

      return this.buildTab(route, url.title, undefined, undefined, isDirty);
    },
    setTab: function setTab(route, model, param) {
      var tabs = this.get('openTabs');
      var bootstrap = this.get('bootstrap');
      var tab = tabs.findBy('route', route);
      if (tab) {
        Ember.set(tab, 'model', model);
        Ember.set(tab, 'param', param);
        Ember.set(tab, 'isDirty', true);
      } else {
        this.addTab(route, bootstrap.getMenuName(route), model, param, true);
      }
    },
    buildTab: function buildTab(route, title, model, param, isDirty) {
      var bootstrap = this.get('bootstrap');
      return {
        route: route,
        title: title,
        model: model,
        param: param,
        icon: bootstrap.getMenuIcon(route),
        isDirty: isDirty
      };
    },
    addTab: function addTab(route, title, model, param, isDirty) {
      if (this.get('isFull')) {
        this.get('openTabs').removeObject(this.fistOne);
      }
      var tab = this.buildTab(route, title, model, param, isDirty);
      var tabs = this.get('openTabs');
      tabs.pushObject(tab);
      this.switchToTab(tab);
      return tab;
    },
    getTab: function getTab(route) {
      var tabs = this.get('openTabs');
      var tab = tabs.findBy('route', route);
      if (tab) {
        return tab;
      }
      return undefined;
    },
    isNewTab: function isNewTab(route) {
      var parentRoute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

      if (parentRoute) {
        return this.getTab(parentRoute) === undefined;
      }
      return this.getTab(route) === undefined;
    },
    isDirtyTab: function isDirtyTab(route) {
      var parentRoute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

      if (parentRoute) {
        var parent = this.getTab(route);
        if (!parent) {
          return false;
        }
        return parent.isDirty;
      }
      var tab = this.getTab(route);
      if (!tab) {
        return false;
      }
      return tab.isDirty;
    },
    addTabFromRoute: function addTabFromRoute(url, isDirty) {
      var tabs = this.get('openTabs');
      var exist = tabs.findBy('route', url.params[0]);
      if (!exist) {
        if (this.get('isFull')) {
          return;
        } else {
          exist = this.createTabFromUrl(url, isDirty);
          tabs.pushObject(exist);
        }
      }
      this.switchToTab(exist);
    },
    closeTab: function closeTab(tab) {
      this.get('openTabs').removeObject(tab);
      var last = _.last(this.get('openTabs'));
      if (last) {
        this.switchToTab(last);
        this.get('router').transitionTo(last.route);
      }
    },
    closeTabByRoute: function closeTabByRoute(route) {
      this.closeTab(this.getTab(route));
    },
    closeInactive: function closeInactive() {
      var tabs = this.get('openTabs');
      var inactiveTabs = _.filter(tabs, function (t) {
        return t.isActive === '';
      });
      inactiveTabs.forEach(function (r) {
        tabs.removeObject(r);
      });
      var last = _.last(this.get('openTabs'));
      if (last) {
        this.switchToTab(last);
      }
    },
    switchToTab: function switchToTab(tab) {
      var tabs = this.get('openTabs');
      tabs.forEach(function (t) {
        Ember.set(t, 'isActive', '');
      });
      var activeTab = tabs.findBy('route', tab.route);
      Ember.set(activeTab, 'isActive', 'active');
    },
    swapTabFromRoute: function swapTabFromRoute(url, isDirty) {
      var tabs = this.get('openTabs');
      if (tabs.length < 1) {
        this.addTabFromRoute(url, isDirty);
        return;
      }
      var openedTab = tabs.findBy('route', url.params[0]);
      if (!openedTab) {
        openedTab = this.createTabFromUrl(url, isDirty);
      }
      this.switchToTab(openedTab);
    },
    fullTabWarning: function fullTabWarning() {
      var max = this.get('maxTab');
      var i18n = this.get('i18n');
      var flash = this.get('flashMessages');
      flash.danger(i18n.t('error.navtab.overflow', { max: max }));
    },
    destroyTabs: function destroyTabs() {
      this.set('openTabs', Ember.A());
    }
  });
});
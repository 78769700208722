define('frodo/pods/components/common/modal-base/component', ['exports', 'ember-deep-set', 'frodo/mixins/result', 'frodo/mixins/service-bus', 'frodo/utils/common-generator'], function (exports, _emberDeepSet, _result, _serviceBus, _commonGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;


  var ModalBase = Ember.Component.extend(_serviceBus.default, _result.default, {

    classNames: ['modal'],
    classNameBindings: ['isAnimate:fade', 'isModaless:modaless'],
    attributeBindings: ['tabindex:tabindex'],

    tabindex: -1,
    readonly: false,
    // detail: false,
    preview: false,
    isTabbed: true,
    isAnimate: true,
    permitEscape: true,

    model: null,
    error: null,
    // onShown: null,
    // onHidden: null,
    modelOriginal: null,
    backdrop: 'static',

    titleKey: null,
    titleValue: null, // {name: 'abc'},
    notificationKey: null,
    notificationValue: null,

    onShown: function onShown() {},
    onHidden: function onHidden() {},


    keyDown: function keyDown(event) {
      //press enter
      if (event.keyCode === 13) {
        window.event.returnValue = false;
        return;
      }
      this._super.apply(this, arguments);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('errors', []);
    },


    isEdit: Ember.computed('model', function () {
      var model = this.get('model');
      return !Ember.isNone(model) && model.id > 0;
    }),

    isCreate: Ember.computed('model', function () {
      var model = this.get('model');
      return Ember.isNone(model) || Ember.isNone(model.id);
    }),

    isModaless: Ember.computed('backdrop', function () {
      return this.get('backdrop') === false;
    }),

    menuKey: Ember.computed('router', function () {
      var menu = this.get('router').get('currentRouteName');
      return this.get('i18n').t('menu.' + menu);
    }),

    title: Ember.computed('readonly', 'isEdit', 'menuKey', function () {
      var i18n = this.get('i18n');
      var titleKey = this.get('titleKey');
      var titleValues = this.get('titleValues');
      if (titleKey) {
        return i18n.t('label.' + titleKey, titleValues ? titleValues : {});
      }

      var name = this.get('menuKey');

      if (this.get('readonly')) {
        return i18n.t('label.dialog.action.detail', { name: name });
      } else if (this.get('isEdit')) {
        return i18n.t('label.dialog.action.edit', { name: name });
      } else {
        return i18n.t('label.dialog.action.add', { name: name });
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().modal({
        show: true,
        backdrop: this.get('backdrop'),
        keyboard: this.get('permitEscape')
      }).on('shown.bs.modal', this.onShown.bind(this)).on('hidden.bs.modal', this.onHidden.bind(this));
    },
    activateTab: function activateTab(errorFields) {
      if (errorFields && errorFields.length > 0) {
        var fieldName = errorFields[0];
        var parents = this.$('[data-field-name="' + fieldName + '"]').parents('.tab-pane');
        if (parents) {
          var first = parents[0],
              tabId = this.$(first).attr('id'),
              $tab = this.$('[href="#' + tabId + '"]');$tab.click();
        }
      }
    },


    actions: {
      back: function back() {
        this.set('error', {});
        this.set('preview', false);
      },
      close: function close() {
        this.$().modal('hide');
      },
      dispose: function dispose() {
        var callback = this.get('on');
        if (callback) callback();
      },
      localCheck: function localCheck() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          var error = {};
          var isValid = false;
          var changeset = _this.get('changeset');
          changeset.validate().then(function () {
            isValid = changeset.get('isValid');
            if (isValid) {/**/} else {
              error = changeset.get('error');
            }
            var errorFields = Object.keys(error);
            _this.set('error', error);
            _this.set('errorFields', errorFields);
            resolve(isValid ? '' : error);
            if (_this.get('isTabbed')) {
              _this.activateTab(errorFields);
            }
          });
        });
      },
      reload: function reload() {
        var name = this.get("router.currentRouteName");
        Ember.getOwner(this).lookup('route:' + name).send('reload');
      },
      handleCheckResult: function handleCheckResult(r) {
        if (this.isSuccess(r)) {
          this.set('preview', true);
        } else {
          if (r) {
            // server side validation
            var error = this.merge(r);
            var errorFields = Object.keys(error);
            this.set('error', error);
            this.set('errorFields', errorFields);
            if (this.get('isTabbed')) {
              this.activateTab(errorFields);
            }
          }
        }
      },
      handleSubmitResult: function handleSubmitResult(r) {
        var i18n = this.get('i18n');
        var name = this.get('menuKey');
        var flash = this.get('flashMessages');
        var notificationKey = this.get('notificationKey');
        if (this.isSuccess(r)) {
          if (notificationKey) {
            flash.info(i18n.t(notificationKey));
          } else {
            if (this.get('isEdit')) {
              flash.info(i18n.t('label.notification.modified', { name: name }));
            } else {
              flash.info(i18n.t('label.notification.added', { name: name }));
            }
          }
        } else {
          if (r && r.key) {
            flash.danger(i18n.t(r.key));
          }
        }

        return new Ember.RSVP.Promise(function (resolve) {
          resolve(r);
        });
      },
      brokerSelected: function brokerSelected() /*selected*/{
        this.reactLp();this.reactSymbol();this.reactBkSymbol();this.reactBook();
        this.selectBroker();
      },
      lpSelected: function lpSelected() /*selected*/{
        this.reactSymbol();
        this.reactBkSymbol();
        this.reactLpAccount();
      },
      symbolSelected: function symbolSelected() /*selected*/{},
      bookSelected: function bookSelected() /*selected*/{}
    },
    selectBroker: function selectBroker() {
      var brokerId = this.get('changeset.brokerId');
      this.set("symbolOptions", this.getSymbolOptions(brokerId));
      this.set("bookOptions", this.getBookOptions(brokerId));
      this.set("lpOptions", this.getLpOptions(brokerId, this.get('lpType')));
    },
    getSymbolOptions: function getSymbolOptions(brokerId) {
      var bkSymbols = this.get('bootstrap').getSelection('broker.symbol');var symbol = Ember.A();
      bkSymbols.forEach(function (o) {
        if (brokerId == o.extra.$group) {
          symbol.push({ name: o.name, value: o.extra.symbolId });
        }
      });
      return symbol;
    },
    getSymbolOptionsByLp: function getSymbolOptionsByLp(lpId) {
      var lpSymbol = this.get("bootstrap").getSelection('broker.lp_symbol');
      var symbol = Ember.A();
      lpSymbol.forEach(function (o) {
        if (lpId == (0, _commonGenerator.getLpIdByLpSymbolId)(o.value)) {
          symbol.push({ name: o.name, value: o.extra.symbolId });
        }
      });
      return symbol;
    },
    getBookOptions: function getBookOptions(brokerId) {
      var r = Ember.A();var books = this.get('bootstrap').getSelection('broker.book');
      books.forEach(function (o) {
        if (brokerId == o.extra.$group) {
          r.push({ name: o.name, value: o.value });
        }
      });
      return r;
    },
    getLpOptions: function getLpOptions(brokerId) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

      var r = Ember.A();
      var all = type === 0 ? true : false;
      var lps = this.bootstrap.getSelection('broker.lp');
      lps.forEach(function (o) {
        if (brokerId == o.extra.$group && (all || type === o.extra.$group2)) {
          r.push({ name: o.name, value: o.value });
        }
      });
      return r;
    },
    getLpAccountOptions: function getLpAccountOptions() {
      var lpId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var hasEmptyOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var all = lpId === 0 ? true : false;
      var emptyOptions = hasEmptyOptions ? [{ name: this.get('i18n').t('label.common.none'), value: 0 }] : [];
      var accounts = this.bootstrap.getSelection('broker.lp_account');
      if (all) {
        return emptyOptions.concat(accounts);
      }
      return emptyOptions.concat(_.filter(accounts, function (a) {
        return a.extra.$group === lpId;
      }));
    },
    reactLp: function reactLp() {
      var r_lpId = this.get('changeset.lpId');
      if (r_lpId > 0) {
        var lp_exist = _.find(this.get('lpOptions'), function (o) {
          o.value === r_lpId;
        });
        if (Ember.isEmpty(lp_exist)) {
          this.set('changeset.lpId', 0);
        }
      }
    },
    reactSymbol: function reactSymbol() {
      var r_symbolId = this.get('changeset.symbolId');
      if (r_symbolId > 0) {
        var symbol_exist = _.find(this.get('symbolOptions'), function (o) {
          o.value === r_symbolId;
        });
        if (Ember.isEmpty(symbol_exist)) {
          this.set('changeset.symbolId', 0);
        }
      }
    },
    reactBkSymbol: function reactBkSymbol() {
      var r_bkSymbolId = this.get('changeset.bkSymbolId');
      if (r_bkSymbolId > 0) {
        var symbol_exist = _.find(this.get('bkSymbolOptions'), function (o) {
          o.value === r_bkSymbolId;
        });
        if (Ember.isEmpty(symbol_exist)) {
          this.set('changeset.bkSymbolId', 0);
        }
      }
    },
    reactAllocator: function reactAllocator() {
      // this.get('changeset').set('allocators',{values:[]});
    },
    reactBook: function reactBook() {
      var r_bookId = this.get('changeset.bookId');
      if (r_bookId > 0) {
        var book_exist = _.find(this.get('bookOptions'), function (o) {
          o.value === r_bookId;
        });
        if (Ember.isEmpty(book_exist)) {
          this.set('changeset.bookId', 0);
        }
      }
    },
    reactLpAccount: function reactLpAccount() {
      var r_lpId = this.get('changeset.lpId');
      var lpAccounts = this.getLpAccountOptions(r_lpId, false);
      this.set("lpAccountOptions", lpAccounts);
      if (Ember.isEmpty(lpAccounts)) {
        this.set('changeset.accountId', 0);
      }
    },
    deepCopy: function deepCopy(source) {
      var r = {};
      keys(source).forEach(function (k) {
        return !_.isFunction(source[k]) && (0, _emberDeepSet.default)(r, k, source[k]);
      });
      return r;
    },
    copy: function copy(model, changeset) {
      var change = changeset.get('change');
      var r = this.deepCopy(model);
      keys(change).forEach(function (key) {
        return (0, _emberDeepSet.default)(r, key, change[key]);
      });
      return r;
    }
  });

  exports.default = ModalBase;
});
define('frodo/services/utility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    navtabs: Ember.inject.service(),
    realtime: Ember.inject.service(),
    dialogManager: Ember.inject.service(),

    logout: function logout() {
      var _this = this;

      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var self = this;
      if (!force) {
        return this.get('ajax').request('rest/utility/logout', { data: { force: force } }).then(function () /*r*/{
          _this.get('ajax').setToken("");
          self.stop();
        });
      } else {
        return new Promise(function (resolve) {
          self.stop();
          resolve(force);
        });
      }
    },
    stop: function stop() {
      this.get('realtime').disconnect();
      this.closeTabs();
      this.closeWindows();
      this.redirectToLogin();
    },
    closeTabs: function closeTabs() {
      this.get('navtabs').destroyTabs();
    },
    closeWindows: function closeWindows() {
      console.log('[utility] close pop windows ');
      this.get('dialogManager').destroyWindows();
    },
    redirectToLogin: function redirectToLogin() {
      console.log('[utility] redirect to login ');
      this.get('router').transitionTo('login');
    },
    redirectToExpired: function redirectToExpired() {
      this.get('router').transitionTo('utility.password');
    },
    changeLanguage: function changeLanguage(v) {
      return this.get('ajax').post('/rest/utility/locale', { data: { lang: v } });
    }
  });
});
define('frodo/pods/components/common/chart-bar-vertical/component', ['exports', 'frodo/pods/components/common/base-chart-bar/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    chartType: 'bar',
    chartData: Ember.computed('labels', 'contents', 'backgroundColor', 'borderColor', 'borderWidth', function () {
      var labels = this.get('labels');
      var contents = this.get('contents');
      var backgroundColor = this.get('backgroundColor') || this.get('default_setting.backgroundColor');
      var borderColor = this.get('borderColor') || this.get('default_setting.borderColor');
      var borderWidth = this.get('borderWidth') || this.get('default_setting.borderWidth');
      var columns = labels.length || 0;
      (false && !(`invalid colums, ${columns}`) && Ember.assert(columns === 0, 'invalid colums, ' + columns));

      var datasets = _.map(contents, function (data, index) {
        data.borderWidth = borderWidth[index];
        data.borderColor = borderColor[index];
        data.backgroundColor = backgroundColor[index];
        // data.categoryPercentage =1.0;
        // data.barPercentage =0.3;
        return data;
      });

      return {
        labels: labels,
        datasets: datasets
      };
    }),

    chartOptions: Ember.computed('options', function () {
      var options = this.get('options');
      var tooltips = options.tooltips || {};
      var legend = options.legend || {};
      return {
        tooltips: tooltips,
        legend: legend,
        scales: {
          xAxes: [{
            categoryPercentage: 1.0,
            barPercentage: 0.4
            // barThickness: 30,
            // maxBarThickness: 30,
            // minBarLength: 15,
            // gridLines: {
            //   offsetGridLines: true
            // }
          }]
        }

      };
    }),

    init: function init() {
      this._super.apply(this, arguments);
    }
  });
});
define('frodo/pods/index/making/making-model', ['exports', 'frodo/pods/index/base-model', 'frodo/models/enums'], function (exports, _baseModel, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PARENT = undefined;

  /**
   * Base Making Model
   */
  var PARENT = exports.PARENT = 'making';

  var BaseMakingModel = _baseModel.default.extend({
    rejected: Ember.computed('status', function () {
      return this.get('status') === _enums.DealingOrderStatus.REJECTED;
    })
  });

  BaseMakingModel.reopenClass({});

  exports.default = BaseMakingModel;
});
define('frodo/pods/index/pricing/quotation/route', ['exports', 'frodo/pods/index/pricing/pricing-route'], function (exports, _pricingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pricingRoute.default.extend({
    preload: true,
    //
    lpSelectAll: true,
    lpMultiSelect: true,
    lpWithAllOption: false,
    //
    bkSymbolSelectAll: true,
    bkSymbolMultiSelect: true,
    bkSymbolWithAllOption: false,
    //
    profitCurrencyWithAllOption: false,
    profitCurrencyOptionSwitchFirst: true,
    //
    ignoreResumeModel: true,
    scheduler: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.taskId = this.get('scheduler').add({
        action: controller.isStale.bind(controller),
        interval: 5000
      });
      controller.isStale();
      var sc = controller.get('subscription');
      controller.set('prev_subscription', sc);
    },
    resetController: function resetController(controller, isExisting) {
      this._super.apply(this, arguments);
      if (this.taskId) {
        this.get('scheduler').stop(this.taskId);
      }

      if (isExisting) {
        var sc = controller.get('subscription');
        controller.set('isSubscribed', false);
        controller.set('quotations', Ember.A());
        controller.send('unsubscribe', sc);
      }
    },
    beforePreload: function beforePreload(controller /* , model */) {
      if (!controller.get('isResumed')) {
        controller._reactLp();
        controller._reactProfitCurrency();
        controller._reactBkSymbol();
      }
    }
  });
});
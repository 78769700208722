define('frodo/pods/components/index/pricing/dealing-list/dealing-price-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'small',

    click: function click() {
      if (isNaN(this.get('value'))) {
        return;
      }
      this.set('model.orderPrice', this.get('value'));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    }
  });
});
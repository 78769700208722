define('frodo/pods/index/operator/operator-access/controller', ['exports', 'frodo/pods/index/operator/operator-access/validator', 'frodo/pods/index/operator/operator-access/model', 'frodo/pods/index/operator/operator-controller', 'frodo/mixins/paginatable'], function (exports, _validator, _model, _operatorController, _paginatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _operatorController.default.extend(_paginatable.default, {

    Validator: _validator.default,

    level: 0,
    operatorId: 0,
    clientType: 0,

    action: null,
    domain: null,

    doSearch: function doSearch(params) {
      var _this = this;

      var p = this.build(params);
      this.set('csvParams', Ember.assign({}, p));
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = r.data.total;
        var records = _.map(r.data.records, function (o) {
          return _model.default.create(o);
        });
        if (_this.hasRecords(r)) {
          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, p);
        return r;
      });
    },
    downloadUrl: function downloadUrl() {
      return (0, _model.csv)(this.get('csvParams'));
    },
    build: function build(p) {
      if (this.get('level')) p.level = this.get('level');
      if (this.get('domain')) p.domain = this.get('domain');
      if (this.get('action')) p.action = this.get('action');
      if (this.get('brokerId')) p.brokerId = this.get('brokerId');
      if (this.get('clientType')) p.clientType = this.get('clientType');
      if (this.get('operatorId')) p.operatorId = this.get('operatorId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('action', p.action);
      this.set('domain', p.domain);
      this.set('level', p.hasOwnProperty('level') ? p.level : 0);
      this.set('brokerId', p.hasOwnProperty('brokerId') ? p.brokerId : 0);
      this.set('clientType', p.hasOwnProperty('clientType') ? p.clientType : 0);
      this.set('operatorId', p.hasOwnProperty('operatorId') ? p.operatorId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
define('frodo/pods/index/market/market/controller', ['exports', 'frodo/pods/index/market/market/model', 'frodo/pods/index/market/market-controller'], function (exports, _model, _marketController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    actions: {
      reload: function reload() {
        var refresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        (0, _model.fetch)(this, refresh).then(this.parse.bind(this)).then(this.refresh.bind(this));
      },
      sessions: function sessions() {
        this.get('router').transitionTo('index.market.market_session');
      }
    },
    parse: function parse(r) {
      if (this.isData(r)) {
        var data = r.data;
        var interval = data.refreshInterval;
        this.set('refreshTime', data.refreshTime);
        var model = _model.default.create(data);
        this.set('model', model);
        this.updateNavtab(this.get('currentRouteName'), {}, {});
        if (interval) {
          this.set('refreshError', false);
          this.set('refreshInterval', interval);
        }
      } else {
        this.set('refreshError', true);
      }
    }
  });
});
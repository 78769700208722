define('frodo/pods/index/risk/trigger/innerValidator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateLpValues;
  function validateLpValues() {
    return function (key, newValue, oldValue, changes) {
      var arr = changes.lpIds === undefined ? [] : changes.lpIds;
      if (Ember.isEmpty(arr)) {
        if (Ember.isEmpty(newValue)) {
          return 'cp can not be empty .';
        }
      }
      return true;
    };
  }
});
define('frodo/pods/components/index/risk/modal-balance-alertor/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/risk/balance-alertor/model'], function (exports, _component, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var brokerId = this.get('changeset.brokerId');
      this.set("lpOptions", this.getLpOptions(brokerId));

      var lpId = this.get('changeset.lpId');
      if (!Ember.isEmpty(lpId)) {
        var lpAccounts = this.getLpAccountOptions(lpId, false);
        this.set("lpAccountOptions", lpAccounts);
      } else {
        this.set("lpAccountOptions", {});
      }
    },

    actions: {
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      }
    }
  });
});
define('frodo/pods/index/market/market-session/route', ['exports', 'frodo/pods/index/market/market-route'], function (exports, _marketRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketRoute.default.extend({
    preload: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('parentNode', { name: 'market', route: 'index.market.market' });
    },
    resetController: function resetController() /*controller, isExisting, transition*/{
      this._super.apply(this, arguments);
    }
  });
});
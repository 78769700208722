define('frodo/services/cookie', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    getCookie: function getCookie(name) {
      var match = document.cookie.match(new RegExp(name + '=([^;]+)'));
      if (match) {
        try {
          return decodeURIComponent(match[1]);
        } catch (err) {
          console.log(err);
        }
      }
      return null;
    },
    setCookie: function setCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var expires = options.expires,
          domain = options.domain,
          path = options.path;

      value = encodeURIComponent(String(value));

      var text = name + '=' + value;

      // expires
      var date = expires;
      if (typeof date === 'number') {
        date = new Date(expires);
      }
      if (date instanceof Date) {
        text += '; expires=' + date.toUTCString();
      }

      // domain
      if (domain) {
        text += '; domain=' + domain;
      }

      // path
      if (path) {
        text += '; path=' + path;
      }

      // secure
      if (options['secure']) {
        text += '; secure';
      }

      document.cookie = text;
      return text;
    },
    clear: function clear(name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      options['expires'] = new Date(0);
      return this.setCookie(name, '', options);
    }
  });
});
define('frodo/pods/index/report/holding-balance-turnover/controller', ['exports', 'frodo/pods/index/report/holding-balance-turnover/model', 'frodo/pods/index/report/report-controller', 'frodo/mixins/paginatable', 'frodo/models/enums'], function (exports, _model, _reportController, _paginatable, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportController.default.extend(_paginatable.default, {
    paginatable: false,
    lpType: _enums.LpType.LIQUIDITY_PROVIDER,
    doSearch: function doSearch() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var records = void 0;
        if (_this.isData(r)) {
          records = _.map(r.data, function (b) {
            return _model.default.create(b);
          });
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { records: records }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('to')) p.to = this.get('to');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('to', p.to);
      this.set('from', p.from);
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('records', model.records);
    }
  });
});
define('frodo/pods/index/report/report-model', ['exports', 'frodo/pods/index/base-model'], function (exports, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PARENT = undefined;
  var PARENT = exports.PARENT = 'report';

  var BaseReportModel = _baseModel.default.extend({});

  BaseReportModel.reopenClass({});

  exports.default = BaseReportModel;
});
define('frodo/pods/components/common/pop-over/component', ['exports', 'frodo/mixins/result'], function (exports, _result) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // eslint-disable-line no-unused-vars

  var INPUT = 'input';
  var DROP_DOWN = 'dropdown';
  exports.default = Ember.Component.extend(_result.default, {

    INPUT: INPUT,
    DROP_DOWN: DROP_DOWN,

    type: INPUT,

    i18n: Ember.inject.service(),
    notifier: Ember.inject.service(),

    field: null,
    options: null, // [{ name: 'ON', value: 1 }, { name: 'OFF', value: -1 }],
    onCancel: null,
    onSubmit: null,
    placement: 'top',
    classNames: ['table-popover'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var type = this.get('type');
      this.$('i').popover({
        html: true,
        sanitize: false,
        // container: 'table',
        fallbackPlacement: 'clockwise',
        placement: this.get('placement'),
        content: this.getContent.bind(this, type)
      });

      this.$('i').on('shown.bs.popover', function () {
        $('input').focus();
        $('.t_pop_cancel').click(this.cancel.bind(this));
        $('.t_pop_confirm').click(this.submit.bind(this));
      }.bind(this));

      // hide popover when click outside
      $('body').on('click', function (e) {
        $('[data-toggle=popover]').each(function () {
          // hide any open popovers when the anywhere else in the body is clicked
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
          }
        });
      });
    },
    cancel: function cancel() {
      this.$('i').popover('hide');
      var value = this._getValue();
      var cancelAction = this.get('onCancel');
      if (cancelAction) {
        cancelAction(value);
      }
    },
    submit: function submit() {
      var _this = this;

      var value = this._getValue();
      var i18n = this.get('i18n');
      var field = this.get('field');
      var name = this.get('notificationKey');
      var submitAction = this.get('onSubmit');
      if (submitAction) {
        submitAction(value, field).then(function (r) {
          if (_this.isSuccess(r)) {
            _this.$('i').popover('hide');
            var successAction = _this.get('onSuccess');
            if (successAction) {
              successAction();
            }
            _this.get('notifier').info(i18n.t('label.notification.modified', { name: name }));
          } else {
            _this.showMessage(i18n.t(_this.parse(r)));
          }
        });
      }
    },
    getContent: function getContent(type) {
      if (type === INPUT) {
        return this._getInputContent();
      } else {
        return this._getDropDownContent();
      }
    },
    _getInputContent: function _getInputContent() {
      var html = '<div class="table-popover-content">' + '<div id="pop_operation" class="operation">' + '<input type="text" class="operation-form-control">' + '<div id="pop_validation" class="field-invalid"></div>' + '</div>' + '<div class="buttons">' + '<button type="button" class="btn btn-sm btn-danger mx-1 t_pop_cancel">' + '<i class="fa fa-fw fa-times"></i>' + '</button>' + '<button type="button" class="btn btn-sm btn-success t_pop_confirm">' + '<i class="fa fa-fw fa-check"></i>' + '</button>' + '</div>' + '</div>';

      return html;
    },
    _getDropDownContent: function _getDropDownContent() {

      var options = this._getOptionHtml();
      var html = '<div class="table-popover-content">' + '<div id="pop_operation" class="operation">' + ('<select class="operation-form-control">' + options + '</select>') + '</div>' + '<div class="buttons">' + '<button type="button" class="btn btn-sm btn-danger mx-1 t_pop_cancel">' + '<i class="fa fa-fw fa-times"></i>' + '</button>' + '<button type="button" class="btn btn-sm btn-success t_pop_confirm">' + '<i class="fa fa-fw fa-check"></i>' + '</button>' + '</div>' + '</div>';

      return html;
    },
    _getOptionHtml: function _getOptionHtml() {
      var options = this.get('options');
      if (Ember.isEmpty(options)) {
        return '';
      }

      var html = '';
      options.forEach(function (op) {
        html += '<option value="' + op.value + '">' + op.name + '</option>';
      });

      return html;
    },
    _getValue: function _getValue() {
      var r = void 0;
      var type = this.get('type');
      if (type === INPUT) {
        r = $('input').val();
      } else {
        r = $('select').val();
      }

      return r;
    },
    parse: function parse(r) {
      (false && !(r && r.type === _result.VALIDATION) && Ember.assert('invalid validation format', r && r.type === _result.VALIDATION));


      var data = r.data;
      if (data) {
        var first = data[0];
        var field = this.get('field');
        return first[field].key;
      }
    },
    showMessage: function showMessage(msg) {
      var target = document.getElementById('pop_validation');
      target.innerHTML = msg;
      $('.operation-form-control').addClass('is-invalid');
    }
  });
});
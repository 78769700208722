define('frodo/pods/index/system/alert/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/system/system-model'], function (exports, _baseModel, _systemModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.appNames = exports.appIds = exports.reset = exports.fetch = undefined;
  exports.default = _systemModel.default.extend({});


  function reset(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/reset', { data: data });
  }

  function appIds(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/appIds', { data: data });
  }

  function appNames(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/appNames', { data: data });
  }

  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/alerts', { data: data });
  }

  exports.fetch = fetch;
  exports.reset = reset;
  exports.appIds = appIds;
  exports.appNames = appNames;
});
define('frodo/helpers/_fx', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getSpread = getSpread;
  exports.getMid = getMid;
  function getSpread(bidPrice, askPrice, quoteScale) {
    var pattern = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '0.[0]';

    if (isNaN(askPrice) || isNaN(bidPrice) || isNaN(quoteScale) || askPrice === null || bidPrice === null || quoteScale === null) {
      return;
    }
    var ask = askPrice * 1;
    var bid = bidPrice * 1;
    var scale = parseInt(quoteScale);
    var delta = ask - bid;
    var spread = delta * Math.pow(10, scale);
    return numeral(spread).format(pattern);
  }

  function getMid(bidPrice, askPrice, quoteScale) {
    if (isNaN(askPrice) || isNaN(bidPrice) || isNaN(quoteScale) || askPrice === null || bidPrice === null || quoteScale === null) {
      return;
    }
    var ask = askPrice * 1;
    var bid = bidPrice * 1;
    var scale = parseInt(quoteScale);
    var mid = (ask + bid) / 2;
    var multiple = Math.pow(10, scale);
    return Math.round(mid * multiple) / multiple;
  }
});
define('frodo/pods/index/system/debug/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/system/system-model'], function (exports, _baseModel, _systemModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.set = exports.fetch = exports.fetchLpSymbols = exports.fetchBkSymbols = exports.fetchItSymbols = undefined;
  exports.default = _systemModel.default.extend({});


  function fetchBkSymbols(owner, brokerId) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/debug/bkSymbol/' + brokerId);
  }

  function fetchLpSymbols(owner, lpId) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/debug/lpSymbol/' + lpId);
  }

  function fetchItSymbols(owner, itId) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/debug/itSymbol/' + itId);
  }

  function set(owner, type, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/debugs/' + type, { data: data });
  }

  function fetch(owner) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/debugs');
  }

  exports.fetchItSymbols = fetchItSymbols;
  exports.fetchBkSymbols = fetchBkSymbols;
  exports.fetchLpSymbols = fetchLpSymbols;
  exports.fetch = fetch;
  exports.set = set;
});
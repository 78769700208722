define('frodo/pods/components/common/multiple-select/display-selected/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // threshold: 2,
    selected: null,
    readonly: false,

    displayed: Ember.computed('selected.[]', 'options.[]', function () {
      var selected = Ember.A(this.get('selected'));
      var items = Ember.A(this.get('options'));
      var name = selected.getEach('name');
      // let threshold = this.get('threshold');
      if (name.length === 1) {
        return name;
        // } else if (name.length <= threshold) {
        //   return name.join(DELIMITER);
      } else {
        return selected.length === items.length ? 'ALL selected(' + name.length + ')' : selected.length + ' selected';
      }
    })
  });
});
define('frodo/pods/index/market/lp-session/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/market/market-model', 'frodo/models/enums'], function (exports, _baseModel, _marketModel, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.balance = exports.disconnect = exports.connect = exports.reset = exports.flush = exports.fetch = undefined;
  exports.default = _marketModel.default.extend({

    tradingConnected: Ember.computed('status', function () {
      return this.get('status') === _enums.DealingSessionStatus.CONNECTED;
    }),

    tradingConnectAble: Ember.computed('status', function () {
      return this.get('status') === _enums.DealingSessionStatus.PENDING || this.get('status') === _enums.DealingSessionStatus.DISCONNECTING;
    }),

    expandClass: Ember.computed('expand', function () {
      return this.get('expand') ? 'fa-minus-circle' : 'fa-plus-circle';
    })
  });


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lpSessions', { data: data });
  }

  function balance(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/holding_balance', { data: data });
  }

  function flush(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lpSession/flush', { data: data });
  }

  function reset(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lpSession/reset', { data: data });
  }

  function connect(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lpSession/connect', { data: data });
  }

  function disconnect(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lpSession/disconnect', { data: data });
  }

  exports.fetch = fetch;
  exports.flush = flush;
  exports.reset = reset;
  exports.connect = connect;
  exports.disconnect = disconnect;
  exports.balance = balance;
});
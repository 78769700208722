define('frodo/pods/components/common/debug-select/component', ['exports', 'frodo/pods/components/common/select-base/component', 'frodo/pods/index/system/applications/model', 'frodo/pods/index/operator/operator/model', 'frodo/pods/index/market/broker/model', 'frodo/pods/index/market/symbol/model', 'frodo/pods/index/system/debug/model'], function (exports, _component, _model, _model2, _model3, _model4, _model5) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    ajax: Ember.inject.service('ajax'),
    bootstrap: Ember.inject.service('bootstrap'),
    placeholder: 'Please select an app',
    loadingMessage: 'loading...',
    noMatchesMessage: 'No Result',
    searchPlaceholder: "Type to filter...",

    delay: 200,
    type: 'application',
    isManager: false,

    typeObserver: Ember.observer('type', function () {
      var type = this.get('type');
      this.set('value', null);
      this.set('promise', []);
      switch (type) {
        case 'application':
          this.set('placeholder', 'Please select an application');
          break;
        case 'broker':
          this.set('placeholder', 'Please select Broker');
          break;
        case 'operator':
          this.set('placeholder', 'Please select an Operator');
          break;
        case 'symbol':
          this.set('placeholder', 'Please select a Symbol');
          break;
        case 'bkSymbol':
          this.set('placeholder', 'Please select a BK Symbol');
          break;
        case 'lpSymbol':
          this.set('placeholder', 'Please select a LP Symbol');
          break;
        case 'itSymbol':
          this.set('placeholder', 'Please select a IT Symbol');
          break;
        default:
          this.set('placeholder', 'Please select something');
          break;
      }
    }),

    actions: {
      handleOpen: function handleOpen() {
        var promise = void 0,
            type = this.get('type');
        switch (type) {
          case 'application':
            promise = this.application();
            break;
          case 'operator':
            promise = this.operators();
            break;
          case 'broker':
            promise = this.brokers();
            break;
          case 'symbol':
            promise = this.symbols();
            break;
          case 'bkSymbol':
            promise = this.bkSymbols();
            break;
          case 'lpSymbol':
            promise = this.lpSymbols();
            break;
          case 'itSymbol':
            promise = this.itSymbols();
            break;
          default:
            throw new Error('invalid type');
        }
        this.set('promise', promise);
      },
      handleClose: function handleClose() {
        this.set('promise', []);
      },
      onchangeCustomer: function onchangeCustomer() {
        var id = this.get('value');
        this.set('value', undefined);
        var selectAction = this.get('onSelect');
        if (selectAction) {
          selectAction(id);
        }
      }
    },

    /**
     * BkSymbol
     */
    bkSymbols: function bkSymbols() {
      var _this = this;

      var bkSymbols = this.get('bootstrap').getSelection('broker.symbol');
      return new Promise(function (resolve) {
        setTimeout(function () {
          return resolve((0, _model5.fetchBkSymbols)(_this, _this.get('brokerId')).then(function (r) {
            var a = [];
            _.each(r.data, function (o) {
              var bkSymbol = _.find(bkSymbols, function (s) {
                return s.value === o.id;
              });
              if (bkSymbol) a.push({ name: bkSymbol.name, value: bkSymbol.value });
            });
            return a;
          }));
        }, _this.get('delay'));
      });
    },


    /**
     * LpSymbol
     */
    lpSymbols: function lpSymbols() {
      var _this2 = this;

      var lpSymbols = this.get('bootstrap').getSelection('broker.lp_symbol');
      return new Promise(function (resolve) {
        setTimeout(function () {
          return resolve((0, _model5.fetchLpSymbols)(_this2, _this2.get('lpId')).then(function (r) {
            var a = [];
            _.each(r.data, function (o) {
              var found = _.find(lpSymbols, function (s) {
                return s.value === o.id;
              });
              if (found) a.push({ name: found.name, value: found.value });
            });
            return a;
          }));
        }, _this2.get('delay'));
      });
    },

    /**
     * ItSymbol
     */
    itSymbols: function itSymbols() {
      var _this3 = this;

      var itSymbols = this.get('bootstrap').getSelection('broker.it_symbol');
      return new Promise(function (resolve) {
        setTimeout(function () {
          return resolve((0, _model5.fetchItSymbols)(_this3, _this3.get('itId')).then(function (r) {
            var a = [];
            _.each(r.data, function (o) {
              var itSymbol = _.find(itSymbols, function (s) {
                return s.value === o.id;
              });
              if (itSymbol) a.push({ name: itSymbol.name, value: itSymbol.value });
            });
            return a;
          }));
        }, _this3.get('delay'));
      });
    },


    /**
     * Symbol
     */
    symbols: function symbols() {
      var _this4 = this;

      return new Promise(function (resolve) {
        setTimeout(function () {
          return resolve((0, _model4.fetch)(_this4).then(function (r) {
            var a = [];
            _.each(r.data, function (o) {
              a.push({ name: o.name, value: o.id });
            });
            return _.sortBy(a, 'value');
          }));
        }, 200);
      });
    },


    /**
     * App
     */
    application: function application() {
      var _this5 = this;

      return new Promise(function (resolve) {
        setTimeout(function () {
          return resolve((0, _model.fetch)(_this5).then(function (r) {
            var a = [];
            _.each(r.data, function (o) {
              var app = o.appName.toLocaleUpperCase();
              a.push({ name: app, value: o.appId });
            });
            return _.sortBy(a, 'value');
          }));
        }, 200);
      });
    },


    /**
     * Broker
     */
    brokers: function brokers() {
      var _this6 = this;

      return new Promise(function (resolve) {
        setTimeout(function () {
          return resolve((0, _model3.fetch)(_this6).then(function (r) {
            var a = [];
            _.each(r.data, function (o) {
              a.push({ name: o.name, value: o.id });
            });
            return _.sortBy(a, 'value');
          }));
        }, 200);
      });
    },


    /**
     * operators
     */
    operators: function operators() {
      var _this7 = this;

      return new Promise(function (resolve) {
        var p = void 0;if (_this7.get('isManager')) {
          p = { brokerId: _this7.get('brokerId') };
        }
        setTimeout(function () {
          return resolve((0, _model2.fetch)(_this7, p).then(function (r) {
            var a = [];
            _.each(r.data, function (o) {
              a.push({ name: o.loginId, value: o.loginId });
            });
            return a;
          }));
        }, _this7.get('delay'));
      });
    }
  });
});
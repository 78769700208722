define('frodo/pods/index/risk/balance-alertor/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    brokerId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],
    lpId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],
    accountId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],
    currencyId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],
    minInterval: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],
    maxTimes: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })]
  };
});
define('frodo/pods/components/index/pricing/dealing-price-input/component', ['exports', 'frodo/pods/components/common/base-spinner/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['amount-input'],
    classNameBindings: ['spinner', 'spinner:input-group', 'fixUnit:input-group', 'error:is-invalid'],
    scale: 0,
    value: 0,
    spinner: false,
    enableKeyboard: true,

    step: Ember.computed('scale', function () {
      var s = this.get('scale');
      var p = parseFloat(1 / Math.pow(10, s));
      return p;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var originalValue = this.get('value');
      this.set('displayValue', originalValue);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var $input = this.$().find('input');
      this.initInputEvent($input);
      if (this.get('spinner')) {
        this.bindUpAction(this.$('.spin-up'));
        this.bindDownAction(this.$('.spin-down'));
      }
    },
    updateUnit: function updateUnit(unit) {
      var fixUnit = this.get('fixUnit');
      if (!fixUnit) {
        this.set('unit', unit);
      } else {
        var curr = this.get('unit');
        if (curr !== unit) Ember.debug('amount-input: dont change unit.[' + curr + '-' + unit + '] ');
      }
    },
    updateValue: function updateValue(real) {
      var value = Ember.isEmpty(real) ? null : real + '';
      this.set('value', value);
      this.updateDisplayValue(value);
    },
    updateDisplayValue: function updateDisplayValue(value) {
      if (Ember.isEmpty(value)) {
        this.set('displayValue', null);
        return;
      }
      var val = value;
      this.set('displayValue', val);
    },
    initInputEvent: function initInputEvent($input) {
      var _this = this;

      $input.on('focus', function () {
        _this.updateDisplayValue(_this.get('value'));
        setTimeout(function () {
          if ($input.val()) $input.select();
        });
      }).on('blur', function () {
        _this.updateDisplayValue(_this.get('value'));
      }).on('change keydown', function (e) {
        if (e.type === 'keydown' && e.keyCode !== 13) {
          return;
        }
        Ember.run.once(function () {
          var val = $input.val();
          _this.tryToUpdateValue(val);
        });
      });
    },
    tryToUpdateValue: function tryToUpdateValue(val) {
      if (isNaN(val) || Ember.isEmpty(val)) {
        return;
      }
      var real = parseFloat(val).toFixed(this.get('scale'));
      var final = parseFloat(real);
      this.updateValue(final);
    }
  });
});
define('frodo/pods/components/nav-tabs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'div',
    navtabs: Ember.inject.service(),
    classNames: ['nav-container'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('openTabs', this.get('navtabs.openTabs'));
    },

    isFull: Ember.computed('navtabs.notFull', function () {
      return !this.get('navtabs.notFull');
    }),

    isLastOne: Ember.computed('navtabs.isLastOne', function () {
      return this.get('navtabs.isLastOne');
    }),

    actions: {
      closeTab: function closeTab(tab) {
        this.get('navtabs').closeTab(tab);
      },
      tabClicked: function tabClicked(tab) {
        this.get('navtabs').switchToTab(tab);
      },
      closeOthers: function closeOthers(owner) {
        owner.get('navtabs').closeInactive();
      }
    }

  });
});
define('frodo/pods/index/market/broker/route', ['exports', 'frodo/pods/index/market/market-route'], function (exports, _marketRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketRoute.default.extend({
    preload: true,
    ignoreResumeModel: true,
    ignoreResumeParam: true,

    beforeModel: function beforeModel(transition) {
      this._super(transition);
    }
  });
});
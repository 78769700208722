define('frodo/services/storage', ['exports', 'frodo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    bootstrap: Ember.inject.service(),

    prefix: Ember.computed(function () {
      // let profile = this.get('bootstrap.profile');
      return _environment.default.modulePrefix ? _environment.default.modulePrefix.concat('-admin') : '';
    }),

    suffix: Ember.computed('bootstrap.profile', function () {
      var profile = this.get('bootstrap.profile');
      return profile ? profile.operatorId : 'anonymous';
    }),

    getKey: function getKey(key) {
      var prefix = this.get('prefix');
      var suffix = this.get('suffix');
      if (prefix) {
        return prefix + '.' + key + '.' + suffix;
      } else {
        return key + '.' + suffix;
      }
    },
    setItem: function setItem(k, value, ver) {
      var key = this.getKey(k);
      // let newValue = value;
      var Storage = localStorage;
      try {
        value = {
          version: ver,
          data: value
        };
        value = JSON.stringify(value);
        Storage.setItem(key, value);
        // this.trigger(k + 'Changed', newValue);
      } catch (err) {
        console.error(err);
      }
    },
    getItem: function getItem(k, ver) {
      var key = this.getKey(k);
      var Storage = localStorage;
      try {
        var value = Storage.getItem(key);
        if (value !== null) {
          value = JSON.parse(value);
          if (value.version === ver) {
            return value.data;
          } else {
            // version is old ,remove it
            setTimeout(function () {
              Storage.removeItem(key);
            });
          }
        }
      } catch (e) {
        Storage.removeItem(key);
        console.error(e);
      }
      return null;
    },


    removeItem: function removeItem(k) {
      var key = this.getKey(k);
      var Storage = localStorage;
      try {
        Storage.removeItem(key);
        this.trigger(k + 'Changed', null);
      } catch (e) {
        console.error(e);
      }
    }
  });
});
define('frodo/helpers/big-number', ['exports', 'frodo/models/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bigNumber = bigNumber;
  function bigNumber(params, hash) {
    var unit = void 0;
    var scale = 0;
    var v = params[0];
    var strict = true;
    var colorful = false;
    var stripTrailingZeros = false;
    var replacement = '-';
    var roundingMode = _enums.RoundingMode.ROUND_DOWN;
    var format = {
      // the primary grouping size of the integer part
      groupSize: 3,
      // the grouping size of the fraction part
      fractionGroupSize: 0,
      // the grouping separator of the integer part
      groupSeparator: ',',
      // the decimal separator
      decimalSeparator: '.',
      // the secondary grouping size of the integer part
      secondaryGroupSize: 0,
      // the grouping separator of the fraction part
      fractionGroupSeparator: ' '
    };
    BigNumber.config({ FORMAT: format, ROUNDING_MODE: roundingMode });

    if (hash && hash.stripTrailingZeros) {
      stripTrailingZeros = hash.stripTrailingZeros;
    }

    if (hash && hash.unit) {
      unit = hash.unit;
    }

    if (hash && hash.unit) {
      unit = hash.unit;
    }

    if (hash && hash.scale) {
      scale = hash.scale;
    }

    if (hash && hash.colorful) {
      colorful = hash.colorful;
    }

    if (hash && !hash.isGroupSeparator) {
      format.groupSize = 0;
      format.groupSeparator = '';
    }

    if (hash && hash.groupSeparator) {
      format.groupSeparator = hash.groupSeparator;
    }

    if (hash && (hash.roundingMode || hash.roundingMode == _enums.RoundingMode.ROUND_UP)) {
      roundingMode = hash.roundingMode;
    }

    if (hash && hash.strict === false) {
      strict = hash.strict;
    }
    if (hash && hash.displayZero === true) {
      if (!isNaN(v) && +v === 0) {
        if (stripTrailingZeros) return 0;else return new BigNumber(v).toFormat(scale);
      }
    }

    if (replacement && hash.replacement) {
      replacement = hash.replacement;
    }

    if (strict && (Ember.isNone(v) || isNaN(v) || +v === 0)) {
      return replacement;
    }

    var decorate = new BigNumber(v).toFormat(scale);

    if (stripTrailingZeros) {
      decorate = decorate.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
    }

    if (unit) {
      decorate += ' ' + unit;
    }

    if (colorful && v < 0) {
      decorate = Ember.String.htmlSafe('<span class="text-danger">' + decorate + '</span>');
    }

    return decorate;
  }

  exports.default = Ember.Helper.helper(bigNumber);
});
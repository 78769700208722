define('frodo/pods/components/common/summer-note/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    value: null,
    tagName: 'textarea',
    $summernote: null,
    height: Ember.computed('rows', function () {
      var rows = this.get("rows");
      return 20 * rows;
    }),

    didInsertElement: function didInsertElement() {
      var self = this;
      var value = this.get('value');
      var height = this.get('height');
      var maxHeight = this.get('maxHeight');
      var $summernote = this.$().summernote({
        height: height,
        maxHeight: maxHeight,
        toolbar: [['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']]],
        callbacks: {
          onChange: function onChange(contents /*, $editable*/) {
            self.set('value', contents);
          }
        }
      });

      if (value) {
        $summernote.summernote('code', value);
      }

      this.set('$summernote', $summernote);
    },
    willDestroyElement: function willDestroyElement() {
      var $sn = this.get('$summernote');
      if ($sn) {
        $sn.summernote('destroy');
      }
    },


    disableObserver: Ember.observer('disabled', function () {
      var $sn = this.get('$summernote');
      if ($sn) {
        $sn.summernote(this.get('disabled') ? 'disable' : 'enable');
      }
    })
  });
});
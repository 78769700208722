define('frodo/pods/index/report/holding-deposit-withdraw/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/report/report-model'], function (exports, _baseModel, _reportModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.edit = exports.add = exports.check = exports.fetch = undefined;
  exports.default = _reportModel.default.extend({});


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _reportModel.PARENT + '/holding_deposit_withdraw', { data: data });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _reportModel.PARENT + '/holding_deposit_withdraw/validate', { data: data });
  }

  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _reportModel.PARENT + '/holding_deposit_withdraw', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _reportModel.PARENT + '/holding_deposit_withdraw/' + data.id, { data: data });
  }
  exports.fetch = fetch;
  exports.check = check;
  exports.add = add;
  exports.edit = edit;
});
define('frodo/pods/components/common/deal-side-button-group/deal-button/component', ['exports', 'frodo/models/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tag: 'div',
    classNames: ['btn-group'],
    classNameBindings: ['customClass', 'isSelected:current-button'],
    attributeBindings: ['roleGroup:role', 'id:aria-controls'],

    id: Ember.computed('buttonId', function () {
      return this.get('buttonId');
    }),

    roleGroup: Ember.computed(function () {
      return 'group';
    }),

    customClass: Ember.computed('buttonId', function () {
      return 'dealing-side-button-' + this.get('buttonId');
    }),

    isSelected: Ember.computed('buttonId', 'value', function () {
      var btnId = this.get('buttonId');
      var val = this.get('value');
      if (val === _enums.Side.BUY) {
        if (btnId === 'buy') {
          return true;
        }
      } else if (val === _enums.Side.SELL) {
        if (btnId === 'sell') {
          return true;
        }
      }
      return false;
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      select: function select() {
        var $target = this.$();
        var side = $target.attr('aria-controls');
        this.set('value', side === 'buy' ? _enums.Side.BUY : _enums.Side.SELL);
      }
    }
  });
});
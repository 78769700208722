define("frodo/utils/numbers", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toInt = toInt;
  exports.lowShort = lowShort;
  exports.highShort = highShort;
  exports.lowInt = lowInt;
  exports.lowByte = lowByte;
  exports.highByte = highByte;
  exports.highInt = highInt;
  exports.toLong = toLong;
  exports.toShort = toShort;
  function toInt(v1, v2) {
    return v1 << 16 & 0xFFFF0000 | v2 & 0x0000FFFF;
  }

  function lowShort(v) {
    return v & 0xFFFF;
  }

  function highShort(v) {
    return v >>> 16 & 0xFFFF;
  }

  function lowInt(v) {
    return v & 0xFFFFFFFF;
  }

  function lowByte(v) {
    return v & 0xFF;
  }
  function highByte(v) {
    return v >>> 8 & 0xFF;
  }

  function highInt(v) {
    return v >>> 32 & 0xFFFFFFFF;
  }

  function toLong(v1, v2) {
    return v1 << 32 & 0xFFFFFFFF00000000 | v2 & 0x00000000FFFFFFFF;
  }

  function toShort(v1, v2) {
    return v1 << 8 & 0xFF00 | v2 & 0x00FF;
  }
});
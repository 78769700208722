define('frodo/controllers/login', ['exports', 'frodo/models/utility'], function (exports, _utility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    //
    errorMsg: null,
    isLogining: false,
    loginBtnText: 'Login',
    ajax: Ember.inject.service(),
    realtime: Ember.inject.service(),
    /**
     *
     */

    setMessage: function setMessage(t) {
      this.set('errorMsg', t);
    },

    actions: {
      login: function login() {
        var _this = this;

        var _getProperties = this.getProperties('loginId', 'password'),
            loginId = _getProperties.loginId,
            password = _getProperties.password;

        if (!loginId || !password) {
          this.set('errorMsg', 'error.password.incorrect');
          return;
        }
        this.set('isLogining', true);
        this.set('loginBtnText', 'Logging in...');
        this.get('ajax').setToken("");
        this.set('errorMsg', null);
        return (0, _utility.login)(this, { loginId: loginId, password: password }).then(function (r) {
          if (r.type === 'DATA') {
            _this.get('ajax').setToken(r.data);
            _this.transitionToRoute('index.index');
          } else {
            _this.set('errorMsg', r.key);
          }
        }, function () {
          alert('Server is not ready');
        }).finally(function () {
          _this.set('isLogining', false);
          _this.set('loginBtnText', 'Login');
        });
      },


      reset: function reset() {
        this.set('errorMsg', null);
      }

    }
  });
});
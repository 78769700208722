define('frodo/pods/index/risk/cp-position/route', ['exports', 'frodo/pods/index/risk/holding-route'], function (exports, _holdingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingRoute.default.extend({
    preload: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('lpId', 0);
    }
  });
});
define('frodo/pods/components/index/utility/modal-password/component', ['exports', 'frodo/pods/components/index/utility/modal-password/model', 'frodo/pods/components/index/utility/modal-password/validator', 'ember-changeset', 'ember-changeset-validations', 'frodo/pods/components/common/modal-base/component', 'frodo/models/utility'], function (exports, _model, _validator, _emberChangeset, _emberChangesetValidations, _component, _utility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var model = _model.default.create({});
      var changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_validator.default), _validator.default);

      this.set('model', model);
      this.set('changeset', changeset);
    },


    actions: {
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _utility.validate)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        return (0, _utility.changePw)(this, obj);
      },
      logout: function logout(r) {
        this.get('utility').logout(true).then(function () /*o*/{
          return new Promise(function (resolve) {
            resolve(r);
          });
        });
      },
      redirect: function redirect(r) {
        if (this.isSuccess(r)) {
          this.get('router').transitionTo('login');
        }
      }
    }

  });
});
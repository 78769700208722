define('frodo/pods/index/making/filter-config/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/making/making-model'], function (exports, _baseModel, _makingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.del = exports.edit = exports.check = exports.fetch = exports.add = undefined;
  exports.default = _makingModel.default.extend({});


  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _makingModel.PARENT + '/filterConfig', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/filterConfig/' + data.id, { data: data });
  }

  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/filterConfigs', { data: p });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/filterConfig/validate', { data: data });
  }

  function del(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + '/' + _makingModel.PARENT + '/filterConfig/' + data.id + '/' + data.version);
  }

  exports.add = add;
  exports.fetch = fetch;
  exports.check = check;
  exports.edit = edit;
  exports.del = del;
});
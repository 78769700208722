define('frodo/pods/index/popup/dealing-panel/controller', ['exports', 'frodo/pods/index/popup/popup-controller', 'frodo/models/enums'], function (exports, _popupController, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _popupController.default.extend({
    state: _enums.PanelState.OPEN,
    init: function init() {
      this._super.apply(this, arguments);
      this.resizeHeightHandler = this.resizeHeightHandler.bind(this);
      var paddingHeight = 30;
      var deltaHeight = paddingHeight;
      if (window.outerHeight - window.innerHeight < 120) {
        deltaHeight += window.outerHeight - window.innerHeight;
      }
      this.set('deltaHeight', deltaHeight);
      Ember.$('body').on('resizeHeight', this.resizeHeightHandler);
    },
    resizeHeightHandler: function resizeHeightHandler(e, height) {
      var deltaHeight = this.get('deltaHeight');
      var winHeight = height + deltaHeight;
      window.resizeTo(window.outerWidth, winHeight); // outerHeight
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember.$('body').off('resizeHeight', this.resizeHeightHandler);
    },


    actions: {}
  });
});
define('frodo/pods/index/making/maintain/route', ['exports', 'frodo/pods/index/making/making-route'], function (exports, _makingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingRoute.default.extend({
    preload: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isShowModal', true);
      if (!controller.get('isResumed')) {
        controller.set('lpId', 0);
        controller.set('broker2Id', this.get('brokerId'));
      }
    },
    resetController: function resetController(controller, isExisting) {
      this._super.apply(this, arguments);
      if (isExisting) {
        controller.set('isShowModal', false);
        controller.set('lpId', 0);
        controller.set('broker2Id', undefined);
      }
    }
  });
});
define('frodo/pods/index/system/system-model', ['exports', 'frodo/pods/index/base-model'], function (exports, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PARENT = undefined;


  /**
   * Base System Model
   */

  var PARENT = exports.PARENT = 'system';

  var BaseOperatorModel = _baseModel.default.extend({});

  BaseOperatorModel.reopenClass({});

  exports.default = BaseOperatorModel;
});
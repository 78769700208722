define('frodo/pods/components/index/pricing/dealing-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'div',
    classNames: ['dealing-market-depth'],

    bidDepths: Ember.computed('depths', function () {
      var size = this.get('depths.bidDepth');
      if (!size) {
        return Ember.A();
      }
      return this.get('depths.bids');
    }),

    askDepths: Ember.computed('depths', function () {
      var size = this.get('depths.askDepth');
      if (!size) {
        return Ember.A();
      }
      return this.get('depths.asks');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    }
  });
});
define('frodo/pods/index/operator/role/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/operator/operator-model'], function (exports, _baseModel, _operatorModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.grant = exports.privileges = exports.edit = exports.check = exports.add = exports.fetch = undefined;
  exports.default = _operatorModel.default.extend({});


  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/role', { data: data });
  }

  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/roles', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/role/' + data.id, { data: data });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/role/validate', { data: data });
  }

  function privileges(owner, o) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/role/' + o.id + '/privileges');
  }

  function grant(owner, o, privileges) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/role/' + o.id + '/privileges', { data: { privileges: privileges.toString() } });
  }

  exports.fetch = fetch;
  exports.add = add;
  exports.check = check;
  exports.edit = edit;
  exports.privileges = privileges;
  exports.grant = grant;
});
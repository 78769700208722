define('frodo/pods/index/operator/privilege/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    name: [(0, _validators.validatePresence)(true)],

    type: [(0, _validators.validatePresence)(true)],

    comment: [(0, _validators.validateLength)({ max: 128 })]

  };
});
define('frodo/routes/index', ['exports', 'frodo/utils/brands', 'frodo/config/environment', 'frodo/mixins/service-bus', 'frodo/models/enums'], function (exports, _brands, _environment, _serviceBus, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_serviceBus.default, {
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === 'index.index') {
        var path = _environment.default.APP.ROOT_ROUTER;
        if (path) {
          this.transitionTo(path);
        }
      }
    },
    model: function model() /*params, transition*/{
      return this.get('ajax').bootstrap();
    },
    afterModel: function afterModel(model) /*transition*/{
      if (model.level === 'ERROR') {
        this.get('utility').redirectToLogin();
      } else if (model && model.data) {
        var data = model.data;
        var profile = data.profile;
        if (profile && profile.passwordExpired) {
          this.get('utility').redirectToExpired();
        } else {
          var bs = this.get('bootstrap');
          bs.boot(data);
        }
      }
    },
    setupController: function setupController(controller) /*model*/{
      this._super.apply(this, arguments);
      //set Brand
      (0, _brands.changeBrand)(document.domain);
      //
      var bs = this.get('bootstrap');
      controller.set('menus', bs.get('menu'));
      controller.set('profile', bs.get('profile'));
      controller.set('env', '' + bs.getEnvironment());
      this._initWebSocket(controller);
      //
      this.get('layoutService').set('pageHeight', window.innerHeight);
    },
    resetController: function resetController() /*controller, isExiting, transition*/{
      this._super.apply(this, arguments);
    },


    actions: {},

    _initWebSocket: function _initWebSocket(controller) {
      var _this = this;

      var realtime = this.get('realtime');
      realtime.connect().subscribe(_enums.WebSocketEvent.COMMON, {}).subscribe(_enums.WebSocketEvent.SESSION, {});

      realtime.on(_enums.WebSocketEvent.SESSION, function (r) {
        if (r === 'LOGOUT') {
          console.warn('realtime SESSION LOGOUT FIRED');
          _this.get('realtime').unsubscribe(_enums.WebSocketEvent.COMMON, {}).unsubscribe(_enums.WebSocketEvent.SESSION, {});
          controller.send('logout', true);
        }
      });

      realtime.on('error', function (r) {
        console.error('realtime error');
        console.error(r);
      });

      realtime.on('reopen', function () {
        realtime.set('resumed', false);
        var subscribed = realtime.get('subscribed');
        subscribed.forEach(function (s) /*i*/{
          if (realtime.get('isDev')) console.log('connection reopen, resend subscribe, id:' + s.id + ' action:' + s.action + ' event:' + s.event);
          realtime.send(s, true);
        });
      });
    }
  });
});
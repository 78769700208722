define('frodo/pods/index/market/lp/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/market/market-model'], function (exports, _baseModel, _marketModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.institution = exports.remove = exports.symbols = exports.edit = exports.check = exports.fetch = exports.add = undefined;
  exports.default = _marketModel.default.extend({});


  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp', { data: data });
  }

  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lps', { data: p });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/validate', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/' + data.id, { data: data });
  }

  function symbols(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/' + data.id + '/symbols');
  }

  function remove(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + '/' + _marketModel.PARENT + '/lp/' + data.id + '/' + data.version);
  }

  function institution(owner, itId) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/institution/' + itId);
  }

  exports.add = add;
  exports.fetch = fetch;
  exports.check = check;
  exports.edit = edit;
  exports.symbols = symbols;
  exports.remove = remove;
  exports.institution = institution;
});
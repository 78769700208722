define('frodo/pods/components/index/operator/modal-operator-role/component', ['exports', 'frodo/pods/components/index/operator/modal-operator-role/model', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/operator/operator/model'], function (exports, _model, _component, _model2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({

    options: Ember.computed('roles', function () {
      var r = Ember.A();
      _.each(this.get('roles'), function (role) {
        var obj = _model.default.create(role);
        obj.set('value', role.id);
        r.push(obj);
      });
      return r;
    }),

    selectedElements: Ember.computed('options', function () {
      return _.filter(this.get('options'), function (option) {
        return option.get('checked');
      });
    }),

    selectedValues: Ember.computed('selectedElements', function () {
      return _.map(this.get('selectedElements'), function (role) {
        return role.get('value');
      });
    }),
    isDisabled: Ember.computed('readonly', 'isEdit', 'preview', 'editDisable', function () {
      if (this.get('readonly') || this.get('preview')) return true;
      return this.get('isEdit') && this.get('editDisable');
    }),

    actions: {
      selectRole: function selectRole(r) {
        var role = _.find(this.get('options'), { id: r.get('value') });
        Ember.run.later(this, function () {
          // role.set('checked',!role.get('checked'));
          role.toggleProperty('checked');
        }, 50);
      },
      check: function check() {
        return { "args": [], "key": "success", "level": "INFO", "type": "SUCCESS" };
      },
      submit: function submit() {
        var model = this.get('model');
        model.set('roles', this.get('selectedValues'));
        return (0, _model2.grant)(this, model);
      },
      dispose: function dispose() {
        this.set('model', undefined);
        this.set('roles', undefined);
      }
    }
  });
});
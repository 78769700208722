define('frodo/pods/index/base-controller', ['exports', 'frodo/pods/index/base-model', 'frodo/mixins/result', 'frodo/mixins/service-bus', 'frodo/mixins/refreshable', 'frodo/utils/common-generator', 'frodo/models/enums', 'frodo/helpers/big-number'], function (exports, _baseModel, _result, _serviceBus, _refreshable, _commonGenerator, _enums, _bigNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_result.default, _serviceBus.default, _refreshable.default, {
    // please use bs default color class
    enumColors: Ember.computed(function () {
      return {
        1: 'text-success',
        2: 'text-warning',
        9: 'text-danger'
      };
    }),

    emptyOptions: Ember.computed(function () {
      return [{ value: 0, name: 'ALL' }];
    }),

    imageServer: Ember.computed('bootstrap', function () {
      return this.get('bootstrap').getImageServer();
    }),

    itOptions: Ember.computed('bootstrap.selection', function () {
      var r = this.get('bootstrap').getSelection('broker.institution');
      var itWithAllOption = this.get('itWithAllOption');
      return itWithAllOption ? this.get('emptyOptions').concat(r) : r;
    }),
    authorisedBrokerId: Ember.computed('brokerId', 'isManager', 'bootstrap.brokerId', function () {
      if (this.get('isManager')) {
        return this.get('bootstrap.brokerId');
      }
      return this.get('brokerId');
    }),
    lpOptions: Ember.computed('brokerId', 'lpType', 'lpWithAllOption', function () {
      var selectedBroker = this.get('brokerId');
      var lpWithAllOption = this.get('lpWithAllOption');
      var lpType = this.get('lpType');
      var r = lpType ? this.get('reaction').reactionBrokerChange(selectedBroker, 0, 0, { lpWithAllOption: lpWithAllOption, lpType: lpType }).lpOptions : this.get('reaction').reactionBrokerChange(selectedBroker, 0, 0, { lpWithAllOption: lpWithAllOption }).lpOptions;
      return r;
    }),
    symbolOptions: Ember.computed('brokerId', 'lpId', 'symbolWithAllOption', function () {
      var selectedLp = this.get('lpId') > 0 ? this.get('lpId') : 0;
      var symbolWithAllOption = this.get('symbolWithAllOption');
      var selectedBroker = this.get('brokerId');
      var r = this.get('reaction').reactionBrokerChange(selectedBroker, selectedLp, 0, { symbolWithAllOption: symbolWithAllOption }).symbolOptions;
      return r;
    }),
    bkSymbolOptions: Ember.computed('brokerId', 'lpId', 'profitCurrencyId', 'bkSymbolWithAllOption', function () {
      var selectedBroker = this.get('brokerId');
      var selectedLp = this.get('lpId') > 0 ? this.get('lpId') : 0;
      var selectedProfitCurrencyId = this.get('profitCurrencyId');
      var bkSymbolWithAllOption = this.get('bkSymbolWithAllOption');
      var r = this.get('reaction').reactionBrokerChange(selectedBroker, selectedLp, 0, { bkSymbolWithAllOption: bkSymbolWithAllOption, profitCurrencyId: selectedProfitCurrencyId }).bkSymbolOptions;
      return r;
    }),
    bookOptions: Ember.computed('brokerId', 'bookWithAllOption', function () {
      var selectedBroker = this.get('brokerId');
      var bookWithAllOption = this.get('bookWithAllOption');
      var r = this.get('reaction').reactionBrokerChange(selectedBroker, 0, 0, { bookWithAllOption: bookWithAllOption }).bookOptions;
      return r;
    }),
    currencyOptions: Ember.computed('brokerId', 'lpId', 'currencyWithAllOption', function () {
      var selectedBroker = this.get('brokerId');
      var selectedLp = this.get('lpId') > 0 ? this.get('lpId') : 0;
      var currencyWithAllOption = this.get('currencyWithAllOption');
      var r = this.get('reaction').reactionBrokerChange(selectedBroker, selectedLp, 0, { currencyWithAllOption: currencyWithAllOption }).currencyOptions;
      return r;
    }),
    profitCurrencyOptions: Ember.computed('profitCurrencyWithAllOption', function () {
      var profitCurrencyWithAllOption = this.get('profitCurrencyWithAllOption');
      var r = this.get('reaction').reactionBrokerChange(0, 0, 0, { profitCurrencyWithAllOption: profitCurrencyWithAllOption }).profitCurrencyOptions;
      return r;
    }),

    refresh: function refresh() {
      var interval = this.get('refreshInterval');
      if (interval) {
        if (this.get('taskId')) {
          Ember.run.cancel(this.get('taskId'));
        }
        this.set('taskId', Ember.run.debounce(this, this._refresh, interval));
      }
    },
    _refresh: function _refresh() /*sender, key, value, rev*/{
      this.send('reload', true);
    },
    onSelectBroker: function onSelectBroker(brokerId) {
      var isMultipleSelect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var isSelectAll = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var isAllLpType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var lpType = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _enums.LpType.LIQUIDITY_PROVIDER;

      var lpsOption = _.filter(this.bootstrap.getSelection('broker.lp'), function (o) {
        return brokerId == o.extra.$group && (isAllLpType || lpType == o.extra.$group2);
      });
      var bkSymbolsOption = _.filter(this.bootstrap.getSelection('broker.symbol'), function (o) {
        return brokerId == o.extra.$group;
      });
      var booksOption = _.filter(this.get('bootstrap').getSelection('broker.book'), function (o) {
        return brokerId == o.extra.$group;
      });

      this.set('lps', isSelectAll ? this.get('emptyOptions').concat(lpsOption) : lpsOption);
      this.set('symbols', isSelectAll ? this.get('emptyOptions').concat(bkSymbolsOption) : bkSymbolsOption);
      this.set('books', isSelectAll ? this.get('emptyOptions').concat(booksOption) : booksOption);

      if (isMultipleSelect) {
        this.set('lpValues', this.multipleSelectValues(lpsOption));
        this.set('symbolValues', this.multipleSelectValues(bkSymbolsOption));
        this.set('booksValues', this.multipleSelectValues(booksOption));
      } else {
        this.set('lpId', this.selectValues(lpsOption, isSelectAll));
        this.set('bkSymbolId', this.selectValues(bkSymbolsOption, isSelectAll));
        this.set('bookId', this.selectValues(booksOption, isSelectAll));
      }
    },
    multipleSelectValues: function multipleSelectValues(v) {
      var r = Ember.A();
      _.each(v, function (s) {
        r.push(s.value);
      });
      return r;
    },
    selectValues: function selectValues(v) {
      var isSelectAll = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (isSelectAll) return 0;else if (!isSelectAll && !_.isEmpty(v)) {
        return v[0].value;
      } else return 0;
    },
    getBkSymbol: function getBkSymbol(symbols, brokerId, symbolId) {
      (false && !(typeof brokerId === 'number') && Ember.assert("brokerId must be number", typeof brokerId === 'number'));
      (false && !(typeof symbolId === 'number') && Ember.assert("symbolId must be number", typeof symbolId === 'number'));

      return Ember.isEmpty(symbols) ? undefined : symbols.find(function (s) {
        return s.value === (0, _commonGenerator.toBksymbolId)(brokerId, symbolId);
      });
    },
    getLpSymbol: function getLpSymbol(symbols, lpId, symbolId) {
      (false && !(typeof lpId === 'number') && Ember.assert("lpId must be number", typeof lpId === 'number'));
      (false && !(typeof symbolId === 'number') && Ember.assert("symbolId must be number", typeof symbolId === 'number'));

      return Ember.isEmpty(symbols) ? undefined : symbols.find(function (s) {
        return s.value === (0, _commonGenerator.toLpsymbolId)(lpId, symbolId);
      });
    },
    getLpSymbolById: function getLpSymbolById(symbols, lpSymbolId) {
      (false && !(typeof lpSymbolId === 'number') && Ember.assert("lpSymbolId must be number", typeof lpSymbolId === 'number'));

      return Ember.isEmpty(symbols) ? undefined : symbols.find(function (s) {
        return s.value === lpSymbolId;
      });
    },
    getItSymbol: function getItSymbol(symbols, itId, symbolId) {
      (false && !(typeof itId === 'number') && Ember.assert("itId must be number", typeof itId === 'number'));
      (false && !(typeof symbolId === 'number') && Ember.assert("symbolId must be number", typeof symbolId === 'number'));

      return Ember.isEmpty(symbols) ? undefined : symbols.find(function (s) {
        return s.value === (0, _commonGenerator.toItSymbolId)(itId, symbolId);
      });
    },
    getItSymbolById: function getItSymbolById(symbols, itSymbolId) {
      return Ember.isEmpty(symbols) ? undefined : symbols.find(function (s) {
        return s.value === itSymbolId;
      });
    },
    formatBigNumber: function formatBigNumber(v, scale) {
      var isAsk = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (v == undefined || scale == undefined) return "";
      var value = [v];
      var param = { scale: scale };
      if (isAsk) param['roundingMode'] = _enums.RoundingMode.ROUND_UP;else param['roundingMode'] = _enums.RoundingMode.ROUND_DOWN;
      return (0, _bigNumber.bigNumber)(value, param);
    },
    getSymbolProfitCurrencyScale: function getSymbolProfitCurrencyScale(symbolId) {
      var symbol = this.get('bootstrap').getSelection('market.symbol', symbolId);
      if (symbol) {
        var currency = this.get('bootstrap').getSelection('market.currency', symbol.extra.profitCurrency);
        if (currency) {
          return currency.extra.scale;
        }
      }
      return undefined;
    },
    getCoSymbol: function getCoSymbol(symbols, companyId, symbolId) {
      (false && !(typeof symbolId === 'number') && Ember.assert("symbolId must be number", typeof symbolId === 'number'));
      (false && !(typeof companyId === 'number') && Ember.assert("companyId must be number", typeof companyId === 'number'));

      return Ember.isEmpty(symbols) ? undefined : symbols.find(function (s) {
        return s.value === (0, _commonGenerator.toBksymbolId)(companyId, symbolId);
      });
    },
    price: function price(v, scale) {
      var pattern = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '0';

      if (v === undefined || v === null) {
        return '-';
      }
      if (scale && scale > 0) {
        pattern += function (s) {
          var e = '.';
          for (var i = 0; i < s; i++) {
            e += '0';
          }
          return e;
        }(scale);
      }
      return numeral(v).format(pattern);
    },


    actions: {
      sortedItems: function sortedItems(items) /*draggedModel*/{
        this.set('model', items);
        return _.map(items, function (item) {
          return item.id;
        });
      },
      sortedItemsModel: function sortedItemsModel(groupModel, itemModels) /*draggedModel*/{
        var parentId = itemModels[0].parent;
        var items = this.get('menus');
        for (var i = 0; i < items.length; i++) {
          if (items[i].get('id') === parentId) items[i].set('subs', itemModels);
        }this.set('menus', items);
        return _.map(items, function (item) {
          return _.map(item.subs, function (sub) {
            return sub.id;
          });
        });
      },
      sortNotify: function sortNotify(r) {
        var i18n = this.get('i18n');
        var router = this.get('router');
        var flash = this.get('flashMessages');

        if (this.isSuccess(r)) {
          var name = router.get('currentRouteName');
          flash.info(i18n.t('label.notification.sorted', { name: i18n.t('menu.' + name) }));
        } else {
          flash.danger(i18n.t(r.key));
        }
      },
      brokerSelected: function brokerSelected(next, prev) {
        this._reactLp();this._reactSymbol();this._reactBkSymbol();this._reactBook();
        if (this.get('currencyReact')) this._reactCurrency();
        if (this.get('profitCurrencyReact')) this._reactProfitCurrency();
        var onSwitchBrokerAction = this.get('onSwitchBroker');
        if (onSwitchBrokerAction) {
          onSwitchBrokerAction(this, next, prev);
        }
        if (this.get('preload')) {
          this.send('reload');
        }
      },
      lpSelected: function lpSelected(next, prev) {
        this._reactSymbol();this._reactBkSymbol();
        if (this.get('currencyReact')) this._reactCurrency();
        if (this.get('profitCurrencyReact')) this._reactProfitCurrency();
        var onSwitchLpAction = this.get('onSwitchLp');
        if (onSwitchLpAction) {
          onSwitchLpAction(this, next, prev);
        }
        if (this.get('preload')) {
          this.send('reload');
        }
      },
      symbolSelected: function symbolSelected(next, prev) {
        var onSwitchSymbolAction = this.get('onSwitchSymbol');
        if (onSwitchSymbolAction) {
          onSwitchSymbolAction(this, next, prev);
        }
        if (this.get('preload')) {
          this.send('reload');
        }
      },
      profitCurrencySelected: function profitCurrencySelected(next, prev) {
        this._reactBkSymbol();
        var onSwitchProfitCurrencyAction = this.get('onSwitchProfitCurrency');
        if (onSwitchProfitCurrencyAction) {
          onSwitchProfitCurrencyAction(this, next, prev);
        }
        if (this.get('preload')) {
          this.send('reload');
        }
      },
      bkSymbolSelected: function bkSymbolSelected(next, prev) {
        var onSwitchBkSymbolAction = this.get('onSwitchBkSymbol');
        if (onSwitchBkSymbolAction) {
          onSwitchBkSymbolAction(this, next, prev);
        }
        if (this.get('preload')) {
          this.send('reload');
        }
      },
      bookSelected: function bookSelected(next, prev) {
        var onSwitchBookAction = this.get('onSwitchBook');
        if (onSwitchBookAction) {
          onSwitchBookAction(this, next, prev);
        }
        if (this.get('preload')) {
          this.send('reload');
        }
      },
      closeModal: function closeModal() {
        this.set('records', Ember.A());
      },
      fillModal: function fillModal() {
        var o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _baseModel.default.create({});

        this.set('record', o);
      }
    },

    _reactLp: function _reactLp() {
      if (this.get('lpMultiSelect') && this.get('lpSelectAll')) {
        this._reactMultiLp();
      } else {
        this._reactSingleLp();
      }
    },
    _reactSingleLp: function _reactSingleLp() {
      var lpId = this.get('lpId');
      var options = this.get('lpOptions');
      var lp_exist = _.find(options, function (o) {
        o.value === lpId;
      });
      if (Ember.isEmpty(lp_exist)) {
        if (this.get('lpOptionSwitchFirst') && !Ember.isEmpty(options)) {
          this.set('lpId', options[0].value);
        } else {
          this.set('lpId', 0);
        }
      }
    },
    _reactMultiLp: function _reactMultiLp() {
      this.set('lpValues', this._getMulitiValue(this.get('lpOptions')));
    },
    _reactSymbol: function _reactSymbol() {
      if (this.get('symbolMultiSelect') && this.get('symbolSelectAll')) {
        this._reactMultiSymbol();
      } else {
        this._reactSingleSymbol();
      }
    },
    _reactSingleSymbol: function _reactSingleSymbol() {
      var symbolId = this.get('symbolId');
      var options = this.get('symbolOptions');
      var symbol_exist = _.find(options, function (o) {
        o.value === symbolId;
      });
      if (Ember.isEmpty(symbol_exist)) {
        if (this.get('symbolOptionSwitchFirst') && !Ember.isEmpty(options)) {
          this.set('symbolId', options[0].value);
        } else {
          this.set('symbolId', 0);
        }
      }
    },
    _reactMultiProfitCurrency: function _reactMultiProfitCurrency() {
      this.set('profitCurrencys', this._getMulitiValue(this.get('profitCurrencyOptions')));
    },
    _reactMultiCurrency: function _reactMultiCurrency() {
      this.set('currencys', this._getMulitiValue(this.get('currencyOptions')));
    },
    _reactMultiSymbol: function _reactMultiSymbol() {
      this.set('symbolValues', this._getMulitiValue(this.get('symbolOptions')));
    },
    _reactBkSymbol: function _reactBkSymbol() {
      if (this.get('bkSymbolMultiSelect') && this.get('bkSymbolSelectAll')) {
        this._reactMultiBkSymbol();
      } else {
        this._reactSingleBkSymbol();
      }
    },
    _reactSingleBkSymbol: function _reactSingleBkSymbol() {
      var bkSymbolId = this.get('bkSymbolId');
      var options = this.get('bkSymbolOptions');
      var bkSymbol_exist = _.find(options, function (o) {
        o.value === bkSymbolId;
      });
      if (Ember.isEmpty(bkSymbol_exist)) {
        if (this.get('bkSymbolOptionSwitchFirst') && !Ember.isEmpty(options)) {
          this.set('bkSymbolId', options[0].value);
        } else {
          this.set('bkSymbolId', 0);
        }
      }
    },
    _reactMultiBkSymbol: function _reactMultiBkSymbol() {
      this.set('bkSymbolValues', this._getMulitiValue(this.get('bkSymbolOptions')));
    },
    _reactCurrency: function _reactCurrency() {
      var cid = this.get('currencyId');
      var options = this.get('currencyOptions');
      var currency_exist = _.find(options, function (o) {
        o.value === cid;
      });
      if (Ember.isEmpty(currency_exist)) {
        if (this.get('currencyOptionSwitchFirst') && !Ember.isEmpty(options)) {
          this.set('currencyId', options[0].value);
        } else {
          this.set('currencyId', 0);
        }
      }
    },
    _reactProfitCurrency: function _reactProfitCurrency() {
      var cid = this.get('profitCurrencyId');
      var options = this.get('profitCurrencyOptions');
      var currency_exist = _.find(options, function (o) {
        o.value === cid;
      });

      if (Ember.isEmpty(currency_exist)) {
        if (this.get('profitCurrencyOptionSwitchFirst') && !Ember.isEmpty(options)) {
          this.set('profitCurrencyId', options[0].value);
        } else {
          this.set('profitCurrencyId', 0);
        }
      }
    },
    _reactBook: function _reactBook() {
      var bookId = this.get('bookId');
      var options = this.get('bookOptions');
      var book_exist = _.find(options, function (o) {
        o.value === bookId;
      });
      if (Ember.isEmpty(book_exist)) {
        if (Ember.isEmpty(options)) {
          this.set('bookId', 0);
        } else {
          this.set('bookId', options[0].value);
        }
      }
    },
    _getMulitiValue: function _getMulitiValue(options) {
      return Ember.isEmpty(options) ? [] : _.map(options, function (o) {
        return o.value;
      });
    },

    // tab
    updateNavtab: function updateNavtab(route, model, param) {
      this.get('navtabs').setTab(route, model, param);
    },
    baseResumeParam: function baseResumeParam(p) {
      if (this.resumeParam) {
        if (this.get('paginatable')) {
          this.resumePageableParam(p);
        }
        this.resumeParam(p);
      }
    },
    baseResumeModel: function baseResumeModel(m) {
      if (this.resumeModel) {
        this.resumeModel(m);
      }
    },
    copyProperties: function copyProperties(dest, source) {
      _.forIn(source, function (value, key) {
        if (dest.hasOwnProperty(key)) {
          dest.set(key, value);
        }
      });
    }
  });
});
define('frodo/services/layout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //
  var WINDOW_RESIZE = exports.WINDOW_RESIZE = 'WINDOW_RESIZE';
  var LEFT_MENU_SHOW = exports.LEFT_MENU_SHOW = 'LEFT_MENU_SHOW';
  var LEFT_MENU_HIDDEN = exports.LEFT_MENU_HIDDEN = 'LEFT_MENU_HIDDEN';
  var MODAL_SIZE_TOGGLE = exports.MODAL_SIZE_TOGGLE = 'MODAL_SIZE_TOGGLE';

  var EVENT_SEARCHING = exports.EVENT_SEARCHING = 'EVENT_SEARCHING';

  exports.default = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments);
      this.resizeHandler = this.resize.bind(this);
      window.addEventListener('resize', this.resizeHandler, false);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      window.removeEventListener('resize', this.resizeHandler, false);
    },
    resize: function resize() {
      this.trigger(WINDOW_RESIZE, this.getPanelBodySize());
      this.set('pageHeight', window.innerHeight); // for `responsive-table` component max-height calculation.
    },
    getPanelBodySize: function getPanelBodySize() {
      var leftOffset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 200;

      // class .panel-body
      // left menu
      var menuWrapper = document.querySelector('#menu-wrapper');
      if (menuWrapper) {
        leftOffset = menuWrapper.offsetWidth;
      }
      var width = window.innerWidth - leftOffset;
      var height = window.innerHeight;
      return { width: width, height: height };
    }
  });
});
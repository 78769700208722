define('frodo/pods/index/operator/operator-controller', ['exports', 'frodo/pods/index/base-controller'], function (exports, _baseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    statusStyles: Ember.computed(function () {
      return { 1: 'green', 5: 'yellow', 9: 'red' };
    })
  });
});
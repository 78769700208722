define('frodo/pods/index/making/target-price-config-revision/controller', ['exports', 'frodo/pods/index/making/target-price-config-revision/model', 'frodo/pods/index/making/making-controller', 'frodo/mixins/paginatable'], function (exports, _model, _makingController, _paginatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingController.default.extend(_paginatable.default, {
    doSearch: function doSearch(params) {
      var _this = this;

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            return _model.default.create(o);
          });
          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, p);
      });
    },
    build: function build(p) {
      if (this.get('brokerId')) p.brokerId = this.get('brokerId');
      if (this.get('bkSymbolId')) p.bkSymbolId = this.get('bkSymbolId');
      if (this.get('configId')) p.configId = this.get('configId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('bkSymbolId', p.bkSymbolId);
      this.set('configId', p.configId);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
define('frodo/pods/components/common/base-chart-line/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    chartType: 'line',
    init: function init() {
      this._super.apply(this, arguments);
      this.set('default_setting', {
        fill: [false],
        borderWidth: [1],
        pointRadius: [0],
        borderColor: ['rgba(11, 23, 70, 0.5)']
      });
      this.set('default_options', {});
    }
  });
});
define('frodo/pods/index/operator/operator-access/model', ['exports', 'frodo/pods/index/base-model', 'frodo/models/enums', 'frodo/pods/index/operator/operator-model'], function (exports, _baseModel, _enums, _operatorModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _operatorModel.default.extend({

    levelColor: Ember.computed('level', function () {
      var level = this.get('level');
      switch (level) {
        case _enums.AuditLevel.INFO:
          return Ember.String.htmlSafe('<i class="text-success fas fa-info-circle"></i>');
        case _enums.AuditLevel.WARN:
          return Ember.String.htmlSafe('<i class="text-warning fas fa-exclamation-circle"></i>');
        case _enums.AuditLevel.ERROR:
          return Ember.String.htmlSafe('<i class="text-danger fas fa-times-circle"></i>');
        default:
          break;
      }
    })

  });


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _operatorModel.PARENT + '/operator/access', { data: data });
  }

  exports.fetch = fetch;
});
define('frodo/pods/components/common/page-size/component', ['exports', 'frodo/pods/components/common/select-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    small: true,
    pageNo: 1,

    init: function init() {
      this._super.apply(this, arguments);
      this.options = [{ value: 25, name: 25 }, { value: 50, name: 50 }, { value: 100, name: 100 }];
    },


    actions: {
      onchange: function onchange(option) {
        var pageSize = option ? option.value : undefined;
        this.set('pageNo', 1);
        this.set('value', pageSize);

        var selectAction = this.get('onSelect');
        if (selectAction) {
          selectAction();
        }
      }
    }
  });
});
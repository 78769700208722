define('frodo/pods/components/common/sortable-menu/component', ['exports', 'frodo/mixins/result'], function (exports, _result) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_result.default, {
    router: Ember.inject.service(),
    parent: null,
    reload: false,
    sortable: false,
    tagName: 'tbody',
    attributeBindings: ['parentName:data-parent-name'],

    parentName: Ember.computed('parent', function () {
      return this.parent.name;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._initSortSubs();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._toggleSortSubs();
    },
    didDestroyElement: function didDestroyElement() {
      this._destroySortSubs();
    },


    actions: {
      end: function end() /*evt*/{
        var _this = this;

        var sorted = [];
        this.$().parent('table').find('tbody>tr').each(function (i, m) {
          sorted.push(Ember.$(m).attr('data-id'));
        });

        // TODO: abstract
        var action = this.get('onDragEnd');
        if (action) {
          action(sorted).then(function (r) {
            var i18n = _this.get('i18n');
            var router = _this.get('router');
            var flash = _this.get('flashMessages');
            if (_this.isSuccess(r)) {
              var name = router.get('currentRouteName');
              flash.info(i18n.t('label.notification.sorted', { name: i18n.t('menu.' + name) }));
              if (_this.get('reload')) {
                Ember.run.later(function () {
                  window.location.reload();
                }, 500);
              }
            } else {
              flash.danger(i18n.t(r.key));
            }
          });
        }
      },
      edit: function edit(menu) {
        this.toggleProperty('isShowModal');

        var before = this.get('onBeforeModal');
        if (before) {
          before(menu);
        }

        var action = this.get('onEdit');
        if (action) {
          action(menu);
        }
      },
      remove: function remove(menu) {
        var before = this.get('onBeforeModal');
        if (before) {
          before(menu);
        }

        var action = this.get('onRemove');
        if (action) {
          action(menu);
        }
      },
      detail: function detail(menu) {
        var action = this.get('onDetail');
        if (action) {
          action(menu);
        }
      }
    },

    _initSortSubs: function _initSortSubs() {
      var options = {
        // filter: '.parent',
        draggable: '.sub',
        ghostClass: "sort-ghost",
        disabled: !this.get('sortable'),
        onEnd: function (evt) {
          this.send('end', evt);
        }.bind(this)
      };

      this.set('stBody', Sortable.create(this.$()[0], options));
    },
    _toggleSortSubs: function _toggleSortSubs() {
      var stBody = this.get('stBody');
      var sortable = this.get('sortable');
      if (stBody) {
        stBody.option('disabled', !sortable);
      }
    },
    _destroySortSubs: function _destroySortSubs() {
      var stBody = this.get('stBody');
      if (stBody) {
        stBody.destroy();
      }
    }
  });
});
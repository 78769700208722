define('frodo/pods/components/common/field-enum/component', ['exports', 'frodo/pods/components/common/field-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    isShowFromLabel: true,
    filter: null,
    onSelect: null,
    emptyLabel: '',
    enumName: null,
    keepBlank: false,
    allowClear: false,
    emptyOption: false,
    searchEnabled: false,
    disabledOptions: null,

    hasHelp: false,
    helpContent: ""
  });
});
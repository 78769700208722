define('frodo/pods/components/common/field-password/component', ['exports', 'frodo/pods/components/common/field-input/component', 'frodo/models/utility'], function (exports, _component, _utility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({

    placeholder: '●●●●●●',
    levelDisplay: '',

    addon: false,
    isLocking: true,
    strengthLevel: 0,
    isLockDisabled: false,
    hasStrengthMeter: false,
    strengthMeterLabel: '',
    progressClass: 'progress-bar progress-bar-danger',

    progressBgColor: Ember.computed(function () {
      return Ember.String.htmlSafe('background-image: linear-gradient(to bottom, white 0%, white 100%)');
    }),

    progressPercentage: Ember.computed(function () {
      return Ember.String.htmlSafe('width: 0%');
    }),

    isInputDisabled: Ember.computed('isDisabled', 'isLocking', function () {
      return this.get('isDisabled') || this.get('isLocking');
    }),

    actions: {
      toggleLock: function toggleLock() {
        if (this.get('isLockDisabled') || this.get('isDisabled')) return;
        this.toggleProperty('isLocking');
        this.set('value', '');
      }
    },

    keyUp: function keyUp() /*event*/{
      var _this = this;

      if (this.get('hasStrengthMeter')) {
        var password = this.get('value');
        if (password === '') {
          this.set('strengthLevel', 0);
        }

        (0, _utility.evaluate)(this, { password: password }).then(function (r) {
          var level = r.data;
          _this.set('strengthLevel', level);
          _this._setPercentage(level, password);
        });
      }
    },
    _setPercentage: function _setPercentage(level, value) {
      if (Ember.isEmpty(value)) {
        this.set('level', Ember.String.htmlSafe('0%'));
        this.set('progressPercentage', Ember.String.htmlSafe('width: 0%'));
      }

      if (level >= 9) {
        this.set('levelDisplay', 'Strong');
        this.set('progressClass', 'progress-bar bg-success');
        this.set('progressPercentage', Ember.String.htmlSafe('width: 100%'));
      } else if (level >= 5) {
        this.set('levelDisplay', 'Medium');
        this.set('progressClass', 'progress-bar bg-warning');
        this.set('progressPercentage', Ember.String.htmlSafe('width: 60%'));
      } else {
        if (this.get('value') === '') {
          this.set('levelDisplay', '');
          this.set('progressPercentage', Ember.String.htmlSafe('width: 0%'));
        } else {
          this.set('levelDisplay', 'Weak');
          this.set('progressClass', 'progress-bar bg-danger');
          this.set('progressPercentage', Ember.String.htmlSafe('width: 30%'));
        }
      }
    }
  });
});
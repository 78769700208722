define('frodo/pods/index/market/lp-session/controller', ['exports', 'frodo/pods/index/market/market-controller', 'frodo/pods/index/market/lp-session/model'], function (exports, _marketController, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    sortable: true,
    statusColors: Ember.computed(function () {
      return { 1: 'text-muted', 2: 'text-success', 3: 'text-warning', 4: 'text-danger' };
    }),

    actions: {
      reload: function reload() {
        var _this = this;

        var p = { brokerId: this.get('brokerId') };
        (0, _model.fetch)(this, p).then(function (r) {
          if (_this.isData(r)) {
            var pre = _this.get('lps');
            var expanded = _.filter(pre, function (lp) {
              return lp.get('expand');
            });
            var arr = _.map(expanded, function (lp) {
              return lp.get('lpId');
            });
            var lpSessions = _.map(r.data.sessions, function (s) {
              if (arr.includes(s.lpId)) {
                s.expand = true;
              } else {
                s.expand = false;
              }
              return _model.default.create(s);
            });
            _this.set('lps', lpSessions);
          }
          //
          _this.updateNavtab(_this.get('currentRouteName'), {}, p);
          _this.set('refreshTime', r.data.refreshTime);
          _this.set('refreshInterval', r.data.refreshInterval);
          if (r.data.refreshInterval) {
            _this.refresh();
          }
        });
      },
      flush: function flush(brokerId) {
        return (0, _model.flush)(this, brokerId);
      },
      connect: function connect() {
        return (0, _model.connect)(this, { lpId: this.get('record.lpId') });
      },
      disconnect: function disconnect() {
        return (0, _model.disconnect)(this, { lpId: this.get('record.lpId') });
      },
      reset: function reset() {
        return (0, _model.reset)(this, { lpId: this.get('record.lpId') });
      }
    },
    onSwitchBroker: function onSwitchBroker(owner) {
      owner.set('groups', Ember.A());
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
    }
  });
});
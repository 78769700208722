define('frodo/pods/components/index/making/modal-dealing-making-statement/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/making/dealing-making-statement/model'], function (exports, _component, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    isTabbed: true,
    actions: {
      completedCheck: function completedCheck(err) {
        if (Ember.isEmpty(err)) {
          return new Ember.RSVP.Promise(function (resolve) {
            resolve();
          });
        } else {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            return reject({ error: err, local: true });
          });
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        return (0, _model.update)(this, {
          restate: obj.restate,
          lpSymbolId: obj.lpSymbolId,
          longThreshold: obj.longThreshold,
          shortThreshold: obj.shortThreshold
        });
      },
      checkResult: function checkResult(r) {

        if (this.isSuccess(r)) {
          return new Ember.RSVP.Promise(function (resolve) {
            resolve(r);
          });
        } else {
          if (r) {
            // server side validation
            var error = this.merge(r);
            var errorFields = Object.keys(error);
            this.set('error', error);
            this.set('errorFields', errorFields);
          }
          return new Ember.RSVP.Promise(function (resolve, reject) {
            reject(r);
          });
        }
      },
      afterSubmit: function afterSubmit(r) {
        if (this.isSuccess(r)) {
          return new Ember.RSVP.Promise(function (resolve) {
            resolve(r);
          });
        } else {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            reject(r);
          });
        }
      }
    }
  });
});
define('frodo/pods/components/index/market/modal-daylight-saving/model', ['exports', 'frodo/pods/index/market/market-model'], function (exports, _marketModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _marketModel.default.extend({});


  function fetch(owner) {
    return owner.get('ajax').request('/rest/market/session/daylight-savings', null, false);
  }

  exports.fetch = fetch;
});
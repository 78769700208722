define('frodo/pods/index/system/config/route', ['exports', 'frodo/pods/index/system/system-route'], function (exports, _systemRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemRoute.default.extend({
    setupController: function setupController(controller /*, model*/) {
      this._super.apply(this, arguments);
      controller.addObserver('domain', controller, controller.get('actions.domainChanged'));
    },
    resetController: function resetController(controller, isExisting /*, transition*/) {
      this._super.apply(this, arguments);
      if (isExisting) {
        controller.removeObserver('domain', controller, controller.get('actions.domainChanged'));
        controller.set('domain', 'COMMON');
      }
    }
  });
});
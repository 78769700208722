define('frodo/helpers/percent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.percent = percent;
  function percent(params, hash) {

    var v = params[0];
    if (v === 0) {
      return numeral(0).format('0.00%');
    }
    if (!v && v !== 0) return '-';

    var decimals = hash && hash.decimals ? hash.decimals : 0;
    var format = '0.';
    for (var i = 0; i < decimals; i++) {
      format += '0';
    }
    format += '%';
    return numeral(v).format(format);
  }

  exports.default = Ember.Helper.helper(percent);
});
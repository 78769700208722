define('frodo/pods/index/risk/balance-alertor/controller', ['exports', 'frodo/pods/index/risk/balance-alertor/validator', 'frodo/pods/index/risk/balance-alertor/model', 'frodo/pods/index/risk/holding-controller'], function (exports, _validator, _model, _holdingController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingController.default.extend({
    Validator: _validator.default,
    sortable: true,
    records: null,

    hasRecords: Ember.computed('model', function () {
      var settings = this.get('model');
      return settings && settings.length > 0;
    }),

    actions: {
      reload: function reload() {
        var _this = this;

        var param = this.build();
        (0, _model.fetch)(this, param).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (a) {
              return _model.default.create(a);
            });
          }
          _this.set('model', model);
          _this.updateNavtab(_this.get('currentRouteName'), model, param);
        });
      },
      remove: function remove(o) {
        return (0, _model.remove)(this, o);
      },
      updates: function updates(value, field) {
        var settings = this.get('model');
        var brokerId = this.get('brokerId');
        var modifications = settings.map(function (setting) {
          var r = { id: setting.id };
          r[field] = value;
          return r;
        });
        var p = { brokerId: brokerId, modifications: JSON.stringify(modifications) };
        return (0, _model.edits)(this, p);
      }
    },

    build: function build() {
      var p = {};
      if (this.get('brokerId')) p.brokerId = this.get('brokerId');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.lpId);
    },
    resumeModel: function resumeModel(model) {
      this.set('model', model);
    }
  });
});
define('frodo/pods/index/market/it-session/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/market/market-model', 'frodo/models/enums'], function (exports, _baseModel, _marketModel, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.disconnect = exports.connect = exports.reset = exports.fetch = undefined;
  exports.default = _marketModel.default.extend({

    pricingConnected: Ember.computed('status', function () {
      return this.get('status') === _enums.DealingSessionStatus.CONNECTED;
    }),

    pricingConnectAble: Ember.computed('status', function () {
      return this.get('status') === _enums.DealingSessionStatus.PENDING || this.get('status') === _enums.DealingSessionStatus.DISCONNECTING;
    })

  });


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/itSessions', { data: data });
  }

  function reset(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/itSession/reset', { data: data });
  }

  function connect(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/itSession/connect', { data: data });
  }

  function disconnect(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/itSession/disconnect', { data: data });
  }

  exports.fetch = fetch;
  exports.reset = reset;
  exports.connect = connect;
  exports.disconnect = disconnect;
});
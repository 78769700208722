define("frodo/pods/index/making/mass-execution-history/controller", ["exports", "frodo/pods/index/making/mass-execution-history/model", "frodo/pods/index/making/making-controller", "frodo/mixins/paginatable", "frodo/models/enums"], function (exports, _model, _makingController, _paginatable, _enums) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingController.default.extend(_paginatable.default, {
    lpType: _enums.LpType.LIQUIDITY_DEMANDER,
    actions: {
      execution: function execution(o) {
        var _this = this;

        return (0, _model.findExecution)(this, { orderId: o.id }).then(function (r) {
          if (_this.isData(r)) {
            o.set('executions', r.data);
            o.toggleProperty('collapseShow');
          }
          return r;
        });
      }
    },
    doSearch: function doSearch(params) {
      var _this2 = this;

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = void 0;
        var records = void 0;
        if (_this2.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            return _model.default.create(o);
          });
          _this2.set('total', total);
          _this2.set('records', records);
        }
        _this2.updateNavtab(_this2.get('currentRouteName'), { total: total, records: records }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('symbolId')) p.symbolId = this.get('symbolId');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('to')) p.to = this.get('to');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
      this.set('symbolId', p.hasOwnProperty('symbolId') ? p.symbolId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
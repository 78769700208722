define('frodo/pods/components/index/risk/modal-router/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/risk/router/model'], function (exports, _component, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    isTabbed: true,

    symbolOptions: Ember.computed('changeset.change.brokerId', function () {
      var selectedBroker = this.get('changeset.change.brokerId') || this.get('changeset.brokerId');
      var r = this.get('reaction').reactionBrokerChange(selectedBroker, 0, 0, { symbolWithAllOption: false }).symbolOptions;
      return r;
    }),
    allocatorOptions: Ember.computed('changeset.change.brokerId}', function () {
      var selectedBroker = this.get('changeset.change.brokerId') || this.get('changeset.brokerId');
      var r = this.get('reaction').reactionBrokerChange(selectedBroker, 0, 0, { allocatorWithAllOption: false }).allocatorOptions;
      return r;
    }),

    actions: {
      selectAllocators: function selectAllocators(selected) {
        var arr = _.map(selected, function (s) {
          return s.value;
        });
        this.set('changeset.allocators', arr);
      },
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          var c = this.copy(model, changeset);
          c.allocators = JSON.stringify(c.allocators);
          return (0, _model.check)(this, c);
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        obj.allocators = JSON.stringify(obj.allocators);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      },
      resetAllocators: function resetAllocators() {
        this.set('changeset.allocators', []);
      }
    }
  });
});
define('frodo/pods/index/market/market/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/market/market-model'], function (exports, _baseModel, _marketModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _marketModel.default.extend({});


  function fetch(owner) {
    var refresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/' + (refresh ? 'market/refresh' : 'market'));
  }

  exports.fetch = fetch;
});
define('frodo/helpers/array-truth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayTruth = arrayTruth;
  function arrayTruth(params, hash) {
    var array = params[0];
    if (!Ember.isArray(array)) return;
    var search = hash.search;
    if (_.find(array, function (o) {
      return o === search;
    })) {
      return true;
    }
    return false;
  }

  exports.default = Ember.Helper.helper(arrayTruth);
});
define('frodo/pods/index/trades/cp-order/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/trades/trades-model'], function (exports, _baseModel, _tradesModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _tradesModel.default.extend({});


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/cp_order', { data: data });
  }

  exports.fetch = fetch;
});
define('frodo/pods/components/common/multiple-select/first-option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchEnable: false,
    searchKey: '',
    value: null,
    options: null,
    readonly: false,
    label: 'Select ALL',
    onSelectAll: null,
    classNames: ['ember-power-select-option'],
    attributeBindings: ['allSelected:aria-selected'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.doSearch();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },


    allSelected: Ember.computed('isCheckAll', function () {
      return this.get('isCheckAll') ? 'true' : 'false';
    }),

    search: Ember.observer('searchKey', function () {
      this.doSearch();
    }),

    doSearch: function doSearch() {
      $('.ember-power-select-search-input').focus();
      var select = this.get('select');
      var search = select.actions.search;
      if (search) search(this.get('searchKey'));
    },


    isCheckAll: Ember.computed('value.[]', 'options.[]', function () {
      var value = Ember.A(this.get('value'));
      var options = Ember.A(this.get('options'));
      var valid = options.filter(function (o) {
        return !o.disabled;
      });
      return !Ember.isEmpty(options) && Ember.isEmpty(_.xor(valid.getEach('value'), value));
    }),
    click: function click(e) {
      var isClickAction = $(e.target).closest('[data-role="click"]').length > 0 || $(e.target)[0].type == "checkbox";
      if (!isClickAction) return;

      if (this.get('readonly')) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      var selected = [];
      var isCheckAll = this.get('isCheckAll');
      if (isCheckAll) {
        this.set('value', []);
      } else {
        var options = this.get('options');
        var valid = options.filter(function (o) {
          return !o.disabled;
        });
        this.set('value', valid.getEach('value'));
        selected = valid;
      }

      var selectAction = this.get('onSelectAll');
      if (selectAction) {
        selectAction(selected);
      }
    }
  });
});
define("frodo/utils/brands", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getTitle = getTitle;
  exports.getIcon = getIcon;
  exports.changeFavicon = changeFavicon;
  exports.changeTitle = changeTitle;
  exports.changeBrand = changeBrand;


  var default_title = "Nexus";
  var default_favicon = "assets/nexus/favicon.ico";

  var brands = {
    //PRO
    BRAND201: { DOMAIN: "ptrade.smartcrypto.cc", TITLE: "Nexus PD", ICON: "assets/nexus/favicon.ico" },
    BRAND202: { DOMAIN: "biki.smartcrypto.cc", TITLE: "Biki MM", ICON: "assets/nexus/favicon.ico" },
    BRAND203: { DOMAIN: "bikia.smartcrypto.cc", TITLE: "Biki MM", ICON: "assets/nexus/favicon.ico" },
    BRAND204: { DOMAIN: "bikis.smartcrypto.cc", TITLE: "Biki MM", ICON: "assets/nexus/favicon.ico" },
    BRAND205: { DOMAIN: "btse.smartcrypto.cc", TITLE: "Nexus BTSE", ICON: "assets/nexus/favicon.ico" },
    BRAND208: { DOMAIN: "coinmark.smartcrypto.cc", TITLE: "Nexus CoinMark", ICON: "assets/nexus/favicon.ico" },
    BRAND209: { DOMAIN: "bpm.smartcrypto.cc", TITLE: "Nexus BPM", ICON: "assets/nexus/favicon.ico" },

    BRAND300: { DOMAIN: "atrade.go-nexus.io", TITLE: "Nexus Abi", ICON: "assets/nexus/favicon.ico" },
    BRAND301: { DOMAIN: "ptrade.go-nexus.io", TITLE: "Nexus PD", ICON: "assets/nexus/favicon.ico" },
    BRAND302: { DOMAIN: "mmtrade.go-nexus.io", TITLE: "Nexus MM", ICON: "assets/nexus/favicon.ico" },
    BRAND303: { DOMAIN: "btse.go-nexus.io", TITLE: "Nexus BTSE", ICON: "assets/nexus/favicon.ico" },
    BRAND304: { DOMAIN: "kryptox.go-nexus.io", TITLE: "Nexus Kryptox", ICON: "assets/nexus/favicon.ico" },
    BRAND305: { DOMAIN: "hotcoin.go-nexus.io", TITLE: "Nexus Hotcoin", ICON: "assets/nexus/favicon.ico" },
    BRAND306: { DOMAIN: "bpm.go-nexus.io", TITLE: "Nexus BPM", ICON: "assets/nexus/favicon.ico" },

    //UAT
    BRAND100: { DOMAIN: "demo.go-nexus.io", TITLE: "NEXUS DEMO", ICON: "assets/nexus/favicon.ico" },
    BRAND101: { DOMAIN: "uat.smartcrypto.cc", TITLE: "uat-btrade", ICON: "assets/uat/favicon.ico" },
    //DEV
    BRAND500: { DOMAIN: "frodo.nextop.cn", TITLE: "dev-btrade", ICON: "assets/dev/favicon.ico" }
  };

  function getTitle(domain) {
    var r = "";
    if (domain === brands.BRAND201.DOMAIN) r = brands.BRAND201.TITLE;else if (domain === brands.BRAND202.DOMAIN) r = brands.BRAND202.TITLE;else if (domain === brands.BRAND203.DOMAIN) r = brands.BRAND203.TITLE;else if (domain === brands.BRAND204.DOMAIN) r = brands.BRAND204.TITLE;else if (domain === brands.BRAND205.DOMAIN) r = brands.BRAND205.TITLE;else if (domain === brands.BRAND208.DOMAIN) r = brands.BRAND208.TITLE;else if (domain === brands.BRAND209.DOMAIN) r = brands.BRAND209.TITLE;else if (domain === brands.BRAND300.DOMAIN) r = brands.BRAND300.TITLE;else if (domain === brands.BRAND301.DOMAIN) r = brands.BRAND301.TITLE;else if (domain === brands.BRAND302.DOMAIN) r = brands.BRAND302.TITLE;else if (domain === brands.BRAND303.DOMAIN) r = brands.BRAND303.TITLE;else if (domain === brands.BRAND304.DOMAIN) r = brands.BRAND304.TITLE;else if (domain === brands.BRAND305.DOMAIN) r = brands.BRAND305.TITLE;else if (domain === brands.BRAND306.DOMAIN) r = brands.BRAND306.TITLE;else if (domain === brands.BRAND100.DOMAIN) r = brands.BRAND100.TITLE;else if (domain === brands.BRAND101.DOMAIN) r = brands.BRAND101.TITLE;else if (domain === brands.BRAND500.DOMAIN) r = brands.BRAND500.TITLE;
    return r;
  }

  function getIcon(domain) {
    var r = "";
    if (domain === brands.BRAND201.DOMAIN) r = brands.BRAND201.ICON;else if (domain === brands.BRAND202.DOMAIN) r = brands.BRAND202.ICON;else if (domain === brands.BRAND203.DOMAIN) r = brands.BRAND203.ICON;else if (domain === brands.BRAND204.DOMAIN) r = brands.BRAND204.ICON;else if (domain === brands.BRAND205.DOMAIN) r = brands.BRAND205.ICON;else if (domain === brands.BRAND208.DOMAIN) r = brands.BRAND208.ICON;else if (domain === brands.BRAND209.DOMAIN) r = brands.BRAND209.ICON;else if (domain === brands.BRAND300.DOMAIN) r = brands.BRAND300.ICON;else if (domain === brands.BRAND301.DOMAIN) r = brands.BRAND301.ICON;else if (domain === brands.BRAND302.DOMAIN) r = brands.BRAND302.ICON;else if (domain === brands.BRAND303.DOMAIN) r = brands.BRAND303.ICON;else if (domain === brands.BRAND304.DOMAIN) r = brands.BRAND304.ICON;else if (domain === brands.BRAND305.DOMAIN) r = brands.BRAND305.ICON;else if (domain === brands.BRAND306.DOMAIN) r = brands.BRAND306.ICON;else if (domain === brands.BRAND100.DOMAIN) r = brands.BRAND100.ICON;else if (domain === brands.BRAND101.DOMAIN) r = brands.BRAND101.ICON;else if (domain === brands.BRAND500.DOMAIN) r = brands.BRAND500.ICON;
    return r;
  }

  function changeFavicon(link) {
    var $favicon = document.querySelector('link[rel="icon"]');
    // If a <link rel="icon"> element already exists,
    // change its href to the given link.
    if ($favicon !== null) {
      $favicon.href = link;
      // Otherwise, create a new element and append it to <head>.
    } else {
      $favicon = document.createElement("link");
      $favicon.rel = "icon";
      $favicon.href = link;
      document.head.appendChild($favicon);
    }
  }

  function changeTitle(title) {
    document.title = title;
  }

  function changeBrand(domain) {
    if (!Ember.isBlank(document.title)) return;
    //
    var title = getTitle(domain);
    var icon = getIcon(domain);
    //
    if (!Ember.isBlank(title)) changeTitle(title);else changeTitle(default_title);
    //
    if (!Ember.isBlank(icon)) changeFavicon(icon);else changeFavicon(default_favicon);
  }
});
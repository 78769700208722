define('frodo/pods/components/common/page-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    pageNo: 0,
    tagName: 'ul',
    totalPage: 0,
    classNames: ['pagination'],

    onHit: null,

    pageObserver: Ember.observer('pageNo', 'totalPage', function () {
      this.rerender();
    }),

    pages: Ember.computed('pageNo', 'totalPage', function () {
      var buffer = Ember.A();
      var pageNo = this.get('pageNo');
      var totalPage = this.get('totalPage');

      if (totalPage === 0) return;

      if (pageNo === 1) {
        buffer.push('<li class="page-item disabled"><a class="page-link" href="#">&laquo;</a></li>');
      } else {
        buffer.push('<li class="page-item previous"><a class="page-link" href="#">&laquo;</a></li>');
      }

      var added = false;
      var omitted = false;
      var add = function add(index) {
        if (index === pageNo) {
          buffer.push('<li class="page-item active"><a class="page-link" href="#">' + index + '</a></li>');
        } else {
          buffer.push('<li class="page-item"><a class="page-link" href="#">' + index + '</a></li>');
        }
        added = true;
        omitted = false;
      };

      if (totalPage > 12) {
        for (var i = 1; i <= totalPage; i++) {
          added = false;
          if (i < pageNo + 3 && i > pageNo - 3) add(i);else {
            if (i > totalPage - 3) add(i);
            if (i < 4) add(i);
          }
          if (!added && !omitted) {
            buffer.push('<li class="page-item disabled"><a class="page-link"> ... </a></li>');
            omitted = true;
            added = false;
          }
        }
      } else for (var j = 1; j <= totalPage; j++) {
        add(j);
      }if (pageNo === totalPage) {
        buffer.push('<li class="page-item disabled"><a class="page-link" href="#">&raquo;</a></li>');
      } else {
        buffer.push('<li class="page-item next"><a class="page-link" href="#">&raquo;</a></li>');
      }

      return buffer;
    }),

    click: function click(e) {
      var $target = this.$(e.target);
      if (!$target.is('a') || $target.hasClass('disabled')) return;

      var strPage = $target.html(),
          currentPage = this.get('pageNo'),
          pageNo = currentPage;

      if (/^\d+$/.test(strPage)) {
        pageNo = parseInt(strPage, 10);
      } else {
        if ($target.parent().hasClass('previous')) pageNo = pageNo - 1;
        if ($target.parent().hasClass('next')) pageNo = pageNo + 1;
      }

      if (currentPage !== pageNo) {
        this.set('pageNo', pageNo);
        this.get('onHit')();
      }
      return false;
    }
  });
});
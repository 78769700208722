define('frodo/pods/index/making/target-price-config/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    brokerId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],

    lpId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],

    symbolId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],

    simulateType: [(0, _validators.validatePresence)(true)],

    bidMinVolume: [(0, _validators.validatePresence)(true)],

    bidMaxVolume: [(0, _validators.validatePresence)(true)],

    askMinVolume: [(0, _validators.validatePresence)(true)],

    askMaxVolume: [(0, _validators.validatePresence)(true)],

    pressureVolume: [(0, _validators.validatePresence)(true)],

    concessionRatio: [(0, _validators.validatePresence)(true)],

    releasePrice: [(0, _validators.validatePresence)(true)],

    volatility: [(0, _validators.validatePresence)(true)]
  };
});
define('frodo/services/bootstrap', ['exports', 'frodo/models/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    enums: null,
    profile: null,
    brokerId: null,
    selection: null,
    defaultMarket: 1,
    defaultTimeZone: 8, // DEFAULT TIMEZONE

    i18n: Ember.inject.service(),
    utility: Ember.inject.service(),
    realtime: Ember.inject.service(),

    boot: function boot(data) {
      var _this = this;

      this.set('menu', data.menu);
      this.set('enums', data.enums);
      this.set('config', data.config);
      this.set('profile', data.profile);
      this.set('selection', data.selection);
      this.setLocaleLanguage();
      this.setDefaultBrokerId();

      this.get('realtime').on('COMMON', function (selection) {
        if (_this.isSelectionData(selection)) {
          var prev = _this.get('selection');
          prev[selection.type] = selection.data;
          _this.notifyPropertyChange('selection');
        }
      });
    },
    isSelectionData: function isSelectionData(data) {
      return data && data.type && data.data;
    },


    /**
     *
     */
    getProfileType: function getProfileType() {
      var profile = this.get('profile');
      return profile ? profile.type : undefined;
    },
    getOperatorId: function getOperatorId() {
      var profile = this.get('profile');
      return profile ? profile.operatorId : null;
    },
    getOperatorName: function getOperatorName() {
      var profile = this.get('profile');
      return profile ? profile.firstName + ' ' + profile.lastName : '-';
    },
    getEnvironment: function getEnvironment() {
      var conf = this.get('config');
      return conf && conf.environment ? conf.environment : 'UNKNOWN';
    },
    getServerTime: function getServerTime() {
      var datatime = this.getSelection('system.datetime');
      return datatime && datatime.length > 0 ? datatime[0].value : '0';
    },
    getSod: function getSod() {
      var market = this.getSelection('market.market', this.get('defaultMarket'));
      return market ? market.extra.sod : undefined;
    },
    getEod: function getEod() {
      var market = this.getSelection('market.market', this.get('defaultMarket'));
      return market ? market.extra.eod : undefined;
    },
    getTradingDate: function getTradingDate() {
      var market = this.getSelection('market.market', this.get('defaultMarket'));
      return market ? market.extra.tradingDate : undefined;
    },
    getPrevTradingDate: function getPrevTradingDate() {
      var market = this.getSelection('market.market', this.get('defaultMarket'));
      return market ? market.extra.prevTradingDate : undefined;
    },
    getSomTradingDate: function getSomTradingDate() {
      var market = this.getSelection('market.market', this.get('defaultMarket'));
      return market ? market.extra.somTradingDate : undefined;
    },
    getTimezone: function getTimezone() {
      var market = this.getSelection('market.market', this.get('defaultMarket'));
      return market ? market.extra.timezone : this.get('defaultTimeZone');
    },
    getTimezoneOffset: function getTimezoneOffset() {
      return this.getTimezone() * 3600 * 1000;
    },
    getLocalTimezone: function getLocalTimezone() {
      return -new Date().getTimezoneOffset() / 60;
    },
    getLocalTimezoneOffset: function getLocalTimezoneOffset() {
      return -new Date().getTimezoneOffset() * 60000;
    },
    getTodayStartTime: function getTodayStartTime() {
      var date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), '0', '0', '0').getTime();
    },
    getTodayEndTime: function getTodayEndTime() {
      var date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), '23', '59', '59').getTime();
    },
    getPreTradingDateTime: function getPreTradingDateTime() {
      var str = this.getPrevTradingDate(),
          y = void 0,
          m = void 0,
          d = void 0;
      if (str && str.length === 8) {
        y = parseInt(str.substring(0, 4));m = parseInt(str.substring(4, 6)) - 1, d = parseInt(str.substring(6, 8));
      } else {
        console.error('invalid pre PreTradingDate');return undefined;
      }
      return new Date(y, m, d, 0, 0, 0).getTime();
    },


    /**
     *
     */
    setDefaultBrokerId: function setDefaultBrokerId(id) {
      var type = this.getProfileType();
      if (type === _enums.AdminOperatorType.MANAGER) {
        // MANAGER
        this.set('brokerId', this.get('profile').brokerId);
      } else {
        var brokers = this.getSelection('broker');
        this.set('brokerId', !Ember.isEmpty(id) ? id : brokers ? brokers[0].value : 0);
      }
    },
    setLocaleLanguage: function setLocaleLanguage() {
      var language = this.get('profile.language');
      var value = language ? language : 1;
      this.set('i18n.locale', (0, _enums.parse)(_enums.Language_i18n, value));
    },


    /**
     *
     */
    getEnum: function getEnum(type) {
      var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

      var enumObject = this.get('enums')[type];

      if (Ember.isEmpty(value)) {
        return enumObject;
      }

      return _.find(enumObject, function (e) {
        return e.value === value;
      });
    },


    /**
     *
     */
    getEnumName: function getEnumName(type, value) {
      var found = this.getEnum(type, value);
      return found ? found.name : null;
    },

    /**
     *
     */
    getMenuI18nName: function getMenuI18nName(route) {
      return this.get('i18n').t('menu.' + route);
    },

    /**
     *
     */
    getMenuIcon: function getMenuIcon(route) {
      var menus = this.get('menu');
      var name = this.getMenuName(route);
      var icon = void 0;
      menus.forEach(function (parent) {
        var found = parent.subs.findBy('name', name);
        if (found) {
          icon = parent.icon;
        }
      });
      return icon;
    },
    getMenuName: function getMenuName(route) {
      var arr = _.split(route, '.');
      var title = [arr[1], arr[2]].join('.');
      return title;
    },


    /**
     *
     */
    getSelection: function getSelection(type) {
      var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

      var selectionObject = this.get('selection')[type];

      if (Ember.isEmpty(value)) {
        return selectionObject;
      }

      return _.find(selectionObject, function (s) {
        return s.value === value;
      });
    },


    /**
     *
     */
    getSelectionByBrokerId: function getSelectionByBrokerId(type, brokerId) {
      var selections = this.getSelection(type);
      return selections.filter(function (s) {
        return s.extra.$group === brokerId;
      });
    },


    /**
     *
     */
    getSelectionName: function getSelectionName(type, value) {
      var found = this.getSelection(type, value);
      return found ? found.name : null;
    },


    /**
     *
     */
    getSelectionNameDash: function getSelectionNameDash(type, value) {
      var name = this.getSelectionName(type, value);
      return Ember.isEmpty(name) ? '-' : name;
    },


    /**
     *
     */
    getImageServer: function getImageServer() {
      var system = this.getSelection('app.config', 'SYSTEM');
      return system ? system.extra['system.image.server'] : '';
    },


    /**
     *
     */
    hasReadPrivilege: function hasReadPrivilege(menuName) {
      var menus = this.get('menu');
      var subs = _.map(menus, 'subs');
      var flattenSubs = _.flatten(subs);
      var menu = _.find(flattenSubs, function (s) {
        return s.name === menuName;
      });

      return menu ? menu.readable : false;
    },


    /**
     *
     */
    hasWritePrivilege: function hasWritePrivilege(menuName) {
      var menus = this.get('menu');
      var subs = _.map(menus, 'subs');
      var flattenSubs = _.flatten(subs);
      var menu = _.find(flattenSubs, function (s) {
        return s.name === menuName;
      });

      return menu ? menu.readable && menu.writable : false;
    }
  });
});
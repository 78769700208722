define('frodo/pods/index/system/config/controller', ['exports', 'frodo/pods/index/system/config/model', 'frodo/pods/index/system/system-controller', 'frodo/pods/index/system/config/validator'], function (exports, _model, _systemController, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemController.default.extend({
    Validator: _validator.default,
    record: null,
    domain: 'COMMON',
    actions: {
      reload: function reload() {
        var _this = this;

        var domain = this.get("domain");
        (0, _model.fetch)(this, domain).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('model', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, domain);
        });
      },
      reset: function reset() {
        var domain = this.get("domain");
        return (0, _model.reset)(this, domain);
      },
      domainChanged: function domainChanged() {
        this.send('reload');
      }
    },
    resumeParam: function resumeParam(p) {
      this.set('domain', p);
    },
    resumeModel: function resumeModel(model) {
      this.set('model', model);
    }
  });
});
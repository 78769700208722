define('frodo/pods/index/risk/cp-position/model', ['exports', 'frodo/pods/index/risk/holding-model', 'frodo/pods/index/base-model'], function (exports, _holdingModel, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.flush = exports.fetch = undefined;
  exports.default = _holdingModel.default.extend({});


  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/cp_position', { data: p });
  }

  function flush(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/cp_position/flush', { data: data });
  }

  exports.fetch = fetch;
  exports.flush = flush;
});
define('frodo/pods/index/pricing/dealing-panel/controller', ['exports', 'frodo/pods/index/pricing/pricing-controller'], function (exports, _pricingController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pricingController.default.extend({
    sortable: true,
    actions: {}
  });
});
define('frodo/helpers/is-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params, hash) {
      var v = params[0];
      if (isNaN(v) || v === null || v === undefined || v == Infinity || v == -Infinity) {
        return false;
      }
      v = _.toNumber(v);
      var isExcludeZero = hash && hash.isExcludeZero ? hash.isExcludeZero : false;
      if (isExcludeZero && _.toNumber(v) === 0) {
        return false;
      }
      return true;
    }
  });
});
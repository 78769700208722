define('frodo/helpers/ip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ip = ip;
  function ip(address /*, hash*/) {
    if (address && address == '0.0.0.0') return '-';
    return address;
  }

  exports.default = Ember.Helper.helper(ip);
});
define('frodo/pods/index/market/institution-symbol/controller', ['exports', 'frodo/pods/index/market/institution-symbol/validator', 'frodo/pods/index/market/institution-symbol/model', 'frodo/pods/index/market/market-controller'], function (exports, _validator, _model, _marketController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    Validator: _validator.default,
    parentRoot: "market.institution",

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this, this.get("intent.itId")).then(function (r) {
          if (_this.isData(r)) {
            var data = _.map(r.data, function (d) {
              return _model.default.create(d);
            });
            _this.set('model', data);
          }
        });
      },
      fill: function fill(e) {
        this.set('record.itId', e.itId);
      },
      sort: function sort(ids) {
        return (0, _model.sort)(this, { itSymbolIds: ids.toString(), itId: this.get('intent.itId') });
      },
      download: function download() {}
    }
  });
});
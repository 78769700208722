define("frodo/utils/common-generator", ["exports", "frodo/models/enums", "frodo/utils/numbers"], function (exports, _enums, _numbers) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toCoSymbolId = toCoSymbolId;
  exports.getSymbolIdByBkSymbolId = getSymbolIdByBkSymbolId;
  exports.getCompanyIdByCoSymbolId = getCompanyIdByCoSymbolId;
  exports.getGroupIdByProductId = getGroupIdByProductId;
  exports.getSymbolIdByProductId = getSymbolIdByProductId;
  exports.getTradeDomainByProductId = getTradeDomainByProductId;
  exports.toProductId = toProductId;
  exports.toLeverageId = toLeverageId;
  exports.getLeverageSequence = getLeverageSequence;
  exports.getItIdByLpId = getItIdByLpId;
  exports.getBrokerIdByLpId = getBrokerIdByLpId;
  exports.toBksymbolId = toBksymbolId;
  exports.toLpsymbolId = toLpsymbolId;
  exports.getLpIdByLpSymbolId = getLpIdByLpSymbolId;
  exports.getSymbolIdByLpSymbolId = getSymbolIdByLpSymbolId;
  exports.getItSymbolIdByLpSymbolId = getItSymbolIdByLpSymbolId;
  exports.toItSymbolId = toItSymbolId;
  exports.getItIdByItSymbolId = getItIdByItSymbolId;
  exports.getSymbolIdByItSymbolId = getSymbolIdByItSymbolId;


  /**
   * CompanyId + SymbolId -> CoSymbolId
   */
  function toCoSymbolId(companyId, symbolId) {
    return (0, _numbers.toInt)(companyId, symbolId);
  }

  function getSymbolIdByBkSymbolId(bkSymbolId) {
    return (0, _numbers.lowShort)(bkSymbolId);
  }

  function getCompanyIdByCoSymbolId(coSymbolId) {
    return (0, _numbers.highShort)(coSymbolId);
  }

  /**
   * GroupId + SymbolId + TradeDomain -> ProductId
   */
  function getGroupIdByProductId(productId) {
    return (0, _numbers.highInt)(productId);
  }

  function getSymbolIdByProductId(productId) {
    return (0, _numbers.highShort)((0, _numbers.lowInt)(productId));
  }

  function getTradeDomainByProductId(productId) {
    return (0, _enums.parse)(_enums.TradeDomain, (0, _numbers.lowShort)((0, _numbers.lowInt)(productId)));
  }

  function toProductId(groupId, symbolId, domain) {
    return (0, _numbers.toLong)(groupId, (0, _numbers.toInt)(symbolId, domain));
  }

  /**
   * CompanyId + Sequence -> LeverageId
   */
  function toLeverageId(companyId, sequence) {
    (false && !(`invalid sequence, ${sequence}`) && Ember.assert(31 >= sequence && sequence >= 0, "invalid sequence, " + sequence));

    return (0, _numbers.toShort)(companyId, sequence);
  }

  function getLeverageSequence(leverageId) {
    return (0, _numbers.lowByte)(leverageId);
  }

  function getItIdByLpId(lpId) {
    return (0, _numbers.lowByte)(lpId);
  }

  function getBrokerIdByLpId(lpId) {
    return (0, _numbers.highByte)(lpId);
  }

  /**
   * brokerId + SymbolId -> bkSymbolId
   */
  function toBksymbolId(brokerId, symbolId) {
    return (0, _numbers.toInt)(brokerId, symbolId);
  }

  /**
   * lpId + symbolId -> lpSymbolId
   */
  function toLpsymbolId(lpId, symbolId) {
    return (0, _numbers.toInt)(lpId, symbolId);
  }

  function getLpIdByLpSymbolId(lpSymbolId) {
    return (0, _numbers.highShort)(lpSymbolId);
  }
  function getSymbolIdByLpSymbolId(lpSymbolId) {
    return (0, _numbers.lowShort)(lpSymbolId);
  }
  function getItSymbolIdByLpSymbolId(lpSymbolId) {
    var lpId = getLpIdByLpSymbolId(lpSymbolId);
    var symbolId = getSymbolIdByLpSymbolId(lpSymbolId);
    var itId = getItIdByLpId(lpId);
    return toItSymbolId(itId, symbolId);
  }

  /**
   * ItId + SymbolId -> ItSymbolId
   */
  function toItSymbolId(itId, symbolId) {
    return (0, _numbers.toInt)(itId, symbolId);
  }

  function getItIdByItSymbolId(itSymbolId) {
    return (0, _numbers.highShort)(itSymbolId);
  }

  function getSymbolIdByItSymbolId(itSymbolId) {
    return (0, _numbers.lowShort)(itSymbolId);
  }
});
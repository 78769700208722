define('frodo/pods/index/market/currency/model', ['exports', 'frodo/pods/index/market/market-model', 'frodo/pods/index/base-model'], function (exports, _marketModel, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sort = exports.edit = exports.add = exports.check = exports.fetch = undefined;
  exports.default = _marketModel.default.extend({});


  function fetch(owner) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _marketModel.PARENT + '/currencies');
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/currency/validate', { data: data });
  }

  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _marketModel.PARENT + '/currency', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/currency/' + data.id, { data: data });
  }

  function sort(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _marketModel.PARENT + '/currency/sort', { data: data });
  }

  exports.fetch = fetch;
  exports.check = check;
  exports.add = add;
  exports.edit = edit;
  exports.sort = sort;
});
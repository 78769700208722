define('frodo/pods/index/system/digest/controller', ['exports', 'frodo/pods/index/system/digest/model', 'frodo/pods/index/system/system-controller'], function (exports, _model, _systemController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemController.default.extend({
    records: null,
    domain: 'COMMON',
    actions: {
      reload: function reload() {
        var _this = this;

        var domain = this.get('domain');
        (0, _model.fetch)(this, domain).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('model', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, domain);
        });
      },
      reset: function reset(o) {
        return (0, _model.reset)(this, o);
      },
      domainChanged: function domainChanged() {
        this.send('reload');
      },
      appDetail: function appDetail(digest) {
        var _this2 = this;

        var domain = this.get('domain');
        return (0, _model.detail)(this, domain, digest.name).then(function (r) {
          if (_this2.isData(r)) {
            var digests = r.data;
            var grouped = _.chain(digests).groupBy(function (m) {
              return m.digest.digest;
            }).map(function (val, key) {
              return { digest: key, list: _.sortBy(val, function (item) {
                  return item.app.appId;
                }) };
            }).value();
            return grouped;
          }
        });
      }
    },
    resumeParam: function resumeParam(p) {
      this.set('domain', p);
    },
    resumeModel: function resumeModel(model) {
      this.set('model', model);
    }
  });
});
define('frodo/mixins/draggable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modalBackdrop: false,

    permitEscape: false,

    dialogManager: Ember.inject.service.service('dialogManager'),

    coord: null, // make init value in instance obj

    isAnimate: false,

    isDragged: false,

    init: function init() {
      this._super.apply(this, arguments);
      var coord = this.get('coord');
      var baseY = 30;
      if (!coord) {
        var dialogManager = this.get('dialogManager');
        var offset = dialogManager.getNewDialogOffset();
        this.set('coord', { x: offset, y: offset + baseY });
      } else if (coord.x !== 0 || coord.y !== baseY) {
        this.set('isDragged', true);
      }
    },


    onInsertElement: Ember.on('didInsertElement', function () {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        var dialogManager = _this.get('dialogManager');
        dialogManager.makeDialogTop(_this);

        _this.move();
        _this.$().on('hidden.bs.modal', function () {
          dialogManager.popDialog(_this);
        });
        // zIndex
        _this.$('.modal-dialog').on('mousedown', function (e) {
          var isAction = Ember.$(e.target).closest('[data-role="action"]').length > 0;
          if (isAction) return;
          dialogManager.makeDialogTop(_this);
        });
        Ember.$(document).off('focusin.bs.modal');

        //enable draggable
        _this.initDraggableEvent();
      });
    }),

    initDraggableEvent: function initDraggableEvent() {
      var _this2 = this;

      var $header = this.$('.modal-header');
      var $dialog = $header.closest('.modal-dialog');
      var $modal = $header.closest('.modal').addClass('draggable');
      $header.on('mousedown', function (e) {
        $header[0].setCapture && $header[0].setCapture();
        window.captureEvents && window.captureEvents(Event.MOUSEMOVE | Event.MOUSEUP);
        $modal.addClass('dragging');

        // console.log('dragging coord');
        // console.log(this.get('coord'));

        var _get = _this2.get('coord'),
            x = _get.x,
            y = _get.y;

        var _ref = [e.clientX, e.clientY],
            ox = _ref[0],
            oy = _ref[1];
        var _ref2 = [Ember.$(window).innerHeight(), Ember.$(window).innerWidth()],
            vpHeight = _ref2[0],
            vpWidth = _ref2[1];
        var _ref3 = [$dialog.height(), $dialog.width()],
            boxHeight = _ref3[0],
            boxWidth = _ref3[1];

        var _$dialog$offset = $dialog.offset(),
            top = _$dialog$offset.top,
            left = _$dialog$offset.left;

        var boxTop = top - y;
        var boxLeft = left - x;
        e.preventDefault();

        Ember.$(document).off('.draggable').on('mousemove.draggable', function (e) {
          // coord saved to stack
          var dx = e.clientX - ox,
              dy = e.clientY - oy;

          var _x = x + dx,
              _y = y + dy;
          // restrict area


          if (dx > 0 && boxLeft + _x + boxWidth >= vpWidth) {
            _x = x;
          } else if (dx < 0 && boxLeft + _x <= 0) {
            _x = x;
          }
          if (dy > 0 && boxTop + _y + boxHeight >= vpHeight) {
            if (boxHeight <= vpHeight) _y = y;
          } else if (dy < 0 && boxTop + _y <= 0) {
            _y = y;
          }
          ox = e.clientX;
          oy = e.clientY;
          x = _x;
          y = Math.max(_y, 0);
          _this2._translate($dialog, { x: x, y: y });
          e.preventDefault();
        }).on('mouseup.draggable', function () {
          Ember.$(document).off('.draggable');
          $modal.removeClass('dragging');
          var coord = _this2.get('coord');
          if (coord.x !== x || coord.y !== y) {
            Ember.run.scheduleOnce('afterRender', function () {
              _this2.set('coord', { x: x, y: y });
              _this2.set('isDragged', true);
            });
          }
          $header[0].releaseCapture && $header[0].releaseCapture();
          window.releaseEvents && window.releaseEvents(Event.MOUSEMOVE | Event.MOUSEUP);
        });
      });
      $header.on('contextmenu', function (e) {
        e.preventDefault();
      });
    },
    move: function move(coord) {
      var _this3 = this;

      // console.log('move par coord:');
      // console.log(coord);
      // console.log('this.getcoord');
      // console.log(this.get('coord'));
      if (this.isDestroyed) return;
      coord = coord || this.get('coord');
      // coord ={x:0,y:30};
      Ember.run.schedule('render', function () {
        var $dialog = _this3.$('.modal-dialog');
        _this3._translate($dialog, coord);
      });
    },
    _translate: function _translate($dialog, _ref4) {
      var x = _ref4.x,
          y = _ref4.y;

      $dialog.css({
        transform: 'translate3d(' + x + 'px, ' + y + 'px, 0)'
      });
    }
  });
});
define('frodo/pods/components/common/select-app/component', ['exports', 'frodo/pods/components/common/select-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({

    type: null,
    appId: null,
    appName: null,
    emptyLabel: '',
    allowClear: false,
    emptyOption: false,
    appIdKey: 'system.app.id',
    appNameKey: 'system.app.name',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },


    options: Ember.computed('type', 'appName', function () {
      var type = this.get('type'),
          appIdKey = this.get('appIdKey'),
          appNameKey = this.get('appNameKey'),
          emptyLabel = this.get('emptyLabel'),
          emptyOption = this.get('emptyOption'),
          sel = this.get('bootstrap').getSelection(type);

      if (type === appNameKey) {
        return emptyOption ? [{ value: 0, name: emptyLabel }].concat(sel) : sel;
      } else if (type === appIdKey) {
        var appName = this.get('appName');
        var r = emptyOption ? [{ value: 0, name: emptyLabel }] : [];
        for (var i = 0; i < sel.length; i++) {
          if (appName === sel[i].value) {
            var ids = sel[i].extra[appName];
            if (ids) {
              for (var j = 0; j < ids.length; j++) {
                r.push({ value: ids[j], name: ids[j] });
              }
            }
          }
        }
        return r;
      }
    }),

    appNameObserver: Ember.observer('appName', function () {})
  });
});
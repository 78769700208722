define("frodo/pods/index/trades/hedge-execution-history/controller", ["exports", "frodo/pods/index/trades/trades-controller", "frodo/mixins/paginatable", "frodo/utils/common-generator", "frodo/models/enums", "frodo/pods/index/trades/hedge-execution-history/model", "frodo/mixins/downloadable"], function (exports, _tradesController, _paginatable, _commonGenerator, _enums, _model, _downloadable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tradesController.default.extend(_paginatable.default, _downloadable.default, {
    actions: {},
    doSearch: function doSearch(params) {
      var _this = this;

      var self = this;
      var p = this.build(params);
      this.set('csvParams', Ember.assign({}, p));
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            o.originalHedgePrice = self.getOriginalHedgePrice(o);
            o.pl = self.getPl(o);
            return _model.default.create(o);
          });
          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, p);
        return r;
      });
    },
    downloadUrl: function downloadUrl() {
      return (0, _model.csv)(this.get('csvParams'));
    },
    build: function build(p) {
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('symbolId')) p.symbolId = this.get('symbolId');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('to')) p.to = this.get('to');
      return p;
    },
    getOriginalHedgePrice: function getOriginalHedgePrice(o) {
      var side = o.side,
          price = o.executePrice,
          id = o.lpSymbolId,
          weight = o.weight,
          cost = void 0;
      var symbolId = (0, _commonGenerator.getSymbolIdByLpSymbolId)(id);
      var scale = this.getSymbolProfitCurrencyScale(symbolId);
      if (side === _enums.Side.SELL) {
        cost = new BigNumber(price).multipliedBy(new BigNumber(1).minus(weight)).toFixed(scale, BigNumber.ROUND_DOWN);
      } else {
        cost = new BigNumber(price).dividedBy(new BigNumber(1).minus(weight)).toFixed(scale, BigNumber.ROUND_DOWN);
      }
      return cost;
    },


    // 持仓成本价 99 ， 对冲成本价 100 (costRate)， 对冲 卖， Volume 100
    // PL  = - (100 - 99) * 100 * （-1)  = 100  ===> (99 - 100) * 100 * （-1)

    // 持仓成本价 99 ， 对冲成本价 100 (costRate)， 对冲 买， Volume 100
    // PL  = - (100 - 99) * 100 * （1)  = -100  ===> (99 - 100) * 100 * （1)
    getPl: function getPl(o) {
      if (!o.costPrice) {
        return undefined;
      }
      var side = o.side,
          originalPositionPrice = o.costPrice,
          originalHedgePrice = o.originalHedgePrice,
          volume = o.executeVolume;
      return new BigNumber(originalPositionPrice).minus(new BigNumber(originalHedgePrice)).multipliedBy(new BigNumber(volume)).multipliedBy(new BigNumber(side)).toNumber();
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
      this.set('symbolId', p.hasOwnProperty('symbolId') ? p.symbolId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
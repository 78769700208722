define('frodo/pods/index/market/institution/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 64 })],
    abbreviation: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 16 })],
    category: [(0, _validators.validatePresence)(true)],
    type: [(0, _validators.validatePresence)(true)],
    pricingUrl: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 128 })],
    pricingEnabled: [(0, _validators.validatePresence)(true)],
    pricingVerbose: [(0, _validators.validatePresence)(true)],
    instrumentType: [(0, _validators.validatePresence)(true)]
  };
});
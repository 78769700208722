define('frodo/pods/components/common/field-number/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),
    bootstrap: Ember.inject.service(),

    tagName: 'div',
    classNameBindings: ['hasFormGroup:form-group', 'inline:row'],

    inline: true,
    preview: false,
    readonly: false,
    withLabel: true,
    editDisable: false,
    hasFormGroup: true,

    unit: null,
    model: null,
    maxlength: null,
    fieldName: null,
    fieldLabel: null,
    valueAlign: 'left',
    labelAlign: 'right',
    labelOffset: 1,
    contentOffset: 0,
    labelWidthRatio: 4,
    contentWidthRatio: 7,

    strict: true,
    value: null,

    isEdit: Ember.computed('model', function () {
      return this.get('model.id') > 0;
    }),

    displayValue: Ember.computed('strict', 'value', function () {
      var value = this.get('value');
      var strict = this.get('strict');
      if (strict && value === 0 || _.isNaN(value) || _.isNull(value)) {
        return '';
      } else {
        return value;
      }
    }),

    // temp solution
    valueChanged: Ember.observer('displayValue', function () {
      this.set('value', this.get('displayValue'));
    }),

    isDisabled: Ember.computed('readonly', 'isEdit', 'preview', 'editDisable', function () {
      if (this.get('readonly') || this.get('preview')) return true;
      return this.get('isEdit') && this.get('editDisable');
    }),

    labelWidthClass: Ember.computed('labelWidthRatio', function () {
      var ratio = this.get('labelWidthRatio');
      return ratio ? 'col-sm-' + ratio : '';
    }),

    labelOffsetClass: Ember.computed('labelOffset', function () {
      var offset = this.get('labelOffset');
      return offset ? 'offset-sm-' + offset : '';
    }),

    contentWidthClass: Ember.computed('contentWidthRatio', function () {
      var ratio = this.get('contentWidthRatio');
      return ratio ? 'col-sm-' + ratio : '';
    }),

    contentOffsetClass: Ember.computed('contentOffset', function () {
      var offset = this.get('contentOffset');
      return offset ? 'offset-sm-' + offset : '';
    }),

    valueAlignClass: Ember.computed('valueAlign', function () {
      return this._align(this.get('valueAlign'));
    }),

    labelAlignClass: Ember.computed('labelAlign', function () {
      return this._align(this.get('labelAlign'));
    }),

    _align: function _align(direction) {
      switch (direction) {
        case 'left':
          return 'text-left';
        case 'right':
          return 'text-right';
        case 'center':
          return 'text-center';
        default:
          return 'text-center';
      }
    }
  });
});
define('frodo/pods/index/trades/hedge-order-history/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/trades/trades-model', 'frodo/models/enums'], function (exports, _baseModel, _tradesModel, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cancel = exports.check = exports.csv = exports.findExecution = exports.fetch = undefined;
  exports.default = _tradesModel.default.extend({

    avgPrice: Ember.computed('executeAmount', 'executeVolume', 'priceScale', 'roundingMode', function () {
      if (isNaN(this.get('executeAmount')) || isNaN(this.get('executeVolume'))) {
        return 0;
      }
      if (parseFloat(this.get('executeVolume')) === 0) {
        return 0;
      }
      var ap = new BigNumber(this.get('executeAmount')).div(new BigNumber(this.get('executeVolume')));
      return ap.toFixed(this.get('priceScale'), this.get('roundingMode'));
    }),

    unfinished: Ember.computed('status', function () {
      return [_enums.DealingOrderStatus.PENDING, _enums.DealingOrderStatus.EXECUTING, _enums.DealingOrderStatus.UNKNOWN, _enums.DealingOrderStatus.UNCONFIRMED].includes(this.get('status'));
    }),

    cancelable: Ember.computed('status', function () {
      return [_enums.DealingOrderStatus.PENDING, _enums.DealingOrderStatus.EXECUTING, _enums.DealingOrderStatus.UNKNOWN, _enums.DealingOrderStatus.UNCONFIRMED].includes(this.get('status'));
    })

  });


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_order_history', { data: data });
  }

  function findExecution(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_execution', { data: data });
  }

  function check(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_order_history/check', { data: data });
  }

  function cancel(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_order_history/cancel', { data: data });
  }

  function csv(p) {
    return _baseModel.NAMESPACE + '/' + _baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_order_history/csv?' + $.param(p);
  }

  exports.fetch = fetch;
  exports.findExecution = findExecution;
  exports.csv = csv;
  exports.check = check;
  exports.cancel = cancel;
});
define('frodo/pods/components/common/modal-confirm-remove/component', ['exports', 'frodo/pods/components/common/modal-confirm/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    actionName: 'remove',
    buttonIcon: 'fa-times',
    buttonClass: 'btn-danger',
    highlightField: null,
    highlightLabel: null,
    notification: 'label.notification.removed',

    contextOptions: Ember.computed('model.{id,name}', function () {

      var target = [];
      var model = this.get('model');
      var highlightField = this.get('highlightField');
      var highlightLabel = this.get('highlightLabel');

      if (highlightField && highlightLabel) {
        var v = model[highlightField];
        target.push(' ' + highlightLabel + ' = ' + v);
      }

      return { target: Ember.isEmpty(target) ? '' : Ember.String.htmlSafe('<strong class="text-danger">' + target.join(',') + '</strong>') };
    })
  });
});
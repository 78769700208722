define("frodo/pods/components/common/chart-bar-horizontal/component", ["exports", "frodo/pods/components/common/base-chart-bar/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    chartType: 'horizontalBar',
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
});
define('frodo/pods/index/trades/cp-order/controller', ['exports', 'frodo/pods/index/trades/cp-order/model', 'frodo/utils/common-generator', 'frodo/pods/index/trades/trades-controller', 'frodo/models/enums'], function (exports, _model, _commonGenerator, _tradesController, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tradesController.default.extend({

    isOkEx: Ember.computed('lpId', function () {
      var lpId = this.get('lpId');
      var itId = (0, _commonGenerator.getItIdByLpId)(lpId);
      var institutions = this.get('bootstrap').getSelection('broker.institution');
      var it = _.find(institutions, function (e) {
        return e.value === itId;
      });
      return it && _enums.ItType.OKEX == it.extra.$group2;
    }),

    actions: {
      search: function search() {
        var _this = this;

        this.set('searched', false);
        this.set('isSearching', true);
        this.set('foundRecord', false);
        this.set('notFoundRecord', false);
        var p = this.build({});
        return (0, _model.fetch)(this, p).then(function (r) {
          _this.set('searched', true);
          _this.set('isSearching', false);
          if (_this.isData(r) && r.data.length > 0) {
            _this.set('records', r.data);
            _this.set('foundRecord', true);
          } else {
            _this.set('notFoundRecord', true);
          }
          p.brokerId = _this.get('brokerId');
          var records = _this.records,
              searched = _this.searched,
              isSearching = _this.isSearching,
              foundRecord = _this.foundRecord,
              notFoundRecord = _this.notFoundRecord;

          _this.updateNavtab(_this.get('currentRouteName'), { records: records, searched: searched, isSearching: isSearching, foundRecord: foundRecord, notFoundRecord: notFoundRecord }, p);
          return r;
        });
      }
    },
    build: function build(p) {
      if (this.get('isOkEx')) {
        if (this.get('to')) p.to = this.get('id_to');
        if (this.get('from')) p.from = this.get('id_from');
      } else {
        if (this.get('to')) p.to = this.get('to');
        if (this.get('from')) p.from = this.get('from');
      }
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('symbolId')) p.symbolId = this.get('symbolId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      if (p.hasOwnProperty('to')) this.set('to', p.to);
      if (p.hasOwnProperty('from')) this.set('from', p.from);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
      this.set('symbolId', p.hasOwnProperty('symbolId') ? p.symbolId : 0);
      this.set('brokerId', p.hasOwnProperty('brokerId') ? p.brokerId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('records', model.records);
      this.set('searched', model.searched);
      this.set('isSearching', model.isSearching);
      this.set('foundRecord', model.foundRecord);
      this.set('notFoundRecord', model.notFoundRecord);
    }
  });
});
define('frodo/pods/index/base-route', ['exports', 'frodo/mixins/service-bus', 'frodo/mixins/broker-aware', 'frodo/mixins/authorization', 'frodo/models/enums', 'frodo/utils/common-generator'], function (exports, _serviceBus, _brokerAware, _authorization, _enums, _commonGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_serviceBus.default, _authorization.default, _brokerAware.default, {

    preload: false,
    sortable: false,
    //
    lpWithAllOption: true,
    bookWithAllOption: true,
    symbolWithAllOption: true,
    bkSymbolWithAllOption: true,
    allocatorWithAllOption: true,
    itWithAllOption: true,
    currencyWithAllOption: true,
    profitCurrencyWithAllOption: true,
    //
    lpOptionSwitchFirst: false,
    bookOptionSwitchFirst: false,
    symbolOptionSwitchFirst: false,
    bkSymbolOptionSwitchFirst: false,
    currencyOptionSwitchFirst: false,
    allocatorOptionSwitchFirst: false,
    profitCurrencyOptionSwitchFirst: false,
    //
    currencyReact: true,
    profitCurrencyReact: true,
    //
    lpSelectAll: false,
    lpMultiSelect: false,
    //
    symbolSelectAll: false,
    symbolMultiSelect: false,
    //
    bkSymbolSelectAll: false,
    bkSymbolMultiSelect: false,
    //
    ignoreResumeModel: false,
    ignoreResumeParam: false,
    //
    unTabbedRoute: false,

    beforeModel: function beforeModel(transition) {
      var tn = transition.targetName;
      this.set('targetName', tn);

      if (tn === 'index.popup.dealing_panel') {
        return;
      }

      var targets = tn ? tn.split('.') : [];
      var targetMenu = void 0,
          authorizedMenu = void 0,
          menus = this.getReadableMenus(tn);

      if (targets.length > 0) {
        targets = targets.removeAt(0);
        targetMenu = targets.join('.');

        authorizedMenu = _.find(menus, function (s) {
          var prefix = s.name;
          return targetMenu.slice(0, prefix.length) == prefix;
        });
      }

      if (!authorizedMenu || !authorizedMenu.readable) {
        if (menus.length > 0) {
          var subs = _.sortBy(menus, function (e) {
            return e.id;
          }),
              sub = subs[0];
          if (sub.readable) {
            this.transitionTo('index.' + sub.name);
          } else {
            this.get('utility').redirectToLogin();
            var lc = this.controllerFor('login');lc.setMessage('error.privileges.empty');
          }
        } else {
          this.get('utility').redirectToLogin();
          var _lc = this.controllerFor('login');_lc.setMessage('error.privileges.empty');
        }
      }

      // if(this.get('unTabbedRoute')){
      //   return ;
      // }
      // let navtabs = this.get('navtabs');
      // let parent = this.get('parentNode');
      // if(parent){
      //   tn = parent.route;
      // }
      // let newTab = navtabs.isNewTab(tn);
      // if(newTab){
      //   if(navtabs.get('isFull')){
      //      navtabs.fullTabWarning();
      //      transition.abort();
      //   }
      // }
    },
    afterModel: function afterModel(model, transition) {
      this._super.apply(this, arguments);
      var indexCtrl = this.controllerFor('index');
      if (indexCtrl) {
        indexCtrl.set('previousTransition', transition);
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.set('controller', controller);
      var navtabs = this.get('navtabs');

      var bootstrap = this.get('bootstrap');
      var tabRoute = this.get('targetName');
      var tabbed = !this.get('unTabbedRoute');
      //
      controller.set('preload', this.get('preload'));
      controller.set('hasRO', this.get('hasRO'));
      controller.set('hasRW', this.get('hasRW'));
      controller.set('to', bootstrap.getTodayEndTime());
      controller.set('from', bootstrap.getTodayStartTime());
      //
      controller.set('isSuper', this.get('isSuper'));
      controller.set('isAdmin', this.get('isAdmin'));
      controller.set('isManager', this.get('isManager'));
      controller.set('operatorType', this.get('operatorType'));
      controller.set('isAdministrator', this.get('isAdministrator'));
      //
      controller.set('currentRouteName', tabRoute);
      controller.set('ignoreResumeParam', this.get('ignoreResumeParam'));
      controller.set('ignoreResumeModel', this.get('ignoreResumeModel'));

      // broker aware
      var resetBrokerId = this.get('resetBrokerId');
      //
      var brokerWithAllOption = this.get('brokerWithAllOption');
      var lpWithAllOption = this.get('lpWithAllOption');
      var symbolWithAllOption = this.get('symbolWithAllOption');
      var bkSymbolWithAllOption = this.get('bkSymbolWithAllOption');
      var bookWithAllOption = this.get('bookWithAllOption');
      var allocatorWithAllOption = this.get('allocatorWithAllOption');
      var itWithAllOption = this.get('itWithAllOption');
      var currencyWithAllOption = this.get('currencyWithAllOption');
      var profitCurrencyWithAllOption = this.get('profitCurrencyWithAllOption');

      var lpOptionSwitchFirst = this.get('lpOptionSwitchFirst');
      var symbolOptionSwitchFirst = this.get('symbolOptionSwitchFirst');
      var bkSymbolOptionSwitchFirst = this.get('bkSymbolOptionSwitchFirst');
      var currencyOptionSwitchFirst = this.get('currencyOptionSwitchFirst');
      var bookOptionSwitchFirst = this.get('bookOptionSwitchFirst');
      var allocatorOptionSwitchFirst = this.get('allocatorOptionSwitchFirst');
      var profitCurrencyOptionSwitchFirst = this.get('profitCurrencyOptionSwitchFirst');

      var lpSelectAll = this.get('lpSelectAll');
      var lpMultiSelect = this.get('lpMultiSelect');
      var symbolSelectAll = this.get('symbolSelectAll');
      var symbolMultiSelect = this.get('symbolMultiSelect');
      var bkSymbolSelectAll = this.get('bkSymbolSelectAll');
      var bkSymbolMultiSelect = this.get('bkSymbolMultiSelect');

      var currencyReact = this.get('currencyReact');
      var profitCurrencyReact = this.get('profitCurrencyReact');

      controller.set('defaultBrokerId', this.get('defaultBrokerId'));

      controller.set('brokerWithAllOption', brokerWithAllOption);
      controller.set('lpWithAllOption', lpWithAllOption);
      controller.set('symbolWithAllOption', symbolWithAllOption);
      controller.set('bkSymbolWithAllOption', bkSymbolWithAllOption);
      controller.set('bookWithAllOption', bookWithAllOption);
      controller.set('allocatorWithAllOption', allocatorWithAllOption);
      controller.set('itWithAllOption', itWithAllOption);
      controller.set('currencyWithAllOption', currencyWithAllOption);
      controller.set('profitCurrencyWithAllOption', profitCurrencyWithAllOption);

      controller.set('lpOptionSwitchFirst', lpOptionSwitchFirst);
      controller.set('symbolOptionSwitchFirst', symbolOptionSwitchFirst);
      controller.set('bkSymbolOptionSwitchFirst', bkSymbolOptionSwitchFirst);
      controller.set('currencyOptionSwitchFirst', currencyOptionSwitchFirst);
      controller.set('bookOptionSwitchFirst', bookOptionSwitchFirst);
      controller.set('allocatorOptionSwitchFirst', allocatorOptionSwitchFirst);
      controller.set('profitCurrencyOptionSwitchFirst', profitCurrencyOptionSwitchFirst);

      controller.set('lpSelectAll', lpSelectAll);
      controller.set('lpMultiSelect', lpMultiSelect);
      controller.set('symbolSelectAll', symbolSelectAll);
      controller.set('symbolMultiSelect', symbolMultiSelect);
      controller.set('bkSymbolSelectAll', bkSymbolSelectAll);
      controller.set('bkSymbolMultiSelect', bkSymbolMultiSelect);

      controller.set('currencyReact', currencyReact);
      controller.set('profitCurrencyReact', profitCurrencyReact);

      //
      if (resetBrokerId) {
        var bkId = this.get('brokerId');
        if (bootstrap.getProfileType() === _enums.AdminOperatorType.MANAGER) {
          controller.set('brokerId', bkId);
        } else {
          controller.set('brokerId', brokerWithAllOption ? 0 : bkId);
        }
        //
        var bkSymbols = bootstrap.getSelectionByBrokerId('broker.symbol', controller.get('brokerId'));
        if (!Ember.isEmpty(bkSymbols)) {
          controller.set('symbolId', symbolWithAllOption ? 0 : (0, _commonGenerator.getSymbolIdByBkSymbolId)(bkSymbols[0].value));
        }
        // default bookId
        var books = bootstrap.getSelectionByBrokerId('broker.book', controller.get('brokerId'));
        if (!Ember.isEmpty(books)) {
          controller.set('bookId', bookWithAllOption ? 0 : books[0].value);
        }
      }

      if (controller.get('paginatable')) {
        Ember.sendEvent(controller, 'resetPaginate');
      }
      controller.set('sortable', this.get('sortable'));

      var parent = this.get('parentNode');
      var parentRoute = parent ? parent.route : undefined;
      var isDirtyTab = navtabs.isDirtyTab(tabRoute, parentRoute);
      var shouldResume = parent ? false : isDirtyTab;

      if (shouldResume) {
        var tab = navtabs.getTab(tabRoute);
        if (tab) {
          controller.set('isResumed', true);
          if (!controller.get('ignoreResumeParam')) {
            controller.baseResumeParam(tab.param);
          }
          if (!controller.get('ignoreResumeModel')) {
            controller.baseResumeModel(tab.model);
          }
        }
      } else if (navtabs.isNewTab(tabRoute, parentRoute)) {
        if (tabbed) {
          if (navtabs.get('isEmpty') && parentRoute) {
            navtabs.addTab(parentRoute, bootstrap.getMenuName(parentRoute), null, null, false);
          } else {
            navtabs.addTab(tabRoute, bootstrap.getMenuName(tabRoute), null, null, false);
          }
        }
      }
      if (this.get('preload')) {
        if (this.beforePreload) {
          this.beforePreload(controller, model);
        }
        controller.send('reload');
      }
    },
    resetController: function resetController(controller, isExisting /*, transition*/) {
      this._super.apply(this, arguments);
      if (isExisting) {
        var taskId = controller.get('taskId');
        if (taskId) {
          Ember.run.cancel(taskId);
        }
        controller.set('isResumed', false);
        controller.set('sortable', false);
        controller.send('closeModal');
      }
    },


    actions: {
      reload: function reload() {
        this.get('controller').send('reload');
      },
      error: function error(_error) /*transition*/{
        console.warn(_error);
        if (_error.status === '403') {
          this.replaceWith('login');
        } else {
          return true;
        }
      }
    }

  });
});
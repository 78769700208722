define('frodo/helpers/abbr', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.abbr = abbr;
  function abbr(params, hash) {
    var value = params[0];
    var length = hash && hash.length ? hash.length : 30;
    if (!value && value !== 0) return '-';
    if (length < 0 || value.length <= length + 1) return value;else return value.substring(0, length + 1) + '...';
  }

  exports.default = Ember.Helper.helper(abbr);
});
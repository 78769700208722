define('frodo/pods/index/risk/risk/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/risk/holding-model'], function (exports, _baseModel, _holdingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _holdingModel.default.extend({

    bootstrap: Ember.inject.service(),

    bestCombination: Ember.computed('lpPositions.@each.{askPrice,bidPrice,weight}', function () {
      var minAsk = null,
          maxBid = null;
      var snapshot = _.cloneDeep(this.get('lpPositions'));
      _.each(snapshot, function (lp) {
        if (lp) {
          if (Ember.isEmpty(minAsk) || Ember.isEmpty(minAsk.get('askPrice'))) {
            minAsk = lp;
          } else if (new BigNumber(minAsk.get('askPrice')).gt(new BigNumber(lp.get('askPrice')))) {
            minAsk = lp;
          }
          if (Ember.isEmpty(maxBid) || Ember.isEmpty(maxBid.get('bidPrice'))) {
            maxBid = lp;
          } else if (new BigNumber(maxBid.get('bidPrice')).lt(new BigNumber(lp.get('bidPrice')))) {
            maxBid = lp;
          }
        }
      });
      return { bestAskLp: minAsk, bestBidLp: maxBid };
    }),

    bookLastTimeLp: Ember.computed('bestCombination', function () {
      var ask = this.get('bestCombination').bestAskLp,
          bid = this.get('bestCombination').bestBidLp;
      if (ask && bid) {
        return _.maxBy([ask, bid], function (p) {
          return p.get('updateDatetime');
        });
      }
    }),
    expandClass: Ember.computed('expand', function () {
      return this.get('expand') ? 'fa-minus-circle' : 'fa-plus-circle';
    }),
    balanceClass: Ember.computed('expand', function () {
      return this.get('expand') ? '' : 'hidden';
    })
  });


  function fetch(owner, data) {
    if (data.refresh) {
      return owner.get('ajax').request(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/position/refresh', { data: data });
    } else {
      return owner.get('ajax').request(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/position', { data: data });
    }
  }
  exports.fetch = fetch;
});
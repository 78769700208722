define('frodo/pods/index/trades/hedge-order-history/route', ['exports', 'frodo/pods/index/trades/trades-route'], function (exports, _tradesRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tradesRoute.default.extend({
    preload: false,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('isResumed')) {
        controller.set('lpId', 0);
        controller.set('status', 0);
        controller.set('type', 0);
      }
    }
  });
});
define('frodo/pods/components/common/field-boolean/component', ['exports', 'frodo/pods/components/common/field-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    size: 'small', // 'small', 'medium', 'large'
    onLabel: 'ON',
    offLabel: 'OFF',
    defaultValue: false,
    hasHelp: false,
    helpContent: "",

    didInsertElement: function didInsertElement() {
      if (typeof this.get('value') === 'undefined') {
        this.set('value', this.get('defaultValue'));
      }
    }
  });
});
define('frodo/pods/index/pricing/quotation-history/controller', ['exports', 'frodo/pods/index/pricing/quotation-history/model', 'frodo/pods/index/pricing/pricing-controller', 'frodo/mixins/paginatable', 'frodo/utils/common-generator', 'frodo/models/enums'], function (exports, _model, _pricingController, _paginatable, _commonGenerator, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pricingController.default.extend(_paginatable.default, {
    timeDisable: true,
    RoundingMode: _enums.RoundingMode,

    doSearch: function doSearch(params) {
      var _this = this;

      var p = this.build(params);
      var bkSymbols = this.get('bootstrap').getSelection('broker.symbol');
      this.set('bkSymbol', this.getBkSymbol(bkSymbols, this.get('brokerId'), (0, _commonGenerator.getSymbolIdByBkSymbolId)(p.bkSymbolId)));

      return (0, _model.fetch)(this, p).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            return _model.default.create(o);
          });
          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, p);
      });
    },
    build: function build(p) {
      if (this.get('brokerId')) p.brokerId = this.get('brokerId');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('bkSymbolId')) p.bkSymbolId = this.get('bkSymbolId');

      if (this.get('date') && this.get('startTime') && this.get('endTime')) {
        var date = new Date(this.get('date'));var fromTime = new Date(this.get('startTime'));var toTime = new Date(this.get('endTime'));
        var from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), fromTime.getHours(), fromTime.getMinutes(), fromTime.getSeconds()).getTime();
        var to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), toTime.getHours(), toTime.getMinutes(), toTime.getSeconds()).getTime();
        p.from = from;
        p.to = to;
      }
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('lpId', p.lpId);
      this.set('brokerId', p.brokerId);
      this.set('bkSymbolId', p.bkSymbolId);
      var start = new Date(p.from);
      var date = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0).getTime();
      this.set('date', date);
      this.set('startTime', p.from);
      this.set('endTime', p.to);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
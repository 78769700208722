define('frodo/helpers/lp-json-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    bootstrap: Ember.inject.service(),
    compute: function compute(params /*, hash*/) {
      if (Ember.isEmpty(params[0]) || Ember.isEmpty(params[1]) || Ember.isEmpty(params[2])) return "-";
      var type = params[0];var action = params[1];var value = Ember.isArray(params[2]) ? params[2] : params[2].values;
      var selectionObject = this.get('bootstrap.selection')[type];
      if (Ember.isEmpty(selectionObject) || Ember.isEmpty(value) || Ember.isEmpty(action) || !Ember.isArray(value)) {
        return '-';
      } else {
        var r = "";

        if (action == "making-config") {
          value.forEach(function (value) {
            var found = _.find(selectionObject, function (e) {
              return e.value == value;
            });
            if (found) r += "," + found.name;
          });
        } else if (action == "filter-config") {
          value.forEach(function (value) {
            var found = _.find(selectionObject, function (e) {
              return e.value == value.v1;
            });
            if (found) r += "," + found.name;
          });
        }
        return _.trim(r, ",");
      }
    }
  });
});
define('frodo/helpers/multiple-selection-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    i18n: Ember.inject.service(),
    bootstrap: Ember.inject.service(),

    compute: function compute(params) /*hash*/{
      var type = params[0];
      var values = params[1];
      var max = params[2];

      if (Ember.isEmpty(values)) {
        return '-';
      }
      if (Ember.isEmpty(type)) {
        return '-';
      }
      var selectionObject = this.get('bootstrap.selection')[type];
      if (Ember.isEmpty(selectionObject)) return '-';
      var names = [];

      values.forEach(function (v) {

        var found = _.find(selectionObject, function (e) {
          return e.value === v || e.value === parseInt(v);
        });
        var n = found ? found.name : '-';
        names.push(n);
      });

      if (Ember.isEmpty(names)) {
        return '-';
      }
      var r = names.join(',');
      if (r.length > max) {
        return r.substring(0, max).concat('...');
      }
      return r;
    }
  });
});
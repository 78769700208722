define('frodo/services/scheduler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_tasks', Ember.A());
    },
    willDestroy: function willDestroy() {
      this.stopAll();
    },


    /**
     *
     */
    start: function start(handle) {
      return this._start('handle', handle);
    },
    stop: function stop(handle) {
      if (handle && typeof clearInterval !== 'undefined') {
        clearInterval(handle);
      }
    },
    remove: function remove(handle) {
      var task = this.get('_tasks').findBy('handle', handle);
      this.stop(task.handle);
      this.get('_tasks').removeObject(task);
    },
    stopAll: function stopAll() {
      var handles = this.get('_tasks').mapBy('handle');
      handles.forEach(this.stop);
    },
    removeAll: function removeAll() {
      var handles = this.get('_tasks').mapBy('handle');
      handles.forEach(this.remove);
    },
    add: function add(_ref) {
      var action = _ref.action,
          interval = _ref.interval;

      if (interval <= 1) {
        throw new Error('Scheduler interval must be greater than 1');
      }

      var handle = this._schedule(action, interval);
      var task = { handle: handle, action: action, interval: interval };
      this.get('_tasks').pushObject(task);
      return handle;
    },


    /**
     *
     */
    _start: function _start(key, value) {
      var task = this.get('_tasks').findBy(key, value);
      if (task) {
        var action = task.action,
            interval = task.interval;

        return this._schedule(action, interval);
      } else {
        console.warn('[scheduler] task:' + key + ' not found');
      }
    },
    _schedule: function _schedule(fn, interval) {
      if (typeof setInterval !== 'undefined') {
        return setInterval(Ember.run.bind(this, fn), interval);
      }
    }
  });
});
define('frodo/mixins/broker-aware', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    resetBrokerId: true,
    brokerWithAllOption: false,

    brokerId: Ember.computed('bootstrap.brokerId', function () {
      return this.get('bootstrap.brokerId');
    }),

    defaultBrokerId: Ember.computed.readOnly('brokerId')

  });
});
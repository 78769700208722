define('frodo/pods/index/risk/position-alertor/model', ['exports', 'frodo/pods/index/risk/holding-model', 'frodo/pods/index/base-model'], function (exports, _holdingModel, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.remove = exports.edit = exports.add = exports.check = exports.fetch = undefined;
  exports.default = _holdingModel.default.extend({});


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/position_alertor', { data: data });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/position_alertor/validate', { data: data });
  }

  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/position_alertor', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/position_alertor/' + data.id, { data: data });
  }

  function remove(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/position_alertor/' + data.id + '/' + data.version);
  }

  exports.fetch = fetch;
  exports.check = check;
  exports.add = add;
  exports.edit = edit;
  exports.remove = remove;
});
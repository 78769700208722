define('frodo/pods/index/market/lp-symbol/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    symbolId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true })],
    pricingRestriction: [(0, _validators.validatePresence)(true)],
    tradingRestriction: [(0, _validators.validatePresence)(true)],
    subscriptionDepth: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true, positive: true, lte: 99 })],
    weight: [(0, _validators.validatePresence)(true)],
    hedgingMaxVolume: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true })],
    hedgingMinVolume: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true })],
    hedgingMinAmount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true })]
  };
});
define('frodo/pods/components/index/making/modal-making-config/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/making/making-config/model', 'frodo/models/enums'], function (exports, _component, _model, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    lpTypeProvider: _enums.LpType.LIQUIDITY_PROVIDER,
    lpTypeDemander: _enums.LpType.LIQUIDITY_DEMANDER,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("demanderLpsOptions", this.getDemanderLpsOptions(this.get('changeset.brokerId')));
      this.set("symbolOptions", this.getSymbolOptionsByLp(this.get('changeset.lpId')));
    },
    getDemanderLpsOptions: function getDemanderLpsOptions(brokerId) {
      var lpType = this.lpTypeDemander;
      return _.filter(this.get("bootstrap").getSelection('broker.lp'), function (o) {
        return brokerId == o.extra.$group && o.extra.$group2 == lpType;
      });
    },


    l1OrderActiveObserver: Ember.observer('changeset.l1OrderActive', function () {
      var l1OrderActive = this.get('changeset.l1OrderActive');
      if (!l1OrderActive) {
        this.set("changeset.tradeActive", false);
        this.set("changeset.l2OrderActive", false);
      }
    }),

    tradeActiveObserver: Ember.observer('changeset.tradeActive', function () {
      var tradeActive = this.get('changeset.tradeActive');
      if (tradeActive) {
        this.set("changeset.l1OrderActive", true);
      }
    }),

    l2OrderActiveObserver: Ember.observer('changeset.l2OrderActive', function () {
      var l2OrderActive = this.get('changeset.l2OrderActive');
      if (l2OrderActive) {
        this.set("changeset.l1OrderActive", true);
      }
    }),

    actions: {
      onSelectBroker: function onSelectBroker() {
        var brokerId = this.get('changeset.brokerId');
        var changeset = this.get('changeset');

        this.set("demanderLpsOptions", this.getDemanderLpsOptions(brokerId));
        this.set("symbolsOptions", this.getSymbolOptionsByLp(changeset.get('lpId')));
      },
      onSelectLp: function onSelectLp() {
        this.set("symbolOptions", this.getSymbolOptionsByLp(this.get('changeset.lpId')));
      },
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      }
    }
  });
});
define('frodo/pods/components/common/button-search/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['col-sm-3', 'ml-sm-auto'],

    onHit: null,
    isSearching: false,
    canNotSearch: false,
    offsetClass: null,

    classNameBindings: ['offsetClass'],

    didRender: function didRender() {
      this.$().attr('style', 'width:auto');
    },


    actions: {
      hit: function hit() {
        this.get('onHit')();
      }
    }
  });
});
define('frodo/pods/components/common/sortable-table/component', ['exports', 'frodo/mixins/result'], function (exports, _result) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_result.default, {

    sortable: false,

    i18n: Ember.inject.service(),
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._initialize();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._toggleSortSubs();
    },
    didDestroyElement: function didDestroyElement() {
      this._destroySortSubs();
    },
    _initialize: function _initialize() {
      var options = {
        handle: '.sort-tr',
        draggable: 'tr',
        ghostClass: "sort-ghost",
        disabled: !this.get('sortable'),
        onEnd: function (evt) {
          this.send('end', evt);
        }.bind(this)
      };

      this.set('tBody', Sortable.create(this.$('tbody')[0], options));
    },


    actions: {
      end: function end() /*evt*/{
        var _this = this;

        var sorted = [];
        this.$('tbody').find('tr').each(function (i, m) {
          sorted.push(+Ember.$(m).attr('data-id'));
        });

        var action = this.get('onSort');
        if (action) {
          action(sorted).then(function (r) {

            var i18n = _this.get('i18n');
            var router = _this.get('router');
            var flash = _this.get('flashMessages');

            if (_this.isSuccess(r)) {
              var name = router.get('currentRouteName');
              flash.info(i18n.t('label.notification.sorted', { name: i18n.t('menu.' + name) }));
            } else {
              flash.danger(i18n.t(r.key));
            }
          });
        }
      }
    },

    _toggleSortSubs: function _toggleSortSubs() {

      var tBody = this.get('tBody');
      var sortable = this.get('sortable');
      if (tBody) {
        tBody.option('disabled', !sortable);
      }
    },
    _destroySortSubs: function _destroySortSubs() {
      var tBody = this.get('tBody');
      if (tBody) {
        tBody.destroy();
      }
    }
  });
});
define('frodo/pods/index/system/alert/controller', ['exports', 'frodo/pods/index/system/alert/model', 'frodo/pods/index/system/system-controller', 'frodo/mixins/paginatable'], function (exports, _model, _systemController, _paginatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemController.default.extend(_paginatable.default, {
    appName: 0,
    appId: 0,
    monitorLevel: 0,

    actions: {
      reset: function reset() {
        return (0, _model.reset)(this);
      },
      guard: function guard() {
        this.get('router').transitionTo('index.system.alert_guard');
      }
    },

    doSearch: function doSearch(params) {
      var _this = this;

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            return _model.default.create(o);
          });
          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('appId')) p.appId = this.get('appId');
      if (this.get('appName')) p.appName = this.get('appName');
      if (this.get('monitorLevel')) p.monitorLevel = this.get('monitorLevel');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('appId', p.hasOwnProperty('appId') ? p.appId : 0);
      this.set('appName', p.hasOwnProperty('appName') ? p.appName : 0);
      this.set('monitorLevel', p.hasOwnProperty('monitorLevel') ? p.monitorLevel : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
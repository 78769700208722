define('frodo/pods/index/pricing/dealing-panel/route', ['exports', 'frodo/pods/index/pricing/pricing-route', 'frodo/pods/index/pricing/dealing-panel/model', 'frodo/models/enums'], function (exports, _pricingRoute, _model, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pricingRoute.default.extend({

    queryParams: {
      reload: {
        refreshModel: true
      },
      payload: {
        refreshModel: true
      }
    },

    model: function model(params, transition) {

      this._super.apply(this, arguments);
      var isReload = params.reload === 'true';
      var payload = params.payload;
      var indexCtrl = this.controllerFor('index');
      var previousTransition = void 0;
      if (indexCtrl) {
        previousTransition = indexCtrl.get('previousTransition');
        if (previousTransition && previousTransition.targetName === this.routeName) {
          previousTransition = undefined;
        }
      }

      if (previousTransition || isReload || payload) {
        transition.abort();
      }

      if (isReload) {
        if (!window.isPopWindow && previousTransition) this.reloadDialog();
        return;
      }
      if (payload) {
        try {
          payload = JSON.parse(payload);
        } catch (err) {
          payload = undefined;
        }
      }
      this.createDialog({}, { payload: payload });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      this.controllerFor('index').set('hideNavbar', true);
      controller.set('windowStackOverflow', this.get('fullWindow'));
    },
    createDialog: function createDialog() {

      var dialog = Ember.getOwner(this).lookup('component:index/pricing/modal-dealing-panel');

      dialog.set('model', _model.default.create({ panelType: _enums.PanelType.DEALING, brokerId: 1, lpId: 257, symbolId: 1, volumeScale: 3, priceScale: 5, orderPrice: 0.0002, orderVolume: 50, orderType: 3, side: 1, timeInForce: _enums.TimeInForce.IOC }));
      var ds = this.get('dialogManager').getDialogSettings();

      var settings = _model.default.create(ds);

      dialog.set('settings', settings);
      dialog.set('hasWritePrivilege', this.get('hasRO'));
      this.get('dialogManager').set('settings', settings);

      var pushed = this.get('dialogManager').pushDialog(dialog);

      if (pushed) {
        dialog.append();
      } else {
        // let i18n = this.get('i18n');
        dialog = null;
        alert('full window');
      }
    },
    reloadDialog: function reloadDialog() {}
  });
});
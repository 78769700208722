define('frodo/helpers/enum-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    i18n: Ember.inject.service(),
    bootstrap: Ember.inject.service(),

    compute: function compute(params, hash) {
      var type = params[0];
      var value = params[1];
      var selectionObject = this.get('bootstrap').getEnum(type);

      if (Ember.isEmpty(selectionObject) || Ember.isEmpty(value) || Ember.isEmpty(type)) {
        return '-';
      } else {
        var found = _.find(selectionObject, function (e) {
          return e.value === value;
        });

        var name = found ? this.get('i18n').t(found.name) : undefined;

        if (!name) return '-';

        if (hash && hash.colors) {
          var color = hash.colors[value];
          return Ember.String.htmlSafe('<span class=' + color + '>' + name + '</span>');
        }

        return name;
      }
    }
  });
});
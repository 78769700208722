define('frodo/pods/components/index/risk/modal-risk-profit-link/component', ['exports', 'frodo/pods/index/risk/trigger/model', 'frodo/pods/index/risk/trigger/validator', 'frodo/pods/index/base-model'], function (exports, _model, _validator, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TRIGGER = undefined;
  var TRIGGER = exports.TRIGGER = 'risk.trigger';
  //
  exports.default = Ember.Component.extend({
    type: null,
    value: null,
    menu: null,
    //
    volume: null,
    amount: null,
    ask: null,
    bid: null,
    scale: null,
    bookId: null,
    bkSymbolId: null,
    //
    record: null,
    recordId: null,
    //
    ValidatorTarget: _validator.default,
    TRIGGER: TRIGGER,
    //
    ajax: Ember.inject.service(),
    bootstrap: Ember.inject.service(),
    i18n: Ember.inject.service(),

    hasR: Ember.computed('menu', function () {
      var menu = this.get('menu');
      return this.get('bootstrap').hasReadPrivilege(menu);
    }),

    hasRW: Ember.computed('menu', function () {
      var menu = this.get('menu');
      return this.get('bootstrap').hasWritePrivilege(menu);
    }),

    title: Ember.computed('menu', function () {
      var menu = this.get('menu');
      var r = "";
      if (menu == TRIGGER) {
        r = this.get('i18n').t('menu.index.risk.trigger');
      }
      return r;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },


    actions: {
      getRecord: function getRecord() {
        var _this = this;

        var menu = this.get('menu');
        switch (menu) {
          case TRIGGER:
            this._getTriger().then(function (r) {
              _this.set('record', _baseModel.default.create(r));
            });break;
          default:
            this.set('record', null);
        }
      },
      resetRecord: function resetRecord() {
        this.set('record', null);
      }
    },
    _getTriger: function _getTriger() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        (0, _model.one)(_this2, { bookId: _this2.get('bookId'), bkSymbolId: _this2.get('bkSymbolId') }).then(function (r) {
          resolve(r.data);
        });
      });
    }
  });
});
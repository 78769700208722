define('frodo/helpers/json-simple-values', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    i18n: Ember.inject.service(),
    bootstrap: Ember.inject.service(),

    compute: function compute(params, hash) {
      var json = params[0] || '';
      var v = hash && hash.values || null;
      var max = hash && hash.length || 30;
      var key = hash && hash.key || 'values';
      var separator = hash && hash.separator || ',';
      //
      var values = void 0;
      var names = [];

      if (v) {
        values = v;
      } else {
        if (!json && json.length === 0) {
          return '-';
        }
        values = json[key];
      }

      if (Ember.isEmpty(values)) {
        return '-';
      }

      values.forEach(function (v) {
        names.push(v);
      });

      if (Ember.isEmpty(names)) {
        return '-';
      }

      var r = names.join(separator);
      if (r.length > max) {
        return r.substring(0, max).concat('...');
      }
      return r;
    }
  });
});
define("frodo/pods/index/operator/operator/model", ["exports", "frodo/pods/index/base-model", "frodo/models/enums", "frodo/pods/index/operator/operator-model"], function (exports, _baseModel, _enums, _operatorModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.grant = exports.roles = exports.remove = exports.edit = exports.check = exports.fetch = exports.add = undefined;
  exports.default = _operatorModel.default.extend({
    isRoot: Ember.computed('type', function () {
      return this.get('type') === _enums.AdminOperatorType.ROOT;
    })
  });


  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + "/" + _operatorModel.PARENT + "/operator", { data: data });
  }

  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + "/" + _operatorModel.PARENT + "/operators", { data: data });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + "/" + _operatorModel.PARENT + "/operator/validate", { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + "/" + _operatorModel.PARENT + "/operator/" + data.id, { data: data });
  }

  function remove(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + "/" + _operatorModel.PARENT + "/operator/" + data.id + "/" + data.version);
  }

  function roles(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + "/" + _operatorModel.PARENT + "/operator/" + data.id + "/roles");
  }

  function grant(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + "/" + _operatorModel.PARENT + "/operator/" + data.id + "/roles", { data: { roles: data.roles.toString() } });
  }

  exports.add = add;
  exports.fetch = fetch;
  exports.check = check;
  exports.edit = edit;
  exports.remove = remove;
  exports.roles = roles;
  exports.grant = grant;
});
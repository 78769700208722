define('frodo/pods/components/common/triples-table/component', ['exports', 'frodo/pods/index/base-model'], function (exports, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    collections: Ember.A(),
    classNameBindings: ['row'],

    buttonDisable: Ember.computed('readonly', 'preview', function () {
      if (this.get('readonly') || this.get('preview')) return true;
    }),

    lastIndexOfElements: Ember.computed('collections.@each', function () {
      if (this.get('collections')) {
        if (Ember.isEmpty(this.get('collections'))) {
          return 0;
        }
        return this.get('collections').length - 1;
      } else {
        return 0;
      }
    }),

    isEdit: Ember.computed('model', function () {
      return this.get('model.id') > 0;
    }),

    updateIndex: function updateIndex() {
      var arr = this.get('collections');
      if (arr) {
        var len = arr.length;
        for (var i = 0; i < len; i++) {
          arr[i][this.get('indexColumn')] = i + 1;
        }
      }
    },


    actions: {
      addElement: function addElement() {
        var copied = this.get('collections');
        if (copied) {
          var max = copied.length;
          var o = { v1: 0, v2: 0 };
          o[this.get('indexColumn')] = max + 1;
          copied.pushObject(o);
        }
        this.notifyPropertyChange('collections');
      },
      removeElement: function removeElement(o) {
        var arr = this.get('collections');
        if (arr && arr.length > 1) {
          arr.removeObject(o);
        }
        this.updateIndex();
        this.notifyPropertyChange('collections');
      },
      upElement: function upElement(o, index) {
        var arr = this.get('collections');
        if (arr) {
          var preObj = _baseModel.default.create(arr[index - 1]);
          arr[index - 1] = o;
          arr[index] = preObj;
        }
        this.updateIndex();
        this.notifyPropertyChange('collections');
      },
      downElement: function downElement(o, index) {
        var arr = this.get('collections');
        if (arr) {
          var nextObj = _baseModel.default.create(arr[index + 1]);
          arr[index + 1] = o;
          arr[index] = nextObj;
        }
        this.updateIndex();
        this.notifyPropertyChange('collections');
      }
    }

  });
});
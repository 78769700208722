define('frodo/pods/index/making/filter-config/route', ['exports', 'frodo/pods/index/making/making-route'], function (exports, _makingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingRoute.default.extend({
    preload: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('isResumed')) {
        controller.set('bkSymbolId', 0);
      }
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('bkSymbolId', 0);
    }
  });
});
define('frodo/pods/index/trades/trades-controller', ['exports', 'frodo/pods/index/base-controller'], function (exports, _baseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({

    reportModeColors: Ember.computed(function () {
      return { 1: 'text-success', 2: 'text-warning' };
    }),

    statusColors: Ember.computed(function () {
      return {
        1: 'text-secondary', // PENDING: 1
        3: 'text-muted', // EXECUTING: 3
        4: 'text-success', // EXECUTED: 4
        5: 'text-secondary', // EXPIRED: 5
        7: 'text-warning', // REJECTED: 7
        8: 'text-muted', // CANCELLING: 8
        9: 'text-warning', // CANCELLED: 9
        97: 'text-danger', // INTERRUPTED: 98
        98: 'text-danger', // INTERRUPTED: 98
        99: 'text-danger' }; // UNKNOWN: 99
    })

  });
});
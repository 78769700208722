define('frodo/pods/index/market/lp-symbol/controller', ['exports', 'frodo/pods/index/market/lp-symbol/validator', 'frodo/pods/index/market/lp-symbol/model', 'frodo/pods/index/market/market-controller'], function (exports, _validator, _model, _marketController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    parentRoot: "market.lp",
    Validator: _validator.default,

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this, { lpId: this.get('intent.lpId'), brokerId: this.get('intent.brokerId') }).then(function (r) {
          if (_this.isData(r)) {
            _this.set('model', _.map(r.data, function (s) {
              return _model.default.create(s);
            }));
          }
        });
      },
      fill: function fill(e) {
        this.set('record.itId', e.itId);
        this.set('record.lpId', e.lpId);
        this.set('record.brokerId', e.brokerId);
      },
      openAdd: function openAdd() {
        this.set('record', _model.default.create({}));
      },


      // openLpSymbol(lp) {
      //   symbols(this, lp).then((r) => {
      //     this.set('symbols',r.data);
      //     this.set('record',lp);
      //   });
      // },
      //
      // symbols(/*lp*/) {
      // },

      sort: function sort(ids) {
        return (0, _model.sort)(this, { lpId: this.get('intent.lpId'), lpSymbolIds: ids.toString() });
      }
    }
  });
});
define('frodo/pods/components/common/quote-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(Ember.Evented, {

    classNames: ['quotation'],
    classNameBindings: ['up', 'down'],

    scale: 0,
    value: null,
    prevValue: null,

    displayValue: Ember.computed('value', 'scale', function () {
      var pattern = '0,0';
      var value = this.get('value');
      var scale = this.get('scale');

      if (value === undefined || value === null) {
        return '-';
      }

      if (scale && scale > 0) {
        pattern += function (s) {
          var e = '.';
          for (var i = 0; i < s; i++) {
            e += '0';
          }
          return e;
        }(scale);
      }

      return numeral(value).format(pattern);
    }),

    up: false,
    down: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.on('valueChanged', this.decorate.bind(this));
    },
    didReceiveAttrs: function didReceiveAttrs() {

      var nextValue = this.get('value');
      var prevValue = this.get('prevValue');

      if (prevValue !== nextValue) {
        this.prevValue = nextValue;
        this.trigger('valueChanged', prevValue, nextValue);
      }
    },
    decorate: function decorate(prev, next) {
      if (prev === next || next === undefined || next === 0) {
        this.set('up', false);
        this.set('down', false);
      }

      this.set('up', prev < next);
      this.set('down', prev > next);
    }
  });
});
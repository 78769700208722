define('frodo/pods/index/risk/risk/route', ['exports', 'frodo/pods/index/risk/holding-route'], function (exports, _holdingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingRoute.default.extend({
    preload: true,
    ignoreResumeModel: true,
    bookWithAllOption: false,
    profitCurrencyWithAllOption: false,
    profitCurrencyOptionSwitchFirst: true,

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var sc = controller.get('subscription');
      controller.set('prev_subscription', sc);
    },
    resetController: function resetController(controller, isExisting) {
      this._super.apply(this, arguments);
      if (this.taskId) {
        this.get('scheduler').stop(this.taskId);
      }
      if (isExisting) {
        var sc = controller.get('subscription');
        controller.set('isSubscribed', false);
        controller.send('unsubscribe', sc);
        controller.resetPosition();
      }
    },
    beforePreload: function beforePreload(controller /* , model */) {
      if (!controller.get('isResumed')) {
        controller._reactProfitCurrency();
      }
    }
  });
});
define('frodo/pods/index/risk/cp-balance/controller', ['exports', 'frodo/pods/index/risk/cp-balance/model', 'frodo/pods/index/risk/holding-controller'], function (exports, _model, _holdingController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = _holdingController.default.extend({
    sortable: true,
    actions: {
      brokerSelected: function brokerSelected() {
        this.set('records', {});
        this.set('groups', {});
        //
        var brokerId = this.get('brokerId');
        this.onSelectBroker(brokerId, true, true, true);
        this._reactMultiCurrency();
        //
        this.send("reload");
      },
      reload: function reload() {
        var _this = this;

        var selectLps = this.get("lpValues");
        var selectCurrencys = this.get("currencys");
        var self = this;
        var brokerId = this.get('brokerId');
        var p = { brokerId: brokerId };
        (0, _model.fetch)(this, p).then(function (r) {
          if (_this.isData(r)) {
            //
            var records = Ember.A();
            var allCurrencies = Ember.A();
            _.mapKeys(r.data.data, function (value, key) {
              //key:lp
              var model = Ember.A();
              var currencies = Ember.A();
              _.mapKeys(value, function (value1, key1) {
                //key1:currency
                var currency = _model.default.create();
                currency.set("lpId", key);
                currency.set("currencyId", key1);
                currency.set("unit", self._currencyScale(key1));

                var accounts = Ember.A();
                var free = new BigNumber(0);
                var frozen = new BigNumber(0);
                var loan = new BigNumber(0);
                _.mapKeys(value1, function (value2, key2) {
                  //key2:balances / currencyId
                  _.mapKeys(value2, function (value3, key3) {
                    //key3:accountId
                    free = free.plus(new BigNumber(value3.free));
                    frozen = frozen.plus(new BigNumber(value3.frozen));
                    loan = value3.loan != undefined ? loan.plus(new BigNumber(value3.loan)) : new BigNumber(NaN);

                    var account = _model.default.create(value3);
                    account.set("accountId", key3);
                    account.set("unit", self._currencyScale(key1));
                    if (!loan.isNaN()) account.set("sum", new BigNumber(value3.free).plus(new BigNumber(value3.frozen)).plus(loan));else account.set("sum", new BigNumber(value3.free).plus(new BigNumber(value3.frozen)));

                    allCurrencies.push(account);
                    accounts.push(account);
                  });
                });

                currency.set("accounts", accounts);
                currency.set("free", free);
                currency.set("frozen", frozen);
                currency.set("loan", loan);

                if (!loan.isNaN()) currency.set("sum", new BigNumber(free).plus(new BigNumber(frozen)).plus(new BigNumber(loan)));else currency.set("sum", new BigNumber(free).plus(new BigNumber(frozen)));

                currencies.push(currency);
              });

              var foundLps = _.find(selectLps, function (e) {
                return e == key;
              });

              if (foundLps) {
                var filterCurrencies = _.filter(currencies, function (e) {
                  return _.includes(selectCurrencys, +e.currencyId);
                });
                model.set("lpId", key);
                model.set("currencies", filterCurrencies);
                records.push(model);
              }
            });

            _this.set('records', records);

            //sum
            var dest = Ember.A();
            var hasSumLoan = false;
            var filterAllCurrencies = _.filter(allCurrencies, function (e) {
              return _.includes(selectCurrencys, e.currencyId);
            });
            var group = _.groupBy(filterAllCurrencies, "currencyId");
            keys(group).forEach(function (currencyId) {
              var value = group[currencyId];
              var sumFree = new BigNumber('0');
              _.each(value, function (o) {
                sumFree = sumFree.plus(new BigNumber(o.free));
              });

              var sumFrozen = new BigNumber('0');
              _.each(value, function (o) {
                sumFrozen = sumFrozen.plus(new BigNumber(o.frozen));
              });

              var sumLoan = new BigNumber('0');
              _.each(value, function (o) {
                if (o.loan != undefined) {
                  hasSumLoan = true;
                  sumLoan = sumLoan.plus(new BigNumber(o.loan));
                }
              });
              var sumAll = new BigNumber('0');
              if (hasSumLoan) {
                sumAll = sumAll.plus(sumFree).plus(sumFrozen).plus(sumLoan);
              } else {
                sumAll = sumAll.plus(sumFree).plus(sumFrozen);
              }
              dest.push({
                currencyId: currencyId,
                free: sumFree,
                frozen: sumFrozen,
                loan: hasSumLoan ? sumLoan : "-",
                all: sumAll,
                unit: self._currencyScale(currencyId)
              });
            });

            if (p.brokerId !== _this.get('prevBrokerId')) {
              (0, _model.balance)(_this, Object.assign(p)).then(function (rr) {
                if (_this.isData(rr)) {
                  _this.set('prevBrokerId', p.brokerId);
                  var balance_arr = rr.data.balanceInfo;
                  if (balance_arr) {
                    var fb = _.first(balance_arr);
                    var prev_balance = fb ? fb.hasOwnProperty('balance') ? fb.balance : undefined : undefined;
                    _this.set('prevBalance', prev_balance);
                    _this.set('groups', _this._enhance(dest, prev_balance));
                  } else {
                    console.warn('invalid data.balanceInfo');
                  }
                } else {
                  console.warn('invalid data.type');
                }
              });
            } else {
              _this.set('groups', _this._enhance(dest, _this.get('prevBalance')));
            }

            //
            _this.set('refreshTime', r.data.refresh.refreshTime);
            _this.set('refreshInterval', r.data.refresh.refreshInterval);
            if (r.data.refresh.refreshInterval) {
              _this.refresh();
            }
            _this.updateNavtab(_this.get('currentRouteName'), {}, p);
          }
        });
      },
      fillRecord: function fillRecord(brokerId) {
        this.set('record', { brokerId: brokerId });
      },
      flush: function flush(brokerId) {
        return (0, _model.flush)(this, brokerId);
      }
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
    },
    resumeModel: function resumeModel(model) {
      this.set('records', model);
    },
    _enhance: function _enhance(source, prev) {
      var enhance = prev ? this._doEnhance(source, prev) : source;
      return enhance;
    },
    _doEnhance: function _doEnhance(source, prev) {
      _.forEach(source, function (s) {
        s.prev = prev[s.currencyId];
        var v1 = _.isNaN(s.all) || s.all === undefined ? 0 : s.all;
        var v2 = _.isNaN(s.prev) || s.prev === undefined ? 0 : s.prev;
        s.changing = new BigNumber(v1).minus(new BigNumber(v2));
      });
      return source;
    }
  });
});
define('frodo/pods/components/index/making/modal-dealing-making-statement/state-button/component', ['exports', 'frodo/mixins/result'], function (exports, _result) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_result.default, {

    tag: 'div',
    classNames: ['modal-footer'],

    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    model: null,
    restating: false,
    defaultLabel: 'label.common.apply',
    restateLabel: 'label.common.apply',

    isEdit: Ember.computed('model', function () {
      return this.get('model.id') > 0;
    }),

    restateDisable: Ember.computed('changeset.change.{longThreshold,shortThreshold,restate}', 'model.{longThreshold2,shortThreshold2,restate}', function () {
      var r = true;
      var m = this.get('model');
      var cs = this.get('changeset');
      var m_restate = m.restate;
      var m_longThreshold = m.longThreshold2;
      var m_shortThreshold = m.shortThreshold2;
      var c_restate = cs.get('restate');
      var c_longThreshold = cs.get('longThreshold');
      var c_shortThreshold = cs.get('shortThreshold');

      r &= c_restate === m_restate;
      if (c_longThreshold || m_longThreshold) {
        r &= c_longThreshold === m_longThreshold;
      }
      if (c_shortThreshold || m_shortThreshold) {
        r &= c_shortThreshold === m_shortThreshold;
      }
      return r;
    }),

    /**
     *
     */
    actions: {
      close: function close() {
        this.get('onClose')();
      },
      restate: function restate() {
        var _this = this;

        this.set('restating', true);
        var i18n = this.get('i18n');
        var flash = this.get('flashMessages');
        var t = false;
        Ember.run.later(this, function () {
          _this.get('onSubmit')().then(function () /*r*/{
            t = true;
            flash.info(i18n.t('label.notification.restated'));
          }, function (r) {
            if (!(r.error && r.local)) {
              // flash.danger(i18n.t(r.key));
            }
          }).finally(function () {
            if (t) {
              _this.send('close');
            }
            _this.set('restating', false);
          });
        }, 200);
      }
    }
  });
});
define('frodo/pods/components/index/market/modal-broker-symbol/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/market/broker-symbol/model'], function (exports, _component, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    actions: {
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      },
      setCurrency: function setCurrency() {
        var symbolId = this.get('changeset.symbolId');
        var symbol = this.get('bootstrap').getSelection('market.symbol', symbolId);
        if (symbolId && symbol) {
          this.set('changeset.type', symbol.extra.type);
          this.set('changeset.image', symbol.extra.image);
          this.set('changeset.baseCurrency', symbol.extra.baseCurrency);
          this.set('changeset.profitCurrency', symbol.extra.profitCurrency);
        } else {
          this.set('changeset.type', undefined);
          this.set('changeset.image', undefined);
          this.set('changeset.baseCurrency', undefined);
          this.set('changeset.profitCurrency', undefined);
        }
      }
    }
  });
});
define('frodo/pods/components/common/multi-select-dp/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'select',
    multiple: true,
    attributeBindings: ['multiple'],

    //
    onSelect: null,
    maxHeight: 300,
    readonly: false,
    primaryKey: 'value',
    validations: null,
    dataprovider: null,

    // official configuration
    numberDisplayed: 3,
    nonSelectedText: '　',
    allSelectedText: false,
    selectAllNumber: true,
    includeSelectAllOption: false,

    buttonClass: Ember.computed('validations', 'readonly', function () {
      if (this.get('readonly')) {
        return 'btn btn-default multiselect-btn-disable';
      }

      if (Ember.isEmpty(this.get('validations'))) {
        return 'btn btn-default multiselect-btn';
      } else {
        return 'btn btn-default multiselect-btn-danger';
      }
    }),

    didUpdateAttrs: function didUpdateAttrs() {
      this._updateDataProvider();
      this._checkDisable();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var value = this.get('value');
      var dataprovider = this.get('dataprovider');
      var $m = this.$().val(value).multiselect(this._init());

      $m.multiselect('dataprovider', dataprovider);

      this._checkDisable();
      this.set('$m', $m);
    },


    actions: {
      doSelect: function doSelect(isSelected, value) {
        var onSelect = this.get('onSelect');
        if (onSelect) {
          onSelect(isSelected, value, this.get('primaryKey'));
        }
      }
    },

    _rebuild: function _rebuild() {
      this.$().multiselect('rebuild');
    },
    _refresh: function _refresh() {
      this.$().multiselect('refresh');
    },
    _init: function _init() {
      return Ember.assign({}, this.__options(), this.__callback(this));
    },
    __options: function __options() {
      var buttonWidth = '100%';
      var maxHeight = this.get('maxHeight');
      var buttonClass = this.get('buttonClass');
      var selectAllNumber = this.get('selectAllNumber');
      var allSelectedText = this.get('allSelectedText');
      var numberDisplayed = this.get('numberDisplayed');
      var nonSelectedText = this.get('nonSelectedText');
      var includeSelectAllOption = this.get('includeSelectAllOption');

      return {
        maxHeight: maxHeight,
        buttonWidth: buttonWidth,
        buttonClass: buttonClass,
        selectAllNumber: selectAllNumber,
        allSelectedText: allSelectedText,
        numberDisplayed: numberDisplayed,
        nonSelectedText: nonSelectedText,
        includeSelectAllOption: includeSelectAllOption
      };
    },
    __callback: function __callback(owner) {
      return {
        onChange: function onChange(option, isSelected) {
          owner.send('doSelect', isSelected, this.$(option).val());
        }
      };
    },
    _checkDisable: function _checkDisable() {
      var _this = this;

      var $mc = Ember.$('.multiselect-container');
      var $options = $mc.find('input');

      var isDisabled = this.get('isDisabled');
      _.each($options, function (op) {
        _this.$(op).prop('disabled', isDisabled);
      });

      if (isDisabled) {
        this.$().parent().find('button').addClass('multiselect-btn-disable');
      } else {
        this.$().parent().find('button').removeClass('multiselect-btn-disable');
      }
    },
    _updateDataProvider: function _updateDataProvider() {
      this.$().multiselect('dataprovider', this.get('dataprovider'));
      this.$().multiselect('rebuild');
    }
  });
});
define('frodo/pods/index/-misc/misc-model', ['exports', 'frodo/pods/index/base-model'], function (exports, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   *
   */
  var BaseMiscModel = _baseModel.default.extend({});

  BaseMiscModel.reopenClass({});

  exports.default = BaseMiscModel;
});
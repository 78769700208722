define('frodo/pods/index/risk/cp-position/controller', ['exports', 'frodo/pods/index/risk/cp-position/model', 'frodo/pods/index/risk/holding-controller', 'frodo/models/enums'], function (exports, _model, _holdingController, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingController.default.extend({
    sortable: true,
    actions: {
      reload: function reload() {
        var _this = this;

        var self = this;
        var p = this.build();
        (0, _model.fetch)(this, p).then(function (r) {
          var positions = Ember.A();
          if (_this.isData(r)) {
            var bkSymbols = _this.get('bootstrap').getSelection('broker.symbol');
            _.mapKeys(r.data.data, function (value, key) {
              var model = Ember.A();
              var position = _.map(value, function (v) {
                var r = _model.default.create(v);
                var bkSymbol = self.getBkSymbol(bkSymbols, self.get('brokerId'), r.symbolId);
                if (bkSymbol) {
                  r.set("tradingVolumeScale", bkSymbol.extra.tradingVolumeScale);
                  r.set("tradingPriceScale", bkSymbol.extra.tradingPriceScale);
                } else {
                  r.set("tradingVolumeScale", 8);
                  r.set("tradingPriceScale", 8);
                }
                return r;
              });
              model.set("lpId", key);
              model.set("position", position);
              positions.push(model);
            });
            _this.set('records', positions);
          }
          _this.set('refreshTime', r.data.refresh.refreshTime);
          _this.set('refreshInterval', r.data.refresh.refreshInterval);
          if (r.data.refresh.refreshInterval) {
            _this.refresh();
          }
          _this.updateNavtab(_this.get('currentRouteName'), positions, p);
        });
      },
      flush: function flush(brokerId) {
        return (0, _model.flush)(this, brokerId);
      },
      fillRecord: function fillRecord(brokerId, lpId) {
        this.set('brokerId', brokerId);
        this.set('lpId', lpId);
        this.set('record', { brokerId: brokerId });
      }
    },
    build: function build() {
      var p = {};
      if (this.get('brokerId')) p.brokerId = this.get('brokerId');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.lpId);
    },

    filterLpOptions: Ember.computed('lpOptions', function () {
      var institutions = this.get('bootstrap').getSelection('broker.institution');
      var lps = _.filter(this.get('lpOptions'), function (s) {
        if (s.value === 0) return s;else {
          var it = _.find(institutions, function (e) {
            return e.value == s.extra.$group1;
          });
          return it && it.extra.$group === _enums.InstrumentType.SWAP;
        }
      });
      return lps;
    })
  });
});
define('frodo/pods/index/making/maintain/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/making/making-model'], function (exports, _baseModel, _makingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stop = exports.pause = exports.start = exports.reset = exports.fetch = undefined;
  exports.default = _makingModel.default.extend({});


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/maintain', { data: data });
  }
  function reset(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/maintain/reset', { data: data });
  }
  function start(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/maintain/start', { data: data });
  }
  function pause(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/maintain/pause', { data: data });
  }
  function stop(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/maintain/stop', { data: data });
  }

  exports.fetch = fetch;
  exports.reset = reset;
  exports.start = start;
  exports.pause = pause;
  exports.stop = stop;
});
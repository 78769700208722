define('frodo/pods/index/risk/holding-controller', ['exports', 'frodo/pods/index/base-controller'], function (exports, _baseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseController.default.extend({
    _currencyScale: function _currencyScale(currencyId) {
      var c = this.get('bootstrap').getSelection('market.currency', _.toNumber(currencyId));
      if (c) return c.extra.scale;else return 8;
    }
  });
});
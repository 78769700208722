define('frodo/pods/components/common/field-selection/component', ['exports', 'frodo/pods/components/common/field-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    group: -1,
    group1: -1,
    group2: -1,
    type: null,
    filter: null,
    onSelect: null,
    keepBlank: false,
    allowClear: false,
    emptyLabel: '',
    emptyOption: false,
    searchEnabled: false,
    hasFormGroup: true,
    mustHaveGroup: false,
    customizeOptions: null
  });
});
define('frodo/pods/components/index/making/modal-target-price-config/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/making/target-price-config/model', 'frodo/models/enums', 'frodo/utils/common-generator'], function (exports, _component, _model, _enums, _commonGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    frequency: 10,
    Level: _enums.Level.M1,
    isDetail: false,
    isNotDetail: Ember.computed.not('isDetail'),
    lpTypeDemander: _enums.LpType.LIQUIDITY_DEMANDER,
    init: function init() {
      this._super.apply(this, arguments);
      var brokerId = this.get('changeset.brokerId');
      this.set("lpOptions", this.getLpOptions(brokerId, this.lpTypeDemander));
      this.set("symbolOptions", this.getSymbolOptionsByLp(this.get('changeset.lpId')));
      this.set('options', {
        grid: {
          width: 'auto',
          height: 'auto',
          borderWidth: 1,
          borderColor: '#ccc'
        },
        xAxis: {
          data: ['0', '20', '50', '100']
        },
        yAxis: {
          splitArea: {
            show: true //显示分割区域
          },
          axisLabel: {
            showMinLabel: false,
            showMaxLabel: false
          }
        },
        series: [{
          type: 'k',
          data: []
        }],
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 40
        }, {
          show: true,
          type: 'slider',
          top: '90%',
          start: 0,
          end: 20
        }]
      });
    },
    getSymbolsOptions: function getSymbolsOptions(lpId) {
      var lpSymbol = this.get("bootstrap").getSelection('broker.lp_symbol');
      var symbol = Ember.A();
      lpSymbol.forEach(function (o) {
        if (lpId == (0, _commonGenerator.getLpIdByLpSymbolId)(o.value)) {
          symbol.push({ name: o.name, value: o.extra.symbolId });
        }
      });
      return symbol;
    },


    actions: {
      trySimulate: function trySimulate() {
        var max = 120;
        var i18n = this.get('i18n');
        var fre = this.get('frequency');
        var level = this.get('Level');
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);

        if (fre === undefined || level === undefined) return this.doSimulate(obj);

        var valid = /^$|(\+?[1-9][0-9]*$)/.test(fre);
        if (!valid) {
          var error = { frequency: { validation: [i18n.t('error.invalid')] } };this.set('error', error);
          var errorFields = Object.keys(error);this.set('errorFields', errorFields);
          if (this.get('isTabbed')) {
            this.activateTab(errorFields);
          }
          return;
        }
        //
        var value = isNaN(fre) ? 0 : parseInt(fre);
        if (value <= 0 || value > max) {
          var _error = { frequency: { validation: [i18n.t('error.common.number.between', { from: 1, to: max })] } };this.set('error', _error);
          var _errorFields = Object.keys(_error);this.set('errorFields', _errorFields);
          if (this.get('isTabbed')) {
            this.activateTab(_errorFields);
          }
          return;
        }
        obj.frequency = value;this.set('error', {});this.set('errorFields', {});
        obj.level = level;
        return this.doSimulate(obj);
      },
      onSelectBroker: function onSelectBroker() {
        var brokerId = this.get('changeset.brokerId');
        this.set("lpOptions", this.getLpOptions(brokerId, this.lpTypeDemander));
      },
      onSelectLp: function onSelectLp() {
        this.set("symbolOptions", this.getSymbolOptionsByLp(this.get('changeset.lpId')));
      },
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          var startTime = changeset.get('startTime');
          changeset.set('startTime', isNaN(startTime) ? 0 : startTime);
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var startTime = changeset.get('startTime');
        changeset.set('startTime', isNaN(startTime) ? 0 : startTime);
        var obj = this.copy(model, changeset);
        this.send('trySimulate');
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      },
      switchTab: function switchTab(tab) {
        tab === 'simulatorTab' ? this.set('simulatorOn', true) : this.set('simulatorOn', false);
      }
    },
    doSimulate: function doSimulate(obj) {
      var _this = this;

      return (0, _model.simulate)(this, obj).then(function (r) {
        _this.set('frequencyInvalidate', false);_this.set('frequencyAlertMessage', undefined);
        var times = _.map(r.data, function (e) {
          return [moment.unix(e.time / 1000).format('YYYY/MM/DD HH:mm:ss')];
        });

        var datas = _.map(r.data, function (e) {
          return [e.start, e.end, e.low, e.high];
        });

        var options = Ember.get(_this, 'options');
        options.series[0].data = datas;
        options.xAxis.data = times;
        options.yAxis.min = 'dataMin';
        options.yAxis.max = 'dataMax';
        Ember.set(_this, 'options', Object.assign({}, options));
      });
    }
  });
});
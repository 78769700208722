define('frodo/pods/index/trades/order-history/controller', ['exports', 'frodo/pods/index/trades/order-history/model', 'frodo/pods/index/trades/hedge-execution-history/model', 'frodo/pods/index/trades/trades-controller', 'frodo/mixins/paginatable', 'frodo/utils/common-generator'], function (exports, _model, _model2, _tradesController, _paginatable, _commonGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tradesController.default.extend(_paginatable.default, {

    actions: {
      subOrders: function subOrders(parent) {
        var _this = this;

        if (parent.get('childrenCollapseShow')) {
          parent.toggleProperty('childrenCollapseShow');return;
        }
        var allItSymbols = this.bootstrap.getSelection('broker.it_symbol');
        return (0, _model.findOrder)(this, { sourceId: parent.id, brokerId: parent.brokerId }).then(function (r) {
          if (_this.isData(r)) {
            if (Ember.isEmpty(r.data)) {
              parent.set('childrenAbsent', true);
            } else {
              parent.set('children', _.map(r.data, function (c) {
                var itId = (0, _commonGenerator.getItIdByLpId)(c.lpId);
                var itSymbol = _.find(allItSymbols, function (e) {
                  return e.extra.$group === itId && e.extra.symbolId === c.symbolId;
                });
                c.executions = [];
                c.executionCollapseShow = false;
                c.priceScale = itSymbol.extra.priceScale;
                c.volumeScale = itSymbol.extra.volumeScale;
                return _model.default.create(c);
              }));
            }
            parent.toggleProperty('childrenCollapseShow');
          }
          return r;
        });
      },
      execution: function execution(c) {
        var _this2 = this;

        if (c.get('executionCollapseShow')) {
          c.toggleProperty('executionCollapseShow');return;
        }
        return (0, _model.findExecution)(this, { orderId: c.id, brokerId: c.brokerId }).then(function (r) {
          if (_this2.isData(r)) {
            if (Ember.isEmpty(r.data)) {
              c.set('executionAbsent', true);
            } else {
              c.set('executions', _.map(r.data, function (exe) {
                return _model2.default.create(exe);
              }));
            }
            c.toggleProperty('executionCollapseShow');
          }
          return r;
        });
      },
      orderExecutions: function orderExecutions(parent) {
        var _this3 = this;

        if (parent.get('childrenCollapseShow')) {
          parent.toggleProperty('childrenCollapseShow');return;
        }
        return (0, _model.findExecution)(this, { orderId: parent.id, brokerId: parent.brokerId }).then(function (r) {
          if (_this3.isData(r)) {
            if (Ember.isEmpty(r.data)) {
              parent.set('executionAbsent', true);
            } else {
              parent.set('executions', _.map(r.data, function (exe) {
                return _model2.default.create(exe);
              }));
            }
            parent.toggleProperty('childrenCollapseShow');
          }
          return r;
        });
      }
    },
    doSearch: function doSearch(params) {
      var _this4 = this;

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this4.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (parent) {
            parent.children = [];
            parent.executions = [];
            parent.childrenCollapseShow = false;
            return _model.default.create(parent);
          });
          _this4.set('total', total);
          _this4.set('records', records);
        }
        _this4.updateNavtab(_this4.get('currentRouteName'), { total: total, records: records }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('symbolId')) p.symbolId = this.get('symbolId');
      if (this.get('status')) p.status = this.get('status');
      if (this.get('type')) p.type = this.get('type');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
      this.set('status', p.hasOwnProperty('status') ? p.status : 0);
      this.set('symbolId', p.hasOwnProperty('symbolId') ? p.symbolId : 0);
      this.set('type', p.hasOwnProperty('type') ? p.type : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
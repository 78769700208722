define('frodo/routes/login', ['exports', 'frodo/utils/brands'], function (exports, _brands) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      //set Brand
      (0, _brands.changeBrand)(document.domain);
    }
  });
});
define('frodo/pods/components/common/chart-line/component', ['exports', 'frodo/pods/components/common/base-chart-line/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    chartData: Ember.computed('contents', 'pointRadius', 'fill', 'borderWidth', function () {
      var contents = this.get('contents');
      var fill = this.get('fill') || this.get('default_setting.fill')[0];
      var pointRadius = this.get('pointRadius') || this.get('default_setting.pointRadius')[0];
      var borderColor = this.get('borderColor') || this.get('default_setting.borderColor')[0];
      var borderWidth = this.get('borderWidth') || this.get('default_setting.borderWidth')[0];
      var dataset = { data: contents, label: '', fill: fill, pointRadius: pointRadius, borderColor: borderColor, borderWidth: borderWidth };
      return { datasets: [dataset] };
    }),

    chartOptions: Ember.computed('options', function () {
      return this.get('options');
    }),

    init: function init() {
      this._super.apply(this, arguments);
    }
  });
});
define('frodo/pods/index/trades/cp-order/route', ['exports', 'frodo/pods/index/trades/trades-route'], function (exports, _tradesRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tradesRoute.default.extend({
    preload: false,
    lpWithAllOption: false,
    symbolWithAllOption: false,
    lpOptionSwitchFirst: true,
    symbolOptionSwitchFirst: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('isResumed')) {
        var lpOptions = controller.get('lpOptions');
        var symbolOptions = controller.get('symbolOptions');
        controller.set('lpId', Ember.isEmpty(lpOptions) ? 0 : lpOptions[0].value);
        controller.set('symbolId', Ember.isEmpty(symbolOptions) ? 0 : symbolOptions[0].value);
      }
    }
  });
});
define('frodo/services/reaction', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    bootstrap: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('lpEmptyOptions', [{ value: 0, name: 'ALL' }]);
      this.set('bookEmptyOptions', [{ value: 0, name: 'ALL' }]);
      this.set('symbolEmptyOptions', [{ value: 0, name: 'ALL' }]);
      this.set('currencyEmptyOptions', [{ value: 0, name: 'ALL' }]);
      this.set('bkSymbolEmptyOptions', [{ value: 0, name: 'ALL' }]);
      this.set('allocatorEmptyOptions', [{ value: 0, name: 'ALL' }]);
      this.set('profitCurrencyEmptyOptions', [{ value: 0, name: 'ALL' }]);
    },
    _filterLp: function _filterLp() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$lpId = _ref.lpId,
          lpId = _ref$lpId === undefined ? 0 : _ref$lpId,
          _ref$lpType = _ref.lpType,
          lpType = _ref$lpType === undefined ? 0 : _ref$lpType,
          _ref$brokerId = _ref.brokerId,
          brokerId = _ref$brokerId === undefined ? 0 : _ref$brokerId,
          _ref$allLps = _ref.allLps,
          allLps = _ref$allLps === undefined ? [] : _ref$allLps;

      if (brokerId > 0) {
        var lpOptions = _.filter(allLps, function (e) {
          return e.extra.$group === brokerId;
        });
        if (lpType !== 0) {
          lpOptions = _.filter(lpOptions, function (e) {
            return e.extra.$group2 === lpType;
          });
        }
        var reverseLpId = !_.isMatch(lpOptions, { value: lpId }) ? 0 : lpId;
        return { lpOptions: lpOptions, reverseLpId: reverseLpId };
      }
      return { lpOptions: allLps, reverseLpId: lpId };
    },
    reactionBrokerChange: function reactionBrokerChange() {
      var brokerId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var lpId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var symbolId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

      var _ref2 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
          _ref2$lpType = _ref2.lpType,
          lpType = _ref2$lpType === undefined ? 0 : _ref2$lpType,
          _ref2$profitCurrencyI = _ref2.profitCurrencyId,
          profitCurrencyId = _ref2$profitCurrencyI === undefined ? 0 : _ref2$profitCurrencyI,
          _ref2$lpWithAllOption = _ref2.lpWithAllOption,
          lpWithAllOption = _ref2$lpWithAllOption === undefined ? true : _ref2$lpWithAllOption,
          _ref2$bookWithAllOpti = _ref2.bookWithAllOption,
          bookWithAllOption = _ref2$bookWithAllOpti === undefined ? true : _ref2$bookWithAllOpti,
          _ref2$symbolWithAllOp = _ref2.symbolWithAllOption,
          symbolWithAllOption = _ref2$symbolWithAllOp === undefined ? true : _ref2$symbolWithAllOp,
          _ref2$bkSymbolWithAll = _ref2.bkSymbolWithAllOption,
          bkSymbolWithAllOption = _ref2$bkSymbolWithAll === undefined ? true : _ref2$bkSymbolWithAll,
          _ref2$currencyWithAll = _ref2.currencyWithAllOption,
          currencyWithAllOption = _ref2$currencyWithAll === undefined ? true : _ref2$currencyWithAll,
          _ref2$allocatorWithAl = _ref2.allocatorWithAllOption,
          allocatorWithAllOption = _ref2$allocatorWithAl === undefined ? true : _ref2$allocatorWithAl,
          _ref2$profitCurrencyW = _ref2.profitCurrencyWithAllOption,
          profitCurrencyWithAllOption = _ref2$profitCurrencyW === undefined ? true : _ref2$profitCurrencyW;

      var reverseLpId = lpId,
          reverseSymbolId = symbolId,
          lpOptions = void 0,
          symbolOptions = void 0,
          bkSymbolOptions = void 0,
          bookOptions = void 0,
          allocatorOptions = void 0,
          currencyOptions = void 0,
          profitCurrencyOptions = void 0;
      //
      var allLps = this.get('bootstrap').getSelection('broker.lp');
      var allBooks = this.get('bootstrap').getSelection('broker.book');
      var allSymbols = this.get('bootstrap').getSelection('market.symbol');
      var allBkSymbols = this.get('bootstrap').getSelection('broker.symbol');
      var allCurrencys = this.get('bootstrap').getSelection('market.currency');
      var allLpSymbols = this.get('bootstrap').getSelection('broker.lp_symbol');
      var allAllocators = this.get('bootstrap').getSelection('broker.allocator');
      //
      var initLpOption = lpWithAllOption ? this.get('lpEmptyOptions') : [];
      var initBookOption = bookWithAllOption ? this.get('bookEmptyOptions') : [];
      var initSymbolOption = symbolWithAllOption ? this.get('symbolEmptyOptions') : [];
      var initBkSymbolOption = bkSymbolWithAllOption ? this.get('bkSymbolEmptyOptions') : [];
      var initCurrencyOption = currencyWithAllOption ? this.get('currencyEmptyOptions') : [];
      var initAllocatorOption = allocatorWithAllOption ? this.get('allocatorEmptyOptions') : [];
      var initProfitCurrencyOption = profitCurrencyWithAllOption ? this.get('profitCurrencyEmptyOptions') : [];
      //
      var profitCurrencyContents = this._filterProfitCurrency({ allSymbols: allSymbols, allCurrencys: allCurrencys });
      //
      if (brokerId === 0) {
        //
        lpOptions = initLpOption.concat(allLps);
        bookOptions = initBookOption.concat(allBooks);
        symbolOptions = initSymbolOption.concat(allSymbols);
        bkSymbolOptions = initBkSymbolOption.concat(allBkSymbols);
        currencyOptions = initCurrencyOption.concat(allCurrencys);
        allocatorOptions = initAllocatorOption.concat(allAllocators);
        profitCurrencyOptions = initProfitCurrencyOption.concat(profitCurrencyContents.profitsCurrenciesOptions);

        return {
          reverseLpId: reverseLpId,
          reverseSymbolId: reverseSymbolId,
          lpOptions: lpOptions,
          bookOptions: bookOptions,
          symbolOptions: symbolOptions,
          bkSymbolOptions: bkSymbolOptions,
          currencyOptions: currencyOptions,
          allocatorOptions: allocatorOptions,
          profitCurrencyOptions: profitCurrencyOptions
        };
      }
      //
      var bookContents = this._filterBook({ brokerId: brokerId, allBooks: allBooks });
      var lpContents = this._filterLp({ lpId: lpId, lpType: lpType, brokerId: brokerId, allLps: allLps });
      var allocatorContents = this._filterAllocator({ brokerId: brokerId, allAllocators: allAllocators });
      var currencyContents = this._filterCurrency({ lpId: lpId, brokerId: brokerId, allSymbols: allSymbols, allBkSymbols: allBkSymbols, allLpSymbols: allLpSymbols, allCurrencys: allCurrencys });
      var symbolContents = this._filterSymbol({ lpId: lpId, lpType: lpType, brokerId: brokerId, symbolId: symbolId, allSymbols: allSymbols, allBkSymbols: allBkSymbols, allLpSymbols: allLpSymbols });
      var bkSymbolContents = this._filterBkSymbol({ lpId: lpId, lpType: lpType, brokerId: brokerId, symbolId: symbolId, profitCurrencyId: profitCurrencyId, allSymbols: allSymbols, allBkSymbols: allBkSymbols, allLpSymbols: allLpSymbols });

      return {
        reverseLpId: lpContents.reverseLpId,
        reverseSymbolId: symbolContents.reverseSymbolId,
        reverseCurrencyId: currencyContents.reverseCurrencyId,
        lpOptions: initLpOption.concat(lpContents.lpOptions),
        bookOptions: initBookOption.concat(bookContents.bookOptions),
        symbolOptions: initSymbolOption.concat(symbolContents.symbolOptions),
        bkSymbolOptions: initBkSymbolOption.concat(bkSymbolContents.bkSymbolOptions),
        currencyOptions: initCurrencyOption.concat(currencyContents.currencyOptions),
        allocatorOptions: initAllocatorOption.concat(allocatorContents.allocatorOptions),
        profitCurrencyOptions: profitCurrencyOptions
      };
    },
    _filterSymbol: function _filterSymbol() {
      var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref3$lpId = _ref3.lpId,
          lpId = _ref3$lpId === undefined ? 0 : _ref3$lpId,
          _ref3$lpType = _ref3.lpType,
          lpType = _ref3$lpType === undefined ? 0 : _ref3$lpType,
          _ref3$brokerId = _ref3.brokerId,
          brokerId = _ref3$brokerId === undefined ? 0 : _ref3$brokerId,
          _ref3$symbolId = _ref3.symbolId,
          symbolId = _ref3$symbolId === undefined ? 0 : _ref3$symbolId,
          _ref3$allSymbols = _ref3.allSymbols,
          allSymbols = _ref3$allSymbols === undefined ? [] : _ref3$allSymbols,
          _ref3$allBkSymbols = _ref3.allBkSymbols,
          allBkSymbols = _ref3$allBkSymbols === undefined ? [] : _ref3$allBkSymbols,
          _ref3$allLpSymbols = _ref3.allLpSymbols,
          allLpSymbols = _ref3$allLpSymbols === undefined ? [] : _ref3$allLpSymbols;

      //
      if (lpId > 0) {
        var lpSymbols = _.filter(allLpSymbols, function (e) {
          return e.extra.$group === lpId;
        });
        var symbolOptions = _.map(lpSymbols, function (item) {
          return { name: item.name, value: item.extra.symbolId };
        });
        var reverseSymbolId = !_.isMatch(symbolOptions, { value: symbolId }) ? 0 : symbolId;
        return { symbolOptions: symbolOptions, reverseSymbolId: reverseSymbolId };
      }

      if (brokerId > 0) {
        var bkSymbols = _.filter(allBkSymbols, function (e) {
          return e.extra.$group === brokerId;
        });
        var _symbolOptions = _.map(bkSymbols, function (item) {
          return { name: item.name, value: item.extra.symbolId };
        });
        var _reverseSymbolId = !_.isMatch(_symbolOptions, { value: symbolId }) ? 0 : symbolId;
        return { symbolOptions: _symbolOptions, reverseSymbolId: _reverseSymbolId };
      }

      return { symbolOptions: allSymbols, reverseSymbolId: symbolId };
    },
    _filterBkSymbol: function _filterBkSymbol() {
      var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref4$lpId = _ref4.lpId,
          lpId = _ref4$lpId === undefined ? 0 : _ref4$lpId,
          _ref4$lpType = _ref4.lpType,
          lpType = _ref4$lpType === undefined ? 0 : _ref4$lpType,
          _ref4$brokerId = _ref4.brokerId,
          brokerId = _ref4$brokerId === undefined ? 0 : _ref4$brokerId,
          _ref4$symbolId = _ref4.symbolId,
          symbolId = _ref4$symbolId === undefined ? 0 : _ref4$symbolId,
          _ref4$profitCurrencyI = _ref4.profitCurrencyId,
          profitCurrencyId = _ref4$profitCurrencyI === undefined ? 0 : _ref4$profitCurrencyI,
          _ref4$allSymbols = _ref4.allSymbols,
          allSymbols = _ref4$allSymbols === undefined ? [] : _ref4$allSymbols,
          _ref4$allBkSymbols = _ref4.allBkSymbols,
          allBkSymbols = _ref4$allBkSymbols === undefined ? [] : _ref4$allBkSymbols,
          _ref4$allLpSymbols = _ref4.allLpSymbols,
          allLpSymbols = _ref4$allLpSymbols === undefined ? [] : _ref4$allLpSymbols;

      //
      if (lpId > 0) {
        var bkSymbols = _.filter(allBkSymbols, function (e) {
          return e.extra.$group === brokerId;
        });
        var lpSymbols = _.filter(allLpSymbols, function (e) {
          return e.extra.$group === lpId;
        });
        var bkSymbolOptions = _.filter(bkSymbols, function (item) {
          var id = item.extra.symbolId;
          var found = _.find(lpSymbols, function (lpsb) {
            return lpsb.extra.symbolId === id;
          });
          return !Ember.isEmpty(found);
        });
        if (profitCurrencyId > 0) {
          bkSymbolOptions = this._filterProfitBkSymbol({ allSymbols: allSymbols, bkSymbolOptions: bkSymbolOptions, profitCurrencyId: profitCurrencyId }).profitBkSymbolOptions;
        }
        return { bkSymbolOptions: bkSymbolOptions };
      }
      //
      if (brokerId > 0) {
        var _bkSymbolOptions = _.filter(allBkSymbols, function (e) {
          return e.extra.$group === brokerId;
        });
        if (profitCurrencyId > 0) {
          _bkSymbolOptions = this._filterProfitBkSymbol({ allSymbols: allSymbols, bkSymbolOptions: _bkSymbolOptions, profitCurrencyId: profitCurrencyId }).profitBkSymbolOptions;
        }
        return { bkSymbolOptions: _bkSymbolOptions };
      }
      return { bkSymbolOptions: allBkSymbols };
    },
    _filterBook: function _filterBook() {
      var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref5$brokerId = _ref5.brokerId,
          brokerId = _ref5$brokerId === undefined ? 0 : _ref5$brokerId,
          _ref5$allBooks = _ref5.allBooks,
          allBooks = _ref5$allBooks === undefined ? [] : _ref5$allBooks;

      if (brokerId > 0) {
        var bookOptions = _.filter(allBooks, function (a) {
          return a.extra.$group === brokerId;
        });
        return { bookOptions: bookOptions };
      }
      return { bookOptions: allBooks };
    },
    _filterAllocator: function _filterAllocator() {
      var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref6$brokerId = _ref6.brokerId,
          brokerId = _ref6$brokerId === undefined ? 0 : _ref6$brokerId,
          _ref6$allAllocators = _ref6.allAllocators,
          allAllocators = _ref6$allAllocators === undefined ? [] : _ref6$allAllocators;

      if (brokerId > 0) {
        var allocatorOptions = _.filter(allAllocators, function (a) {
          return a.extra.$group === brokerId;
        });
        return { allocatorOptions: allocatorOptions };
      }
      return { allocatorOptions: allAllocators };
    },
    _filterCurrency: function _filterCurrency() {
      var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref7$lpId = _ref7.lpId,
          lpId = _ref7$lpId === undefined ? 0 : _ref7$lpId,
          _ref7$brokerId = _ref7.brokerId,
          brokerId = _ref7$brokerId === undefined ? 0 : _ref7$brokerId,
          _ref7$currencyId = _ref7.currencyId,
          currencyId = _ref7$currencyId === undefined ? 0 : _ref7$currencyId,
          _ref7$allSymbols = _ref7.allSymbols,
          allSymbols = _ref7$allSymbols === undefined ? [] : _ref7$allSymbols,
          _ref7$allBkSymbols = _ref7.allBkSymbols,
          allBkSymbols = _ref7$allBkSymbols === undefined ? [] : _ref7$allBkSymbols,
          _ref7$allLpSymbols = _ref7.allLpSymbols,
          allLpSymbols = _ref7$allLpSymbols === undefined ? [] : _ref7$allLpSymbols,
          _ref7$allCurrencys = _ref7.allCurrencys,
          allCurrencys = _ref7$allCurrencys === undefined ? [] : _ref7$allCurrencys;

      if (lpId > 0) {
        var lpSymbols = _.filter(allLpSymbols, function (e) {
          return e.extra.$group === lpId;
        });
        var intersections = _.filter(allSymbols, function (item) {
          var id = item.value;
          var found = _.find(lpSymbols, function (lpsb) {
            return lpsb.extra.symbolId === id;
          });
          return !Ember.isEmpty(found);
        });
        var currencyOptions = this._isolateCurrencys(allCurrencys, intersections);
        var reverseCurrencyId = !_.isMatch(currencyOptions, { value: currencyId }) ? 0 : currencyId;
        return { currencyOptions: currencyOptions, reverseCurrencyId: reverseCurrencyId };
      }

      if (brokerId > 0) {
        var bkSymbols = _.filter(allBkSymbols, function (e) {
          return e.extra.$group === brokerId;
        });
        var _intersections = _.filter(allSymbols, function (item) {
          var id = item.value;
          var found = _.find(bkSymbols, function (bksb) {
            return bksb.extra.symbolId === id;
          });
          return !Ember.isEmpty(found);
        });
        var _currencyOptions = this._isolateCurrencys(allCurrencys, _intersections);
        var _reverseCurrencyId = !_.isMatch(_currencyOptions, { value: currencyId }) ? 0 : currencyId;
        return { currencyOptions: _currencyOptions, reverseCurrencyId: _reverseCurrencyId };
      }

      return { currencyOptions: allCurrencys };
    },
    _filterProfitCurrency: function _filterProfitCurrency() {
      var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref8$allSymbols = _ref8.allSymbols,
          allSymbols = _ref8$allSymbols === undefined ? [] : _ref8$allSymbols,
          _ref8$allCurrencys = _ref8.allCurrencys,
          allCurrencys = _ref8$allCurrencys === undefined ? [] : _ref8$allCurrencys;

      var profitsCurrencies = _.sortBy(_.uniq(_.map(allSymbols, function (s) {
        return s.extra.profitCurrency;
      })));
      var profitsCurrenciesOptions = _.filter(allCurrencys, function (o) {
        return profitsCurrencies.includes(o.value);
      });
      return { profitsCurrenciesOptions: profitsCurrenciesOptions };
    },
    _isolateCurrencys: function _isolateCurrencys(currencys, symbolOptions) {
      return _.filter(currencys, function (item) {
        var found = _.find(symbolOptions, function (smb) {
          var base = smb.extra.baseCurrency;
          var prof = smb.extra.profitCurrency;
          return item.value === base || item.value === prof;
        });
        return !Ember.isEmpty(found);
      });
    },
    _filterProfitBkSymbol: function _filterProfitBkSymbol() {
      var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref9$allSymbols = _ref9.allSymbols,
          allSymbols = _ref9$allSymbols === undefined ? [] : _ref9$allSymbols,
          _ref9$bkSymbolOptions = _ref9.bkSymbolOptions,
          bkSymbolOptions = _ref9$bkSymbolOptions === undefined ? [] : _ref9$bkSymbolOptions,
          _ref9$profitCurrencyI = _ref9.profitCurrencyId,
          profitCurrencyId = _ref9$profitCurrencyI === undefined ? 0 : _ref9$profitCurrencyI;

      var profitBkSymbolOptions = _.filter(bkSymbolOptions, function (item) {
        var found_symbol = _.find(allSymbols, function (symbol) {
          return symbol.value === item.extra.symbolId;
        });
        return profitCurrencyId === found_symbol.extra.profitCurrency;
      });

      return { profitBkSymbolOptions: profitBkSymbolOptions };
    }
  });
});
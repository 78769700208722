define('frodo/pods/components/common/refresh-timer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    refreshTime: 0,
    refreshError: false,
    classNames: ['float-right', 'small', 'refresh-timer'],
    classNameBindings: ['refreshError:red']
  });
});
define('frodo/controllers/index', ['exports', 'frodo/mixins/result'], function (exports, _result) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_result.default, {

    router: Ember.inject.service(),
    utility: Ember.inject.service(),

    /**
     *
     */
    actions: {
      logout: function logout() {
        var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        return this.get('utility').logout(force);
      },
      toLogin: function toLogin() {
        return this.get('utility').redirectToLogin();
      },
      closeWindows: function closeWindows() {
        return this.get('utility').closeWindows();
      }
    }
  });
});
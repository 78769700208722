define('frodo/pods/index/market/institution/controller', ['exports', 'frodo/pods/index/market/institution/validator', 'frodo/pods/index/market/institution/model', 'frodo/pods/index/market/market-controller'], function (exports, _validator, _model, _marketController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    Validator: _validator.default,
    sortable: true,
    records: null,
    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('institutions', model);
          }
        });
      },
      openAdd: function openAdd() {
        this.set('record', _model.default.create({}));
      },
      openEdit: function openEdit(o) {
        this.set('record', o);
      },
      openDetail: function openDetail(o) {
        this.set('record', o);
      },
      symbols: function symbols(o) {
        this.transitionToRoute('index.market.institution_symbol', o.id);
      },
      currencies: function currencies(o) {
        this.transitionToRoute('index.market.institution_currency', o.id);
      }
    }
  });
});
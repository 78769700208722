define('frodo/helpers/volume-formatter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.volumeFormatter = volumeFormatter;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function volumeFormatter(param) {
    return _formatAmountValue(param);
  }

  function _formatAmountValue(val) {
    if (Ember.isEmpty(val)) {
      return {
        unit: null,
        real: null,
        origin: val,
        value: 0
      };
    }
    if (+val < 1000) {
      return {
        unit: null,
        real: val,
        origin: val,
        value: val
      };
    }

    val = String(val);
    // step:
    // 1. val to number
    // 2. convert to M/K
    var num = void 0,
        newVal = void 0,
        unit = void 0,
        real = void 0,
        origin = void 0;
    var M = 1000000;
    var K = 1000;
    val = val.replace(/^\s+|[,]|\s+$/g, '').toLowerCase();
    origin = val;
    var match = val.match(/(.*?)([mk])?$/);
    num = parseFloat(match[1]);
    if (isNaN(num) || num < 0) {
      num = 0;
    }
    if (match[2]) {
      var factor = match[2] === 'm' ? M : K;
      num = num * factor;
    }
    real = num;
    if (num >= M) {
      newVal = num / M;
      unit = 'M';
    } else {
      newVal = num / K;
      unit = 'K';
    }

    // let decimals = _decimals(newVal);
    // if (decimals >= 2) {
    //   decimals = 2;
    //   let tmp = newVal.toFixed(decimals);
    //   if (tmp.charAt(tmp.length - 1) === '0') {
    //     decimals = 1;
    //   }
    // } else {
    //   decimals = 1;
    // }
    var decimals = 1;
    var result = truncate(newVal, decimals) + unit;
    return {
      unit: unit,
      real: real,
      origin: origin,
      value: result
    };
  }

  function truncate(val, precision) {
    var scale = Math.pow(10, precision);
    var extending = val * scale;
    return (Math.floor(extending) / scale).toFixed(precision);
  }

  // function _decimals(num) {
  //   let origin = num + '';
  //   let point = origin.indexOf('.');
  //   if (point === -1) return 1;
  //   let decimalsStr = origin.substr(point + 1);
  //   return decimalsStr.length;
  // }

  exports.default = Ember.Helper.helper(function (_ref /*, hash*/) {
    var _ref2 = _slicedToArray(_ref, 1),
        param = _ref2[0];

    return volumeFormatter(param).value;
  });
});
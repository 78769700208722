define('frodo/pods/components/common/select-selection/component', ['exports', 'frodo/pods/components/common/select-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    type: null,
    group: -1,
    group1: -1,
    group2: -1,
    filter: null,
    messageUp: true,
    emptyLabel: 'ALL',
    allowClear: false,
    defaultSelected: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('defaultSelected') && !this.get('emptyOption') && !this.get('value')) {
        var options = this.get('options');
        if (options && options.length > 0) {
          this.set('value', options[0].value);
        } else {
          this.set('value', null);
        }
      }
    },


    options: Ember.computed('type', 'group', 'group1', 'group2', 'filter', 'customizeOptions.@each', function () {
      var type = this.get('type');
      if (Ember.isEmpty(type)) {
        return this.get('customizeOptions');
      }
      var filter = this.get('filter');
      var group = parseInt(this.get('group'));
      var group1 = parseInt(this.get('group1'));
      var group2 = parseInt(this.get('group2'));
      var selections = type ? this.get('bootstrap').getSelection(type) : [];

      if (group >= 0) {
        selections = _.filter(selections, function (s) {
          return s.extra.$group === group;
        });
      }

      if (group1 >= 0) {
        selections = _.filter(selections, function (s) {
          return s.extra.$group1 === group1;
        });
      }

      if (group2 >= 0) {
        selections = _.filter(selections, function (s) {
          return s.extra.$group2 === group2;
        });
      }

      if (filter) {
        selections = _.filter(selections, function (s) {
          return _.includes(filter, s.value);
        });
      }
      return this.get('emptyOption') ? [{ value: 0, name: this.get('emptyLabel') }].concat(selections) : selections;
    })
  });
});
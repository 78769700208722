define('frodo/pods/components/common/triger-strategy-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    collections: Ember.A(),
    classNameBindings: ['row'],

    buttonDisable: Ember.computed('readonly', 'preview', function () {
      if (this.get('readonly') || this.get('preview')) return true;
    }),

    isEdit: Ember.computed('model', function () {
      return this.get('model.id') > 0;
    }),

    actions: {
      addElement: function addElement() {
        var copied = this.get('collections');
        if (copied) {
          var max = copied.length;
          var o = { v1: 0, v2: 0 };
          o[this.get('indexColumn')] = max + 1;
          copied.pushObject(o);
        }
        this.notifyPropertyChange('collections');
      },
      removeElement: function removeElement(o) {
        var arr = this.get('collections');
        if (arr && arr.length > 1) {
          arr.removeObject(o);
        }
        this.notifyPropertyChange('collections');
      }
    }

  });
});
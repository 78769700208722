define("frodo/utils/words", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.gblen = gblen;
  function gblen(source) {
    if (source) {
      var len = 0;
      for (var i = 0; i < source.length; i++) {
        if (source.charCodeAt(i) > 127 || this.charCodeAt(i) == 94) {
          len += 2;
        } else {
          len++;
        }
      }
      return len;
    }
    return 0;
  }
});
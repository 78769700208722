define('frodo/pods/components/common/modal-button/component', ['exports', 'frodo/mixins/result'], function (exports, _result) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_result.default, {
    classNames: ['modal-footer'],
    model: null,

    strict: false,
    readonly: false,

    checkBtnDisable: false,
    submitBtnDisable: false,

    successActionName: 'reload',
    submitBtnText: 'save',
    resetBtnText: 'label.common.btn.reset',
    actionBtnText: 'label.common.btn.save',
    checkBtnText: 'label.common.btn.preview',
    notificationPrefix: 'label.notification.',

    i18n: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    isEdit: Ember.computed('model', function () {
      return this.get('model.id') > 0;
    }),

    /**
     *
     */
    actions: {
      close: function close() {
        this.get('onClose')();
        if (this.get('onDispose')) this.get('onDispose')();
      },
      back: function back() {
        this.get('onBack')();
      },
      reset: function reset() {
        this.get('onReset')();
      },
      submit: function submit() {
        var _this = this;

        this.set('submitBtnDisable', true);
        this.set('actionBtnText', 'label.common.btn.loading');

        var i18n = this.get('i18n');
        var flash = this.get('flashMessages');

        Ember.run.later(this, function () {
          _this.get('onSubmit')().then(function () /*r*/{
            _this.set('submitBtnDisable', false);
            _this.set('actionBtnText', 'label.common.btn.' + _this.get('submitBtnText'));
          }, function (r) {
            _this.set('actionBtnText', 'label.common.btn.loading');
            flash.danger(i18n.t(r.key));
          }).finally(function () {
            _this.send('close');
          });
        }, 200);
      },
      check: function check() {
        var _this2 = this;

        this.set('checkBtnDisable', true);
        this.set('checkBtnText', 'label.common.btn.loading');

        Ember.run.later(this, function () {
          _this2.get('onCheck')().then(function () /*r*/{
            _this2.set('checkBtnDisable', false);
            _this2.set('checkBtnText', 'label.common.btn.preview');
          });
        }, 200);
      }
    }
  });
});
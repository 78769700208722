define('frodo/pods/components/common/selection-link/component', ['exports', 'frodo/pods/index/making/source-liquidity-config/model', 'frodo/pods/index/making/target-price-config/model', 'frodo/pods/index/making/source-liquidity-config/validator', 'frodo/pods/index/making/target-price-config/validator', 'frodo/pods/index/base-model'], function (exports, _model, _model2, _validator, _validator2, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TARGET_PRICE_CONFIG = exports.SOURCE_LIQUIDITY_CONFIG = undefined;

  //
  var SOURCE_LIQUIDITY_CONFIG = exports.SOURCE_LIQUIDITY_CONFIG = 'making.source_liquidity_config';
  //

  //

  //
  var TARGET_PRICE_CONFIG = exports.TARGET_PRICE_CONFIG = 'making.target_price_config';

  exports.default = Ember.Component.extend({
    popup: true,
    type: null,
    value: null,
    record: null,
    parentRecord: null,
    menu: null,
    recordId: null,

    ValidatorSourceLiquidityConfig: _validator.default,
    ValidatorTargetPriceConfig: _validator2.default,

    SOURCE_LIQUIDITY_CONFIG: SOURCE_LIQUIDITY_CONFIG,
    TARGET_PRICE_CONFIG: TARGET_PRICE_CONFIG,

    ajax: Ember.inject.service(),
    bootstrap: Ember.inject.service(),
    i18n: Ember.inject.service(),

    hasRW: Ember.computed('menu', function () {
      var menu = this.get('menu');
      return this.get('bootstrap').hasWritePrivilege(menu);
    }),

    title: Ember.computed('menu', function () {
      var menu = this.get('menu');
      var r = "";
      if (menu == SOURCE_LIQUIDITY_CONFIG) {
        r = this.get('i18n').t('menu.index.making.source_liquidity_config');
      } else if (menu == TARGET_PRICE_CONFIG) {
        r = this.get('i18n').t('menu.index.making.target_price_config');
      }
      return r;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },


    actions: {
      getRecord: function getRecord() {
        var _this = this;

        var menu = this.get('menu');
        switch (menu) {
          case SOURCE_LIQUIDITY_CONFIG:
            this._getSourceLiquidityConfig().then(function (r) {
              if (r) {
                _this.set('record', _baseModel.default.create(r));
              } else {
                var parentRecord = _this.get('parentRecord');
                _this.set('record', { brokerId: parentRecord.brokerId, lpId: parentRecord.lpId, symbolId: parentRecord.symbolId });
              }
            });break;
          case TARGET_PRICE_CONFIG:
            this._getTragetPriceConfig().then(function (r) {
              if (r) {
                _this.set('record', _baseModel.default.create(r));
              } else {
                var parentRecord = _this.get('parentRecord');
                _this.set('record', { brokerId: parentRecord.brokerId, lpId: parentRecord.lpId, symbolId: parentRecord.symbolId });
              }
            });break;
          default:
            this.set('record', null);
        }
      },
      resetRecord: function resetRecord() {
        this.set('record', null);
      }
    },

    _getSourceLiquidityConfig: function _getSourceLiquidityConfig() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        (0, _model.one)(_this2, { lpSymbolId: _this2.get('recordId') }).then(function (r) {
          return resolve(r.data);
        });
      });
    },
    _getTragetPriceConfig: function _getTragetPriceConfig() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        (0, _model2.one)(_this3, { lpSymbolId: _this3.get('recordId') }).then(function (r) {
          return resolve(r.data);
        });
      });
    }
  });
});
define('frodo/mixins/refreshable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    refreshTime: 0,
    refreshInterval: 0,
    refreshError: false
  });
});
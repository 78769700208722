define('frodo/pods/index/market/market-session/controller', ['exports', 'frodo/pods/index/market/market-controller', 'frodo/pods/index/market/market-session/model'], function (exports, _marketController, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    parentRoot: 'market.market',

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          if (_this.isData(r)) {
            _this.set('sessions', _.map(r.data, function (s) {
              return _model.default.create(s);
            }));
          }
        });
      }
    }

  });
});
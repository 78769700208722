define('frodo/pods/components/index/utility/modal-password/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    currentPw: [(0, _validators.validatePresence)({ presence: true, message: 'Old password can\'t be blank' })],

    newPw1: [(0, _validators.validatePresence)({ presence: true, message: 'New password can\'t be blank' })],

    newPw2: [(0, _validators.validatePresence)({ presence: true, message: 'Confirm password can\'t be blank' })]
  };
});
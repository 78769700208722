define('frodo/router', ['exports', 'frodo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    rootURL: _environment.default.rootURL,
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('login');

    this.route('index', { path: '/' }, function () {
      this.route('system', function () {
        this.route('debug');
        this.route('applications');
        this.route('config');
        this.route('batch');
        this.route('alert_guard');
        this.route('alert');
        this.route('digest');
        this.route('batch_history', { path: 'batch/:job_name/history' });
      });

      this.route('market', function () {
        this.route('broker');
        this.route('broker_symbol', { path: 'broker/:bid/symbols' });
        this.route('symbol');
        this.route('market');
        this.route('market_session', { path: 'sessions' });
        this.route('currency');
        this.route('lp');
        this.route('institution');
        this.route('institution_symbol', { path: 'institution/:iid/symbols' });
        this.route('institution_currency', { path: 'institution/:iid/currencies' });
        this.route('it_session');
        this.route('lp_symbol', { path: 'lp/:lid/symbols' });
        this.route('lp_account', { path: 'lp/:lid/accounts' });
        this.route('lp_session');
      });

      this.route('making', function () {
        this.route('making_config');
        this.route('filter_config');
        this.route('mass_order_history');
        this.route('mass_execution_history');
        this.route('source_liquidity_config');
        this.route('target_price_config');
        this.route('making_config_revision');
        this.route('source_liquidity_config_revision');
        this.route('target_price_config_revision');
        this.route('dealing_making_statement');
        this.route('dealing_making_statement_history');
        this.route('maintain');
      });

      this.route('operator', function () {
        this.route('role');
        this.route('menu');
        this.route('privilege');
        this.route('operator_access');
        this.route('operator');
      });

      this.route('pricing', function () {
        this.route('quotation');
        this.route('quotation_history');
      });

      this.route('trades', function () {
        this.route('hedge_order_history');
        this.route('hedge_execution_history');
        this.route('order_history');
        this.route('cp_order');
      });

      this.route('risk', function () {
        this.route('book');
        this.route('risk');
        this.route('router');
        this.route('trigger');
        this.route('position_alertor');
        this.route('balance_alertor');
        this.route('trade_alertor');
        this.route('bugrate_alertor');
        this.route('allocator');
        this.route('cp_statement_history');
        this.route('statement_history');
        this.route('cp_balance');
        this.route('cp_position');
        this.route('alertor_history');
      });

      this.route('report', function () {
        this.route('holding_balance');
        this.route('holding_daily_pl');
        this.route('holding_daily_trade');
        this.route('holding_balance_flow');
        this.route('holding_balance_turnover');
        this.route('holding_deposit_withdraw');
        this.route('holding_daily_trade_compare');
        this.route('holding_balance_daily_change');
      });

      this.route('popup', function () {
        this.route('dealing_panel');
      });
    });

    this.route('utility', function () {
      this.route('password', { path: '/password' });
    });

    this.route('error', function () {
      this.route('403');
      this.route('404');
    });
  });

  exports.default = Router;
});
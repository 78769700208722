define('frodo/pods/index/risk/allocator/innerValidator', ['exports', 'ember-validators'], function (exports, _emberValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateInnerBooks;
  function validateInnerBooks(options) {
    return function (key, newValue) /*oldValue, changes, content*/{

      if (Ember.isEmpty(newValue)) {
        return 'books can not be empty .';
      }
      var idxs = Ember.A();
      for (var i = 0; i < newValue.length; i++) {
        var book = newValue[i];
        // v1
        if ((0, _emberValidators.validate)('presence', book.v1, options, null, key) !== true) {
          return 'book:' + i + ':' + 'v1' + ':notEmpty';
        }
        if ((0, _emberValidators.validate)('number', book.v1, options, null, key) !== true) {
          return 'book:' + i + ':' + 'v1' + ':invalid';
        }
        // v2
        if ((0, _emberValidators.validate)('presence', book.v2, options, null, key) !== true) {
          return 'book:' + i + ':' + 'v2' + ':notEmpty';
        }
        if ((0, _emberValidators.validate)('number', book.v2, options, null, key) !== true) {
          return 'book:' + i + ':' + 'v2' + ':invalid';
        }
        var v2_options = Object.assign({}, options, { integer: true });
        if ((0, _emberValidators.validate)('number', book.v2, v2_options, null, key) !== true) {
          return 'book:' + i + ':' + 'v2' + ':invalid';
        }

        if (idxs.includes(book.v1)) {
          return 'book:' + i + ':' + 'v1' + ':duplicated';
        }
        idxs.push(book.v1);
      }
      return true;
    };
  }
});
define('frodo/pods/index/pricing/quotation-history/route', ['exports', 'frodo/pods/index/pricing/pricing-route'], function (exports, _pricingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pricingRoute.default.extend({
    preload: false,
    bootstrap: Ember.inject.service(),
    lpWithAllOption: false,
    lpOptionSwitchFirst: true,
    bkSymbolWithAllOption: false,
    bkSymbolOptionSwitchFirst: true,
    profitCurrencyOptionSwitchFirst: true,

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('isResumed')) {
        controller.set('date', this.get('bootstrap').getTodayStartTime());
        controller.set('endTime', this.get('bootstrap').getTodayEndTime());
        controller.set('startTime', this.get('bootstrap').getTodayStartTime());
        var lpOptions = controller.get('lpOptions');
        var bkSymbolOptions = controller.get('bkSymbolOptions');
        controller.set('lpId', Ember.isEmpty(lpOptions) ? 0 : lpOptions[0].value);
        controller.set('bkSymbolId', Ember.isEmpty(bkSymbolOptions) ? 0 : bkSymbolOptions[0].value);
      }
    }
  });
});
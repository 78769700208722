define('frodo/pods/index/making/target-price-config-revision/model', ['exports', 'frodo/pods/index/making/making-model', 'frodo/pods/index/base-model'], function (exports, _makingModel, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _makingModel.default.extend({});


  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/targetPriceConfigRevisionHistory', { data: p });
  }

  exports.fetch = fetch;
});
define('frodo/pods/index/making/dealing-making-statement/controller', ['exports', 'frodo/pods/index/making/dealing-making-statement/model', 'frodo/pods/index/making/making-controller', 'frodo/pods/index/making/dealing-making-statement/validator', 'frodo/models/enums'], function (exports, _model, _makingController, _validator, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingController.default.extend({
    lpType: _enums.LpType.LIQUIDITY_DEMANDER,
    Validator: _validator.default,
    actions: {
      reload: function reload() {
        var _this = this;

        var r = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var p = this.build(r);
        if (p.refresh) {
          return (0, _model.refresh)(this, p).then(function (r) {
            _this.send('updateStatement', { data: r, param: p });
          });
        } else {
          return (0, _model.fetch)(this, p).then(function (r) {
            _this.send('updateStatement', { data: r, param: p });
          });
        }
      },
      updateStatement: function updateStatement(o) {
        var self = this;
        var data = o.data;
        var param = o.param;
        if (self.isData(data)) {
          var interval = data.data.refreshInterval;
          var model = _.map(data.data.statements, function (s) {
            s.restate = false;
            s.longThreshold2 = s.longThreshold;
            s.shortThreshold2 = s.shortThreshold;
            return s;
          });
          self.set('model', model);
          if (interval) {
            self.set('refreshError', false);
            self.set('refreshInterval', interval);
          }
          self.updateNavtab(self.get('currentRouteName'), model, param);
          self.refresh();
        } else {
          self.set('refreshError', true);
        }
      }
    },

    build: function build(refresh) {
      var p = { refresh: refresh };
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('profitCurrencyId')) p.currencyId = this.get('profitCurrencyId');
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      return p;
    },
    resetStatements: function resetStatements() {
      this.set('records', Ember.A());
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
      this.set('profitCurrencyId', p.hasOwnProperty('currencyId') ? p.currencyId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('model', model);
    }
  });
});
define('frodo/pods/index/risk/position-alertor/route', ['exports', 'frodo/pods/index/risk/holding-route'], function (exports, _holdingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingRoute.default.extend({
    preload: true,
    bookOptionSwitchFirst: true,
    symbolOptionSwitchFirst: true
  });
});
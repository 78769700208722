define('frodo/pods/index/market/market-model', ['exports', 'frodo/pods/index/base-model'], function (exports, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PARENT = undefined;


  /**
   * Base Market Model
   */
  var PARENT = exports.PARENT = 'market';

  var BaseMarketModel = _baseModel.default.extend({});

  BaseMarketModel.reopenClass({});

  exports.default = BaseMarketModel;
});
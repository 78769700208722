define('frodo/models/utility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ROOT = exports.ROOT = '';
  var PARENT = exports.PARENT = 'utility';

  exports.default = Ember.Object.extend({});


  function logout(owner) {
    return owner.get('ajax').request(ROOT + '/' + PARENT + '/logout');
  }

  function login(owner, data) {
    return owner.get('ajax').post(ROOT + '/' + PARENT + '/login', { data: data });
  }

  function evaluate(owner, data) {
    return owner.get('ajax').post(ROOT + '/' + PARENT + '/password/evaluate', { data: data });
  }

  function validate(owner, data) {
    return owner.get('ajax').post(ROOT + '/' + PARENT + '/password/validate', { data: data });
  }

  function changePw(owner, data) {
    return owner.get('ajax').post(ROOT + '/' + PARENT + '/password', { data: data });
  }

  exports.login = login;
  exports.logout = logout;
  exports.evaluate = evaluate;
  exports.validate = validate;
  exports.changePw = changePw;
});
define('frodo/pods/index/operator/operator-access/route', ['exports', 'frodo/pods/index/operator/operator-route'], function (exports, _operatorRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _operatorRoute.default.extend({
    preload: false,
    brokerWithAllOption: true
  });
});
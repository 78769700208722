define("frodo/pods/index/risk/alertor-history/controller", ["exports", "frodo/mixins/paginatable", "frodo/pods/index/risk/alertor-history/model", "frodo/pods/index/risk/holding-controller"], function (exports, _paginatable, _model, _holdingController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingController.default.extend(_paginatable.default, {
    doSearch: function doSearch(params) {
      var _this = this;

      var param = this.build(params);
      return (0, _model.fetch)(this, param).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            return _model.default.create(o);
          });
          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, param);
      });
    },
    build: function build(p) {
      p.brokerId = this.get('brokerId');
      if (this.get('alertType')) p.alertType = this.get('alertType');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('to')) p.to = this.get('to');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('alertType', p.hasOwnProperty('alertType') ? p.alertType : 0);
      this.set('from', p.hasOwnProperty('from') ? p.from : 0);
      this.set('to', p.hasOwnProperty('to') ? p.to : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
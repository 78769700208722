define('frodo/pods/index/system/debug/route', ['exports', 'frodo/pods/index/system/system-route'], function (exports, _systemRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemRoute.default.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      if (!controller.get('isResumed')) {
        controller.set('type', "application");
      }
    }
  });
});
define('frodo/pods/index/risk/position-alertor/controller', ['exports', 'frodo/pods/index/risk/position-alertor/validator', 'frodo/pods/index/risk/position-alertor/model', 'frodo/pods/index/risk/holding-controller'], function (exports, _validator, _model, _holdingController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingController.default.extend({
    Validator: _validator.default,
    sortable: true,
    records: null,
    actions: {
      reload: function reload() {
        var _this = this;

        var param = this.build();
        (0, _model.fetch)(this, param).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (a) {
              return _model.default.create(a);
            });
          }
          _this.set('model', model);
          _this.updateNavtab(_this.get('currentRouteName'), model, param);
        });
      },
      remove: function remove(o) {
        return (0, _model.remove)(this, o);
      }
    },

    build: function build() {
      var p = {};
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      if (this.get('symbolId')) p.symbolId = this.get('symbolId');
      if (this.get('bookId')) p.bookId = this.get('bookId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('bookId', p.bookId || 0);
      this.set('symbolId', p.symbolId || 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('model', model);
    }
  });
});
define("frodo/pods/index/risk/statement-history/controller", ["exports", "frodo/mixins/paginatable", "frodo/pods/index/risk/statement-history/model", "frodo/pods/index/risk/holding-controller"], function (exports, _paginatable, _model, _holdingController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingController.default.extend(_paginatable.default, {
    doSearch: function doSearch(params) {
      var _this = this;

      var param = this.build(params);

      return (0, _model.fetch)(this, param).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            var priceScale = 12,
                volumeScale = 12;
            var bkSymbols = _this.get('bootstrap').getSelection('broker.symbol');
            var bkSymbol = _this.getBkSymbol(bkSymbols, o.brokerId, o.symbolId);
            if (bkSymbol) {
              priceScale = bkSymbol.extra.tradingPriceScale;
              volumeScale = bkSymbol.extra.tradingVolumeScale;
            }
            o.avgCost = _this.avgCost(o.amount, o.volume, priceScale);
            o.dailyAvgCost = _this.avgCost(o.dailyAmount, o.dailyVolume, priceScale);
            o.grossAvgCost = _this.avgCost(o.grossAmount, o.volume, priceScale);
            o.priceScale = priceScale;
            o.volumeScale = volumeScale;
            return _model.default.create(o);
          });

          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, param);
      });
    },
    isNumber: function isNumber(value) {
      var r = false;
      var v = _.toNumber(value);
      if (!isNaN(v) && typeof v === 'number') r = true;
      return r;
    },
    avgCost: function avgCost(amount, volume, scale) {
      if (!this.isNumber(amount) || !this.isNumber(volume) || !this.isNumber(scale)) {
        console.error("must be number,amount: " + amount + " volume:" + volume + " scale:" + scale);
        return "0";
      }
      var round = BigNumber.ROUND_CEIL;
      if (volume < 0) {
        round = BigNumber.ROUND_FLOOR;
      }
      return !new BigNumber(volume).isZero() ? new BigNumber(amount).dividedBy(new BigNumber(volume).multipliedBy(new BigNumber(-1))).toFixed(scale, round) : "0";
    },
    build: function build(p) {
      p.brokerId = this.get('brokerId');
      if (this.get('bookId')) p.bookId = this.get('bookId');
      if (this.get('bkSymbolId')) p.bkSymbolId = this.get('bkSymbolId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('bookId', p.hasOwnProperty('bookId') ? p.bookId : 0);
      this.set('bkSymbolId', p.hasOwnProperty('bkSymbolId') ? p.bkSymbolId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
define('frodo/pods/index/pricing/pricing-model', ['exports', 'frodo/pods/index/base-model'], function (exports, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PARENT = undefined;


  /**
   * Base Pricing Model
   */
  var PARENT = exports.PARENT = 'pricing';

  var BasePricingModel = _baseModel.default.extend({});

  BasePricingModel.reopenClass({});

  exports.default = BasePricingModel;
});
define('frodo/helpers/from-now', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    bootstrap: Ember.inject.service('bootstrap'),

    compute: function compute(params /*, hash*/) {
      var v = parseInt(params[0], 10);
      return v ? moment.unix(v / 1000).fromNow() : '-';
    }
  });
});
define('frodo/pods/index/operator/operator/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    loginId: [(0, _validators.validatePresence)(true)],

    type: [(0, _validators.validatePresence)(true)],

    status: [(0, _validators.validatePresence)(true)],

    // password: [
    //   validatePresence(true),
    //   validateLength({ max: 128 })
    // ],

    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 16 })],

    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 16 })],

    passwordExpirePolicy: [(0, _validators.validatePresence)(true)]
  };
});
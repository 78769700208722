define("frodo/pods/index/risk/cp-statement-history/controller", ["exports", "frodo/mixins/paginatable", "frodo/pods/index/risk/cp-statement-history/model", "frodo/pods/index/risk/holding-controller"], function (exports, _paginatable, _model, _holdingController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingController.default.extend(_paginatable.default, {
    doSearch: function doSearch(params) {
      var _this = this;

      var param = this.build(params);
      return (0, _model.fetch)(this, param).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            return _model.default.create(o);
          });
          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, param);
      });
    },
    build: function build(p) {
      p.brokerId = this.get('brokerId');
      if (this.get('bookId')) p.bookId = this.get('bookId');
      if (this.get('bkSymbolId')) p.bkSymbolId = this.get('bkSymbolId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('bookId', p.hasOwnProperty('bookId') ? p.bookId : 0);
      this.set('bkSymbolId', p.hasOwnProperty('bkSymbolId') ? p.bkSymbolId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
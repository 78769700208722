define('frodo/services/notifier', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {

    flashMessages: Ember.inject.service(),

    info: function info(content) {
      this.get('flashMessages').info(content);
    },
    warning: function warning(content) {
      this.get('flashMessages').warning(content, { timeout: 6000 });
    },
    danger: function danger(content) {
      this.get('flashMessages').danger(content, { timeout: 3000 });
    }
  });
});
define('frodo/pods/index/making/filter-config/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    brokerId: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 8 })],

    symbolId: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 8 })],

    active: [(0, _validators.validatePresence)(true)]
  };
});
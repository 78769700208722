define('frodo/pods/index/report/holding-daily-trade-compare/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/report/report-model'], function (exports, _baseModel, _reportModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _reportModel.default.extend({});


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _reportModel.PARENT + '/holding_daily_trade_compare', { data: data });
  }
  exports.fetch = fetch;
});
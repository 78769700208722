define('frodo/helpers/icon', ['exports', 'frodo/models/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    bootstrap: Ember.inject.service(),

    compute: function compute(params, hash) {
      var imageId = params[0];
      var imageServer = params[1];
      var width = hash && hash.width ? hash.width : 22;
      var height = hash && hash.height ? hash.height : 22;
      var type = hash && hash.type ? hash.type : _enums.ImageType.SYMBOL;

      if (imageId === undefined) {
        return '-';
      }

      var url = '' + imageServer + type.value + '/' + imageId + '.' + type.suffix;
      return Ember.String.htmlSafe('<img style="width: ' + width + 'px; height: ' + height + 'px" src="' + url + '"/>');
    }
  });
});
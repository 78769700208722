define('frodo/pods/components/index/pricing/modal-dealing-container/component', ['exports', 'frodo/mixins/service-bus', 'frodo/models/enums', 'frodo/pods/components/index/pricing/dealing-list/model', 'frodo/pods/components/index/pricing/dealing-list/band_model', 'frodo/pods/components/index/pricing/dealing-postion/model', 'frodo/pods/components/index/pricing/modal-dealing-container/model', 'frodo/mixins/result', 'frodo/utils/common-generator'], function (exports, _serviceBus, _enums, _model, _band_model, _model2, _model3, _result, _commonGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_serviceBus.default, Ember.Evented, _result.default, {
    classNames: ['dealing-panel-container'],
    init: function init() {
      this._super.apply(this, arguments);
      var ws_subscribe = this.get('subscription');
      var settings = this.get('dialogManager').getSettings();
      //
      this.set('isCloseAll', false);
      this.set('orderVolumeReadonly', false);
      this.set('dealingPosition', Ember.A());
      this.set('asks_store', Ember.A());
      this.set('bids_store', Ember.A());
      this.set('switching', false);
      this.set('settings', settings);
      this.set('state', _enums.PanelState.OPEN);
      this.set('lpType', _enums.LpTypes.LIQUIDITY_PROVIDER);
      this.set('orderPriceEnables', [_enums.DealingOrderType.LIMIT]);
      this.set('slippageEnables', [_enums.DealingOrderType.SLIPPAGE]);
      this.set('orderPriceDisables', [_enums.DealingOrderType.MARKET, _enums.DealingOrderType.SLIPPAGE]);
      this.set('dealingOrderTypeFilter', [_enums.DealingOrderType.LIMIT, _enums.DealingOrderType.MARKET, _enums.DealingOrderType.SLIPPAGE]);
      //
      this.send('afterLpChange', this.get('model.lpId'));
      this.send('afterSymbolChange', this.get('model.symbolId'));
      this.send('afterSubscriptionChange', ws_subscribe, ws_subscribe);
      this.send('dealingReload');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.unsubscribe(this.get('subscription'));
      this.resetQuoteStore();
      this.set('state', _enums.PanelState.CLOSE);
      this._super.apply(this, arguments);
    },


    isBookWithAllOption: Ember.computed('model.bookWithAllOption', function () {
      var bookWithAllOption = this.get('model.bookWithAllOption');
      return bookWithAllOption;
    }),

    digits: Ember.computed('model.initPriceScale', function () {
      var digit = this.get('model.initPriceScale');
      if (isNaN(digit)) return '';
      var digits = Ember.A();
      for (var i = digit; i >= 0; i--) {
        digits.push(i);
      }
      return _.map(digits, function (d) {
        return { name: d, value: d, groupName: '' };
      });
    }),

    depths: Ember.computed('asks', 'bids', function () {
      var asks = this.get('asks');
      var bids = this.get('bids');
      return { askDepth: asks.length, bidDepth: bids.length, asks: asks, bids: bids };
    }),

    subscription: Ember.computed('model.{brokerId,symbolId}', 'model.lpIds.@each', function () {
      var _get = this.get('model'),
          brokerId = _get.brokerId,
          symbolId = _get.symbolId;

      var sub = { brokerId: brokerId, symbolId: symbolId };
      sub.lpId = this.parseSubscriptionLpId(this.get('model.lpIds')); // keep subscription old way
      return sub;
    }),

    providerIds: Ember.computed('lpOptions.@each', function () {
      var pids = this.get('model.lpIds');
      if (pids && pids.length === 0) return _.map(this.get('lpOptions'), function (p) {
        return p.value;
      });else return pids;
    }),

    lpOptions: Ember.computed('model.{brokerId}', function () {
      var lpType = this.get('lpType');
      var selectedBroker = this.get('model.brokerId');
      var lpOptions = this.get('reaction').reactionBrokerChange(selectedBroker, 0, 0, {
        lpType: lpType,
        lpWithAllOption: false
      }).lpOptions;
      return _.filter(lpOptions, function (o) {
        return o.extra.$group3;
      });
    }),

    symbolOptions: Ember.computed('model.brokerId', 'model.lpIds.@each', function () {
      var lpIds = this.get('model.lpIds');
      var selectedLp = this.parseSubscriptionLpId(lpIds);
      var selectedBroker = this.get('model.brokerId');
      return this.get('reaction').reactionBrokerChange(selectedBroker, selectedLp, 0, { symbolWithAllOption: false }).symbolOptions;
    }),

    bookOptions: Ember.computed('model.{brokerId}', function () {
      var selectedBroker = this.get('model.brokerId');
      return this.get('reaction').reactionBrokerChange(selectedBroker, 0, 0, { bookWithAllOption: false }).bookOptions;
    }),

    asks: Ember.computed('asks_store.@each', function () {
      var self = this;
      var asks_store = _.cloneDeep(this.get('asks_store'));
      var priceScale = this.get('model.priceScale');
      var volumeScale = this.get('model.volumeScale');
      var weightFeeEnabled = this.get('settings.weightFeeEnabled');
      var asks_bands = _.map(asks_store, function (ask) {
        var commissionRate = weightFeeEnabled ? ask.get('weight') : 0;
        var lpName = self.get('bootstrap').getSelectionName('broker.lp', ask.get('lpId'));
        _.forEach(ask.get('bands'), function (band) {
          band.set('time', ask.get('time'));
          band.set('lpId', ask.get('lpId'));
          band.set('weight', ask.get('weight'));
          band.set('lpName', lpName);
          var raw_price = new BigNumber(band.get('price')).toFixed(priceScale, BigNumber.ROUND_UP);
          var weightPrice = new BigNumber(raw_price).dividedBy(new BigNumber(1).minus(commissionRate)).toFixed(priceScale, BigNumber.ROUND_UP);
          band.set('price', weightPrice);
          band.set('volume', new BigNumber(band.get('volume')).toFixed(volumeScale, BigNumber.ROUND_DOWN));
        });
        return ask.get('bands');
      });

      var asks_store_flatten = _.flattenDepth(asks_bands, 1);
      var grouped = self.groupByPriceLpId(asks_store_flatten, volumeScale);
      grouped.sort(function (a1, a2) {
        if (a1.price === a2.price) {
          return a1.time - a2.time;
        } else {
          return a1.price - a2.price;
        }
      });
      if (Ember.isNone(self.get('initPrice')) && grouped && grouped.length > 0 && self.get('model.side') === _enums.Side.BUY) {
        self.set('initPrice', grouped[0].get('price'));
        self.set('model.orderPrice', grouped[0].get('price'));
      }
      return grouped;
    }),

    bids: Ember.computed('bids_store.@each', function () {
      var self = this;
      var bids_store = _.cloneDeep(this.get('bids_store'));
      var priceScale = this.get('model.priceScale');
      var volumeScale = this.get('model.volumeScale');
      var weightFeeEnabled = this.get('settings.weightFeeEnabled');
      var bids_bands = _.map(bids_store, function (bid) {
        var commissionRate = weightFeeEnabled ? bid.get('weight') : 0;
        var lpName = self.get('bootstrap').getSelectionName('broker.lp', bid.get('lpId'));
        _.forEach(bid.get('bands'), function (band) {
          band.set('time', bid.get('time'));
          band.set('lpId', bid.get('lpId'));
          band.set('weight', bid.get('weight'));
          band.set('lpName', lpName);
          var raw_price = new BigNumber(band.get('price')).toFixed(priceScale, BigNumber.ROUND_DOWN);
          var weightPrice = new BigNumber(raw_price).multipliedBy(new BigNumber(1).minus(commissionRate)).toFixed(priceScale, BigNumber.ROUND_DOWN);
          band.set('price', weightPrice);
          band.set('volume', new BigNumber(band.get('volume')).toFixed(volumeScale, BigNumber.ROUND_DOWN));
        });
        return bid.get('bands');
      });

      var bids_store_flatten = _.flattenDepth(bids_bands, 1);
      var grouped = self.groupByPriceLpId(bids_store_flatten, volumeScale);

      grouped.sort(function (b1, b2) {
        if (b1.price === b2.price) {
          return b2.time - b1.time;
        } else {
          return b2.price - b1.price;
        }
      });

      if (Ember.isNone(self.get('initPrice')) && grouped && grouped.length > 0 && self.get('model.side') === _enums.Side.SELL) {
        self.set('initPrice', grouped[0].get('price'));
        self.set('model.orderPrice', grouped[0].get('price'));
      }
      return grouped;
    }),

    buttonClass: Ember.computed('model.side', function () {
      this.set('initPrice', null);
      if (this.get('model.side') === _enums.Side.BUY) {
        return 'panel_btn_buy color_buy_bg';
      } else if (this.get('model.side') === _enums.Side.SELL) {
        return 'panel_btn_sell color_sell_bg';
      }
      return '';
    }),

    volumeButtonLabel: Ember.computed('isCloseAll', function () {
      if (this.get('isCloseAll')) {
        return 'label.common.btn.closePosition';
      } else {
        return 'label.common.btn.closeAllPosition';
      }
    }),

    buttonLabel: Ember.computed('model.side', 'state', function () {
      if (this.get('model.side') === _enums.Side.BUY) {
        if (this.get('state') === _enums.PanelState.ORDERING) {
          return 'label.common.btn.buying';
        } else {
          return 'label.common.btn.buy';
        }
      } else if (this.get('model.side') === _enums.Side.SELL) {
        if (this.get('state') === _enums.PanelState.ORDERING) {
          return 'label.common.btn.selling';
        } else {
          return 'label.common.btn.sell';
        }
      }
      return '';
    }),

    dealingPositionVolume: Ember.observer('dealingPosition.volume', function () {
      if (this.get('isDealingPanelType')) {
        //risk dealing
        var volume = new BigNumber(this.get('dealingPosition.volume'));
        if (volume > 0) {
          //
          this.set('model.side', _enums.Side.SELL);
          //
          this.set('buttonClass', 'panel_btn_sell color_sell_bg');
          if (this.get('state') === _enums.PanelState.ORDERING) {
            this.set('buttonLabel', 'label.common.btn.selling');
          } else {
            this.set('buttonLabel', 'label.common.btn.sell');
          }
        } else {
          //
          this.set('model.side', _enums.Side.BUY);
          //
          this.set('buttonClass', 'panel_btn_buy color_buy_bg');
          if (this.get('state') === _enums.PanelState.ORDERING) {
            this.set('buttonLabel', 'label.common.btn.buying');
          } else {
            this.set('buttonLabel', 'label.common.btn.buy');
          }
        }
        //
        if (volume.isNaN() || volume == 0) {
          this.set('buttonDisable', true);
        } else {
          this.set('buttonDisable', false);
        }
        //
        if (this.get('isCloseAll')) {
          this.set('model.orderVolume', volume.isNaN() ? 0 : volume.absoluteValue());
        }
      }
    }),

    buttonDisable: Ember.computed('state', 'model.hasRW', function () {
      if (!this.get('model.hasRW')) {
        return true;
      }
      if (this.get('state') === _enums.PanelState.ORDERING) {
        return true;
      }
      return false;
    }),

    isDealingPanelType: Ember.computed('model', function () {
      return this.get('model.panelType') == _enums.PanelType.DEALING;
    }),

    suffixLabel: Ember.computed('model.side', function () {
      if (this.get('model.side') === _enums.Side.BUY) {
        return 'label.pricing.dealing_panel.suffixLabel';
      } else if (this.get('model.side') === _enums.Side.SELL) {
        return 'label.pricing.dealing_panel.suffixLabel';
      }
      return '';
    }),

    orderPriceDisable: Ember.computed('model.orderType', function () {
      var t = this.get('model.orderType');
      var array = this.get('orderPriceDisables');
      for (var i = 0; i < array.length; i++) {
        if (array[i] === t) return true;
      }
      return false;
    }),

    resetQuoteStore: function resetQuoteStore() {
      this.set('initPrice', null);
      this.set('asks_store', Ember.A());
      this.set('bids_store', Ember.A());
    },
    build_ws_payload: function build_ws_payload(subscription) {
      return { broker: subscription.brokerId, lp: subscription.lpId, symbol: subscription.symbolId };
    },
    subscribe: function subscribe(subscription) {
      var realtime = this.get('realtime');
      realtime.subscribe(_enums.WebSocketEvent.LP_QUOTE, this.build_ws_payload(subscription));
      realtime.on(_enums.WebSocketEvent.LP_QUOTE, this.onLpQuote.bind(this));
      return new Ember.RSVP.Promise(function (resolve) {
        return resolve(subscription);
      });
    },
    unsubscribe: function unsubscribe(subscription) {
      var realtime = this.get('realtime');
      realtime.off(_enums.WebSocketEvent.LP_QUOTE);
      realtime.unsubscribe(_enums.WebSocketEvent.LP_QUOTE, this.build_ws_payload(subscription));
    },
    onLpQuote: function onLpQuote(quotes) {
      var self = this;
      if (self.get('switching')) return;

      var ids = self.get('model.lpIds');
      var lpOptions = _.map(self.get('lpOptions'), 'value');
      var selected = _.intersection(ids, lpOptions);
      _.forEach(quotes, function (quote) {
        self.set('refreshTime', quote.time);
        if (!Ember.isEmpty(selected)) {
          if (selected.includes(quote.lpId)) {
            self.replace(quote);
          }
        } else if (lpOptions.includes(quote.lpId)) {
          self.replace(quote);
        }
      });
      self.refreshPosition();
    },
    refreshPosition: function refreshPosition() {
      var self = this;
      var refreshInterval = self.get("refreshInterval");
      var lastUpdateTime = self.get("lastUpdateTime");
      var currentUpdateTime = self.get("refreshTime");
      if (lastUpdateTime != undefined) {
        if (currentUpdateTime - lastUpdateTime > refreshInterval) {
          self.send('dealingReload', true);
          self.set('lastUpdateTime', currentUpdateTime);
        }
      } else {
        self.set('lastUpdateTime', currentUpdateTime);
      }
    },
    replace: function replace(quote) {
      //
      var askBands = _.map(quote.asks, function (ab) {
        return _band_model.default.create(ab);
      });
      var ask_find = _.find(this.get('asks_store'), function (o) {
        return quote.lpId === o.get('lpId');
      });
      if (ask_find) {
        ask_find.setProperties({ lpId: quote.lpId, time: quote.time, weight: quote.weight, bands: askBands });
      } else {
        this.get('asks_store').push(_model.default.create({
          lpId: quote.lpId,
          time: quote.time,
          weight: quote.weight,
          bands: askBands
        }));
      }

      var bidBands = _.map(quote.bids, function (bb) {
        return _band_model.default.create(bb);
      });
      var bid_find = _.find(this.get('bids_store'), function (o) {
        return quote.lpId === o.get('lpId');
      });
      if (bid_find) {
        bid_find.setProperties({ lpId: quote.lpId, time: quote.time, weight: quote.weight, bands: bidBands });
      } else {
        this.get('bids_store').push(_model.default.create({
          lpId: quote.lpId,
          time: quote.time,
          weight: quote.weight,
          bands: bidBands
        }));
      }
      //
      this.notifyPropertyChange('asks_store');
      this.notifyPropertyChange('bids_store');
    },
    groupByPriceLpId: function groupByPriceLpId(data, volumeScale) {
      var groupData = _.groupBy(data, function (band) {
        return band.get('price') + '_' + band.get('lpId');
      });
      var result = _.map(groupData, function (e) {
        if (_.isArray(e)) {
          var sum = _.sumBy(e, function (item) {
            return parseFloat(item.get('volume'));
          });
          var first = _.head(e);
          var obj = first.copy();
          obj.set('volume', new BigNumber(sum).toFixed(volumeScale, BigNumber.ROUND_DOWN));
          return obj;
        }
        return e;
      });
      return result;
    },
    parseSubscriptionLpId: function parseSubscriptionLpId(lpIds) {
      if (!lpIds || _.isEmpty(lpIds)) return 0;
      return lpIds.length > 1 ? 0 : lpIds[0];
    },

    actions: {
      openSettingModal: function openSettingModal() {
        var dialog = Ember.getOwner(this).lookup('component:index/pricing/modal-dealing-setting');
        dialog.set('model', this.get('settings'));
        dialog.set('hasRW', this.get('hasRW'));
        dialog.append();
      },
      volumeButton: function volumeButton() {
        if (this.get('isCloseAll')) {
          this.set('isCloseAll', false);
          this.set('model.orderVolume', 0);
          this.set('orderVolumeReadonly', false);
        } else {
          var volume = new BigNumber(this.get('dealingPosition.volume'));
          this.set('isCloseAll', true);
          this.set('model.orderVolume', volume.isNaN() ? 0 : volume.absoluteValue());
          this.set('orderVolumeReadonly', true);
        }
      },
      placeOrder: function placeOrder() {
        var m = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        this.set('state', _enums.PanelState.ORDERING);
        //
        var i18n = this.get('i18n');
        m.placeType = this.get('isCloseAll') ? _enums.DealingPlaceType.MANUAL_HEDGE : m.placeType;
        m.hedgeAll = this.get('isCloseAll');
        var brokerId = m.brokerId,
            symbolId = m.symbolId,
            bookId = m.bookId,
            orderPrice = m.orderPrice,
            orderVolume = m.orderVolume,
            orderType = m.orderType,
            side = m.side,
            timeInForce = m.timeInForce,
            slippage = m.slippage,
            placeType = m.placeType,
            hedgeAll = m.hedgeAll;

        var order = {
          brokerId: brokerId,
          symbolId: symbolId,
          bookId: bookId,
          orderPrice: orderPrice,
          orderVolume: orderVolume,
          orderType: orderType,
          side: side,
          timeInForce: timeInForce,
          slippage: slippage,
          placeType: placeType,
          hedgeAll: hedgeAll
        };

        //
        order.lpIds = m.lpIds;
        //
        var error = {};
        //if(isEmpty(order.lpIds.values)){error.lpIds ={ validation:[i18n.t('error.dealingPanel.INVALID')]};}
        if (Ember.isEmpty(order.hedgeAll) || isNaN(order.hedgeAll)) {
          error.hedgeAll = { validation: [i18n.t('error.dealingPanel.INVALID')] };
        }
        if (Ember.isEmpty(order.placeType) || isNaN(order.placeType)) {
          error.placeType = { validation: [i18n.t('error.dealingPanel.INVALID')] };
        }
        if (Ember.isEmpty(order.symbolId) || isNaN(order.symbolId)) {
          error.symbolId = { validation: [i18n.t('error.dealingPanel.INVALID')] };
        }
        if (order.orderType === _enums.DealingOrderType.LIMIT && (Ember.isEmpty(order.orderPrice) || isNaN(order.orderPrice) || parseFloat(order.orderPrice) === 0)) {
          error.orderPrice = { validation: [i18n.t('error.dealingPanel.INVALID')] };
        }
        if (Ember.isEmpty(order.orderVolume) || isNaN(order.orderVolume) || parseFloat(order.orderVolume) === 0) {
          error.orderVolume = { validation: [i18n.t('error.dealingPanel.INVALID')] };
        }
        if (order.orderType === _enums.DealingOrderType.SLIPPAGE && (Ember.isEmpty(order.slippage) || isNaN(order.slippage))) {
          error.slippage = { validation: [i18n.t('error.dealingPanel.INVALID')] };
        }
        //
        if (!_.isEmpty(error)) {
          console.error("place error", error);
          this.set('error', error);
          this.set('state', _enums.PanelState.OPEN);
          return new Ember.RSVP.Promise(function (resolve) {
            resolve(error);
          });
        }
        this.set('error', {});
        var json = JSON.stringify(order);
        return (0, _model3.place)(this, { order: json });
      },
      handleResult: function handleResult(r) {
        var i18n = this.get('i18n');
        var flash = this.get('flashMessages');
        if (this.isSuccess(r)) {
          flash.success(i18n.t('label.notification.ordered'));
        } else if (this.isValidation(r)) {
          // let errs = {};
          _.forIn(r.data, function (value, key) {
            // if (fields.includes(key)) {
            //   flash.danger(i18n.t('error.dealingPanel.field.' + key, {error: value}));
            // } else {
            //   errs[key] = {validation: [i18n.t('error.dealingPanel.' + value)]};
            // }
            flash.danger(i18n.t('error.dealingPanel.common', { key: key, value: value }));
          });
          // this.set('error', errs);
        } else if (this.isError(r)) {
          flash.danger(r.key);
        } else {
          if (r && r.key) {
            flash.danger(r.key);
          }
        }
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(r);
        });
      },
      resetState: function resetState(r) {
        if (r && this.get('state') === _enums.PanelState.ORDERING) {
          this.set('state', _enums.PanelState.OPEN);
        }
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(r);
        });
      },
      onBookSelect: function onBookSelect(nextOption, pre_value) {
        this.send('dealingReload');
      },
      onLpSelect: function onLpSelect(nextOption, pre_value) {
        var values = Ember.A();
        _.forEach(nextOption, function (o) {
          values.push(o.value);
        });
        this.set('model.lpIds', values);

        var next_lpId = this.parseSubscriptionLpId(values);
        var pre_lpId = this.parseSubscriptionLpId(pre_value);
        var subscription = this.get('subscription');

        var prev = Object.assign({}, subscription, { lpId: pre_lpId });
        this.send('afterSubscriptionChange', this.get('subscription'), prev);
        this.send('afterLpChange', next_lpId);
      },
      onSymbolSelect: function onSymbolSelect(next, prev) {
        var target = Object.assign({}, this.get('subscription'), { symbolId: prev });
        this.send('afterSubscriptionChange', this.get('subscription'), target);
        this.send('afterSymbolChange', next);
      },
      onDigitSelect: function onDigitSelect() /*next,prev*/{
        this.set('initPrice', null);
      },
      afterSubscriptionChange: function afterSubscriptionChange(next, prev) {
        var _this = this;

        this.set('switching', true);
        if (prev) this.unsubscribe(prev);
        this.resetQuoteStore();
        this.subscribe(next).then(function () {
          Ember.run.later(_this, function () {
            _this.set('switching', false);
          }, 500);
        });
      },
      afterSymbolChange: function afterSymbolChange(symbolId) {
        if (symbolId === null) return;
        var brokerId = this.get('model.brokerId');
        var bkSymbols = this.get('bootstrap').getSelection('broker.symbol');
        var exactly = _.find(bkSymbols, function (s) {
          return s.extra.$group === brokerId && s.extra.symbolId === symbolId;
        });
        if (!Ember.isNone(exactly)) {
          this.set('model.priceScale', exactly.extra.tradingPriceScale);
          this.set('model.volumeScale', exactly.extra.tradingVolumeScale);
          this.set('model.initPriceScale', exactly.extra.tradingPriceScale);
        }
        var allSymbols = this.bootstrap.getSelection('market.symbol');
        var ts = _.find(allSymbols, function (s) {
          return s.value === symbolId;
        });
        if (ts) {
          document.title = ts.name;
        }
      },
      afterLpChange: function afterLpChange(lpId) {
        var symbolId = this.get('model.symbolId');
        var brokerId = this.get('model.brokerId');
        //
        if (lpId == 0) {
          // bk symbol match
          var bkSymbols = this.get('bootstrap').getSelection('broker.symbol');
          var filter_bkSymbols = _.filter(bkSymbols, function (s) {
            return s.extra.$group === brokerId;
          });
          var exactlyBkSymbol = _.find(filter_bkSymbols, function (o) {
            return o.extra.symbolId === symbolId;
          });
          if (!exactlyBkSymbol) {
            if (filter_bkSymbols && filter_bkSymbols.length > 0) {
              this.set('model.symbolId', filter_bkSymbols[0].extra.symbolId);
            }
          }
          return;
        }
        // lp symbol match
        var lpSymbols = this.get('bootstrap').getSelection('broker.lp_symbol');
        var filter_lpSymbols = _.filter(lpSymbols, function (s) {
          return s.extra.$group === lpId;
        });
        var exactlyLpSymbol = _.find(filter_lpSymbols, function (o) {
          return o.extra.symbolId === symbolId;
        });
        if (!exactlyLpSymbol) {
          if (filter_lpSymbols && filter_lpSymbols.length > 0) {
            this.set('model.symbolId', filter_lpSymbols[0].extra.symbolId);
          }
        }
      },
      dealingReload: function dealingReload() {
        var _this2 = this;

        var posRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var self = this;
        var p = self.build(posRefresh, this.get('model.brokerId'), this.get('model.symbolId'), this.get('model.bookId'));

        if (!(p.brokerId && p.bookId && p.symbolId)) {
          console.warn('brokerId or bookId or symbolId is empty');
          return;
        }

        return (0, _model3.fetch)(this, p).then(function (r) {
          _this2.send('updatePosition', r);
        });
      },
      updatePosition: function updatePosition(response) {
        var self = this;
        if (this.isData(response)) {
          this.set('dealingPosition', self.calculation(response.data.dealingPosition));
          var interval = response.data.refreshInterval;
          if (interval) {
            self.set('refreshError', false);
            self.set('refreshInterval', interval);
          }
        } else {
          self.set('refreshError', true);
        }
      }
    },
    build: function build(posRefresh, brokerId, symbolId, bookId) {
      var p = { refresh: posRefresh };
      if (brokerId) p.brokerId = brokerId;
      if (symbolId) p.symbolId = symbolId;
      if (bookId) p.bookId = bookId;
      return p;
    },
    isNumber: function isNumber(value) {
      var r = false;
      var v = _.toNumber(value);
      if (!isNaN(v) && typeof v === 'number') r = true;
      return r;
    },
    avgCost: function avgCost(amount, volume, scale) {
      if (!this.isNumber(amount) || !this.isNumber(volume) || !this.isNumber(scale)) {
        console.warn("must be number,amount: " + amount + " volume:" + volume + " scale:" + scale);
        return "0";
      }
      var round = BigNumber.ROUND_CEIL;
      if (volume < 0) {
        round = BigNumber.ROUND_FLOOR;
      }
      return !new BigNumber(volume).isZero() ? new BigNumber(amount).dividedBy(new BigNumber(volume).multipliedBy(new BigNumber(-1))).toFixed(scale, round) : "-";
    },
    calculation: function calculation(data) {
      if (!data) return _model2.default.create({});
      var self = this;
      var priceScale = 12,
          volumeScale = 12;
      var bkSymbols = self.get('bootstrap').getSelection('broker.symbol');
      var bkSymbol = self.getBkSymbol(bkSymbols, data.brokerId, data.symbolId);
      if (bkSymbol) {
        priceScale = bkSymbol.extra.tradingPriceScale;
        volumeScale = bkSymbol.extra.tradingVolumeScale;
      }

      data.avgCost = this.avgCost(data.amount, data.volume, priceScale);
      data.dailyAvgCost = this.avgCost(data.dailyAmount, data.dailyVolume, priceScale);
      data.grossAvgCost = this.avgCost(data.grossAmount, data.volume, priceScale);
      data.priceScale = priceScale;
      data.volumeScale = volumeScale;
      return _model2.default.create(data);
    },
    getBkSymbol: function getBkSymbol(symbols, brokerId, symbolId) {
      (false && !(typeof brokerId === 'number') && Ember.assert("brokerId must be number", typeof brokerId === 'number'));
      (false && !(typeof symbolId === 'number') && Ember.assert("symbolId must be number", typeof symbolId === 'number'));

      return Ember.isEmpty(symbols) ? undefined : symbols.find(function (s) {
        return s.value === (0, _commonGenerator.toBksymbolId)(brokerId, symbolId);
      });
    }
  });
});
define('frodo/pods/index/system/config/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    id: [(0, _validators.validatePresence)(true)],
    domain: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 32 })],
    dynamic: [(0, _validators.validatePresence)(true)],
    configType: [(0, _validators.validatePresence)(true)],
    configKey: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 64 })],
    configValue: [(0, _validators.validateLength)({ max: 255 })],
    version: [(0, _validators.validatePresence)(true)]
  };
});
define('frodo/pods/components/common/dialog-base/component', ['exports', 'frodo/mixins/service-bus', 'frodo/pods/components/common/dialog-base/template', 'frodo/mixins/result', 'frodo/models/enums'], function (exports, _serviceBus, _template, _result, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DialogBase = Ember.Component.extend(_serviceBus.default, _result.default, {

    tagName: 'div',
    layout: _template.default,
    classNames: ['modal'],
    classNameBindings: ['isAnimate:fade', 'isModaless:modaless'],

    detail: false,
    preview: false,
    isTabbed: false,
    isAnimate: true,
    isMaximum: false,
    permitEscape: true,
    hasWritePrivilege: true,

    model: null,
    shownAction: null,
    hidenAction: null,
    modelOriginal: null,
    modalBackdrop: 'static',
    customizeTitle: null,
    customizeTitleValues: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('modelOriginal', Ember.copy(this.get('model')));
    },
    didInsertElement: function didInsertElement() {
      this.$().modal({
        show: true,
        backdrop: this.get('modalBackdrop'),
        keyboard: this.get('permitEscape')
      }).on('hidden.bs.modal', function () {
        try {
          this.remove();
        } catch (err) {
          Ember.debug(err.toString());
        }

        var hidenAction = this.get('hidenAction');
        if (hidenAction) {
          hidenAction.call(this);
        }
      }.bind(this)).on('shown.bs.modal', function () {
        var shownAction = this.get('shownAction');
        if (shownAction) {
          shownAction.call(this);
        }
      }.bind(this)).attr('tabindex', -1);
    },


    readonly: Ember.computed('preview', function () {
      return this.get('preview');
    }),

    isEdit: Ember.computed('model', function () {
      return this.get('model').get('id') > 0;
    }),

    isCreate: Ember.computed('model', function () {
      return Ember.isNone(this.get('model.id'));
    }),

    isModaless: Ember.computed('modalBackdrop', function () {
      return this.get('modalBackdrop') === false;
    }),

    title: Ember.computed('name', 'detail', 'isEdit', function () {

      var i18n = this.get('i18n'),
          customizeTitle = this.get('customizeTitle'),
          customizeTitleValues = this.get('customizeTitleValues');
      if (customizeTitle) {
        if (customizeTitleValues) {
          return i18n.t('label.' + customizeTitle, customizeTitleValues);
        } else {
          return i18n.t('label.' + customizeTitle);
        }
      }

      var t_key = i18n.t(this.get('key'));
      if (this.get('detail')) {
        return i18n.t('label.dialog.action.detail', { name: t_key });
      } else if (this.get('isEdit')) {
        return i18n.t('label.dialog.action.edit', { name: t_key });
      } else {
        return i18n.t('label.dialog.action.add', { name: t_key });
      }
    }),

    actions: {
      back: function back() {
        this.set('preview', false);
      },
      close: function close() {
        this.$().modal('hide');
      },
      toggleSize: function toggleSize() {
        var $body = Ember.$('.modal-body');
        var $dialog = Ember.$('.modal-dialog');
        if (this.get('isMaximum')) {
          $body.addClass('xl-modal');
          $dialog.removeClass('maximize-modal');
        } else {
          $body.removeClass('xl-modal');
          $dialog.addClass('maximize-modal');
        }

        this.toggleProperty('isMaximum');
      },
      changeCompany: function changeCompany(companyId) {
        var model = this.get('model');

        // filter corresponding broker select
        var brokers = this.get('bootstrap').getSelection('company.broker');
        brokers = _.filter(brokers, function (s) {
          return s.extra.$group === companyId;
        });

        if (brokers && brokers.length > 0) {
          model.set('brokerId', brokers[0].value);
        } else {
          model.set('brokerId', 0);
        }

        // filter corresponding lp select
        var lps = this.get('bootstrap').getSelection('company.liquidity_provider');
        lps = _.filter(lps, function (s) {
          return s.extra.$group === companyId;
        });

        if (lps && lps.length > 0) {
          model.set('lpId', lps[0].value);
        } else {
          model.set('lpId', 0);
        }
      },
      handleCheckResult: function handleCheckResult(r) {
        if (this.isSuccess(r)) {
          this.set('preview', true);
        } else {
          if (r) {
            // server side validation
            var error = this.merge(r);
            var errorFields = Object.keys(error);
            this.set('error', error);
            this.set('errorFields', errorFields);
            if (this.get('isTabbed')) {
              this.activateTab(errorFields);
            }
          }
        }
      },
      handleSubmitResult: function handleSubmitResult(r) {

        var i18n = this.get('i18n');
        var name = this.get('menuKey');
        var flash = this.get('flashMessages');
        var notificationKey = this.get('notificationKey');
        if (this.isSuccess(r)) {
          if (notificationKey) {
            flash.info(i18n.t(notificationKey));
          } else {
            if (this.get('panelType') === _enums.PanelType.DEALING) {
              flash.success(i18n.t('label.notification.executed', { name: name }));
            } else if (this.get('isEdit')) {
              flash.info(i18n.t('label.notification.modified', { name: name }));
            } else {
              flash.info(i18n.t('label.notification.added', { name: name }));
            }
          }
        } else {

          if (r && r.key) {
            if (this.isError(r)) {
              flash.danger(i18n.t('error.dialog.' + r.key, { name: this.get('menuKey') }));
            } else {
              flash.danger(i18n.t('' + r.key));
            }
          }
        }

        return new Promise(function (resolve) {
          resolve(r);
        });
      }
    }

  });

  exports.default = DialogBase;
});
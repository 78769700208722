define('frodo/pods/index/market/broker-symbol/controller', ['exports', 'frodo/pods/index/market/broker-symbol/validator', 'frodo/pods/index/market/broker-symbol/model', 'frodo/pods/index/market/market-controller'], function (exports, _validator, _model, _marketController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    Validator: _validator.default,
    parentRoot: "market.broker",
    actions: {
      reload: function reload() {
        var _this = this;

        var intent = this.get("intent");
        (0, _model.fetch)(this, intent.brokerId).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (d) {
              return _model.default.create(d);
            });
          }
          _this.set('model', model);
        });
      },
      fill: function fill(e) {
        this.set('record.brokerId', e.brokerId);
      },
      sort: function sort(ids) {
        return (0, _model.sort)(this, { bkSymbolIds: ids.toString(), brokerId: this.get('intent.brokerId') });
      },
      download: function download() {}
    }
  });
});
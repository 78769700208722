define('frodo/pods/components/common/field-percentage/component', ['exports', 'frodo/pods/components/common/field-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    CON_SWITCH: 100,
    hasHelp: false,
    helpContent: "",
    init: function init() {
      this._super.apply(this, arguments);
      var value = this.get('value');
      if (this.isNumbers(value)) this.set('diplayValue', new BigNumber(value).multipliedBy(this.CON_SWITCH).toFixed());else this.set('diplayValue', value);
    },


    displayValue: Ember.computed('diplayValue', function () {
      return this.get('diplayValue');
    }),

    actions: {
      input: function input(value) {
        this.setValue(value);
      },
      focusIn: function focusIn(value) {
        this.setValue(value);
      },
      keyUp: function keyUp(value) {
        this.setValue(value);
      },
      focusOut: function focusOut(value) {
        this.setValue(value);
      }
    },

    setValue: function setValue(value) {
      if (this.isNumbers(value)) {
        this.set('value', new BigNumber(value).dividedBy(this.CON_SWITCH).toFixed());
      } else {
        this.set('value', value);
      }
    },
    isNumbers: function isNumbers(value) {
      var v = +value;
      if (isNaN(parseFloat(value)) || isNaN(v) || typeof v !== 'number') return false;else return true;
    }
  });
});
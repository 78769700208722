define('frodo/helpers/has-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasError = hasError;
  function hasError(params /*, hash*/) {
    var fields = params[0];
    var keys = params[1];

    var intersection = _.intersection(fields, keys);
    return !Ember.isEmpty(intersection);
  }

  exports.default = Ember.Helper.helper(hasError);
});
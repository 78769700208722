define('frodo/pods/components/common/deal-side-tab/component', ['exports', 'frodo/models/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: null,
    click: function click() {
      this.updateSide();
      this.onChange(this.get('value'));
    },
    init: function init() {
      this._super.apply(this, arguments);
    },
    updateSide: function updateSide() {
      var $target = this.$('.tab-current').children('a');
      var v = $target.attr('aria-controls');
      this.set('value', v === 'buy' ? _enums.Side.BUY : _enums.Side.SELL);
    },


    buttonValue: Ember.computed('value', function () {
      var v = this.get('value');
      return v === _enums.Side.BUY ? 'buy' : 'sell';
    }),

    actions: {}
  });
});
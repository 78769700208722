define('frodo/pods/components/index/market/modal-lp/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/market/lp/model', 'frodo/models/enums'], function (exports, _component, _model, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = _component.default.extend({
    isTabbed: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('lpAccountOptions', this.getLpAccountOptions(this.get('model.id'), true));
      this.get('isEdit') ? this.set('types', keys(_enums.LpType).map(function (k) {
        return { name: k, value: _enums.LpType[k] };
      })) : this.set('types', []);
    },


    actions: {
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      onSelectExchange: function onSelectExchange(value) {
        var _this = this;

        (0, _model.institution)(this, value).then(function (r) {
          if (_this.isData(r)) {
            var itType = r.data.type;
            if (itType === 0) {
              _this.set('types', []);
            } else if (itType === _enums.LpType.LIQUIDITY_PROVIDER) {
              _this.set('types', [{ name: 'LIQUIDITY_PROVIDER', value: _enums.LpType.LIQUIDITY_PROVIDER }]);
            } else if (itType === _enums.LpType.LIQUIDITY_DEMANDER) {
              _this.set('types', [{ name: 'LIQUIDITY_DEMANDER', value: _enums.LpType.LIQUIDITY_DEMANDER }]);
            } else {
              _this.set('types', keys(_enums.LpType).map(function (k) {
                return { name: k, value: _enums.LpType[k] };
              }));
            }
          }
        });
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      }
    }
  });
});
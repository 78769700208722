define('frodo/pods/index/risk/trade-alertor/route', ['exports', 'frodo/pods/index/risk/holding-route'], function (exports, _holdingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingRoute.default.extend({
    preload: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('isResumed')) {
        controller.set('lpId', 0);
      }
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('lpId', 0);
    }
  });
});
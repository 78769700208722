define('frodo/pods/components/common/alert-warning/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tag: 'div',
    i18n: Ember.inject.service(),
    classNameBindings: ['isAlert:alert', 'isAlertWarning:alert-warning', 'isCenter:message-alert-center'],
    isAlert: true,
    isCenter: true,
    isAlertWarning: true,
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
});
define('frodo/pods/components/index/making/modal-source-liquidity-config/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/models/enums', 'frodo/pods/index/making/source-liquidity-config/model'], function (exports, _component, _enums, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({

    lpTypeProvider: _enums.LpType.LIQUIDITY_PROVIDER,
    lpTypeDemander: _enums.LpType.LIQUIDITY_DEMANDER,
    init: function init() {
      this._super.apply(this, arguments);
      var brokerId = this.get('changeset.brokerId');
      this.set("lpOptions", this.getLpOptions(brokerId, this.lpTypeDemander));
      this.set("symbolOptions", this.getSymbolsOptions(brokerId));
      this.set("refLpsOptions", this.getRefLpsOptions(brokerId));
      this.set("refLpsValues", this.getRefLpsValues());
    },
    getSymbolsOptions: function getSymbolsOptions(brokerId) {
      var symbol = Ember.A();
      if (!brokerId) return symbol;
      var brokerSymbols = _.filter(this.get("bootstrap").getSelection('broker.symbol'), function (o) {
        return brokerId == o.extra.$group;
      });
      brokerSymbols.forEach(function (o) {
        symbol.push({ name: o.name, value: o.extra.symbolId });
      });
      return symbol;
    },
    getRefLpsOptions: function getRefLpsOptions(brokerId) {
      var lpType = this.lpTypeProvider;
      return _.filter(this.get("bootstrap").getSelection('broker.lp'), function (o) {
        return brokerId == o.extra.$group && o.extra.$group2 == lpType;
      });
    },
    getRefLpsValues: function getRefLpsValues() {
      var r = Ember.A();
      var o = this.get('model.refLps');
      if (o) o.forEach(function (k) {
        r.push(k);
      });
      return r;
    },


    baseLpsOptions: Ember.computed('refLpsValues', 'refLpsOptions', function () {
      var r = Ember.A();
      var refLpsOptions = this.get('refLpsOptions');
      var refLpsValue = this.get('refLpsValues');
      if (refLpsOptions) refLpsOptions.forEach(function (k) {
        if (refLpsValue.indexOf(k.value) >= 0) {
          r.push(k);
        }
      });
      return r;
    }),

    actions: {
      refLpsSelected: function refLpsSelected(next) {
        var changeset = this.get('changeset');
        var pre_baseLp = this.get('changeset.baseLp');
        var find = _.find(next, function (o) {
          return o.value === pre_baseLp;
        });
        if (!find) {
          changeset.set('baseLp', undefined);
        }
      },
      onSelectBroker: function onSelectBroker() {
        var brokerId = this.get('changeset.brokerId');
        this.set("lpOptions", this.getLpOptions(brokerId, this.lpTypeDemander));
        this.set("symbolOptions", this.getSymbolsOptions(brokerId));
        this.set("refLpsOptions", this.getRefLpsOptions(brokerId));
        this.set("refLpsValues", this.getRefLpsValues());
      },
      onSelectLp: function onSelectLp() {
        this.set("symbolOptions", this.getSymbolOptionsByLp(this.get('changeset.lpId')));
      },
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          changeset.set('refLps', JSON.stringify(this.refLpsValues));
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        changeset.set('refLps', JSON.stringify(this.refLpsValues));
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      }
    }
  });
});
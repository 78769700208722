define('frodo/pods/index/system/batch-history/controller', ['exports', 'frodo/pods/index/system/batch-history/model', 'frodo/pods/index/system/system-controller', 'frodo/mixins/paginatable'], function (exports, _model, _systemController, _paginatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemController.default.extend(_paginatable.default, {
    parentRoot: "system.batch",
    timeDisable: true,

    actions: {
      remove: function remove(o) {
        return (0, _model.remove)(this, o);
      },
      restart: function restart(o) {
        return (0, _model.reschedule)(this, o);
      },
      fetchSteps: function fetchSteps(o) {
        var _this = this;

        return (0, _model.steps)(this, o).then(function (r) {
          if (_this.isData(r)) {
            return _.map(r.data, function (s) {
              return _model.default.create(s);
            });
          }
          return [];
        });
      }
    },

    doSearch: function doSearch(params) {
      var _this2 = this;

      return (0, _model.fetch)(this, this.get('jobName'), params).then(function (r) {
        if (_this2.hasRecords(r)) {
          _this2.set('total', r.data.total);
          _this2.set('records', _.map(r.data.records, function (o) {
            return _model.default.create(o);
          }));
        }
        return r;
      });
    }
  });
});
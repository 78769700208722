define('frodo/pods/index/market/it-session/controller', ['exports', 'frodo/pods/index/market/it-session/model', 'frodo/pods/index/market/market-controller'], function (exports, _model, _marketController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    sortable: true,
    statusColors: Ember.computed(function () {
      return { 1: 'text-muted', 2: 'text-success', 3: 'text-warning', 4: 'text-danger' };
    }),

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          if (_this.isData(r)) {
            var model = _.map(r.data.sessions, function (s) {
              return _model.default.create(s);
            });
            _this.set('it_sessions', model);
            _this.set('refreshTime', r.data.refreshTime);
            _this.set('refreshInterval', r.data.refreshInterval);
            if (r.data.refreshInterval) {
              _this.refresh();
            }
          }
          _this.updateNavtab(_this.get('currentRouteName'), {}, {});
        });
      },
      connect: function connect() {
        return (0, _model.connect)(this, { itId: this.get('record.itId') });
      },
      disconnect: function disconnect() {
        return (0, _model.disconnect)(this, { itId: this.get('record.itId') });
      },
      reset: function reset() {
        return (0, _model.reset)(this, { itId: this.get('record.itId') });
      }
    }
  });
});
define('frodo/mixins/service-bus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    clock: Ember.inject.service(),
    cookie: Ember.inject.service(),
    router: Ember.inject.service(),
    navtabs: Ember.inject.service(),
    utility: Ember.inject.service(),
    storage: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    reaction: Ember.inject.service(),
    realtime: Ember.inject.service(),
    scheduler: Ember.inject.service(),
    bootstrap: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    dialogManager: Ember.inject.service(),
    layoutService: Ember.inject.service('layout')
  });
});
define('frodo/pods/index/pricing/quotation/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/pricing/pricing-model'], function (exports, _baseModel, _pricingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _pricingModel.default.extend({});


  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _pricingModel.PARENT + '/quotation', { data: p });
  }

  exports.fetch = fetch;
});
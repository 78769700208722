define('frodo/pods/components/common/select-enum/component', ['exports', 'frodo/pods/components/common/select-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    enumName: null,
    allowClear: false,
    defaultSelected: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('defaultSelected') && !this.get('emptyOption') && !this.get('value')) {
        var options = this.get('options');
        if (options && options.length > 0) {
          this.set('value', options[0].value);
        } else {
          this.set('value', null);
        }
      }
    },


    options: Ember.computed('enumName', 'filter', function () {
      return this._populate(this.get('enumName'), this.get('filter'));
    }),

    _populate: function _populate(enumName, filter) {
      var r = [];

      if (this.get('emptyOption')) {
        var emptyLabel = this.get('emptyLabel') || '';
        r.push({ name: emptyLabel, value: 0 });
      }

      var disabledOptions = this.get('disabledOptions');
      var enums = this.get('bootstrap').getEnum(enumName);
      if (!Ember.isEmpty(enums)) {
        for (var i = 0; i < enums.length; i++) {
          var key = enums[i].name;
          var value = enums[i].value;
          var name = this.get('i18n').t(key);

          if (filter && filter.length > 0 && !_.includes(filter, value)) continue;

          if (disabledOptions && disabledOptions.length > 0 && _.includes(disabledOptions, value)) {
            r.push({ name: name ? name : key, value: enums[i].value, disabled: true });
          } else {
            r.push({ name: name ? name : key, value: enums[i].value });
          }
        }
      }

      return r;
    }
  });
});
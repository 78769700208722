define('frodo/helpers/price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    bootstrap: Ember.inject.service(),
    compute: function compute(params, hash) {
      var safe = true,
          price = params[0],
          scale = hash.scale,
          highlight = hash.highlight,
          currency = hash.currency;
      if (price === null || price === undefined || isNaN(price) || price === Infinity || price === -Infinity) {
        return '-';
      }
      var count = 0;
      var exp = '0,0';
      if (scale && scale > 0) {
        count = scale;
      } else if (currency && currency > 0) {
        var selectionObject = this.get('bootstrap.selection')['market.currency'];
        var found = _.find(selectionObject, function (e) {
          return e.value === currency;
        });
        if (found) {
          count = found.extra.scale;
        }
      }

      if (count > 0) {
        exp += function (s) {
          var e = '.';
          for (var i = 0; i < s; i++) {
            e += '0';
          }
          return e;
        }(count);
      }

      var text = numeral(price).format(exp);
      if (highlight) {
        var p1 = text.length - 3;
        var p2 = text.length - 1;
        var t1 = text.slice(0, p1);
        var t2 = text.slice(p1, p2);
        var t3 = text.slice(p2);
        if (highlight === 'strong') {
          text = '<small>' + t1 + '</small><strong>' + t2 + '</strong><small>' + t3 + '</small>';
        }

        if (highlight === 'i') {
          text = t1 + '<i>' + t2 + '</i>' + t3;
        }
      }
      if (!Ember.isNone(hash.safe)) safe = hash.safe;
      return safe ? Ember.String.htmlSafe(text) : text;
    }
  });
});
define('frodo/pods/index/market/lp-symbol/route', ['exports', 'frodo/pods/index/market/market-route', 'frodo/utils/common-generator'], function (exports, _marketRoute, _commonGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketRoute.default.extend({
    preload: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('parentNode', { name: 'lp', route: 'index.market.lp' });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    beforePreload: function beforePreload(controller, model) {
      controller.set('intent', { lpId: parseInt(model.lid), brokerId: (0, _commonGenerator.getBrokerIdByLpId)(model.lid), itId: (0, _commonGenerator.getItIdByLpId)(model.lid) });
    }
  });
});
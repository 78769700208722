define('frodo/pods/components/index/pricing/dealing-order-type/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    }
  });
});
define("frodo/pods/index/making/source-liquidity-config/controller", ["exports", "frodo/pods/index/making/making-controller", "frodo/pods/index/making/source-liquidity-config/model", "frodo/pods/index/making/source-liquidity-config/validator"], function (exports, _makingController, _model, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingController.default.extend({
    Validator: _validator.default,
    record: null,
    isSearching: true,
    actions: {
      reload: function reload() {
        var _this = this;

        var p = this.build({});
        (0, _model.fetch)(this, p).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              s.restate = false;
              return _model.default.create(s);
            });
            _this.set('records', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, p);
        });
      },
      openAdd: function openAdd() {
        this.set('record', _model.default.create({}));
      },
      remove: function remove(o) {
        return (0, _model.del)(this, o);
      }
    },
    build: function build(p) {
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      if (this.get('bkSymbolId')) p.bkSymbolId = this.get('bkSymbolId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('bkSymbolId', p.hasOwnProperty('bkSymbolId') ? p.bkSymbolId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('records', model);
    }
  });
});
define('frodo/helpers/boolean', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.boolean = boolean;
  function boolean(params /*, hash*/) {
    var v = params[0];
    return v ? '<i class="fa fa-fw fa-check text-success"></i>' : '<i class="fa fa-times text-danger"></i>';
  }

  exports.default = Ember.Helper.helper(boolean);
});
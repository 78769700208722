define('frodo/pods/index/market/currency/controller', ['exports', 'frodo/pods/index/market/currency/validator', 'frodo/pods/index/market/currency/model', 'frodo/pods/index/market/market-controller'], function (exports, _validator, _model, _marketController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    Validator: _validator.default,
    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('model', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), {}, {});
        });
      },
      openModal: function openModal() {
        var isEdit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        this.set('readonly', !isEdit);
      },
      openAdd: function openAdd() {
        this.set('record', _model.default.create({}));
      },
      sort: function sort(ids) {
        return (0, _model.sort)(this, { currencyIds: ids.toString() });
      }
    }
  });
});
define('frodo/pods/index/risk/cp-statement-history/route', ['exports', 'frodo/pods/index/risk/holding-route'], function (exports, _holdingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingRoute.default.extend({
    preload: false,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('isResumed')) {
        var brokerId = this.get('brokerId');
        controller.onSelectBroker(brokerId, false, true, true);
      }
    }
  });
});
define('frodo/pods/index/report/holding-daily-pl/controller', ['exports', 'frodo/pods/index/report/holding-daily-pl/model', 'frodo/pods/index/report/report-controller', 'frodo/mixins/paginatable'], function (exports, _model, _reportController, _paginatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportController.default.extend(_paginatable.default, {
    paginatable: false,
    doSearch: function doSearch() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var profitCurrency = void 0,
            records = void 0,
            totalPL = void 0;
        if (_this.isData(r)) {
          var summary = r.data[0]; // may be multi summary in future
          if (summary) {
            totalPL = summary.totalPL;
            profitCurrency = summary.profitCurrency;
            records = _.map(summary.pl, function (s) {
              return _model.default.create(s);
            });
            _this.set('records', records);
            _this.set('totalPL', totalPL);
            _this.set('profitCurrency', profitCurrency);
            _this.set('unit', _this.get('bootstrap').getSelectionNameDash('market.currency', p.currencyId));
          }
        }
        _this.updateNavtab(_this.get('currentRouteName'), { records: records, totalPL: totalPL, profitCurrency: profitCurrency }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('to')) p.to = this.get('to');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('currencyId')) p.currencyId = this.get('currencyId');
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('to', p.to);
      this.set('from', p.from);
      this.set('brokerId', p.brokerId);
      this.set('currencyId', p.currencyId);
    },
    resumeModel: function resumeModel(model) {
      this.set('records', model.records);
      this.set('totalPL', model.totalPL);
      this.set('profitCurrency', model.profitCurrency);
    }
  });
});
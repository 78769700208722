define('frodo/pods/components/tab-to/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({

    isTop: false,
    isMenu: false,
    openTab: false,
    navtabs: Ember.inject.service(),
    classNames: ['nav-link', 'nav-tab-block'],
    classNameBindings: ['isActive:active', 'isTop:nav-tab-to-top', 'isMenu:nav-tab-to-menu'],

    sendTabAction: function sendTabAction() {
      var action = this.get('actioned');
      if (action) action();
    },
    attachAddEvent: function attachAddEvent() {
      this.get('navtabs').addTabFromRoute(this, false);
    },
    attachSwapEvent: function attachSwapEvent() {
      this.get('navtabs').swapTabFromRoute(this, false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var eventName = this.get('eventName');
      if (this.get('actioned')) {
        this.on(eventName, this, this.sendTabAction);
      } else {
        if (this.get('openTab')) {
          this.on(eventName, this, this.attachAddEvent);
        } else {
          // this.on(eventName, this, this.attachSwapEvent);
        }
      }
    },
    willDestroy: function willDestroy() {
      if (this.get('actioned')) {
        this.off(this.get('eventName'), this, this.sendTabAction);
      } else {
        if (this.get('openTab')) {
          this.off(this.get('eventName'), this, this.attachAddEvent);
        } else {
          this.off(this.get('eventName'), this, this.attachSwapEvent);
        }
      }
    }
  });
});
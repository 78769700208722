define('frodo/pods/components/index/market/modal-lp-symbol/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/market/lp-symbol/model', 'frodo/models/enums'], function (exports, _component, _model, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = _component.default.extend({
    isTabbed: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('lpAccountOptions', this.getLpAccountOptions(this.get('model.lpId'), true));
      this.set('hedgingCopTypes', keys(_enums.HedgingCopType).map(function (k) {
        return { name: k, value: _enums.HedgingCopType[k] };
      }));
      //
      var itId = this.get('model.itId');
      var it = _.find(this.get('bootstrap').getSelection('broker.institution'), function (e) {
        return e.value == itId;
      });
      this.set('isSwap', it.extra.$group == _enums.InstrumentType.SWAP);
    },


    bk_symbols_filter: Ember.computed('model.brokerId', 'changeset.itId', function () {
      var bkId = this.get('model.brokerId'),
          allBkSymbols = this.get('bootstrap').getSelection('broker.symbol'),
          allItSymbol = this.get('bootstrap').getSelection('broker.it_symbol');
      if (bkId === 0) return [];
      var itId = this.get('changeset.itId');
      var itSymbols = _.filter(allItSymbol, function (e) {
        return e.extra.$group === itId;
      });
      var filterSymbols = Ember.A();
      itSymbols.forEach(function (s) {
        filterSymbols.push(s.extra.symbolId);
      });
      var symbols = _.filter(allBkSymbols, function (e) {
        return e.extra.$group === bkId && _.indexOf(filterSymbols, e.extra.symbolId) != -1;
      });
      return _.map(symbols, function (s) {
        return s.extra.symbolId;
      });
    }),

    actions: {
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      }
    }
  });
});
define('frodo/pods/components/common/responsive-table/component', ['exports', 'frodo/services/layout'], function (exports, _layout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NAV_HEIGHT = 35;
  var PANEL_HEADER_HEIGHT = 50;
  var PAGINATION_HEIGHT = 50;
  var STICKY_FOOTER_HEIGHT = 15;

  var PAGINATION_OFFSITE = 15;
  var HEIGHT_OFFSITE = 65;

  var supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

  exports.default = Ember.Component.extend({
    classNames: ['table-sticky'],
    attributeBindings: ['style'],
    classNameBindings: ['isTablist:has-tablist', 'isPaging:has-paging', 'isTabPaging:has-tab-paging'],

    isPaging: true,
    isTablist: false,
    layoutService: Ember.inject.service('layout'),
    tableContainer: '.right-frame .panel-body',

    isTabPaging: Ember.computed('isPaging', 'isTablist', function () {
      return this.get('isPaging') && this.get('isTablist');
    }),

    //FROM + PAGINATION_HEIGHT
    hidenHeight: Ember.computed('isPaging', function () {
      var height = 0;
      var form = document.querySelector('form');
      if (form) {
        height += form.getBoundingClientRect().height;
      }
      return this.get('isPaging') ? height + PAGINATION_HEIGHT : height;
    }),

    style: Ember.computed('isPaging', 'isTablist', 'hidenHeight', function () {
      var isPaging = this.get('isPaging');
      var isTablist = this.get('isTablist');
      var hidenHeight = this.get('hidenHeight');
      var height = this.getHeight(isPaging, isTablist, hidenHeight);
      return Ember.String.htmlSafe('max-height:' + height + 'px; overflow-x: auto; overflow-y: auto;');
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var target = this.get('element');
      var layout = this.get('layoutService');
      var tableContainer = this.get('tableContainer');
      this.panelBody = document.querySelector(tableContainer);
      this.handler = function (e) {
        return Ember.run.throttle(_this, 'onScroll', e, 50, false);
      };
      // register
      target.addEventListener('scroll', this.handler, false);
      layout.on(_layout.WINDOW_RESIZE, function () {
        return _this.send('resizePanel');
      });
      layout.on(_layout.EVENT_SEARCHING, function () {
        return _this.send('scrollTop', target);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var target = this.get('element');
      var layout = this.get('layoutService');
      layout.off(_layout.WINDOW_RESIZE);layout.off(_layout.EVENT_SEARCHING);
      target.removeEventListener('scroll', this.handler, false);
      this.send('scrollTop', target);
    },


    actions: {
      resizePanel: function resizePanel() {
        Ember.run.debounce(this, this.setStyle, 50);
      },
      scrollTop: function scrollTop(target) {
        if (target) {
          if (supportsNativeSmoothScroll) {
            target.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }

        if (this.panelBody) {
          this.panelBody.classList.remove('panel-vertical-scrolling');
        }
      }
    },

    onScroll: function onScroll() {
      var _this2 = this;

      var element = this.get('element');

      if (this.panelBody) {
        window.requestAnimationFrame(function () {
          var position = element.scrollTop;
          var scrollLeft = element.scrollLeft;
          var scrollHeight = element.scrollHeight;
          var hidenHeight = _this2.get('hidenHeight');
          var tableMaxHeight = parseInt(element.style.maxHeight);
          if (scrollHeight - tableMaxHeight > hidenHeight) {
            if (position > 0) {
              _this2.panelBody.classList.add('panel-vertical-scrolling');
            } else {
              _this2.panelBody.classList.remove('panel-vertical-scrolling');
            }
          }

          if (scrollLeft > 0) {
            _this2.panelBody.classList.add('panel-horizon-scrolling');
          } else {
            _this2.panelBody.classList.remove('panel-horizon-scrolling');
          }
        });
      }
    },
    setStyle: function setStyle() {
      var isPaging = this.get('isPaging');
      var isTablist = this.get('isTablist');
      var hidenHeight = this.get('hidenHeight');
      var height = this.getHeight(isPaging, isTablist, hidenHeight);
      this.set('style', Ember.String.htmlSafe('max-height:' + height + 'px; overflow-x: auto; overflow-y: auto;'));
    },
    getHeight: function getHeight(isPaging, isTablist, hidenHeight) {
      var pageHeight = this.get('layoutService').get('pageHeight');
      var outer = NAV_HEIGHT + PANEL_HEADER_HEIGHT + STICKY_FOOTER_HEIGHT + HEIGHT_OFFSITE;
      return pageHeight - outer - hidenHeight - (isPaging ? PAGINATION_HEIGHT + PAGINATION_OFFSITE : 0);
    }
  });
});
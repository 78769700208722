define('frodo/pods/index/making/dealing-making-statement/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    longThreshold2: [(0, _validators.validateNumber)({ allowBlank: true, allowNone: true, gt: 0, message: 'Number is invalid' })],
    shortThreshold2: [(0, _validators.validateNumber)({ allowBlank: true, allowNone: true, gt: 0, message: 'Number is invalid' })]
  };
});
define('frodo/pods/index/system/alert-guard/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/system/system-model'], function (exports, _baseModel, _systemModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.edit = exports.del = exports.add = exports.check = exports.fetch = undefined;
  exports.default = _systemModel.default.extend({});


  function fetch(owner) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/alert/guards');
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/alert/guard/check', { data: data });
  }

  function add(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/alert/guard/new', { data: data });
  }

  function del(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + '/' + _systemModel.PARENT + '/alert/guard/' + data.id + '/' + data.version);
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/alert/guard/' + data.id, { data: data });
  }

  exports.fetch = fetch;
  exports.check = check;
  exports.add = add;
  exports.del = del;
  exports.edit = edit;
});
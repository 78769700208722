define('frodo/pods/index/market/institution-symbol/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    itId: [(0, _validators.validatePresence)(true)],
    symbolId: [(0, _validators.validatePresence)(true)],
    quoteCode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 32 })],
    tradeCode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 32 })],
    hedgingMinVolume: [(0, _validators.validatePresence)(true)],
    hedgingMaxVolume: [(0, _validators.validatePresence)(true)],
    hedgingMinAmount: [(0, _validators.validatePresence)(true)],
    hedgingVolumeScale: [(0, _validators.validatePresence)(true)],
    hedgingPriceScale: [(0, _validators.validatePresence)(true)],
    subscriptionType: [(0, _validators.validatePresence)(true)],
    subscriptionDepth: [(0, _validators.validatePresence)(true)],
    persistDepth: [(0, _validators.validatePresence)(true)],
    liquidityType: [(0, _validators.validatePresence)(true)]
  };
});
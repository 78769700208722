define('frodo/pods/components/common/select-lp/component', ['exports', 'frodo/pods/components/common/select-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    type: 'broker.lp',
    brokerId: null,
    isManager: false,
    emptyOption: false,
    allowClear: false
  });
});
define('frodo/pods/components/common/button-download/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'button',
    disabled: false,
    onDownload: null,
    attributeBindings: ['disabled', 'style'],
    classNames: ['btn', 'btn-sm', 'btn-outline-primary', 'float-right', 'py-0'],

    style: Ember.computed('top', function () {
      var css = '';
      var marginTop = this.get('marginTop');
      if (marginTop !== undefined) {
        css += 'margin-top: ' + marginTop + 'px';
      }

      return Ember.String.htmlSafe(css);
    }),

    click: function click() {
      var _this = this;

      if (this.get('disabled')) return;

      var onDownload = this.get('onDownload');
      if (onDownload) {
        onDownload().then(function (url) {
          _this.set('url', url);
          _this.download(url);
        });
      }
    },
    download: function download(url) {
      var a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', "");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return true;
    }
  });
});
define('frodo/pods/utility/password/model', ['exports', 'frodo/models/utility'], function (exports, _utility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.check = exports.add = undefined;
  exports.default = _utility.default.extend({});


  function add(owner, data) {
    return owner.get('ajax').put(_utility.ROOT + '/' + _utility.PARENT + '/password', { data: data });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_utility.ROOT + '/' + _utility.PARENT + '/password/validate', { data: data });
  }

  exports.add = add;
  exports.check = check;
});
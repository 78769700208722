define('frodo/pods/index/base-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Base Model
   */
  var ROOT = exports.ROOT = '';
  var NAMESPACE = exports.NAMESPACE = 'rest';

  var BaseModel = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    copy: function copy() {
      return BaseModel.create(this);
    },
    toObject: function toObject() {
      return JSON.parse(JSON.stringify(this, exclude));
    }
  });

  BaseModel.reopenClass({});

  function exclude(k, v) {
    // Filtering out _changeset property
    if (k === '_changeset') {
      return undefined;
    }
    return v;
  }

  exports.default = BaseModel;
});
define('frodo/pods/components/common/tooltip-query/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    pop: false,
    tagName: 'div',
    maxlength: 20,

    hasLpOrderId: Ember.computed('record.lpOrderId', function () {
      return !Ember.isEmpty(this.get('record.lpOrderId'));
    }),

    queryDisabled: Ember.computed('record.hasVerified', function () {
      return this.get('record.hasVerified') || !this.get('hasRW');
    }),

    cancelDisabled: Ember.computed('record.cancelable', function () {
      return !this.get('record.cancelable') || !this.get('hasRW');
    }),

    click: function click() {
      this.toggleProperty('pop');
    },


    actions: {
      query: function query(e) {
        if (_.isEmpty(this.get('error'))) {
          this.set('error', null);
          var actionName = this.get('onQuery');
          if (actionName) {
            actionName(e);
          }
          this.toggleProperty('pop');
        }
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve(e);
        });
      },
      cancel: function cancel(e) {
        this.set('error', null);
        var actionName = this.get('onCancel');
        if (actionName) {
          actionName(e);
        }
        this.toggleProperty('pop');
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve(e);
        });
      },
      validate: function validate(e) {
        // let errors = {};
        // let i18n = this.get('i18n');
        // if(isEmpty(e.get('lpOrderId')) && isEmpty(e.get('inputLpOrderId'))){
        //   errors.inputLpOrderId = { validation: [ i18n.t('error.hedgeOrder.EMPTY',{name:'LP Order ID'})]};
        //   this.set('error',errors);
        // }
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve(e);
        });
      },
      reset: function reset(e) {
        this.set('error', null);
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve(e);
        });
      },
      close: function close() {
        this.toggleProperty('pop');
      }
    }
  });
});
define("frodo/pods/index/system/batch-history/model", ["exports", "frodo/pods/index/base-model", "frodo/pods/index/system/system-model", "frodo/pods/index/system/batch/model"], function (exports, _baseModel, _systemModel, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reschedule = exports.remove = exports.steps = exports.fetch = undefined;
  exports.default = _model.default.extend({
    restartable: Ember.computed('status', function () {
      return this.get('status') !== 'COMPLETED';
    })
  });


  function steps(owner, data) {
    var p = { jobExecutionId: data.jobExecutionId };
    return owner.get('ajax').request(_baseModel.ROOT + "/" + _systemModel.PARENT + "/batch/job/execution/steps", { data: p });
  }

  function fetch(owner, jobName, data) {
    return owner.get('ajax').request(_baseModel.ROOT + "/" + _systemModel.PARENT + "/batch/job/" + jobName + "/executions", { data: data });
  }

  function remove(owner, data) {
    var p = { jobInstanceId: data.jobInstanceId };
    return owner.get('ajax').post(_baseModel.ROOT + "/" + _systemModel.PARENT + "/batch/job/instance/remove", { data: p });
  }

  function reschedule(owner, data) {
    var p = { jobExecutionId: data.jobExecutionId };
    return owner.get('ajax').post(_baseModel.ROOT + "/" + _systemModel.PARENT + "/batch/job/instance/reschedule", { data: p });
  }

  exports.fetch = fetch;
  exports.steps = steps;
  exports.remove = remove;
  exports.reschedule = reschedule;
});
define('frodo/pods/components/common/deal-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    attributeBindings: ['type'],
    type: 'button',
    onDeal: null,
    isSearching: false,
    canNotDeal: false,
    offsetClass: null,
    classNameBindings: ['isBuy:panel_btn_buy:panel_btn_sell', 'isBuy:color_buy_bg:color_sell_bg'],

    isBuy: Ember.computed('buttonClasses', function () {
      return this.get('buttonClasses');
    }),

    click: function click() {
      this.get('onTap')();
    },
    didRender: function didRender() {
      //console.log(this.get('buttonSide'));
    },


    actions: {
      deal: function deal() {
        this.get('onDeal')();
      }
    }
  });
});
define('frodo/helpers/hyphen', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hyphen = hyphen;
  function hyphen(params /*, hash*/) {
    var v = params[0];
    return Ember.isEmpty(v) || v === 0 || v === '0' || v === 'null' ? '-' : v;
  }

  exports.default = Ember.Helper.helper(hyphen);
});
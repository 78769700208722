define('frodo/pods/index/report/holding-daily-trade-compare/route', ['exports', 'frodo/pods/index/report/report-route'], function (exports, _reportRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportRoute.default.extend({
    preload: false,
    lpSelectAll: true,
    lpMultiSelect: true,
    lpWithAllOption: false,
    symbolWithAllOption: false,
    symbolOptionSwitchFirst: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('options', {});
      if (!controller.get('isResumed')) {
        var bootstrap = this.get('bootstrap');
        var to = parseInt(bootstrap.getSod(), 10) + 1 * 24 * 60 * 60 * 1000,
            from = to - controller.get('default_days') * 24 * 60 * 60 * 1000;
        controller.set('from', from);
        controller.set('to', to);
        controller._reactLp();
      }
    }
  });
});
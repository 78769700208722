define('frodo/helpers/menu-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.menuIcon = menuIcon;
  function menuIcon(params, hash) {
    var v = params[0];
    var small = hash && hash.small ? hash.small : false;
    if (!v) {
      return hash.hyphen ? '-' : '';
    }
    return Ember.String.htmlSafe('<i class="fa fa-fw ' + (small ? '' : 'fa-lg') + ' ' + v + '"></i>');
  }

  exports.default = Ember.Helper.helper(menuIcon);
});
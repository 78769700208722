define('frodo/helpers/floating-profit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    /*
    multiple lp:
    	Sum(OpenVolume) = 0
    	  Profit = Sum(OpenAmount)
    	Sum(OpenVolume) > 0
    	  Profit = Sum(OpenVolme)  * Best Bid +  Sum(OpenAmount)
    	Sum(OpenVolume) < 0
    	   Profit = Sum(OpenVolme)  * Best Ask +  Sum(OpenAmount)
    */
    compute: function compute(params, hash) {
      var stripTrailingZeros = false;

      if (Ember.isEmpty(params[0]) || Ember.isEmpty(params[1]) || Ember.isEmpty(params[2]) || Ember.isEmpty(params[3])) {
        return '-';
      }
      var volume = params[0];
      var amount = params[1];
      var ask = params[2];
      var bid = params[3];
      var scale = hash.scale;

      var format = '0.';
      for (var i = 0; i < scale; i++) {
        format += '0';
      }

      if (hash && hash.stripTrailingZeros) {
        stripTrailingZeros = hash.stripTrailingZeros;
      }

      var v = 0;
      if (numeral(volume).value() == 0) {
        v = amount;
      } else if (numeral(volume).value() > 0) {
        v = numeral(volume).multiply(bid).add(amount).value();
      } else if (numeral(volume).value() < 0) {
        v = numeral(volume).multiply(ask).add(amount).value();
      }
      v = numeral(v).format(format);
      if (stripTrailingZeros) {
        v = v.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
      }
      return v;
    }
  });
});
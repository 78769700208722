define('frodo/pods/components/index/risk/modal-trigger/component', ['exports', 'frodo/models/enums', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/risk/trigger/model'], function (exports, _enums, _component, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({

    lpType: _enums.LpType.LIQUIDITY_PROVIDER,
    slippageTypes: [_enums.DealingOrderType.LIMIT, _enums.DealingOrderType.SLIPPAGE],
    triggerStrategy: [_enums.TriggerStrategy.NPL],
    init: function init() {
      this._super.apply(this, arguments);
      this.selectBroker();
    },


    actions: {
      selectLps: function selectLps(selected) {
        var arr = _.map(selected, function (s) {
          return s.value;
        });
        this.set('changeset.lpIds', arr);
      },
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          var c = this.copy(model, changeset);
          c.lpIds = JSON.stringify(c.lpIds);
          // let data = _.pickBy(c, function (value, key) { return value != ''; });
          return (0, _model.check)(this, c);
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        obj.lpIds = JSON.stringify(obj.lpIds);
        // let data = _.pickBy(obj, function (value, key) { return value != ''; });
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      }
    }
  });
});
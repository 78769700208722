define('frodo/pods/components/common/scroll-to-top/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    hashLocation: '#',
    anchor: '#page-top',
    attributeBindings: ['hashLocation:href'],
    classNames: ['scroll-to-top', 'rounded'],

    click: function click(e) {
      window.scrollTo(0, 0);
      e.preventDefault();
      e.stopPropagation();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var that = this.$();
      var doc = this.$(document);
      doc.scroll(function () {
        var distance = doc.scrollTop();
        if (distance > 100) {
          that.fadeIn();
        } else {
          that.fadeOut();
        }
      });
    }
  });
});
define('frodo/pods/components/common/input-base/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'input',

    value: null,
    error: false,

    onInput: null,
    onFocusIn: null,
    onFocusOut: null,

    placeholder: null,
    autocomplete: 'off',
    classNames: ['form-control'],
    classNameBindings: ['valueAlignClass', 'error:is-invalid'],
    attributeBindings: ['value', 'disabled', 'autocomplete', 'placeholder'],

    valueAlignClass: Ember.computed('valueAlign', function () {
      var direction = this.get('valueAlign');
      switch (direction) {
        case 'left':
          return 'text-left';
        case 'right':
          return 'text-right';
        case 'center':
          return 'text-center';
        default:
          return 'text-right';
      }
    }),

    input: function input(e) {
      var value = e.target.value;
      this.set('value', value);
      var action = this.get('onInput');
      if (action) {
        action(value, e);
      }
    },
    focusIn: function focusIn(e) {
      var action = this.get('onFocusIn');
      if (action) {
        action(e.target.value, e);
      }
    },
    focusOut: function focusOut(e) {
      var action = this.get('onFocusOut');
      if (action) {
        action(e.target.value, e);
      }
    },
    keyUp: function keyUp(e) {
      var action = this.get('onKeyUp');
      if (action) {
        action(e.target.value, e);
      }
    }
  });
});
define('frodo/pods/index/making/making-config-revision/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/making/making-model'], function (exports, _baseModel, _makingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _makingModel.default.extend({});


  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfigRevisionHistory', { data: p });
  }
  exports.fetch = fetch;
});
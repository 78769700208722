define('frodo/pods/index/trades/hedge-order-history/controller', ['exports', 'frodo/pods/index/trades/hedge-order-history/model', 'frodo/pods/index/trades/trades-controller', 'frodo/mixins/paginatable', 'frodo/utils/common-generator', 'frodo/mixins/downloadable'], function (exports, _model, _tradesController, _paginatable, _commonGenerator, _downloadable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tradesController.default.extend(_paginatable.default, _downloadable.default, {

    actions: {
      execution: function execution(o) {
        var _this = this;

        if (o.get('collapseShow')) {
          o.toggleProperty('collapseShow');return;
        }
        return (0, _model.findExecution)(this, { orderId: o.id, brokerId: o.brokerId }).then(function (r) {
          if (_this.isData(r)) {
            if (Ember.isEmpty(r.data)) {
              o.set('absent', true);
            } else {
              o.set('absent', false);
              o.set('executions', r.data);
            }
            o.toggleProperty('collapseShow');
          }
          return r;
        });
      },
      query: function query(o) {
        var _this2 = this;

        var self = this;
        var i18n = this.get('i18n');
        var flash = this.get('flashMessages');
        var id = o.get('id'),
            lpOrderId = o.get('lpOrderId'),
            inputLpOrderId = Ember.isEmpty(o.get('inputLpOrderId')) ? "" : o.get('inputLpOrderId');
        o.set('hasVerified', true);
        var lpOid = Ember.isEmpty(lpOrderId) ? inputLpOrderId : lpOrderId;
        (0, _model.check)(this, { id: id, lpOrderId: lpOid }).then(function (r) {
          if (_this2.isData(r)) {
            var remote = _model.default.create(r.data);
            self.copyProperties(o, remote);
            o.set('hasVerified', false);
          } else if (_this2.isSuccess(r)) {
            flash.success(i18n.t('label.notification.queried', 'Hedge Order'));
          } else {
            if (r && r.key) {
              flash.danger(i18n.t('' + r.key));
            }
          }
        });
      },
      cancel: function cancel(o) {
        var _this3 = this;

        var self = this;
        var id = o.get('id'),
            i18n = this.get('i18n'),
            flash = this.get('flashMessages');
        (0, _model.cancel)(this, { id: id }).then(function (r) {
          if (_this3.isData(r)) {
            var remote = _model.default.create(r.data);
            self.copyProperties(o, remote);
          } else if (_this3.isSuccess(r)) {
            flash.success(i18n.t('label.notification.cancelled', 'Hedge Order'));
          } else {
            if (r && r.key) {
              flash.danger(i18n.t('' + r.key));
            }
          }
        });
      }
    },
    doSearch: function doSearch(params) {
      var _this4 = this;

      var p = this.build(params);
      var allItSymbols = this.bootstrap.getSelection('broker.it_symbol');
      this.set('csvParams', Ember.assign({}, p));
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this4.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (o) {
            var itId = (0, _commonGenerator.getItIdByLpId)(o.lpId);
            var itSymbol = _.find(allItSymbols, function (e) {
              return e.extra.$group === itId && e.extra.symbolId === o.symbolId;
            });
            o.executions = [];
            o.collapseShow = false;
            o.priceScale = itSymbol.extra.priceScale;
            o.volumeScale = itSymbol.extra.volumeScale;
            return _model.default.create(o);
          });
          _this4.set('total', total);
          _this4.set('records', records);
        }
        _this4.updateNavtab(_this4.get('currentRouteName'), { total: total, records: records }, p);
        return r;
      });
    },
    downloadUrl: function downloadUrl() {
      return (0, _model.csv)(this.get('csvParams'));
    },
    build: function build(p) {
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('symbolId')) p.symbolId = this.get('symbolId');
      if (this.get('status')) p.status = this.get('status');
      if (this.get('type')) p.type = this.get('type');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('to')) p.to = this.get('to');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
      this.set('status', p.hasOwnProperty('status') ? p.status : 0);
      this.set('type', p.hasOwnProperty('type') ? p.type : 0);
      this.set('symbolId', p.hasOwnProperty('symbolId') ? p.symbolId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
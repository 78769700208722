define('frodo/helpers/image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    bootstrap: Ember.inject.service(),

    compute: function compute(params, hash) {
      var url = params[0];
      var width = hash && hash.width ? hash.width : 22;
      var height = hash && hash.height ? hash.height : 22;
      return url ? Ember.String.htmlSafe('<img style="width: ' + width + 'px; height: ' + height + 'px" src="' + url + '"/>') : '-';
    }
  });
});
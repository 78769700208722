define('frodo/helpers/volume', ['exports', 'frodo/utils/common-generator'], function (exports, _commonGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var regexp = /(?:\.0*|(\.\d+?)0+)$/;

  exports.default = Ember.Helper.extend({
    bootstrap: Ember.inject.service(),
    compute: function compute(params, hash) {
      var v = params[0];
      var color = hash && hash.color ? hash.color : false;
      var scale = hash && hash.scale ? hash.scale : 0;
      var brokerId = hash && hash.brokerId ? hash.brokerId : 0;
      var symbolId = hash && hash.symbolId ? hash.symbolId : 0;
      var count = 0;
      var exp = '0,0';

      if (scale && scale > 0) {
        count = scale;
      } else if (brokerId && symbolId) {
        var bkSymbol = (0, _commonGenerator.toBksymbolId)(brokerId, symbolId);
        var selectionObject = this.get('bootstrap.selection')['broker.symbol'];
        var found = _.find(selectionObject, function (e) {
          return e.value === bkSymbol;
        });
        if (found) {
          scale = found.extra.tradingVolumeScale;
          count = scale;
        }
      }
      if (count > 0) {
        exp += function (s) {
          var e = '.';
          for (var i = 0; i < s; i++) {
            e += '0';
          }
          return e;
        }(count);
      }

      var pattern = hash && hash.format ? hash.format : count > 0 ? exp : '0,0.000000000';
      var displayZero = hash && hash.displayZero ? true : false;
      var displayHyphen = hash && hash.displayHyphen ? true : false;
      if (Ember.isNone(v)) return '-';
      if (0 === parseFloat(v)) return displayHyphen ? '-' : displayZero ? 0 : pattern ? numeral(v).format(pattern) : '';
      var decorate = numeral(v).format(pattern);
      decorate = decorate.replace(regexp, '$1');
      return color && v < 0 ? Ember.String.htmlSafe('<span class="text-danger">' + decorate + '</span>') : decorate;
    }
  });
});
define('frodo/pods/index/trades/trades-model', ['exports', 'frodo/pods/index/base-model', 'frodo/models/enums'], function (exports, _baseModel, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PARENT = undefined;
  var PARENT = exports.PARENT = 'trades';

  var BaseTradesModel = _baseModel.default.extend({

    roundingMode: Ember.computed('side', function () {
      if (this.get('side') === _enums.Side.BUY) {
        return BigNumber.ROUND_UP;
      }
      if (this.get('side') === _enums.Side.SELL) {
        return BigNumber.ROUND_DOWN;
      }
      return undefined;
    }),

    rejected: Ember.computed('status', function () {
      return this.get('status') === _enums.DealingOrderStatus.REJECTED;
    })

  });

  BaseTradesModel.reopenClass({});

  exports.default = BaseTradesModel;
});
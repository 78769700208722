define('frodo/pods/index/system/batch-history/route', ['exports', 'frodo/pods/index/system/system-route'], function (exports, _systemRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemRoute.default.extend({
    preload: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('parentNode', { name: 'batch', route: 'index.system.batch' });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    beforePreload: function beforePreload(controller, model) {
      controller.set('jobName', model.job_name);
    }
  });
});
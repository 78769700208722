define('frodo/services/event-bus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var DOMAIN = location.protocol + '//' + location.host;

  /**
   * only one-way event flow
   */
  exports.default = Ember.Service.extend(Ember.Evented, {

    isOrigin: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var isOrigin = !window.isPopWindow;
      this.set('isOrigin', isOrigin);
      if (!isOrigin) {
        window.addEventListener('message', function (e) {
          if (e.origin !== DOMAIN || e.data && e.data.type !== 'event_bus') return;

          var _ref = e.data || {},
              args = _ref.payload;

          _this.trigger.apply(_this, _toConsumableArray(args));
          Ember.debug('received event from parent window: ' + JSON.stringify(args));
        });
      }
    },
    trigger: function trigger() {
      var result = this._super.apply(this, arguments);
      var isOrigin = this.get('isOrigin');
      if (isOrigin) {
        // let windowsSet = window.windowsSet;
        // let eventParams = [...arguments];
        // let wins = windowsSet.getAll();
        // wins.forEach(win => {
        //   win.postMessage({
        //     type: 'event_bus',
        //     payload: eventParams
        //   }, DOMAIN);
        // });
      }
      return result;
    }
  });
});
define('frodo/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    // RULES:
    // 1. Please make the key clearly!
    // 2. Please order the line by length!

    // =====================================
    // menu
    // =====================================
    menu: {
      index: {
        market: {
          self: 'Market',
          broker: 'Maker',
          broker_symbol: 'Maker Symbol',
          market: 'Market',
          symbol: 'Symbol',
          currency: 'Currency',
          institution: 'Exchange',
          institution_symbol: 'Ex Symbol',
          institution_currency: 'Ex Currency',
          it_session: 'SE Session',
          lp: 'Counterparty',
          lp_session: 'CP Session',
          lp_symbol: 'CP Symbol',
          lp_account: 'CP Account',
          market_session: 'Session',
          console_config: 'Console Config',
          console_release: 'Console Release',
          system_mail_template: 'Mail Template'
        },
        making: {
          self: 'Making',
          making_config: 'Making Config',
          filter_config: 'Filter Config',
          mass_order_history: 'Making Order',
          mass_execution_history: 'Making Trade',
          source_liquidity_config: 'Source Liquidity Config',
          target_price_config: 'Target Price Config',
          making_config_revision: 'Making Config Revision',
          source_liquidity_config_revision: 'Source Liquidity Config Revision',
          target_price_config_revision: 'Target Price Config Revision',
          dealing_making_statement: 'Making Statement',
          dealing_making_statement_history: 'Making Statement History',
          maintain: 'Making Maintain'
        },
        operator: {
          self: 'Operation',
          menu: 'Menu',
          role: 'Role',
          notice: 'Notice',
          notice_template: 'Template',
          operator: 'Operator Account',
          privilege: 'Menu Privilege',
          operator_config: 'Operator Config',
          operator_access: 'Operator Access',
          operator_notice: 'Operator Notice',
          operator_session: 'Operator Session'
        },
        system: {
          self: 'System',
          alert: 'Alert',
          batch: 'Batch',
          batch_history: 'History',
          debug: 'Debug',
          config: 'Config',
          digest: 'Digest',
          upload: 'Upload',
          monitor: 'Monitor',
          applications: 'Application',
          alert_guard: 'Setting'
        },
        pricing: {
          self: 'Pricing',
          quotation: 'Quotation',
          dealing_panel: 'Dealing Panel',
          quotation_history: 'Quote'
        },
        trades: {
          self: 'Trades',
          hedge_order_history: 'Hedge Order',
          hedge_execution_history: 'Hedge Trade',
          order_history: 'Order',
          cp_order: 'CP Order'
        },
        popup: {
          dealing_panel: 'Config Setting'
        },
        risk: {
          self: 'Risk',
          book: 'Book',
          allocator: 'Allocator',
          router: 'Router',
          trigger: 'Trigger',
          risk: 'Risk',
          cp_statement_history: 'CP Statement',
          statement_history: 'Statement',
          position_alertor: 'Position Alertor',
          balance_alertor: 'Balance Alertor',
          trade_alertor: 'Trade Alertor',
          bugrate_alertor: 'Bugrate Alertor',
          cp_balance: 'CP Balance',
          cp_position: 'CP Position',
          alertor_history: 'Alert History'
        },
        report: {
          self: 'Report',
          holding_balance: 'Balance',
          holding_daily_pl: 'Daily PL',
          holding_daily_trade: 'Daily Trade',
          holding_balance_flow: 'Balance Flow',
          holding_balance_turnover: 'Balance Turnover',
          holding_deposit_withdraw: 'Deposit Withdraw',
          holding_daily_trade_compare: 'Daily Trade Compare',
          holding_balance_daily_change: 'Balance Daily Change'
        }
      },
      logout: {
        self: 'Log out'
      },
      password: {
        self: 'Password'
      },
      navtab: {
        closeOthers: 'Close Others Tabs'
      }
    },
    // =====================================
    // enums
    // =====================================
    enums: {
      Language: {
        EN: 'English',
        JA: 'Japanese',
        ZH: 'Chinese'
      },
      DaylightSavingType: {
        SUMMER: 'SUMMER',
        WINTER: 'WINTER'
      },
      MarketSessionType: {
        NORMAL: 'NORMAL',
        ROLLOVER: 'ROLLOVER'
      },
      SymbolType: {
        C2F: 'C2F',
        C2C: 'C2C'
      },
      SymbolCode: {
        AUD: 'AUD',
        CAD: 'CAD',
        CHF: 'CHF',
        CNH: 'CNH',
        EUR: 'EUR',
        GBP: 'GBP',
        HKD: 'HKD',
        JPY: 'JPY',
        NZD: 'NZD',
        USD: 'USD',
        VND: 'VND',
        ZAR: 'ZAR',
        TRY: 'TRY',
        MXN: 'MXN',
        GAS: 'GAS',
        OIL: 'OIL',
        XAU: 'XAU',
        GOLD: 'GOLD',
        JPN225: 'JPN225'
      },
      AdminRoleStatus: {
        NORMAL: 'NORMAL',
        LOCKED: 'LOCKED',
        DISABLED: 'DISABLED'
      },
      CompanyStatus: {
        ACTIVE: 'ACTIVE',
        DISABLED: 'DISABLED'
      },
      ConvertOperation: {
        MUL: 'MUL',
        DIV: 'DIV',
        MUL_MUL: 'MUL_MUL',
        MUL_DIV: 'MUL_DIV',
        DIV_MUL: 'DIV_MUL',
        DIV_DIV: 'DIV_DIV'
      },
      AdminPrivilegeType: {
        MENU: 'MENU',
        API: 'API'
      },
      AdminOperatorType: {
        ROOT: 'ROOT',
        ADMIN: 'ADMIN',
        MANAGER: 'MANAGER'
      },
      AdminOperatorStatus: {
        NORMAL: 'NORMAL',
        LOCKED: 'LOCKED',
        DISABLED: 'DISABLED'
      },
      AdminPasswordExpirePolicy: {
        NEVER: 'NEVER',
        ALWAYS: 'ALWAYS'
      },
      AdminNoticeLevel: {
        INFO: 'INFO',
        WARN: 'WARN',
        ERROR: 'ERROR'
      },
      HoldingTriggerProperty: {
        ORDER_TYPE: 'ORDER_TYPE',
        ORDER_DELAY_TIME: 'ORDER_DELAY_TIME',
        ORDER_MAX_VOLUME: 'ORDER_MAX_VOLUME'
      },
      AdminNoticeStatus: {
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        DELETED: 'DELETED'
      },
      AdminOperatorNoticeStatus: {
        PENDING: 'PENDING',
        ACCEPTED: 'ACCEPTED'
      },
      ItType: {
        BINANCE: 'BINANCE',
        HUOBI: 'HUOBI',
        OKX: 'OKX',
        CHAINUP: 'CHAINUP',
        ZB: 'ZB',
        CHAOEX: 'CHAOEX',
        AMPEX: 'AMPEX',
        MXC: 'MXC',
        BKEX: 'BKEX',
        AEX: 'AEX',
        CEX: 'CEX',
        QBTC: 'QBTC',
        PARAMOUNTDAX: 'PARAMOUNTDAX',
        ZT: 'ZT',
        COWEX: 'COWEX',
        FTX: 'FTX',
        GATE: 'GATE',
        BIKI: 'BIKI',
        BTSE: 'BTSE',
        KRYPTOX: 'KRYPTOX',
        HOTCOINEX: 'HOTCOINEX',
        ASCENDEX: 'ASCENDEX',
        B2C2: 'B2C2',
        COINMARK: 'COINMARK',
        BPM: 'BPM',
        PARALLELCAPITAL: 'PARALLELCAPITAL',
        BITMART: 'BITMART',
        TOKENOMY: 'TOKENOMY',
        WHITEBIT: 'WHITEBIT',
        LBANK: 'LBANK'
      },
      AdminNoticeDomain: {
        SYSTEM: 'SYSTEM',
        PRICING: 'PRICING',
        TRADING: 'TRADING'
      },
      AdminNoticeType: {
        INSERT_TRADING_EXECUTION: 'INSERT_TRADING_EXECUTION',
        DELETE_TRADING_EXECUTION: 'DELETE_TRADING_EXECUTION',
        MODIFY_TRADING_EXECUTION: 'MODIFY_TRADING_EXECUTION'
      },
      MonitorLevel: {
        INFO: 'INFO',
        WARN: 'WARN',
        ERROR: 'ERROR'
      },
      MonitorPolicy: {
        WARN: 'WARN',
        ERROR: 'ERROR',
        COUNT_HWM: 'COUNT_HWM',
        LATENCY_HWM: 'LATENCY_HWM'
      },
      MonitorFilterPolicy: {
        WARN: 'WARN',
        ERROR: 'ERROR',
        COUNT_HWM: 'COUNT_HWM',
        LATENCY_HWM: 'LATENCY_HWM'
      },
      PriceAdjustType: {
        VALUE: 'VALUE',
        PERCENTAGE: 'PERCENTAGE'
      },
      MakingTickType: {
        TOB: 'TOB',
        MID: 'MID'
      },
      HaStatus: {
        SLAVE: 'SLAVE',
        MASTER: 'MASTER',
        GIVING_UP: 'GIVING_UP',
        TAKING_OVER: 'TAKING_OVER'
      },
      CustomerProfileStatus: {
        ACTIVE: 'ACTIVE',
        CLOSED: 'CLOSED'
      },
      CustomerAccountStatus: {
        ACTIVE: 'ACTIVE',
        CLOSED: 'CLOSED'
      },
      CustomerConfigType: {
        MIDAPI: 'MIDAPI',
        ADVANCE: 'ADVANCE',
        EXPRESS: 'EXPRESS',
        TRADING: 'TRADING',
        PRICING: 'PRICING'
      },
      CustomerMessageType: {
        LOSSCUT: 'LOSSCUT',
        MARGIN_CALL: 'MARGIN_CALL',
        TRIGGER_ORDER: 'TRIGGER_ORDER'
      },
      CustomerMailType: {
        LOSSCUT: 'LOSSCUT',
        OVERDRAFT: 'OVERDRAFT',
        MARGIN_CALL: 'MARGIN_CALL',
        EXPIRE_ORDER: 'EXPIRE_ORDER',
        EXECUTE_ORDER: 'EXECUTE_ORDER'
      },
      CustomerDeviceStatus: {
        ACTIVE: 'ACTIVE',
        PAUSED: 'PAUSED',
        DISABLED: 'DISABLED'
      },
      IssueStatus: {
        PENDING: 'PENDING',
        ISSUING: 'ISSUING',
        SUCCESS: 'SUCCESS',
        FAILURE: 'FAILURE'
      },
      ClientType: {
        WEB: 'WEB',
        ADMIN: 'ADMIN',
        ADMAPI: 'ADMAPI',
        MGRAPI: 'MGRAPI',
        MIDAPI: 'MIDAPI'
      },
      MobileType: {
        IOS: 'IOS',
        ANDROID: 'ANDROID'
      },
      CurrencyType: {
        FIAT: 'FIAT',
        CRYPTO: 'CRYPTO'
      },
      LoginRestriction: {
        NORMAL: 'NORMAL',
        OFF: 'OFF'
      },
      MarginRestriction: {
        NORMAL: 'NORMAL',
        LOSSCUT_ONLY: 'LOSSCUT_ONLY',
        RISKCUT_ONLY: 'RISKCUT_ONLY',
        OFF: 'OFF'
      },
      PlacingRestriction: {
        NORMAL: 'NORMAL',
        OPEN_SHORT_OFF: 'OPEN_SHORT_OFF',
        OPEN_LONG_OFF: 'OPEN_LONG_OFF',
        OPEN_OFF: 'OPEN_OFF',
        OFF: 'OFF'
      },
      TransferRestriction: {
        NORMAL: 'NORMAL',
        DEPOSIT_ONLY: 'DEPOSIT_ONLY',
        WITHDRAW_ONLY: 'WITHDRAW_ONLY',
        OFF: 'OFF'
      },
      GroupStatus: {
        ACTIVE: 'ACTIVE',
        DISABLED: 'DISABLED'
      },
      HoldingPolicy: {
        NORMAL: 'NORMAL',
        DISABLED: 'DISABLED'
      },
      LosscutPolicy: {
        FULL: 'FULL',
        INCREMENTAL: 'INCREMENTAL'
      },
      MarginPolicy: {
        NORMAL: 'NORMAL',
        UNLIMITED: 'UNLIMITED'
      },
      FreeMarginPolicy: {
        REALIZED: 'REALIZED',
        UNREALIZED: 'UNREALIZED',
        UNREALIZED_LOSS: 'UNREALIZED_LOSS',
        UNREALIZED_PROFIT: 'UNREALIZED_PROFIT'
      },
      RequiredMarginPolicy: {
        NORMAL: 'NORMAL',
        NET_MARGIN: 'NET_MARGIN',
        MAX_MARGIN: 'MAX_MARGIN'
      },
      TradeDomain: {
        SPOT: 'SPOT',
        MARGIN: 'MARGIN'
      },
      SymbolStatus: {
        ACTIVE: 'ACTIVE',
        HIDDEN: 'HIDDEN',
        DISABLED: 'DISABLED'
      },
      CommissionType: {
        MONEY: 'MONEY',
        POINT: 'POINT',
        PERCENTAGE: 'PERCENTAGE'
      },
      SwapPolicy: {
        DEFAULT: 'DEFAULT',
        PRODUCT: 'PRODUCT'
      },
      CommissionUnit: {
        PER_LOT: 'PER_LOT',
        PER_DEAL: 'PER_DEAL'
      },
      MarginFormulaType: {
        FX: 'FX - Lots / Leverage * ContractSize * MarginPercentage',
        CFD: 'CFD - Lots * Price * ContractSize * MarginPercentage',
        CFD_INDEX: 'CFD_INDEX - Lots * Price * ContractSize * TickPrice / TickSize * MarginPercentage',
        CFD_LEVERAGE: 'CFD_LEVERAGE - Lots * Price / Leverage * ContractSize * MarginPercentage'
      },
      MobileReleaseStatus: {
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        DISABLED: 'DISABLED'
      },
      ChartType: {
        M1: 'M1',
        M5: 'M5',
        M10: 'M10',
        M15: 'M15',
        M30: 'M30',
        H1: 'H1',
        H2: 'H2',
        H4: 'H4',
        D1: 'D1',
        W1: 'W1',
        MN1: 'MN1'
      },
      TradingRestriction: {
        NORMAL: 'NORMAL',
        SHORT_OFF: 'SHORT OFF',
        LONG_OFF: 'LONG OFF',
        OFF: 'OFF'
      },
      AuditLevel: {
        INFO: 'INFO',
        WARN: 'WARN',
        ERROR: 'ERROR'
      },
      TriggerStrategy: {
        NPL: 'NPL'
      },
      PricingRestriction: {
        NORMAL: 'NORMAL',
        OFF: 'OFF'
      },
      TradingAccountType: {
        TRADER: 'TRADER',
        FEEDER: 'FEEDER'
      },
      CompanyConfigType: {
        URL_HOMEPAGE: 'HOMEPAGE'
      },
      BrokerStatus: {
        ACTIVE: 'ACTIVE',
        DISABLED: 'DISABLED'
      },
      PricingQuoteType: {
        SNAPSHOT: 'SNAPSHOT',
        INCREMENTAL: 'INCREMENTAL'
      },
      DealingSessionStatus: {
        PENDING: 'PENDING',
        CONNECTED: 'CONNECTED',
        CONNECTING: 'CONNECTING',
        DISCONNECTING: 'DISCONNECTING'
      },
      DealingOrderType: {
        STOP: 'STOP',
        LIMIT: 'LIMIT',
        MARKET: 'MARKET',
        INSTANT: 'INSTANT',
        SLIPPAGE: 'SLIPPAGE'
      },
      DepthType: {
        NORMAL: 'NORMAL',
        COMBINE: 'COMBINE'
      },
      Side: {
        BUY: 'BUY',
        SELL: 'SELL'
      },
      HedgingCopType: {
        WHOLE: 'WHOLE',
        SEPARATE: 'SEPARATE'
      },
      DealingOrderStatus: {
        PENDING: 'PENDING',
        EXECUTING: 'EXECUTING',
        EXECUTED: 'EXECUTED',
        EXPIRED: 'EXPIRED',
        REJECTED: 'REJECTED',
        CANCELLING: 'CANCELLING',
        CANCELLED: 'CANCELLED',
        UNKNOWN: 'UNKNOWN',
        UNCONFIRMED: 'UNCONFIRMED',
        INTERRUPTED: 'INTERRUPTED'
      },
      ExecuteReportMode: {
        NORM: 'NORM',
        SYNC: 'SYNC',
        TERM: 'TERM',
        FIX: 'FIX'
      },
      LpType: {
        LIQUIDITY_PROVIDER: 'LIQUIDITY_PROVIDER',
        LIQUIDITY_DEMANDER: 'LIQUIDITY_DEMANDER'
      },
      TimeInForce: {
        IOC: 'IOC',
        GTC: 'GTC'
      },
      DealingPlaceType: {
        DEAL: 'DEAL',
        TRADE: 'TRADE',
        AUTO_HEDGE: 'AUTO HEDGE',
        MANUAL_HEDGE: 'MANUAL HEDGE',
        TRADE_REFLECT: 'TRADE REFLECT',
        INPUT: 'INPUT'
      },
      MakingOrderType: {
        SOURCE_LIQUIDITY: 'SOURCE_LIQUIDITY',
        TARGET_PRICE: 'TARGET_PRICE',
        SYNTHETIC_INDEX: 'SYNTHETIC_INDEX'
      },
      AlertType: {
        BLANCE: 'BLANCE',
        BUGRATE: 'BUGRATE',
        TRADE: 'TRADE',
        POSITION: 'POSITION'
      },
      VmsStatus: {
        CONNECTED: 'CONNECTED',
        LOST: 'LOST',
        REJECT: 'REJECT',
        ERROR: 'ERROR'
      },
      Level: {
        D1: 'D1',
        H4: 'H4',
        M30: 'M30',
        M5: 'M5',
        M1: 'M1'
      },
      MakingDiffType: {
        MID: 'MID',
        BOB: 'BOB'
      },
      MakingRestriction: {
        OFF: 'OFF',
        NORMAL: 'NORMAL'
      },
      LiquidityType: {
        DIRECT: 'DIRECT',
        CROSSED: 'CROSSED',
        PARALLEL: 'PARALLEL'
      },
      AllocateRestriction: {
        NORMAL: 'NORMAL',
        OFF: 'OFF'
      },
      DealingLadderType: {
        L1_TOB: 'L1 TOB',
        L2_TOB: 'L2 TOB',
        L1_ORDER: 'L1 ORDER',
        L2_ORDER: 'L2 ORDER',
        TRADE_ORDER: 'TRADE ORDER',
        TRADE: 'TRADE'
      },
      InstrumentType: {
        SPOT: 'SPOT',
        SWAP: 'SWAP',
        MARGIN: 'MARGIN'
      },
      AlertRestriction: {
        NORMAL: 'NORMAL',
        OFF: 'OFF'
      },
      SimulateType: {
        DEFAULT: 'DEFAULT',
        ITO: 'ITO'
      }
    },
    // =====================================
    // label
    // =====================================
    label: {
      common: {
        ms: 'ms',
        id: 'ID',
        lp: 'CP',
        to: 'To',
        pt: 'pt',
        no: 'No',
        all: 'ALL',
        sec: 's',
        ask: 'Ask',
        bid: 'Bid',
        pip: 'pip',
        alp: 'ALP',
        tob: 'TOB',
        wap: 'WAP',
        key: 'Key',
        sum: 'Sum',
        base: 'Base',
        name: 'Name',
        type: 'Type',
        date: 'Date',
        side: 'Side',
        meta: 'Meta',
        book: 'Book',
        open: 'Open',
        apply: 'Apply',
        other: 'Other',
        from: 'From',
        time: 'Time',
        none: 'None',
        alert: 'Alert',
        count: 'Count',
        group: 'Group',
        value: 'Value',
        ratio: 'Ratio',
        total: 'TOTAL',
        long: 'Long',
        short: 'Short',
        trigger: 'Trigger',
        restate: 'Restate',
        launch: 'execute',
        executing: 'executing',
        trade: 'Trade',
        price: 'Price',
        maxPrice: 'Max Price',
        minPrice: 'Min Price',
        avgPrice: 'Avg Price',
        quote: 'Quote',
        reset: 'Reset',
        forceReset: 'Forced Reset',
        forceStartPrice: 'Force Start Price',
        basic: 'Basic',
        books: 'Books',
        shade: 'Shade',
        login: 'Login',
        image: 'Image',
        charge: 'Charge',
        domain: 'Domain',
        status: 'Status',
        placeType: 'Place Type',
        margin: 'Margin',
        notice: 'Notice',
        profit: 'Profit',
        verbose: 'Verbose',
        active: 'Active',
        detail: 'Detail',
        simulate: 'Simulate',
        frequency: 'Frequency',
        Level: 'Level',
        timeFrom: 'Time From',
        timeTo: 'Time To',
        ladder: 'Ladder',
        action: 'Action',
        quotes: 'Quotes',
        config: 'Config',
        flurry: 'Flurry',
        broker: 'Maker',
        points: 'pt',
        result: 'Result',
        spread: 'Spread',
        uptime: 'Uptime',
        weight: 'Weight',
        symbol: 'Symbol',
        volume: 'Volume',
        risk: 'Risk',
        amount: 'Amount',
        allAmount: 'Gross Amount',
        balance: 'Balance',
        comment: 'Comment',
        dynamic: 'Dynamic',
        realized: 'Realized',
        unrealized: 'Unrealized',
        avgCost: 'Avg.Cost',
        allAvgCost: 'Gross Avg.Cost',
        floatingPL: 'Floating PL',
        netVolume: 'Net Volume',
        netPL: 'Net PL',
        hedgeVolume: 'Hedge Volume',
        hedgePL: 'Hedge PL',
        today: 'Today',
        m2m: 'M2M',
        pl: 'PL',
        history: 'History',
        summary: 'Summary',
        configs: 'Configs',
        version: 'Version',
        message: 'Message',
        contact: 'Contact',
        placing: 'Placing',
        pricing: 'Pricing',
        dealing: 'Dealing',
        trading: 'Trading',
        execute: 'Trade',
        currency: 'Currency',
        transfer: 'Transfer',
        condition: 'condition',
        leverage: 'Leverage',
        LpAccount: 'Lp Account',
        realtime: 'Realtime',
        accounts: 'Accounts',
        strategy: 'Strategy',
        matching: 'Matching',
        sourceId: 'Source ID',
        orderType: 'Order Type',
        operator: 'Operator',
        brokerId: 'Maker',
        contextId: 'Context ID',
        accountId: 'Account ID',
        spreadFix: 'Spread Fix',
        spreadVar: 'Spread Var',
        subscribe: 'subscribe',
        threshold: 'Threshold',
        reliefThreshold: 'Relief Threshold',
        minInterval: 'Min Interval',
        maxTimes: 'Max Times',
        execution: 'Trade',
        customerId: 'Customer ID',
        allocation: 'Allocator',
        adjustment: 'Adjustment',
        startTime: 'Start Time',
        endTime: 'End Time',
        lastUpdate: 'Last Update',
        prevDate: 'Previous Date',
        tradingDate: 'Trading Date',
        restriction: 'Restriction',
        institution: 'Exchange',
        unsubscribe: 'unsubscribe',
        priceLadder: 'Price Ladder',
        remoteAddress: 'Remote Address',
        abbreviation: 'Abbreviation',
        longVolume: 'Long Volume',
        longAmount: 'Long Amount',
        longTrades: 'Long Trades',
        shortVolume: 'Short Volume',
        shortAmount: 'Short Amount',
        shortTrades: 'Short Trades',
        baseCurrency: 'Base Currency',
        volumeLadder: 'Volume Ladder',
        spreadDynamic: 'Spread Dynamic',
        subscriptions: 'Subscriptions',
        modal_detail: '{{name}} {{type}}',
        updateOperator: 'Operator',
        updateDatetime: 'Last Update',
        changePassword: 'Change Password',
        profitCurrency: 'Profit Currency',
        position: 'Position',
        netPosition: 'Net Position',
        liquidityProvider: 'CP',
        commission: 'Commission',
        cost: 'Cost',
        bidPrice: 'bidPrice',
        askPrice: 'askPrice',
        bidVolume: 'bidVolume',
        askVolume: 'askVolume',
        making_config: 'Making Config',
        changes: 'changes',
        changing: 'Changing',
        high: 'high',
        hedging: 'Hedging',
        low: 'low',
        dow: {
          1: 'Mon',
          2: 'Tue',
          3: 'Wed',
          4: 'Thu',
          5: 'Fri',
          6: 'Sat',
          7: 'Sun'
        },
        btn: {
          on: 'ON',
          off: 'OFF',
          add: 'Add',
          csv: 'CSV',
          buy: 'Buy',
          sell: 'Sell',
          mid: 'Mid',
          ba: 'Bid/Ask',
          back: 'Back',
          edit: 'Edit',
          copy: 'Copy',
          lock: 'Lock',
          save: 'Save',
          sort: 'Sort',
          bind: 'Bind',
          more: 'More',
          abort: 'Abort',
          flush: 'Flush',
          retry: 'Retry',
          apply: 'Apply',
          clear: 'Clear',
          books: 'Books',
          debug: 'Debug',
          steps: 'Steps',
          close: 'Close',
          closePosition: 'Close',
          closeAllPosition: 'Close All',
          reset: 'Reset',
          forceReset: 'Forced Reset',
          roles: 'Roles',
          stop: 'Stop',
          start: 'Start',
          emptyReason: 'Reason: Empty',
          pause: 'Pause',
          aggressive: 'Aggressively Stop',
          buying: 'Buying',
          selling: 'Selling',
          unlock: 'Unlock',
          resume: 'Resume',
          submit: 'Submit',
          logout: 'Logout',
          config: 'Config',
          quotes: 'Quotes',
          cancel: 'Cancel',
          detail: 'Detail',
          devices: 'Devices',
          search: 'Search',
          remove: 'Remove',
          brokers: 'Makers',
          configs: 'Configs',
          symbols: 'Symbols',
          currencys: 'Currencies',
          columns: 'Columns',
          restart: 'Restart',
          refresh: 'Refresh',
          preview: 'Preview',
          history: 'History',
          loading: 'Loading...',
          connect: 'Connect',
          setting: 'Setting',
          realtime: 'Realtime',
          holidays: 'Holidays',
          template: 'Template',
          continue: 'Continue',
          revision: 'Revision',
          accounts: 'Accounts',
          sessions: 'Sessions',
          schedule: 'Schedule',
          transfer: 'Transfer',
          execution: 'Trade',
          searching: 'Searching',
          quickEdit: 'Quick Edit',
          subscribe: 'Subscribe',
          privileges: 'Privileges',
          disconnect: 'Disconnect',
          refreshing: 'Refreshing...',
          unsubscribe: 'Unsubscribe',
          conversions: 'Conversions',
          subscribing: 'Subscribing...',
          downloading: 'Downloading...',
          tradingPanel: 'TradingPanel',
          unsubscribing: 'Unsubscribing...'
        },
        time_unit: {
          year: 'year',
          month: 'month',
          day: 'day'
        },
        language: {
          EN: 'English',
          JA: 'Japanese',
          ZH: 'Chinese'
        },
        no_data_found: 'No data found.',
        appTitle: 'Market Making',
        appName: 'Market Making Admin'
      },
      dialog: {
        action: {
          default: 'Confirmation',
          add: 'Add {{name}}',
          edit: 'Edit {{name}}',
          stop: 'Stop Confirmation',
          start: 'Start Confirmation',
          pause: 'Pause Confirmation',
          aggressive: 'Aggressive Confirmation',
          detail: '{{name}} Detail',
          save: 'Save Confirmation',
          abort: 'Abort Confirmation',
          reset: 'Reset Confirmation',
          forceReset: 'Forced Reset Confirmation',
          retry: 'Retry Confirmation',
          apply: 'Apply Confirmation',
          order: 'Order Confirmation',
          flush: 'Flush Confirmation',
          cancel: 'Cancel Confirmation',
          resume: 'Resume Confirmation',
          logout: 'Logout Confirmation',
          remove: 'Remove Confirmation',
          connect: 'Connect Confirmation',
          schedule: 'Schedule Confirmation',
          disconnect: 'Disconnect Confirmation'
        },
        content: {
          save: 'Are you sure to save ?',
          abort: 'Are you sure to abort ?',
          apply: 'Are you sure to apply ?',
          retry: 'Are you sure to retry ?',
          reset: 'Are you sure to reset ?',
          forceReset: 'Are you sure to forced reset ?',
          stop: 'Are you sure to stop ?',
          start: 'Are you sure to start ?',
          pause: 'Are you sure to pause ?',
          aggressive: 'Are you sure to aggressive ?',
          flush: 'Are you sure to flush ?',
          logout: 'Are you sure to logout ?',
          resume: 'Are you sure to resume ?',
          remove: 'Are you sure to remove this record {{target}} ?',
          cancel: 'Are you sure to cancel ?',
          connect: 'Are you sure to connect ?',
          schedule: 'Are you sure to schedule ?',
          disconnect: 'Are you sure to disconnect ?'
        }
      },
      notification: {
        added: '{{name}} successfully added',
        saved: '{{name}} successfully saved',
        reset: '{{name}} successfully reset',
        forceReset: '{{name}} successfully forced reset',
        start: '{{name}} successfully start',
        abort: '{{name}} successfully abort',
        logout: '{{name}} successfully logout',
        stop: '{{name}} successfully stopped',
        pause: '{{name}} successfully paused',
        aggressive: '{{name}} successfully aggressived',
        rebuild: '{{name}} successfully rebuild',
        removed: '{{name}} successfully removed',
        applied: '{{name}} successfully applied',
        resumed: '{{name}} successfully resumed',
        connect: '{{name}} successfully connected',
        modified: '{{name}} successfully modified',
        executed: '{{name}} successfully executed',
        restarted: '{{name}} successfully restarted',
        cancelled: '{{name}} successfully cancelled',
        scheduled: '{{name}} successfully scheduled',
        flushed: '{{name}} successfully flushed',
        queried: '{{name}} successfully queried',
        debugEmpty: 'No Debugging {{name}}',
        disconnect: '{{name}} successfully disconnected',
        subscribed: 'Successfully subscribed',
        unsubscribed: 'Successfully unsubscribed',
        passwordChanged: 'Operator password successfully changed',
        sorted: '{{name}} display order successfully saved',
        ordered: 'Successfully Ordered',
        restated: 'Successfully restated'
      },
      making: {
        making_config: {
          list: {
            l1OrderActive: 'L1 Order',
            l2OrderActive: 'L2 Order',
            tradeActive: 'Trades',
            l1Type: 'L1 Type',
            l1Config: 'L1 Config',
            l1Version: 'L1 Version'
          },
          tab: {
            basic: 'Basic',
            level1: 'Level1',
            level2: 'Level2',
            trades: 'Trades'
          },
          basic: {
            frequency: 'Frequency/m',
            l1OrderActive: 'L1 Order Active',
            l2OrderActive: 'L2 Order Active',
            tradeActive: 'Trades Active',
            tradeStrict: 'Avoid Order Match',
            expiration: 'Expiration',
            tickSize: 'Tick Size',
            volumeMinScale: 'Volume Min Scale',
            volumeMaxScale: 'Volume Max Scale',
            volumeRoundRatio: 'Volume Round Ratio'
          },
          level1: {
            spreadType: 'Spread Type',
            configType: 'Config Type',
            config: 'Config',
            ladderDepth: 'Depth',
            stepHeight: 'Step Height',
            spreadValue: 'Min Spread',
            spreadPercentage: 'Min Spread %',
            maxDeviation: 'Price Limit'
          },
          level2: {
            benchmark: "Benchmark",
            benchmarkType: 'Benchmark Type',
            differenceValue: 'Difference Value',
            differencePercentage: 'Difference Percentage',
            thresholdValue: 'Threshold Value',
            thresholdPercentage: 'Threshold Percentage',
            l2MinDifference: 'Difference',
            ladderDepth: 'Depth',
            maxDeviation: 'Price Limit',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            minThreshold: 'Threshold',
            l2DiffType: 'Diff Type',
            l2BidMinVolume: 'Bid Min Volume',
            l2BidMaxVolume: 'Bid Max Volume',
            l2AskMinVolume: 'Ask Min Volume',
            l2AskMaxVolume: 'Ask Max Volume'
          },
          trades: {
            tradeType: 'Trades Type',
            tradePriceType: 'Trades Price Type',
            tradeSendRatio: 'Send Ratio',
            tradeVolumeDeduct: 'Volume Deduct',
            minTradeVolumeRatio: 'Min Ratio',
            maxTradeVolumeRatio: 'Max Ratio',
            tradeMidDeviation: 'Mid Deviation',
            tradeRefSpread: 'Ref Spread',
            tradeVolumeLowerLimit: 'Min Volume',
            tradeVolumeUpperLimit: 'Max Volume',
            volumeBoundaryDeviation: 'Volume Boundary',
            tradeVolumeMultiplier: 'Volume Multiplier',
            tradeAnnexableSize: 'Force Trade Volume'
          }
        },
        filter_config: {
          deviation: 'Deviation',
          refLps: 'Ref Cps',
          active: 'Active'
        },
        mass_order_history: {
          orderPrice: 'Order Price',
          orderVolume: 'Order Volume',
          execAmount: 'Exec Amount',
          execVolume: 'Exec Volume',
          orderTime: 'Order Time',
          execTime: 'Exec Time',
          orderType: 'Order Type',
          lpOrderId: 'CP Order ID',
          ladderType: 'Ladder Type',
          placedTime: 'Placed Time',
          cancelledTime: 'Cancel Time'
        },
        source_liquidity_config: {
          refLps: 'Ref Cps',
          baseLp: 'Base CP',
          source_quote_expiration: 'Source Quote Expiration',
          bid_volume_adjust_ratio: 'Bid Volume Adjust Ratio',
          ask_volume_adjust_ratio: 'Ask Volume Adjust Ratio',
          max_volume: 'Max Volume',
          max_volume_ratio: 'Max Volume Ratio',
          price_adjust_type: 'Price Adjust Type',
          bid_price_adjust_ratio: 'Bid Price Adjust Ratio',
          ask_price_adjust_ratio: 'Ask Price Adjust Ratio',
          bid_price_adjust_value: 'Bid Price Adjust Value',
          ask_price_adjust_value: 'Ask Price Adjust Value',
          basic: 'Basic',
          detail: 'Detail',
          bidMaxVolume: 'Bid Max Volume',
          askMaxVolume: 'Ask Max Volume'
        },
        target_price_config: {
          startTime: 'Start Time',
          startPrice: 'Start Price',
          targetTime: 'Target Time',
          targetPrice: 'Target Price',
          volatility: 'Volatility/s',
          fluctuation: 'Fluctuation',
          maxSlope: 'Max Slope',
          maxDeviation: 'Price Limit',
          minVolume: 'Min Volume',
          maxVolume: 'Max Volume',
          simulator: 'Simulator',
          bidMinVolume: 'Bid Min Volume',
          bidMaxVolume: 'Bid Max Volume',
          askMinVolume: 'Ask Min Volume',
          askMaxVolume: 'Ask Max Volume',
          pressureVolume: 'Pressure Volume',
          concessionRatio: 'Concession Ratio',
          releasePrice: 'Release Price',
          simulateType: 'Simulate Type'
        },
        making_config_revision: {
          making_config_id: 'Config ID'
        },
        source_liquidity_config_revision: {
          source_liquidity_config_id: 'Config ID'
        },
        target_price_config_revision: {
          target_price_config_id: 'Config ID'
        },
        dealing_making_statement_history: {
          id: 'ID'
        },
        dealing_making_statement: {
          id: 'ID',
          startTime: 'Start Time',
          endTime: 'End Time',
          configVersion: 'Config Version',
          configRevisions: 'Config Revisions',
          longThreshold: 'Long Threshold',
          shortThreshold: 'Short Threshold',
          matchingVolume: 'Matching Volume',
          matchingAmount: 'Matching Amount',
          matchingCount: 'Matching Count',
          matchingPrice: 'Matching Price',
          longVolume: 'Long Volume',
          longAmount: 'Long Amount',
          longCount: 'Long Count',
          longMaxPrice: 'Long Max Price',
          longMinPrice: 'Long Min Price',
          longAvgPrice: 'Long Avg Price',
          shortVolume: 'Short Volume',
          shortAmount: 'Short Amount',
          shortCount: 'Short Count',
          shortMaxPrice: 'Short Max Price',
          shortMinPrice: 'Short Min Price',
          shortAvgPrice: 'Short Avg Price'
        }
      },
      market: {
        broker: {
          abbreviation: 'Abbreviation',
          baseCurrency: 'Base Currency',
          abbrevation: 'Abbrevation',
          loginRestriction: 'Login Restriction',
          tradingRestriction: 'Trading Restriction',
          pricingRestriction: 'Pricing Restriction',
          makingRestriction: 'Making Restriction',
          triggerRestriction: 'Trigger Restriction',
          ratefilterRestriction: 'Ratefilter Restriction',
          allocateRestriction: 'Allocate Restriction',
          alertRestriction: 'Alert Restriction',
          vmsKey: 'VMS Key',
          vmsSecret: 'VMS Secret',
          vmsNumber: 'VMS Number',
          balanceAlertTts: 'Balance Alert TTS',
          balanceAlertTel: 'Balance Alert TEL',
          balanceAlertActive: 'Balance Alert Active',
          positionAlertTts: 'Position Alert TTS',
          positionAlertTel: 'Position Alert TEL',
          positionAlertActive: 'Position Alert Active',
          tradeAlertTts: 'Trade Alert TTS',
          tradeAlertTel: 'Trade Alert TEL',
          tradeAlertActive: 'Trade Alert Active',
          alertBackupList: 'Alert Backup List',
          bugrateAlertTts: 'Bugrate Alert TTS',
          bugrateAlertTel: 'Bugrate Alert TEL',
          bugrateAlertActive: 'Bugrate Alert Active',
          symbol: {
            profitCurrency: 'Profit Currency',
            pricingFrequency: 'Pricing Frequency',
            tradingPriceScale: 'Price Scale',
            tradingVolumeScale: 'Volume Scale',
            tradingMinVolume: 'Min Volume',
            tradingMaxVolume: 'Max Volume'
          }
        },
        market: {
          self: 'Market',
          time: 'Current Time',
          sodTime: 'Sod Date Time',
          eodTime: 'Eod Date Time',
          tradingDate: 'Trading Date',
          prevSodTime: 'Prev Sod Time',
          prevEodTime: 'Prev Eod Time',
          restriction: 'Restriction',
          tradingRestriction: 'Trading',
          pricingRestriction: 'Pricing',
          loginRestriction: 'Login',
          marginRestriction: 'Margin',
          placingRestriction: 'Placing',
          transferRestriction: 'Transfer',
          prevTradingDate: 'Prev Trading Date',
          market_session: {
            type: 'Session Type',
            startTime: 'Start Time',
            endTime: 'End Time'
          }
        },
        market_session: {
          self: 'Session',
          to: 'To',
          type: 'Session Type',
          from: 'From',
          endTime: 'End Time',
          startTime: 'Start Time',
          daylightSavings: 'Daylight Savings',
          daylightSavingType: 'Daylight Saving Type'
        },
        symbol: {
          id: 'ID',
          name: 'Name',
          type: 'Type',
          image: 'Image',
          scale: 'Scale',
          displayOrder: 'Display Order',
          baseCurrency: 'Base Currency',
          profitCurrency: 'Profit Currency',
          version: 'Version',
          insertOperator: 'Insert Operator',
          updateOperator: 'Update Operator',
          updateDatetime: 'Last Update'
        },
        currency: {
          type: 'Type',
          unit: 'Unit',
          name: 'Name',
          scale: 'Scale',
          image: 'Image'
        },
        institution: {
          self: 'Exchange',
          id: 'ID',
          name: 'Name',
          status: 'Status',
          version: 'Version',
          abbreviation: 'Abbreviation',
          category: 'Category',
          pricingUrl: 'Url',
          quotingKey: 'Quoting Key',
          quotingSecret: 'Quoting Secret',
          pricingEnabled: 'Enabled',
          pricingVerbose: 'Verbose',
          instrumentType: 'InstrumentType',
          symbol: {
            institution: 'Institution',
            hedging: 'Hedging',
            quoteCode: 'Quote Code',
            tradeCode: 'Trade Code',
            displayOrder: 'Display Order',
            subscriptionType: 'Type',
            subscriptionDepth: 'Depth',
            quoteSubscription: 'Subscription',
            persistDepth: 'Persist Depth',
            hedgingMinVolume: 'Min Volume',
            hedgingMaxVolume: 'Max Volume',
            hedgingVolumeScale: 'Volume Scale',
            hedgingMinAmount: 'Min Amount',
            hedgingPriceScale: 'Price Scale',
            quoteFrequency: 'Frequency',
            persistTick: 'Persist Tick',
            crossed: 'Crossed',
            baseSymbol: 'Base Symbol',
            profitSymbol: 'Profit Symbol',
            liquidityType: 'Liquidity Type',
            askPriceRatio: 'Ask Price Ratio',
            bidPriceRatio: 'Bid Price Ratio'
          }
        },
        lp: {
          self: 'Liquidity Provider',
          id: 'ID',
          itId: 'Exchange',
          lpType: 'Type',
          abbreviation: 'Abbreviation',
          brokerId: 'Maker',
          tradingEnabled: 'Enabled',
          tradingVerbose: 'Verbose',
          balanceCheck: 'Balance Check',
          tradingUrl: 'Url',
          tradingKey: 'Key',
          tradingSecret: 'Secret',
          tradingPassphrase: 'Passphrase',
          tradingDefaultAccount: 'Default Account ID',
          tradingExternalAccounts: 'External Account ID',
          tradingRetryTimes: 'Retry Times',
          tradingRetryInterval: 'Retry Interval',
          symbol: {
            base: {
              self: 'Base',
              name: 'Name',
              code: 'Code',
              displayOrder: 'Display Order'
            },
            pricing: {
              self: 'Pricing',
              subscriptionType: 'Type',
              subscriptionDepth: 'Depth',
              quoteExpiration: 'Expiration',
              quoteSubscription: 'Subscription',
              pricingRestriction: 'Restriction'
            },
            trading: {
              self: 'Trading',
              hedgingMinVolume: 'Min Volume',
              hedgingMaxVolume: 'Max Volume',
              hedgingMinAmount: 'Min Amount',
              hedgingLpAccount: 'Hedge CP Account',
              hedgingVolumeScale: 'Scale',
              tradingRestriction: 'Restriction',
              persistMassOrder: 'Persist Mass Order',
              persistMassExecution: 'Persist Mass Trade',
              hedgingLevel: 'Hedging Level',
              hedgingCopType: 'hedging Cop Type'
            }
          },
          account: {
            tradingAccount: 'Trading Account',
            accountAlias: 'Account Alias',
            accountId: 'Account ID',
            tradingKey: 'Trading Key',
            tradingSecret: 'Trading Secret',
            tradingPassphrase: 'Trading Passphrase'
          }
        }
      },
      operator: {
        role: {
          menu: 'Menu',
          self: 'Role',
          name: 'Name',
          status: 'Status',
          broker: 'Maker',
          comment: 'Comment',
          privileges: {
            self: 'Role Privileges',
            menu: 'Menu',
            api: 'Api',
            allow: 'Allow'
          }
        },
        privilege: {
          self: 'Privilege',
          id: 'ID',
          type: 'Type',
          name: 'Name',
          read: 'Read',
          write: 'Write',
          comment: 'Comment',
          displayOrder: 'Display Order',
          updateOperator: 'Update Operator',
          updateDatetime: 'Last Update'
        },
        menu: {
          self: 'Menu',
          id: 'ID',
          url: 'URL',
          name: 'Name',
          type: 'Type',
          icon: 'Icon',
          view: 'View',
          parent: 'Parent',
          displayOrder: 'Display Order',
          readPrivilege: 'Read Privilege',
          writePrivilege: 'Write Privilege'
        },
        access: {
          level: 'Level',
          action: 'Action',
          domain: 'Domain',
          target: 'Target',
          resource: 'Resource',
          parameter: 'Parameter',
          parameters: 'Parameters',
          accessTime: 'Access Time',
          clientType: 'Client Type',
          clientVersion: 'Client Version',
          clientAddress: 'Client IP'
        },
        operator: {
          self: 'Operator',
          id: 'ID',
          name: 'Name',
          type: 'Type',
          status: 'Status',
          broker: 'Maker',
          loginId: 'Login ID',
          password: 'Password',
          newPw: 'New Password',
          newPw1: 'New Password',
          newPw2: 'Confirm Password',
          lastName: 'Last Name',
          firstName: 'First Name',
          expireTime: 'Expire Time',
          currentPw: 'Old Password',
          confirmPw: 'Confirm Password',
          expirePolicy: 'Expire Policy',
          passwordExpireTime: 'Expire Time',
          passwordExpirePolicy: 'Expire Policy'
        }
      },
      system: {
        digest: {
          self: 'Digest',
          host: 'Host',
          name: 'Name',
          appId: 'App ID',
          domain: 'Domain',
          status: 'Status',
          appName: 'App Name',
          processId: 'Process ID',
          updateDatetime: 'Last Update'
        },
        alert: {
          self: 'Alert',
          id: 'ID',
          to: 'To',
          from: 'From',
          time: 'Time',
          value: 'Value',
          app: 'App',
          appId: 'App ID',
          comment: 'Comment',
          property: 'Property',
          appName: 'App Name',
          timestamp: 'Timestamp',
          monitorKey: 'Monitor Key',
          monitorName: 'Monitor Name',
          monitorType: 'Monitor Type',
          monitorLevel: 'Monitor Level',
          monitorPolicy: 'Monitor Policy'
        },
        alert_guard: {
          self: 'Setting',
          id: 'ID',
          enabled: 'Enabled',
          appName: 'App Name',
          threshold: 'Threshold',
          monitorKey: 'Monitor Key',
          monitorName: 'Monitor Name',
          monitorLevel: 'Monitor Level',
          monitorPolicy: 'Monitor Policy',
          comment: 'Comment',
          version: 'Version'
        },
        batch: {
          self: 'Batch',
          jobName: 'Job Name',
          jobParameters: 'Job Parameters',
          status: 'Status',
          lastUpdated: 'Last Updated'
        },
        batch_history: {
          self: 'Batch History',
          createTime: 'Create Time',
          endTime: 'End Time',
          exitCode: 'Exit Code',
          exitMessage: 'Exit Message',
          jobExecutionId: 'Execution ID',
          jobInstanceId: 'Instance ID',
          startTime: 'Start Time',
          status: 'Status',
          stepExecutionId: 'Step Execution ID',
          stepName: 'Step Name',
          lastUpdated: 'Last Updated'
        },
        config: {
          self: 'Config',
          id: 'ID',
          domain: 'Domain',
          dynamic: 'Dynamic',
          configType: 'Type',
          configKey: 'Key',
          configValue: 'Value',
          version: 'Version',
          updateDatetime: 'Last Update'
        },
        debug: {
          self: 'Debug',
          application: {
            self: 'Application'
          },
          operator: {
            self: 'Operator'
          },
          broker: {
            self: 'Maker'
          },
          symbol: {
            self: 'Symbol'
          },
          bkSymbol: {
            self: 'BK Symbol'
          },
          lpSymbol: {
            self: 'CP Symbol'
          },
          itSymbol: {
            self: 'IT Symbol'
          }
        },
        application: {
          self: 'Application',
          appId: 'App ID',
          uptime: 'Uptime',
          appName: 'App Name',
          appHost: 'App Host',
          appHome: 'App Home',
          processId: 'Process ID',
          ha: 'High Availability',
          updateDatetime: 'Last Update'
        }
      },
      pricing: {
        quotation_history: {
          self: 'Quote',
          lp: 'CP',
          price: 'Price',
          change: 'Change',
          change_percentage: 'Change %',
          volume: 'Volume',
          high: 'High',
          low: "Low",
          updateDatetime: 'Last Update'
        },
        dealing_panel: {
          self: 'Depth Panel',
          setting: 'Config Setting',
          orderType: 'Order Type',
          orderPrice: 'Order Price',
          slippage: 'Slippage',
          orderVolume: 'Order Volume',
          suffixLabel: 'ing',
          volume: 'Volume',
          amount: 'Amount',
          allAmount: 'Gross Amount',
          avgCost: 'Avg.Cost',
          allAvgCost: 'Gross Avg.Cost'
        }
      },
      trades: {
        hedge_order_history: {
          placeType: 'Place Type',
          orderType: 'Order Type',
          orderPrice: 'Order Price',
          avgPrice: 'Avg Price',
          orderVolume: 'Order Volume',
          hedgePrice: 'Hedge Price',
          execAmount: 'Trade Amount',
          execPrice: 'Trade Price',
          execVolume: 'Trade Volume',
          execCommission: 'Trade Commission',
          orderTime: 'Order Time',
          execTime: 'Trade Time',
          lpOrderId: 'CP Order ID',
          lpExcutionId: 'CP Trade ID',
          emptyRejectReason: 'Reject Reason: Empty',
          query: 'Sync',
          cancel: 'Force Cancel',
          execution: {
            button: 'trade',
            executeTime: 'Trade Time',
            executePrice: 'Trade Price',
            executeReportMode: 'Mode',
            executeAmount: 'Trade Amount',
            executeVolume: 'Trade Volume',
            commission: 'Commission'
          }
        },
        hedge_execution_history: {
          orderId: 'Order ID',
          executeTime: 'Trade Time',
          orderTime: 'Order Time',
          executePrice: 'Trade Price',
          pl: 'PL',
          executeReportMode: 'Mode',
          executeAmount: 'Trade Amount',
          lpOrderId: 'CP Order ID',
          lpExcutionId: 'CP Trade ID',
          executeVolume: 'Trade Volume',
          commission: 'Commission',
          executeComment: 'Trade Comment',
          selfMatching: 'Self Matching',
          fetchTime: 'Fetch Time',
          recieveTime: 'Receive Time'
        },
        order_history: {
          lpOrderId: 'CP Order ID',
          orderType: 'Order Type',
          orderPrice: 'Order Price',
          placeType: 'Place Type',
          orderVolume: 'Order Volume',
          execAmount: 'Trade Amount',
          commission: 'Commission',
          execVolume: 'Trade Volume',
          orderTime: 'Order Time',
          executeTime: 'Trade Time',
          order: {
            button: 'sub order',
            execution: {
              button: 'trade',
              executePrice: 'Trade Price',
              lpOrderId: 'CP Order ID',
              lpExcutionId: 'CP Trade ID',
              executeReportMode: 'Mode',
              executeVolume: 'Trade Volume',
              executeAmount: 'Trade Amount',
              commission: 'Commission',
              executeTime: 'Trade Time'
            }
          }
        },
        cp_order: {
          from: 'From',
          to: 'To'
        }
      },
      risk: {
        book: {},
        allocator: {
          self: 'Allocator',
          minVolume: 'Min Volume',
          maxVolume: 'Max Volume',
          priority: 'Priority',
          placeType: 'Place Type',
          timeInForce: 'Time In Force',
          orderType: 'Order Type',
          books: 'Books'
        },
        trigger: {
          self: 'Trigger',
          slippage: 'Slippage',
          longEnabled: 'Long Enabled',
          shortEnabled: 'Short Enabled',
          longVolume: 'Long Volume',
          shortVolume: 'Short Volume',
          longRatio: 'Long Ratio',
          shortRatio: 'Short Ratio',
          maxAmount: 'Max Amount',
          minAmount: 'Min Amount',
          minVolume: 'Min Volume',
          maxVolume: 'Max Volume',
          tpThreshold: 'Tp Threshold',
          slThreshold: 'Sl Threshold',
          profitEnabled: 'Profit Enabled',
          lossEnabled: 'Loss Enabled',
          minSpread: 'Min Spread',
          maxSpread: 'Max Spread',
          priceLimit: 'Price Limit',
          tab: {
            basic: 'Basic',
            strategy: 'Strategy'
          }
        },
        router: {
          self: 'Router',
          allocators: 'Allocator'
        },
        risk: {
          self: 'Risk',
          openVolume: 'Volume',
          openAmount: 'Amount',
          costRate: 'Cost Rate',
          floatingProfit: 'Floating PL',
          closeVolume: 'Close Volume',
          realizedProfit: 'Realized Profit',
          netVolume: 'Net Volume',
          netPl: 'Net PL',
          hedgeVolume: 'Hedge Volume',
          hedgePl: 'Hedge PL',
          longVolume: 'Long Volume',
          longAmount: 'Long Amount',
          shortVolume: 'Short Volume',
          shortAmount: 'Short Amount',
          unrealized: 'Unrealized',
          realized: 'Realized'
        },
        cp_statement_history: {
          openAmount: 'Amount',
          openVolume: 'Volume',
          mtmPl: 'MTM PL',
          mtmQuote: 'MTM Quote',
          closePl: 'Close PL',
          closeVolume: 'Close Volume'
        },
        statement_history: {
          amount: 'Amount',
          volume: 'Volume',
          hedgePl: 'Hedge PL',
          hedgeVolume: 'Hedge Volume',
          netPl: 'Net PL',
          netVolume: 'Net Volume',
          mtmPl: 'MTM PL',
          mtmQuote: 'MTM Quote'
        },
        cp_balance: {
          free: 'Free',
          frozen: 'Frozen',
          borrowed: 'Borrowed',
          sum: 'Sum'
        },
        cp_position: {
          marginType: 'Margin Type',
          markPrice: 'Mark Price',
          liquidationPrice: 'Liquidation Price',
          avgPrice: 'Avg Price',
          unrealizedPl: 'Unrealized Pl'
        },
        position_alertor: {
          nop: 'Nop',
          increment: 'Increment'
        },
        balance_alertor: {},
        trade_alertor: {
          longThreshold: 'Long Threshold',
          shortThreshold: 'Short Threshold',
          longReliefThreshold: 'Long Relief',
          shortReliefThreshold: 'Short Relief'
        },
        bugrate_alertor: {
          threshold: 'Threshold',
          minInterval: 'Min Interval',
          maxTimes: 'Max Times'
        },
        alertor_history: {
          time: 'Time',
          alertType: 'Alert Type',
          tel: 'Tel',
          vmsStatus: 'Status',
          alertComment: 'Alert Comment',
          vmsComment: 'Vms Comment'
        }
      },
      report: {
        self: 'Report',
        holding_daily_pl: {},
        holding_daily_trade: {
          trade_times: 'Times',
          trade_amount: 'Amount',
          trade_amount_ratio: 'Ratio',
          hedge_amount: 'Hedge Amount',
          hedge_ratio: 'Hedge Ratio',
          buy_volume: 'Buy',
          sell_volume: 'Sell',
          net_buy_volume: 'Net Buy'
        },
        holding_balance_turnover: {
          turnover_ratio: 'Turnover Ratio'
        },
        holding_deposit_withdraw: {
          commission: 'Commission'
        }
      }
    },
    // =====================================
    // error
    // =====================================
    error: {
      invalid: 'Invalid',
      overlap: 'Overlap',
      expired: 'Expired',
      overflow: 'Overflow',
      required: 'Required',
      readonly: 'Read Only',
      underflow: 'Underflow',
      overlength: 'Over Length',
      unknown: 'Internal Error.',
      duplicated: 'Duplicated',
      unauthorized: 'Unauthorized Access',
      incompatible: 'Incompatible',
      type_mismatch: 'Type mismatch.',
      not_found: 'Resource not found.',
      invalid_account: 'Invalid account.',
      invalid_execution: 'Invalid Execution',
      resource_not_found: 'Resource not found.',
      must_choose_master: 'Must choose a master Cp',
      total_share_invalid: 'Sum must be equal to 1',
      invalid_company_cas_url: 'Invalid company CAS URL.',
      timeout: 'Service timeout, please try again later.',
      server_busy: 'Server is busy, please try again later.',
      service_timeout: 'Service timeout, please try again later.',
      stale_version: 'Stale data, please reload page and try again.',
      rejected: 'Service rejected, please connect the administrator.',
      restricted: 'Service restricted, please connect the administrator.',
      service_unavailable: 'Service unavailable, please try again later.',
      service_rejected: 'Service rejected, please connect the administrator.',
      service_restricted: 'Service restricted, please connect the administrator.',
      common: {
        invalid: '{{name}} invalid',
        notEmpty: '{{name}} can\'t be empty',
        duplicated: '{{name}} duplicated',
        number: {
          less_than: 'less than',
          more_than: 'more than',
          between: 'between {{from}} and {{to}}'
        }
      },
      date: {
        out_of_range: 'time out of {{days}} {{unit}}'
      },
      password: {
        mismatch: 'Password mismatch.',
        incorrect: 'Invalid login ID or Password.',
        invalid_length: 'Password length invalid [6-16]',
        almost_expire: 'Password is about to expire in {0} days.'
      },
      privileges: {
        empty: 'Have no privileges'
      },
      broker: {
        absent: 'Maker Not Exist',
        mismatch: 'Maker Mismatch',
        restriction: 'Maker Restricted',
        making: {
          restriction: {
            off: 'Maker Making Restricted Already',
            normal: 'Maker Making Normal Already'
          }
        }
      },
      lp: {
        miss: 'CP is missed',
        absent: 'CP Not Exist',
        length: 'CP is empty',
        liquidity: 'CP type is liquidity',
        type: {
          error: 'Type Error'
        },
        making: {
          restriction: {
            off: 'Maker Making Restricted Already',
            normal: 'Maker Making Normal Already'
          }
        }
      },
      config: {
        absent: 'Config Not Exist'
      },
      dialog: {
        TIMEOUT: '{{name}} TIMEOUT',
        REJECTED: '{{name}} REJECTED',
        RESTRICTED: '{{name}} RESTRICTED',
        STALE_VERSION: '{{name}} STALE_VERSION',
        INVALID_POSITION: '{{name}} INVALID_POSITION',
        INVALID_ORDER: '{{name}} INVALID_ORDER',
        INSUFFICIENT_LIQUIDITY: '{{name}} INSUFFICIENT_LIQUIDITY',
        INSUFFICIENT_MARGIN: '{{name}} INSUFFICIENT_MARGIN',
        NO_AVAILABLE_LP: '{{name}} NO_AVAILABLE_LP',
        INTERNAL_ERROR: '{{name}} INTERNAL_ERROR',
        INVALID_REQUEST: '{{name}} INVALID_REQUEST'
      },
      dealingPanel: {
        INVALID: 'invalid',
        EXPIRED: 'expired',
        OVERFLOW: 'overflow',
        READONLY: 'readonly',
        REQUIRED: 'required',
        UNDERFLOW: 'underflow',
        DUPLICATED: 'duplicated',
        RESTRICTED: 'restricted',
        OVERLENGTH: 'overlength',
        field: {
          brokerId: 'Maker {{error}}',
          lpSymbolId: 'CP Or Symbol {{error}}',
          bkSymbolId: 'BK Or Symbol {{error}}'
        },
        common: 'Place Order Invalid,option: {{key}} reason:{{value}}'
      },
      hedgeOrder: {
        EMPTY: '{{name}} is empty !'
      },
      allocators: {
        restricted: ' router {{name}} is using this allocator !'
      },
      editable: {
        required: 'This field is required',
        number_required: 'Number required'
      },
      navtab: {
        overflow: 'Exceeds the maximum limit {{max}} tab pages'
      },
      typeMismatch: {
        byte: 'Number is invalid',
        short: 'Number is invalid',
        int: 'Number is invalid',
        long: 'Number is invalid',
        float: 'Number is invalid',
        double: 'Number is invalid',
        java: {
          lang: {
            Byte: 'Number is invalid',
            Long: 'Number is invalid',
            Short: 'Number is invalid',
            Float: 'Number is invalid',
            Double: 'Number is invalid',
            Integer: 'Number is invalid'
          },
          math: {
            BigDecimal: 'Number is invalid'
          }
        }
      }
    },
    // =====================================
    // help
    // =====================================
    help: {
      market: {
        lp: {
          tradingExternalAccounts: "-"
        },
        institution: {
          institution_currency: {
            name: "-"
          },
          symbol: {
            quoteCode: "-",
            tradeCode: "-",
            persistDepth: "-",
            liquidityType: "-"
          }
        }
      },

      making: {
        making_config: {
          tradeStrict: "Removes previous market making orders before adding in new orders to prevent duplication or unwanted self-trades.",
          volumeBoundaryDeviation: "Largest deviation range the order will be set in terms of volume.",
          volumeRoundRatio: "Sets orders between the volume minimum scale and volume maximum scale as market making orders do not have a fixed precision.",
          maxDeviation: "Maximum price deviation allowed from the median price.",
          l2DiffType: "-",
          l2MinDifference: "-",
          minThreshold: "-",
          tradeSendRatio: "Percentage of orders that will be a self-trade based on the Frequency/m from the basic tab.",
          tradeMidDeviation: "Deviation between bid and ask mid-prices.",
          tradeVolumeMultiplier: "Corrects the transaction order’s volume so that the volume lies between the minimum and maximum instead of at either extreme.",
          tradeAnnexableSize: "Orders with volume below the volume stated here will be issued."
        },

        source_liquidity_config: {
          baseLp: "Counterparty selected to generate the order based on its median value of liquidity. If no counterparty is indicated here, the median value of all associated liquidity is used instead."
        },

        target_price_config: {
          pressureVolume: "Orders with volume above this pressure volume will be avoided.",
          concessionRatio: "Percentage of price retracement from price where the pressure volume was hit.",
          releasePrice: "The price where every order will be filled despite of its order size.",
          startPrice: "If there are no current price for the token/coin, the token/coin will adopt this price. However, if the token/coin currently has a price, and the force start price box is ticked, this start price will override the current price of the token/coin. If the force start price box isn’t ticked, the token/coin will continue on with its current price.",
          startTime: "Time which market making will start."
        },

        trigger: {
          priceLimit: "D1",
          longRatio: "D2",
          shortRatio: "D3"
        }
      }
    }
  };
});
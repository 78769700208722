define('frodo/pods/components/common/field-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'dev',
    classNames: ['checkbox'],
    hasLabel: false,
    contentStyle: 'cr',
    labelClass: null,

    contentClass: Ember.computed('contentStyle', function () {
      return this.get('contentStyle') + '-icon';
    }),

    actions: {
      onCheck: function onCheck() {
        var v = this.toggleProperty('value');
        var selectAction = this.get('onCheck');
        if (selectAction) {
          selectAction(v);
        }
      }
    }
  });
});
define('frodo/helpers/error-rebuilder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(params /*, hash*/) {
      var self = this;
      var error = params[0];
      var index = params[1];
      var field = params[2];
      var name = params[3];
      var validations = Ember.A();

      if (!error || !error.validation) {
        return null;
      }
      _.each(error.validation, function (message) {
        if (message && message.length > 0) {
          var arr = message.split(':');
          if (arr.length === 4) {
            if (index === parseInt(arr[1]) && field === arr[2]) {
              validations.pushObject(self.get('i18n').t('error.common.' + arr[3], { name: name }));
            }
          }
        }
      });

      if (Ember.isEmpty(validations)) {
        return null;
      } else {
        return { validation: validations };
      }
    }
  });
});
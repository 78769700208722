define('frodo/pods/components/common/datetime-picker/component', ['exports', 'frodo/mixins/service-bus'], function (exports, _serviceBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_serviceBus.default, {
    value: '',
    hasMaxDate: false,
    hasMinDate: false,
    maxDate: 0,
    minDate: 0,
    error: null,
    disabled: false,
    withAddon: true,
    valueAlign: 'left',
    datePickable: true,
    timePickable: true,

    timePattern: 'HH:mm:ss',
    datePattern: 'YYYY/MM/DD',
    allowInputToggle: false,

    classNames: ['date'],
    classNameBindings: ['withAddon:input-group'],
    hasError: Ember.computed.notEmpty('error'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      delete Ember.$.fn.collapse; // compatible for bootstrap 4
      var pattern = this.get('pattern'),
          disabled = this.get('disabled'),

      //bootstrap = this.get('bootstrap'),
      //timezone = bootstrap.getLocalTimezone(),
      value = parseInt(this.get('value'), 10),
          allowInputToggle = this.get('allowInputToggle'),
          date = moment.unix(value / 1000),
          maxDate = this.get('maxDate'),
          minDate = this.get('minDate');
      this.$().datetimepicker({
        format: pattern,
        //useCurrent: true,
        showClear: false,
        keyBinds: null, // check the offical doc
        ignoreReadonly: true,
        showTodayButton: true,
        allowInputToggle: allowInputToggle,
        defaultDate: value ? date : false,
        minDate: this.hasMinDate ? date - 86400000 * minDate : false,
        maxDate: this.hasMaxDate ? date + 86400000 * maxDate : false,
        icons: { // compatible for bootstrap 4
          time: 'far fa-clock',
          date: 'fas fa-calendar-alt',
          up: 'fa fa-chevron-up',
          down: 'fa fa-chevron-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-arrows-alt',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        }
      }).on('dp.change', function () {
        var timestamp = moment(_this.$().data('date'), pattern).unix() * 1000;
        _this.set('value', timestamp);
      }).find('input').attr('readonly', disabled).attr('disabled', disabled);
    },


    disabledObserver: Ember.observer('disabled', function () {
      var $input = this.$().find('input');
      var disabled = this.get('disabled');
      $input.attr('readonly', disabled).attr('disabled', disabled);
    }),

    // valueObserver: observer('value', function() {
    //   let value = this.get('value'),
    //     timezone = this.get('bootstrap').getTimezone();
    //   if (value) {
    //     this.$().data("DateTimePicker").date(moment.unix(value / 1000).utcOffset(timezone));
    //   } else {
    //     this.$().data("DateTimePicker").date(null);
    //   }
    // }),

    pattern: Ember.computed('datePattern', 'timePattern', 'datePickable', 'timePickable', function () {
      var date = this.get('datePattern'),
          time = this.get('timePattern'),
          datePickable = this.get('datePickable'),
          timePickable = this.get('timePickable');
      if (datePickable && timePickable || !datePickable && !timePickable) {
        return [date, time].join(' ');
      }
      if (datePickable) {
        return date;
      }
      if (timePickable) {
        return time;
      }
    }),

    valueAlignClass: Ember.computed('valueAlign', function () {
      switch (this.get('valueAlign')) {
        case 'left':
          return 'text-left';
        case 'right':
          return 'text-right';
        case 'center':
          return 'text-center';
        default:
          return 'text-center';
      }
    })
  });
});
define('frodo/helpers/selection-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    i18n: Ember.inject.service(),
    bootstrap: Ember.inject.service(),

    compute: function compute(params /*, hash*/) {
      var type = params[0];
      var value = params[1];
      var group = params[2];
      var group1 = params[3];
      var group2 = params[4];
      var selectionObject = this.get('bootstrap.selection')[type];
      if (Ember.isEmpty(selectionObject) || Ember.isEmpty(value) || Ember.isEmpty(type)) {
        return '-';
      } else {
        var found = _.find(selectionObject, function (e) {
          var r = e.value === value || e.value === parseInt(value);
          if (r && !Ember.isEmpty(group)) r = e.extra.$group == group;
          if (r && !Ember.isEmpty(group1)) r = e.extra.$group1 == group1;
          if (r && !Ember.isEmpty(group2)) r = e.extra.$group2 == group2;
          return r;
        });

        return found ? found.name : '-';
      }
    }
  });
});
define('frodo/pods/components/common/field-multiple-select/component', ['exports', 'frodo/pods/components/common/field-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    options: null,
    onSelect: null,
    searchEnable: false,
    validations: Ember.computed('error', function () {
      var error = this.get('error');
      return error ? error.validation : [];
    }),

    isInvalid: Ember.computed('validations.[]', function () {
      return !Ember.isEmpty(this.get('validations'));
    }),

    actions: {
      onSelect: function onSelect(selected) {
        if (this.get('readonly')) return false;
        var selectAction = this.get('onSelect');
        if (selectAction) {
          selectAction(selected);
        }
      }
    }
  });
});
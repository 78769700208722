define('frodo/pods/index/operator/menu/controller', ['exports', 'frodo/pods/index/operator/menu/validator', 'frodo/pods/index/operator/operator-controller', 'frodo/pods/index/operator/menu/model'], function (exports, _validator, _operatorController, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _operatorController.default.extend({

    Validator: _validator.default,

    sortable: false,

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('menus', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, {});
        });
      },
      remove: function remove(o) {
        return (0, _model.remove)(this, o);
      },
      openModal: function openModal() {
        var _this2 = this;

        var isEdit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        (0, _model.privileges)(this).then(function (r) {
          _this2.set('readonly', !isEdit);
          _this2.set('privileges', _.map(r.data, function (p) {
            return { name: p.name, value: p.id };
          }));
        });
      },
      openAdd: function openAdd() {
        var _this3 = this;

        (0, _model.privileges)(this).then(function (r) {
          _this3.set('record', _model.default.create({}));
          _this3.set('privileges', _.map(r.data, function (p) {
            return { name: p.name, value: p.id };
          }));
        });
      },
      sort: function sort(ids) {
        return (0, _model.sort)(this, { menuIds: ids.toString() });
      },
      toggleSort: function toggleSort() {
        this.toggleProperty('sortable');
      }
    },
    resumeModel: function resumeModel(model) {
      this.set('menus', model);
    }
  });
});
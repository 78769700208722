define('frodo/models/enums', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parse = parse;
  var EnvType = exports.EnvType = {
    DEV: 'development',
    PROD: 'production'
  };

  var WebSocketAction = exports.WebSocketAction = {
    SUBSCRIBE: 'SUBSCRIBE',
    UNSUBSCRIBE: 'UNSUBSCRIBE',
    PING: 'PING'
  };

  var WebSocketEvent = exports.WebSocketEvent = {
    COMMON: 'COMMON', COMMON_SUBSCRIBE_RESPONSE: 'COMMON_SUBSCRIBE_RESPONSE',
    SESSION: 'SESSION', SESSION_SUBSCRIBE_RESPONSE: 'SESSION_SUBSCRIBE_RESPONSE',
    IT_TICK: 'IT_TICK', IT_TICK_SUBSCRIBE_RESPONSE: 'IT_TICK_SUBSCRIBE_RESPONSE',
    LP_QUOTE: 'LP_QUOTE', LP_QUOTE_SUBSCRIBE_RESPONSE: 'LP_QUOTE_SUBSCRIBE_RESPONSE',
    QUOTATION: 'QUOTATION', QUOTATION_SUBSCRIBE_RESPONSE: 'QUOTATION_SUBSCRIBE_RESPONSE'
  };

  var AdminOperatorType = exports.AdminOperatorType = {
    ROOT: 0,
    ADMIN: 1,
    MANAGER: 2
  };

  var AdminPasswordExpirePolicy = exports.AdminPasswordExpirePolicy = {
    NEVER: 1,
    ALWAYS: 2
  };

  var BrokerStatus = exports.BrokerStatus = {
    ACTIVE: 1,
    DISABLED: 3
  };

  var AdminNoticeDomain = exports.AdminNoticeDomain = {
    SYSTEM: 1,
    PRICING: 3,
    TRADING: 5
  };

  var PricingQuoteStatus = exports.PricingQuoteStatus = {
    NORMAL: 1,
    DEVIANT: 2,
    STALE: 3
  };

  var AdminNoticeType = exports.AdminNoticeType = {
    INSERT_TRADING_EXECUTION: { value: 501, domain: AdminNoticeDomain.TRADING },
    DELETE_TRADING_EXECUTION: { value: 502, domain: AdminNoticeDomain.TRADING },
    MODIFY_TRADING_EXECUTION: { value: 503, domain: AdminNoticeDomain.TRADING }
  };

  var AuditLevel = exports.AuditLevel = {
    INFO: 1,
    WARN: 5,
    ERROR: 9
  };

  var DealingLadderType = exports.DealingLadderType = {
    L1_TOB: 1,
    L2_TOB: 3,
    L1_ORDER: 5,
    L2_ORDER: 7,
    TRADE_ORDER: 8,
    TRADE: 9

  };

  var LpTypes = exports.LpTypes = {
    LIQUIDITY_PROVIDER: 1 << 0,
    LIQUIDITY_DEMANDER: 1 << 1
  };

  var CurrencyTypes = exports.CurrencyTypes = {
    ALL: 0,
    BASE: 1,
    PROFIT: 2
  };

  var AdminMenuType = exports.AdminMenuType = {
    APP: 1,
    WEB: 2
  };

  var TradingOrderConfig = exports.TradingOrderConfig = {
    STOP: 1,
    LIMIT: 4,
    MARKET: 16,
    INSTANT: 64
  };

  var TradeDomain = exports.TradeDomain = {
    SPOT: 1,
    MARGIN: 2
  };

  var DaylightSavingType = exports.DaylightSavingType = {
    SUMMER: 1,
    WINTER: 2
  };

  var MobileType = exports.MobileType = {
    IOS: 1,
    ANDROID: 2
  };

  var MobileReleaseStatus = exports.MobileReleaseStatus = {
    ACTIVE: 1,
    PENDING: 2,
    DISABLED: 9
  };

  var WebSocketCloseStatus = exports.WebSocketCloseStatus = {
    NORMAL: 1000,
    GOING_AWAY: 1001,
    NO_CLOSE_FRAME: 1006,
    ABNORMAL_CODE: 4096,
    SESSION_NOT_RELIABLE: 4500,
    SERVER_ERROR: 1011
  };

  var HoldingTriggerProperty = exports.HoldingTriggerProperty = {
    ORDER_TYPE: 1,
    ORDER_DELAY_TIME: 2,
    ORDER_MAX_VOLUME: 3
  };

  var AdminRoleStatus = exports.AdminRoleStatus = {
    NORMAL: 1,
    LOCKED: 2,
    DISABLED: 9
  };

  var TriggerStrategy = exports.TriggerStrategy = {
    NPL: 3
  };

  var SwapPolicy = exports.SwapPolicy = {
    DEFAULT: 1,
    PRODUCT: 2
  };

  var CommissionType = exports.CommissionType = {
    MONEY: 1,
    POINT: 2,
    PERCENTAGE: 3
  };

  var CommissionUnit = exports.CommissionUnit = {
    PER_LOT: 1,
    PER_DEAL: 2
  };

  var CustomerAccountMaturity = exports.CustomerAccountMaturity = {
    FLEDGED: 1,
    SECURED: 2
  };

  var IssueStatus = exports.IssueStatus = {
    PENDING: 1,
    ISSUING: 2,
    SUCCESS: 3,
    FAILURE: 9
  };

  var AdminLoginRestriction = exports.AdminLoginRestriction = {
    ADMIN: 1,
    ADMAPI: 2,
    DLRAPI: 4
  };

  var ImageType = exports.ImageType = {
    SYMBOL: {
      value: 1,
      suffix: 'png'
    },

    CURRENCY: {
      value: 5,
      suffix: 'png'
    }
  };

  var QuoteStatus = exports.QuoteStatus = {
    SOD: 1,
    EOD: 16
  };

  var ChartType = exports.ChartType = {
    M1: 1,
    M5: 2,
    M10: 3,
    M15: 4,
    M30: 5,
    H1: 6,
    H2: 7,
    H4: 8,
    D1: 9,
    W1: 10,
    MN1: 16
  };

  var AlertType = exports.AlertType = {
    BLANCE: 1,
    BUGRATE: 3,
    TRADE: 5,
    POSITION: 6
  };

  var DealingSessionStatus = exports.DealingSessionStatus = {
    PENDING: 1,
    CONNECTED: 2,
    CONNECTING: 3,
    DISCONNECTING: 4
  };

  var DealingOrderType = exports.DealingOrderType = {
    STOP: 1,
    LIMIT: 3,
    MARKET: 5,
    SLIPPAGE: 7
  };
  var Side = exports.Side = {
    BUY: 1,
    SELL: -1
  };
  var TimeInForce = exports.TimeInForce = {
    IOC: 1,
    GTC: 3
  };
  var PanelState = exports.PanelState = {
    OPEN: 1,
    ORDERING: 5,
    CLOSE: 9
  };
  var PanelType = exports.PanelType = {
    DEALING: 1,
    OTHER: 9
  };

  var RoundingMode = exports.RoundingMode = {
    ROUND_UP: 0,
    ROUND_DOWN: 1,
    ROUND_CEIL: 2,
    ROUND_FLOOR: 3,
    ROUND_HALF_UP: 4,
    ROUND_HALF_DOWN: 5,
    ROUND_HALF_EVEN: 6,
    ROUND_HALF_CEIL: 7,
    ROUND_HALF_FLOOR: 8
  };
  var DealingOrderStatus = exports.DealingOrderStatus = {
    PENDING: 1,
    EXECUTING: 3,
    EXECUTED: 4,
    EXPIRED: 5,
    REJECTED: 7,
    CANCELLED: 9,
    UNCONFIRMED: 97,
    INTERRUPTED: 98,
    UNKNOWN: 99
  };
  var ExecuteReportMode = exports.ExecuteReportMode = {
    NORM: 1,
    SYNC: 2,
    TERM: 3,
    FIX: 4
  };

  var LpType = exports.LpType = {
    LIQUIDITY_PROVIDER: 1,
    LIQUIDITY_DEMANDER: 2
  };

  var MakingRestriction = exports.MakingRestriction = {
    NORMAL: 1,
    OFF: 9
  };

  var ItType = exports.ItType = {
    OKEX: 3
  };

  var InstrumentType = exports.InstrumentType = {
    SPOT: 1,
    SWAP: 2,
    MARGIN: 3
  };

  var HedgingCopType = exports.HedgingCopType = {
    WHOLE: 1,
    SEPARATE: 2
  };

  var Language_i18n = exports.Language_i18n = {
    1: 'en-US',
    2: 'ja-JP',
    3: 'zh-CN'
  };
  var Language_Translation = exports.Language_Translation = {
    1: 'EN',
    2: 'JA',
    3: 'ZH'
  };
  var ExpandStatus = exports.ExpandStatus = {
    OPEN: 'open',
    CLOSE: 'close',
    MIX: 'mix'
  };
  var DealingPlaceTypes = exports.DealingPlaceTypes = {
    TRADE: 1 << 0,
    DEAL: 1 << 1
  };

  var DealingPlaceType = exports.DealingPlaceType = {
    TRADE: 1,
    DEAL: 2,
    AUTO_HEDGE: 3,
    MANUAL_HEDGE: 4,
    TRADE_REFLECT: 5,
    INPUT: 7
  };

  var MakingOrderType = exports.MakingOrderType = {
    SOURCE_LIQUIDITY: 1,
    TARGET_PRICE: 2,
    SYNTHETIC_INDEX: 3
  };

  var Level = exports.Level = {
    D1: 1,
    H4: 2,
    M30: 3,
    M5: 4,
    M1: 5
  };

  var MakingDiffType = exports.MakingDiffType = {
    MID: 1,
    BOB: 2
  };

  var LiquidityType = exports.LiquidityType = {
    DIRECT: 1,
    CROSSED: 2,
    PARALLEL: 3
  };

  var SimulateType = exports.SimulateType = {
    DEFAULT: 1,
    ITO: 2
  };

  function parse(type, value) {
    return type[value];
  }
});
define("frodo/pods/index/making/maintain/controller", ["exports", "frodo/pods/index/making/maintain/model", "frodo/models/enums", "frodo/pods/index/making/making-controller"], function (exports, _model, _enums, _makingController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingController.default.extend({
    lpType: _enums.LpType.LIQUIDITY_DEMANDER,
    actions: {
      reload: function reload() {
        var _this = this;

        var params = this.concern();
        (0, _model.fetch)(this, params).then(function (r) {
          var model = _model.default.create({}),
              brokers = void 0,
              lps = void 0;
          if (_this.isData(r)) {
            brokers = _.map(r.data.brokers, function (s) {
              return _model.default.create(s);
            });

            lps = _.map(r.data.lps, function (s) {
              return _model.default.create(s);
            });
            model.set('brokers', brokers);
            model.set('lps', lps);
            _this.set('records', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, params);
        });
      },
      reset: function reset(force) {
        var params = this.concern();
        var lpId = params.lpId;
        var brokerId = params.brokerId;
        this.updateNavtab(this.get('currentRouteName'), {}, params);
        return (0, _model.reset)(this, { brokerId: brokerId, lpId: lpId, force: force });
      },
      start: function start() {
        var params = this.concern();
        this.updateNavtab(this.get('currentRouteName'), {}, params);
        return (0, _model.start)(this, params);
      },
      pause: function pause() {
        var params = this.concern();
        this.updateNavtab(this.get('currentRouteName'), {}, params);
        return (0, _model.pause)(this, params);
      },
      stop: function stop(force) {
        var params = this.concern();
        var lpId = params.lpId;
        var brokerId = params.brokerId;
        this.updateNavtab(this.get('currentRouteName'), {}, params);
        return (0, _model.stop)(this, { brokerId: brokerId, lpId: lpId, force: force });
      }
    },

    concern: function concern() {
      var brokerId = this.get('brokerId');
      var lpId = this.get('lpId');
      return { lpId: lpId, brokerId: brokerId };
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
    }
  });
});
define('frodo/pods/index/popup/dealing-panel/route', ['exports', 'frodo/pods/index/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    preload: false,
    isPopWindow: true,
    menuName: 'dealing.panel',

    closeWindow: function closeWindow() {
      window.close();
    },
    model: function model(params, transition) {
      if (!window.isPopWindow) {
        transition.abort();
        this.closeWindow();
      }
      var state = window.windowParams;
      if (state.hasRO === false) {
        transition.abort();
        return window.close();
      }
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(state);
      });
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);
      var isPopWindow = this.get('isPopWindow');
      if (isPopWindow) {
        this.controllerFor('index').set('hideNavbar', true);
      }
    }
  });
});
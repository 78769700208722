define('frodo/pods/index/trades/hedge-execution-history/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    brokerId: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 8 })],

    lpId: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 8 })],

    symbolId: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 8 })],

    side: [(0, _validators.validatePresence)(true)],

    executeAmount: [(0, _validators.validatePresence)(true)],

    executeVolume: [(0, _validators.validatePresence)(true)],

    executeTime: [(0, _validators.validatePresence)(true)],

    lpOrderId: [(0, _validators.validatePresence)(true)]
  };
});
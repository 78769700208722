define('frodo/pods/components/common/select-base/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    bootstrap: Ember.inject.service(),
    value: null,
    error: null,
    disabled: false,
    keepBlank: false,
    allowClear: true,
    searchField: 'name',
    searchEnabled: false,
    placeholder: '',
    dropdownClass: '',
    searchPlaceholder: '',
    loadingMessage: 'loading...',
    noMatchesMessage: 'No Result',
    onSelect: null,

    init: function init() {
      this._super.apply(this, arguments);
    },


    triggerClass: Ember.computed('error', function () {
      if (this.get('error')) {
        return 'form-control is-invalid';
      } else {
        return 'form-control';
      }
    }),

    selected: Ember.computed('value', 'options', function () {
      var val = this.get('value');
      var options = this._flatten(this.get('options'));
      return _.find(options, function (o) {
        return o.value === val;
      });
    }),

    actions: {
      onchange: function onchange(option) {
        var prev = this.get('value');
        var next = option ? option.value : undefined;
        this.set('value', next);

        var selectAction = this.get('onSelect');
        if (selectAction) {
          selectAction(next, prev);
        }
      }
    },

    _flatten: function _flatten(options) {
      var r = [];
      if (options && options.length) {
        (function walk(options) {
          if (!options) {
            return null;
          }
          for (var i = 0; i < options.length; i++) {
            var entry = options[i];
            if (Ember.isNone(entry)) continue;
            if (entry.groupName && entry.options) {
              walk(entry.options);
            } else {
              r.push(entry);
            }
          }
        })(options);
      }
      return r;
    }
  });
});
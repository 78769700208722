define('frodo/pods/components/index/operator/modal-operator/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/operator/operator/model', 'frodo/models/enums'], function (exports, _component, _model, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = _component.default.extend({

    isBrokerEditDisable: Ember.computed('changeset.type', 'preview', 'isEdit', 'readonly', function () {
      return this.get('changeset.type') != _enums.AdminOperatorType.MANAGER || this.get('preview') || this.get('isEdit') || this.get('readonly');
    }),

    isExpireTimeReadonly: Ember.computed('changeset.{id,passwordExpirePolicy}', function () {
      return this.get('changeset.id') > 0 || this.get('changeset.passwordExpirePolicy') === _enums.AdminPasswordExpirePolicy.NEVER || this.get('readonly');
    }),

    restrictions: Ember.computed('model', function () {
      return keys(_enums.AdminLoginRestriction).map(function (k) {
        return { name: k, value: _enums.AdminLoginRestriction[k] };
      });
    }),

    operatorTypeFilter: Ember.computed('model.type', function () {
      if (this.get('model.type') === _enums.AdminOperatorType.MANAGER) {
        return [_enums.AdminOperatorType.MANAGER];
      }
      if (this.get('model.type') === _enums.AdminOperatorType.ADMIN) {
        return [_enums.AdminOperatorType.ADMIN, _enums.AdminOperatorType.MANAGER];
      }
      if (this.get('model.type') === _enums.AdminOperatorType.ROOT) {
        return [_enums.AdminOperatorType.ROOT, _enums.AdminOperatorType.ADMIN, _enums.AdminOperatorType.MANAGER];
      }
    }),

    actions: {
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      },
      resetBroker: function resetBroker() {
        var type = this.get('changeset.type');
        if (type !== _enums.AdminOperatorType.MANAGER) {
          this.set('changeset.brokerId', 0);
        }
      },
      resetPolicy: function resetPolicy() {
        var policy = this.get('changeset.passwordExpirePolicy');
        if (policy === _enums.AdminPasswordExpirePolicy.NEVER) {
          this.set('changeset.passwordExpireTime', '');
        } else if (policy === _enums.AdminPasswordExpirePolicy.ALWAYS) {
          this.set('changeset.passwordExpireTime', this.get('model.passwordExpireTime'));
        }
      }
    }

  });
});
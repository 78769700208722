define('frodo/pods/components/common/modal-confirm/component', ['exports', 'frodo/mixins/result', 'frodo/mixins/service-bus'], function (exports, _result, _serviceBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_serviceBus.default, _result.default, {
    classNames: ['modal'],
    classNameBindings: ['isAnimate:fade', 'isModaless:modaless'],
    attributeBindings: ['tabindex:tabindex'],

    tabindex: -1,
    model: null,
    isAnimate: true,
    permitEscape: true,
    contextOptions: null,
    buttonDisabled: false,
    backdrop: 'static',
    actionName: 'remove',
    buttonIcon: 'fa-times',
    buttonClass: 'btn-danger',
    contentPrefix: 'label.dialog.content',
    notification: 'label.notification.removed',

    onFail: null,
    onSuccess: null,
    onConfirm: null,

    onShown: function onShown() {},
    onHidden: function onHidden() {},


    isModaless: Ember.computed('backdrop', function () {
      return this.get('backdrop') === false;
    }),

    contentText: Ember.computed('contentPrefix', 'actionName', 'contextOptions', function () {
      var action = this.get('actionName');
      var prefix = this.get('contentPrefix');
      var options = this.get('contextOptions');
      return this.get('i18n').t(prefix + '.' + action, options || {});
    }),

    didInsertElement: function didInsertElement() {
      this.$().modal({
        show: true,
        backdrop: this.get('backdrop'),
        keyboard: this.get('permitEscape')
      }).on('shown.bs.modal', this.onShown.bind(this)).on('hidden.bs.modal', this.onHidden.bind(this));
    },


    actions: {
      close: function close() {
        this.$().modal('hide');
      },
      confirm: function confirm() {
        var self = this;
        this.set('buttonDisabled', true);
        var confirm = this.get('onConfirm');
        var frame = this.$();
        confirm().then(this._resolve.bind(this), this._reject.bind(this)).finally(function () {
          if (!self.isDestroyed) self.set('buttonDisabled', false);
          if (frame && frame.modal) {
            frame.modal('hide');
          } else {
            console.log('confirm finally');
          }
        });
      }
    },

    _resolve: function _resolve(r) {
      var i18n = this.get('i18n');
      var flash = this.get('flashMessages');
      var onSuccess = this.get('onSuccess');
      var notification = this.get('notification');
      if (this.isSuccess(r)) {
        var routeName = 'menu.' + this.get('router.currentRouteName');
        flash.info(i18n.t(notification, { name: i18n.t(routeName) }));
        if (onSuccess) {
          onSuccess();
        }
      } else if (this.isValidation(r)) {
        var data = r.data;
        _.each(_.keys(data), function (k) {
          var v = data[k];
          flash.danger(i18n.t(v.key, { name: v.args }));
        });
      } else {
        if (r && r.key) flash.danger(i18n.t(r.key));
      }
    },
    _reject: function _reject(r) {
      var i18n = this.get('i18n');
      if (r && r.key) this.get('flashMessages').danger(i18n.t(r.key));
    }
  });
});
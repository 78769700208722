define('frodo/pods/index/making/making-config/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    brokerId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 }), (0, _validators.validateLength)({ max: 8 })],

    symbolId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 }), (0, _validators.validateLength)({ max: 8 })],

    lpId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 }), (0, _validators.validateLength)({ max: 8 })],

    frequency: [(0, _validators.validatePresence)(true)],

    l1OrderActive: [(0, _validators.validatePresence)(true)],

    tradeActive: [(0, _validators.validatePresence)(true)],

    l2OrderActive: [(0, _validators.validatePresence)(true)],

    l1ConfigType: [(0, _validators.validatePresence)(true)],

    l1LadderDepth: [(0, _validators.validatePresence)(true)],

    tickSize: [(0, _validators.validatePresence)(true)],

    l1StepHeight: [(0, _validators.validatePresence)(true)],

    l1SpreadType: [(0, _validators.validatePresence)(true)],

    l1PriceLimit: [(0, _validators.validatePresence)(true)],

    l2LadderDepth: [(0, _validators.validatePresence)(true)],

    l2PriceLimit: [(0, _validators.validatePresence)(true)],

    l2Benchmark: [(0, _validators.validatePresence)(true)],

    l2BenchmarkType: [(0, _validators.validatePresence)(true)],

    tradeSendRatio: [(0, _validators.validatePresence)(true)],

    tradeMidDeviation: [(0, _validators.validatePresence)(true)],

    tradeMinVolume: [(0, _validators.validatePresence)(true)],

    tradeMaxVolume: [(0, _validators.validatePresence)(true)],

    tradeVolumeMultiplier: [(0, _validators.validatePresence)(true)],

    expiration: [(0, _validators.validatePresence)(true)],

    l2BidMinVolume: [(0, _validators.validatePresence)(true)],

    l2BidMaxVolume: [(0, _validators.validatePresence)(true)],

    l2AskMinVolume: [(0, _validators.validatePresence)(true)],

    l2AskMaxVolume: [(0, _validators.validatePresence)(true)],

    volumeBoundaryDeviation: [(0, _validators.validatePresence)(true)],

    volumeMinScale: [(0, _validators.validatePresence)(true)],

    volumeMaxScale: [(0, _validators.validatePresence)(true)],

    tradeAnnexableSize: [(0, _validators.validatePresence)(true)]
  };
});
define('frodo/pods/components/index/system/modal-maintain/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/system/maintain/model'], function (exports, _component, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    isTabbed: true,
    actions: {
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        return (0, _model.edit)(this, obj);
      },
      dispose: function dispose() {
        var name = this.get('router').get('currentRouteName');
        this.get('navtabs').closeTabByRoute(name);
      }
    }
  });
});
define('frodo/mixins/downloadable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    csvParams: null,

    actions: {
      download: function download() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          resolve(_this.downloadUrl());
        });
      }
    }
  });
});
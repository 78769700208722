define('frodo/helpers/is-modified', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params, hash) {
      var next = +params[0];
      var prev = +params[1];
      var strict = hash && hash.strict ? hash.strict : false;

      if (isNaN(prev) || isNaN(next)) {
        return false;
      }

      if (prev === null || next === null) {
        return false;
      }

      if (prev === undefined || next === undefined) {
        return false;
      }

      return strict ? prev !== next : prev != next;
    }
  });
});
define('frodo/helpers/time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    i18n: Ember.inject.service(),
    bootstrap: Ember.inject.service(),

    compute: function compute(params /*, hash*/) {
      var v = params[0];
      if (!v && v !== 0) return '-';
      return moment.unix(v / 1000).format('HH:mm:ss');
    }
  });
});
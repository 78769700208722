define('frodo/pods/index/operator/privilege/controller', ['exports', 'frodo/pods/index/operator/privilege/validator', 'frodo/pods/index/operator/privilege/model', 'frodo/pods/index/operator/operator-controller'], function (exports, _validator, _model, _operatorController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _operatorController.default.extend({

    Validator: _validator.default,

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('privileges', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, {});
        });
      },
      openAdd: function openAdd() {
        this.set('record', _model.default.create({}));
      },
      resumeModel: function resumeModel(model) {
        this.set('privileges', model);
      }
    }
  });
});
define('frodo/pods/index/pricing/quotation/controller', ['exports', 'frodo/models/enums', 'frodo/pods/index/pricing/pricing-controller', 'frodo/pods/index/pricing/quotation/model', 'frodo/mixins/popup-window', 'frodo/config/environment'], function (exports, _enums, _pricingController, _model, _popupWindow, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pricingController.default.extend(Ember.Evented, _popupWindow.default, {
    isSubscribed: false,
    isWeightChecked: true,
    popWidth: 650,
    popHeight: 650,
    popupUrl: Ember.computed(function () {
      return _environment.default.rootURL + '#/popup/dealing_panel';
    }),
    quotations: Ember.A(),
    brokerId: Ember.A(),
    bkSymbols: Ember.A(),
    lpSymbols: Ember.A(),
    scheduler: Ember.inject.service(),
    bootstrap: Ember.inject.service(),
    clock: Ember.inject.service(),

    subscription: Ember.computed('brokerId', 'profitCurrencyId', function () {
      return { broker: this.get('brokerId'), currency: this.get('profitCurrencyId') };
    }),

    isStale: function isStale() {
      var _this = this;

      //check timeout
      var quotations = this.get('quotations');
      this.lpSymbols = this.get('bootstrap').getSelection('broker.lp_symbol');
      quotations.forEach(function (row) {
        row.columns.forEach(function (item) {
          if (item.lpSymbolId > 0) {
            var lpSymbol = _this.getLpSymbolById(_this.lpSymbols, item.lpSymbolId);
            var exp = _this._isExpired(item.time, lpSymbol);
            item.set('is_timeout', exp);
          }
        });
      });
    },
    _isExpired: function _isExpired(time, lpSymbol) {
      if (time === 0) return true;
      if (lpSymbol) {
        var period = lpSymbol.extra.quoteExpiration;
        // let server_time = this.bootstrap.getServerTime();
        var client_time = new Date().getTime();
        var diff = parseInt(client_time - time);
        var expired = period > 0 && diff > period;
        return expired;
      }
      return true;
    },


    actions: {
      reload: function reload() {
        var _this2 = this;

        this.bkSymbols = this.get('bootstrap').getSelection('broker.symbol');
        this.lpSymbols = this.get('bootstrap').getSelection('broker.lp_symbol');
        var p = {
          brokerId: this.brokerId,
          configLps: this.get('lpValues').toString(),
          configBkSymbols: this.get('bkSymbolValues').toString()
        };
        (0, _model.fetch)(this, p).then(function (r) {
          if (_this2.isData(r)) {
            _this2.set('quotations', r.data.rows.map(function (s) {
              var a = Ember.A();
              s.columns.forEach(function (item) {
                if (item) {
                  var bkSymbol = _this2.getBkSymbol(_this2.bkSymbols, _this2.brokerId, item.symbolId);
                  var lpSymbol = _this2.getLpSymbol(_this2.lpSymbols, item.lpId, item.symbolId);
                  if (bkSymbol && lpSymbol) {
                    item = _this2.addWeight(bkSymbol, lpSymbol, item);
                    item.askPrice = _this2.formatBigNumber(item.askPrice, bkSymbol.extra.tradingPriceScale, true);
                    item.bidPrice = _this2.formatBigNumber(item.bidPrice, bkSymbol.extra.tradingPriceScale);
                    item.askVolume = _this2.formatBigNumber(item.askVolume, bkSymbol.extra.tradingVolumeScale);
                    item.bidVolume = _this2.formatBigNumber(item.bidVolume, bkSymbol.extra.tradingVolumeScale);
                    item.priceScale = bkSymbol.extra.tradingPriceScale;
                    item.volumeScale = bkSymbol.extra.tradingVolumeScale;
                    item.status = item.status;
                  }
                  //
                  var ask = _this2.split(item.askPrice);
                  item.ask_major = ask.major;
                  item.ask_middle = ask.middle;
                  item.ask_minor = ask.minor;
                  item.ask_trend = 'fix';
                  //
                  var bid = _this2.split(item.bidPrice);
                  item.bid_major = bid.major;
                  item.bid_middle = bid.middle;
                  item.bid_minor = bid.minor;
                  item.bid_trend = 'fix';
                  // item.is_timeout = false;
                  item.quote_status = _this2._setStatusColor(item.status);
                  item.is_timeout = item.timeout;
                }
                a.pushObject(_model.default.create(item));
              });
              s.columns = a;
              return _model.default.create(s);
            }));

            if (r.data.columns) {
              _this2.set('itIds', r.data.columns);
            }
            //
            if (_this2.get('isSubscribed')) {
              _this2.send('unsubscribe', _this2.get('prev_subscription'));
            }
            _this2.send('subscribe', _this2.get('subscription'));
            _this2.set('isSubscribed', true);
          }
          var profitCurrencyId = _this2.get('profitCurrencyId');
          _this2.updateNavtab(_this2.get('currentRouteName'), { profitCurrencyId: profitCurrencyId }, p);
        });
      },
      subscribe: function subscribe(payload) {
        var realtime = this.get('realtime');
        realtime.subscribe(_enums.WebSocketEvent.QUOTATION, payload);
        realtime.on(_enums.WebSocketEvent.QUOTATION, this.onQuotation.bind(this));
        realtime.on(_enums.WebSocketEvent.QUOTATION_SUBSCRIBE_RESPONSE, this.onQuotationSubscribeResponse.bind(this));
      },
      unsubscribe: function unsubscribe(payload) {
        var realtime = this.get('realtime');
        realtime.unsubscribe(_enums.WebSocketEvent.QUOTATION, payload);
        realtime.off(_enums.WebSocketEvent.QUOTATION);
        realtime.off(_enums.WebSocketEvent.QUOTATION_SUBSCRIBE_RESPONSE);
      },
      selectSymbolCell: function selectSymbolCell(symbolId) {
        var param = {
          brokerId: this.brokerId,
          symbolId: symbolId,
          lpId: 0,
          orderPrice: 0,
          volumeScale: 0,
          priceScale: 0,
          orderVolume: 0,
          side: _enums.Side.BUY,
          orderType: _enums.DealingOrderType.LIMIT,
          placeType: _enums.DealingPlaceType.DEAL
        };
        this.openPopupWindows(param);
      },
      selectAskCell: function selectAskCell(v) {
        if (Ember.isEmpty(v.askPrice) || isNaN(v.askPrice)) return;
        var found = _.find(this.bootstrap.getSelection('broker.lp'), function (s) {
          return s.value === v.lpId && s.extra.$group2 == _enums.LpType.LIQUIDITY_DEMANDER;
        });
        var lpId = found ? 0 : v.lpId;
        var param = {
          brokerId: this.brokerId,
          lpId: lpId,
          symbolId: v.symbolId,
          orderPrice: v.askPrice,
          volumeScale: v.volumeScale,
          priceScale: v.priceScale,
          orderVolume: 0,
          side: _enums.Side.BUY,
          orderType: _enums.DealingOrderType.LIMIT,
          placeType: _enums.DealingPlaceType.DEAL
        };
        this.openPopupWindows(param);
      },
      selectBidCell: function selectBidCell(v) {
        if (Ember.isEmpty(v.bidPrice) || isNaN(v.bidPrice)) return;
        var found = _.find(this.bootstrap.getSelection('broker.lp'), function (s) {
          return s.value === v.lpId && s.extra.$group2 == _enums.LpType.LIQUIDITY_DEMANDER;
        });
        var lpId = found ? 0 : v.lpId;
        var param = {
          brokerId: this.brokerId,
          lpId: lpId,
          symbolId: v.symbolId,
          orderPrice: v.bidPrice,
          volumeScale: v.volumeScale,
          priceScale: v.priceScale,
          orderVolume: 0,
          side: _enums.Side.SELL,
          orderType: _enums.DealingOrderType.LIMIT,
          placeType: _enums.DealingPlaceType.DEAL
        };
        this.openPopupWindows(param);
      }
    },
    onSwitchBroker: function onSwitchBroker(owner, next, prev) {
      var t = Object.assign({}, owner.get('subscription'), { broker: prev });
      owner.set('prev_subscription', t);
    },
    onSwitchProfitCurrency: function onSwitchProfitCurrency(owner, next, prev) {
      var t = Object.assign({}, owner.get('subscription'), { currency: prev });
      owner.set('prev_subscription', t);
    },
    openPopupWindows: function openPopupWindows(v) {
      var _this3 = this;

      var lpIds = v.lpId === 0 ? [] : [v.lpId];
      var broker = this.get('bootstrap').getSelection("broker");
      var filter_broker = _.find(broker, function (s) {
        return s.value === v.brokerId && s.extra.$status == _enums.BrokerStatus.ACTIVE;
      });if (!filter_broker) return;
      var params = {
        bookId: 0,
        bookWithAllOption: true,
        brokerId: v.brokerId,
        lpIds: lpIds,
        symbolId: v.symbolId,
        volumeScale: v.volumeScale,
        priceScale: v.priceScale,
        orderVolume: v.orderVolume,
        hasRW: this.get('hasRW'),
        hasR0: this.get('hasR0'),
        orderType: v.orderType,
        side: v.side,
        timeInForce: _enums.TimeInForce.IOC,
        placeType: v.placeType,
        panelType: _enums.PanelType.OTHER,
        coord: { x: 0, y: 30 }
      };
      this.popupWindow({
        windowParams: params,
        width: this.get('popWidth'),
        height: this.get('popHeight'),
        queryPath: this.get('popupUrl')
      }).then(function (r) {
        _this3.get('dialogManager').pushWindow(r);
      });
    },
    addWeight: function addWeight(bkSymbol, lpSymbol, item) {
      if (this.isWeightChecked) {
        if (lpSymbol && bkSymbol) {
          var askPrice = parseFloat(item.askPrice);
          var bidPrice = parseFloat(item.bidPrice);
          var offset = new BigNumber(1).minus(parseFloat(lpSymbol.extra.weight));
          //ask =  round(ask/(1-weight),TRADING_PRICE_SCALE,up)
          item.askPrice = new BigNumber(askPrice).dividedBy(offset).toFixed(bkSymbol.extra.tradingPriceScale, BigNumber.ROUND_UP);
          //bid = round(bid*(1-weight),TRADING_PRICE_SCALE,down)
          item.bidPrice = new BigNumber(bidPrice).multipliedBy(offset).toFixed(bkSymbol.extra.tradingPriceScale, BigNumber.ROUND_DOWN);
        }
      }
      return item;
    },
    split: function split(value) {
      var r = {
        major: '', middle: '', minor: ''
      };
      if (isNaN(value) || !value) {
        return r;
      }
      try {
        var len = value.length;
        var offset = 3;
        var mid = value.slice(len - offset, len - 1);
        var idx = String(mid).indexOf('.');
        if (idx != -1) offset = 4;
        r = {
          major: value.substr(0, len - offset),
          middle: value.slice(len - offset, len - 1),
          minor: value.substr(-1)
        };
      } catch (error) {
        console.warn(value, error);
      }
      return r;
    },
    _setStatusColor: function _setStatusColor(status) {
      if (_enums.PricingQuoteStatus.DEVIANT == status) {
        return "text-danger";
      } else if (_enums.PricingQuoteStatus.STALE == status) {
        return "text-warning";
      }
      return "";
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpValues', Ember.isEmpty(p.configLps) ? Ember.A() : _.map(p.configLps.split(","), function (v) {
        return parseInt(v);
      }));
      this.set('bkSymbolValues', Ember.isEmpty(p.configBkSymbols) ? Ember.A() : _.map(p.configBkSymbols.split(","), function (v) {
        return parseInt(v);
      }));
    },
    resumeModel: function resumeModel(model) {
      this.set('profitCurrencyId', model.profitCurrencyId);
    },
    onQuotationSubscribeResponse: function onQuotationSubscribeResponse() /*response*/{},
    onQuotation: function onQuotation(quotions) {
      var _this4 = this;

      // console.time();
      quotions.forEach(function (q) {
        _this4.refreshPanel(q);
      });
      // console.timeEnd();
    },
    refreshPanel: function refreshPanel(v) {
      var quotations = this.get('quotations');
      var found_row = _.find(quotations, function (item) {
        return item.symbolId === v.symbolId;
      });
      if (found_row) {
        var found_column = _.find(found_row.columns, function (item) {
          return item.lpSymbolId === v.lpSymbolId;
        });

        if (found_column) {
          var bkSymbol = this.getBkSymbol(this.bkSymbols, this.brokerId, v.symbolId);
          var lpSymbol = this.getLpSymbol(this.lpSymbols, v.lpId, v.symbolId);
          if (bkSymbol && lpSymbol) {
            v = this.addWeight(bkSymbol, lpSymbol, v);
            v.askPrice = this.formatBigNumber(v.askPrice, bkSymbol.extra.tradingPriceScale, true);
            v.bidPrice = this.formatBigNumber(v.bidPrice, bkSymbol.extra.tradingPriceScale, false);
            v.askVolume = this.formatBigNumber(v.askVolume, bkSymbol.extra.tradingVolumeScale);
            v.bidVolume = this.formatBigNumber(v.bidVolume, bkSymbol.extra.tradingVolumeScale);
          }
          var ask1 = parseFloat(v.askPrice);
          var ask2 = parseFloat(found_column.askPrice);
          var bid1 = parseFloat(v.bidPrice);
          var bid2 = parseFloat(found_column.bidPrice);
          //
          var ask = this.split(v.askPrice);
          var bid = this.split(v.bidPrice);
          found_column.set('askPrice', v.askPrice);
          found_column.set('bidPrice', v.bidPrice);
          found_column.set('askVolume', v.askVolume);
          found_column.set('bidVolume', v.bidVolume);
          found_column.set('ask_major', ask.major);
          found_column.set('ask_middle', ask.middle);
          found_column.set('ask_minor', ask.minor);
          found_column.set('bid_major', bid.major);
          found_column.set('bid_middle', bid.middle);
          found_column.set('bid_minor', bid.minor);
          found_column.set('time', v.time);
          found_column.set('is_timeout', this._isExpired(v.time, lpSymbol));
          found_column.set('quote_status', this._setStatusColor(v.status));
          //
          if (ask1 > ask2) {
            found_column.set('ask_trend', 'up');
          } else if (ask1 < ask2) {
            found_column.set('ask_trend', 'down');
          } else {
            found_column.set('ask_trend', 'fix');
          }

          if (bid1 > bid2) {
            found_column.set('bid_trend', 'up');
          } else if (bid1 < bid2) {
            found_column.set('bid_trend', 'down');
          } else {
            found_column.set('bid_trend', 'fix');
          }
        }
      }
    }
  });
});
define('frodo/pods/index/making/making-config/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/making/making-model', 'frodo/models/enums'], function (exports, _baseModel, _makingModel, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stop = exports.pause = exports.start = exports.reset = exports.csv = exports.del = exports.edit = exports.check = exports.fetch = exports.add = undefined;
  exports.default = _makingModel.default.extend({
    popMenu: Ember.computed('l1ConfigType', function () {
      var r = "";
      if (this.get('l1ConfigType') == _enums.MakingOrderType.SOURCE_LIQUIDITY) {
        r = "making.source_liquidity_config";
      } else if (this.get('l1ConfigType') == _enums.MakingOrderType.TARGET_PRICE) {
        r = "making.target_price_config";
      }
      return r;
    })
  });


  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfig', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfig/' + data.id, { data: data });
  }

  function del(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfig/' + data.id + '/' + data.version);
  }

  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfigs', { data: p });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfig/validate', { data: data });
  }
  function csv(p) {
    return _baseModel.NAMESPACE + '/' + _baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfigs/csv?' + $.param(p);
  }

  function reset(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfig/reset', { data: data });
  }
  function start(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfig/start', { data: data });
  }
  function pause(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfig/pause', { data: data });
  }
  function stop(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/makingConfig/stop', { data: data });
  }

  exports.add = add;
  exports.fetch = fetch;
  exports.check = check;
  exports.edit = edit;
  exports.del = del;
  exports.csv = csv;
  exports.reset = reset;
  exports.start = start;
  exports.pause = pause;
  exports.stop = stop;
});
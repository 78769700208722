define('frodo/pods/components/index/market/modal-institution/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/models/enums', 'frodo/pods/index/market/institution/model'], function (exports, _component, _enums, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = _component.default.extend({

    types: Ember.computed('model', function () {
      return keys(_enums.LpTypes).map(function (k) {
        return { name: k, value: _enums.LpTypes[k] };
      });
    }),

    instrumentTypes: Ember.computed('model', function () {
      return keys(_enums.InstrumentType).map(function (k) {
        return { name: k, value: _enums.InstrumentType[k] };
      });
    }),

    actions: {
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      }
    }
  });
});
define('frodo/pods/components/nav/nav-menu/component', ['exports', 'frodo/pods/components/nav/nav-menu/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    style: null,
    menus: null,
    tagName: 'ul',
    classNames: ['navbar-nav', 'navbar-sidenav'],
    attributeBindings: ['style'],
    router: Ember.inject.service(),
    registeredMenus: Ember.computed('menus', '_registeredRoutes', function () {
      return _model.default.parse(this.get('menus'), this.get('_registeredRoutes'));
    }),

    _registeredRoutes: Ember.computed(function () {
      var router = Ember.getOwner(this).lookup('router:main');
      var registeredRoutes = router.get('_routerMicrolib.recognizer.names');
      return _.keys(registeredRoutes);
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      //
      var height = window.innerHeight - 35;
      this.set('style', Ember.String.htmlSafe('overflow-y: scroll;max-height: ' + height + 'px'));
      //
      Ember.$('.navbar-sidenav [data-toggle="tooltip"]').tooltip({
        template: '<div class="tooltip navbar-sidenav-tooltip" role="tooltip" style="pointer-events: none;">' + '<div class="arrow"></div><div class="tooltip-inner"></div></div>'
      });

      var currentRoute = this.get('router').get('currentRouteName');
      this._setAccordionStatus(currentRoute);
    },


    actions: {
      collapse: function collapse(e) {
        e.preventDefault();
        Ember.$("body").removeClass("sidenav-toggled");
      }
    },

    _setAccordionStatus: function _setAccordionStatus(currentRoute) {
      var names = currentRoute.split('.');
      if (names.length > 1) {
        var parentName = names[1];
        var $parent = Ember.$('#' + parentName).parents('.nav-item');
        if ($parent) {
          var $menu = $parent[0];
          var $targets = this.$($menu).find('.nav-link');
          if ($targets) {
            var t = $targets[0];
            if (t) {
              t.click();
            }
          }
        } else {
          (false && Ember.warn('nav menu struct mass'));
        }
      }
    }
  });
});
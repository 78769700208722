define('frodo/pods/index/market/market-session/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/market/market-model'], function (exports, _baseModel, _marketModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.check = exports.fetch = undefined;
  exports.default = _marketModel.default.extend({});


  function fetch(owner, type) {
    var p = {};
    if (type) p.type = type;
    return owner.get('ajax').request('/' + _baseModel.ROOT + '/' + _marketModel.PARENT + '/sessions', { data: p });
  }

  function check(owner, s) {
    return owner.get('ajax').post('/' + _baseModel.ROOT + '/' + _marketModel.PARENT + '/market/session/validate', s);
  }

  exports.fetch = fetch;
  exports.check = check;
});
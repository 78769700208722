define('frodo/pods/index/system/batch/controller', ['exports', 'frodo/pods/index/system/batch/model', 'frodo/pods/index/system/system-controller', 'frodo/pods/index/system/batch/validator'], function (exports, _model, _systemController, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemController.default.extend({
    Validator: _validator.default,
    record: null,

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('model', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, {});
        });
      },
      history: function history(o) {
        this.transitionToRoute('index.system.batch_history', o.jobName);
      },
      jobSchedule: function jobSchedule(o) {
        return (0, _model.jobSchedule)(this, o);
      },
      fillScheduleModal: function fillScheduleModal() {
        this.set("record", _model.default.create({ jobParameters: [{ key: '', value: '' }] }));
      }
    },
    resumeModel: function resumeModel(model) {
      this.set('model', model);
    }
  });
});
define('frodo/pods/index/risk/cp-statement-history/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/risk/holding-model'], function (exports, _baseModel, _holdingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _holdingModel.default.extend({});


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _holdingModel.PARENT + '/cpStatementHistory', { data: data });
  }
  exports.fetch = fetch;
});
define('frodo/helpers/timestamp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    bootstrap: Ember.inject.service(),

    compute: function compute(params, hash) {

      var v = params[0],
          fmt = hash && hash.fmt ? hash.fmt : 'YYYY/MM/DD HH:mm:ss';

      if (!v || v === '0' || v === 0) return '-';
      //
      var ms = v % 1000,
          str = moment.unix(v / 1000).format(fmt);
      ms = ms.toString();
      if (ms.length < 3) {
        if (3 - ms.length === 1) {
          ms = '0' + ms;
        } else {
          ms = '00' + ms;
        }
      }

      return hash && hash.ms ? str += '.' + ms : str;
    }
  });
});
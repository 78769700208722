define('frodo/pods/components/index/risk/modal-allocator/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/risk/allocator/model', 'frodo/models/enums'], function (exports, _component, _model, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    isTabbed: true,

    bookDisabled: Ember.computed('readonly', 'isEdit', 'preview', 'editDisable', function () {
      if (this.get('readonly') || this.get('preview')) return true;
      return this.get('isEdit') && this.get('editDisable');
    }),

    bookOptions: Ember.computed('changeset.change.brokerId', function () {
      var selectedBroker = this.get('changeset.change.brokerId') || this.get('changeset.brokerId');
      var r = this.get('reaction').reactionBrokerChange(selectedBroker, 0, 0, { bookWithAllOption: false }).bookOptions;
      return r;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'dealingPlaceTypes', _.map(Object.keys(_enums.DealingPlaceTypes), function (k) {
        if (k === 'AUTO_HEDGE' || k === 'MANUAL_HEDGE' || k === 'TRADE_REFLECT') {
          return { name: k, value: _enums.DealingPlaceTypes[k], disabled: true };
        }
        return { name: k, value: _enums.DealingPlaceTypes[k], disabled: false };
      }));
      Ember.set(this, 'innerChangeset', Ember.copy(this.get('changeset.books.values')), true);
    },
    wrap: function wrap(o) {
      return { values: o };
    },

    actions: {
      innerCheck: function innerCheck(validation) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          if (Ember.isEmpty(validation)) {
            var changeset = _this.get('changeset');
            changeset.set('books', _this.get('innerChangeset'));
            changeset.validate().then(function () {
              var isValid = changeset.get('isValid');
              if (isValid) {
                resolve('');
              } else {
                var error = changeset.get('error');
                var errorFields = Object.keys(error);
                _this.set('error', error);
                _this.set('errorFields', errorFields);
                if (_this.get('isTabbed')) {
                  _this.activateTab(errorFields);
                }
                resolve(error);
              }
            });
          } else {
            resolve(validation);
          }
        });
      },
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          var c = this.copy(model, changeset);
          c.books = JSON.stringify(this.wrap(c.books));
          return (0, _model.check)(this, c);
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        var obj = this.copy(model, changeset);
        obj.books = JSON.stringify(this.wrap(obj.books));
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      },
      resetInnerBook: function resetInnerBook() {
        var target = this.get('innerChangeset');
        if (target && target.length > 0) {
          _.each(target, function (item) {
            Ember.set(item, 'v1', 0);
          });
        }
      }
    }
  });
});
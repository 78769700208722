define('frodo/services/ajax', ['exports', 'frodo/config/environment', 'frodo/mixins/result', 'ember-ajax/services/ajax'], function (exports, _environment, _result, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var version = _environment.default.APP.version;
  exports.default = _ajax.default.extend({
    router: Ember.inject.service(),
    storage: Ember.inject.service(),
    namespace: '/rest',
    utility: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    headers: Ember.computed({
      get: function get() {
        var token = localStorage.getItem('token');
        var headers = {
          '__FRODO_VER__': version,
          'X-API-TOKEN': Ember.isEmpty(token) ? '' : token
        };
        return headers;
      }
    }),

    isSuccess: function isSuccess(status, headers, payload) {
      var isSuccess = this._super.apply(this, arguments);

      if (payload.type === _result.MESSAGE && payload.level === _result.ERROR && payload.key === 'error.unauthorized') {
        this.get('utility').redirectToLogin();
        return false;
      }

      if (status === 502) {
        // alert('Server is not ready');
        return;
      }

      if (isSuccess && payload.status) {
        // when status === 200 and payload has status property,
        // check that payload.status is also considered a success request
        return this._super(payload.status);
      }
      return isSuccess;
    },
    setToken: function setToken(token) {
      if (token == undefined || token == 'undefined') return;
      this.headers['X-API-TOKEN'] = token;
      localStorage.setItem("token", token, 1);
      return this.set('token', token);
    },
    bootstrap: function bootstrap() {
      return this.post('rest/utility/bootstrap');
    }
  });
});
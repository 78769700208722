define('frodo/pods/components/common/select-boolean/component', ['exports', 'frodo/pods/components/common/select-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    placeholder: null,
    options: Ember.computed(function () {
      return [{
        name: 'ON',
        value: true,
        icon: 'fa-check',
        color: 'green'
      }, {
        name: 'OFF',
        value: false,
        icon: 'fa-times',
        color: 'red'
      }];
    })

  });
});
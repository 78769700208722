define('frodo/helpers/isInnerError', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isInnerError = isInnerError;
  function isInnerError(error) {
    if (error && error.length > 0) {
      var err = error[0];
      if (err && err.validation) {
        var validations = err.validation;
        if (validations && validations.length > 0) {
          var message = validations[0];
          var params = message.split(':');
          if (params && params.length === 4) {
            var _error = params[0];
            var index = parseInt(params[1]);
            var field = params[2];
            var name = params[3];
            if (typeof _error === 'string' && typeof index === 'number' && typeof field === 'string' && typeof name === 'string') {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  exports.default = Ember.Helper.helper(isInnerError);
});
define('frodo/locales/zh/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    // RULES:
    // 1. Please make the key clearly!
    // 2. Please order the line by length!

    // =====================================
    // menu
    // =====================================
    menu: {
      index: {
        market: {
          self: '市场',
          broker: '做市商',
          broker_symbol: '做市商币对',
          market: '市场',
          symbol: '币对',
          currency: '货币',
          institution: '交易所',
          institution_symbol: '交易所币对',
          institution_currency: '交易所货币',
          it_session: '交易所连接',
          lp: '交易对手',
          lp_session: '交易对手连接',
          lp_symbol: '交易对手币对',
          lp_account: '交易对手账户',
          market_session: '连接',
          console_config: '控制台配置',
          console_release: '控制台版本',
          system_mail_template: '邮件模块'
        },
        making: {
          self: '做市',
          making_config: '做市配置',
          filter_config: '错价配置',
          mass_order_history: '做市订单',
          mass_execution_history: '做市成交',
          source_liquidity_config: '流动性来源配置',
          target_price_config: '目标价格配置',
          making_config_revision: '做市配置历史',
          source_liquidity_config_revision: '流动性来源配置历史',
          target_price_config_revision: '目标价格配置历史',
          dealing_making_statement: '做市统计',
          dealing_making_statement_history: '做市统计历史',
          maintain: '做市维护'
        },
        operator: {
          self: '运营管理员',
          menu: '菜单管理',
          role: '角色权限',
          notice: '通知',
          notice_template: '通知模块',
          operator: '账号管理',
          privilege: '菜单权限',
          operator_config: '管理员设置',
          operator_access: '操作记录',
          operator_notice: '管理员通知',
          operator_session: '管理员连接'
        },
        system: {
          self: '系统',
          alert: '警告',
          batch: '批处理',
          batch_history: '批处理历史',
          debug: '调试',
          config: '配置',
          digest: '摘要',
          upload: '上传',
          monitor: '监控',
          applications: '应用',
          alert_guard: '警告设置'
        },
        pricing: {
          self: '价格',
          quotation: '报价',
          dealing_panel: '成交面板',
          quotation_history: '报价历史'
        },
        trades: {
          self: '交易',
          hedge_order_history: '对冲订单',
          hedge_execution_history: '对冲成交',
          order_history: '订单',
          cp_order: '交易所订单'
        },
        popup: {
          dealing_panel: '成交面板'
        },
        risk: {
          self: '风险控制',
          book: '账簿',
          allocator: '分配规则',
          router: '路由设置',
          trigger: '触发配置',
          risk: '风控面板',
          cp_statement_history: '对手结算历史',
          statement_history: '结算历史',
          position_alertor: '持仓报警',
          balance_alertor: '资产报警',
          trade_alertor: '交易报警',
          bugrate_alertor: '错价报警',
          cp_balance: '对手余额',
          cp_position: '对手持仓',
          alertor_history: '报警历史'
        },
        report: {
          self: '报表',
          holding_balance: '资产余额',
          holding_daily_pl: '每日盈亏',
          holding_daily_trade: '每日交易量',
          holding_balance_flow: '总资产变化',
          holding_balance_turnover: '资产周转率',
          holding_deposit_withdraw: '出入金记录',
          holding_daily_trade_compare: '资产交易量对比',
          holding_balance_daily_change: '每日资产变化'
        }
      },
      logout: {
        self: '退出'
      },
      password: {
        self: '密码'
      },
      navtab: {
        closeOthers: '关闭其他'
      }
    },
    // =====================================
    // enums
    // =====================================
    enums: {
      Language: {
        EN: 'English',
        JA: 'Japanese',
        ZH: 'Chinese'
      },
      DaylightSavingType: {
        SUMMER: 'SUMMER',
        WINTER: 'WINTER'
      },
      MarketSessionType: {
        NORMAL: 'NORMAL',
        ROLLOVER: 'ROLLOVER'
      },
      SymbolType: {
        C2F: 'C2F',
        C2C: 'C2C'
      },
      SymbolCode: {
        AUD: 'AUD',
        CAD: 'CAD',
        CHF: 'CHF',
        CNH: 'CNH',
        EUR: 'EUR',
        GBP: 'GBP',
        HKD: 'HKD',
        JPY: 'JPY',
        NZD: 'NZD',
        USD: 'USD',
        VND: 'VND',
        ZAR: 'ZAR',
        TRY: 'TRY',
        MXN: 'MXN',
        GAS: 'GAS',
        OIL: 'OIL',
        XAU: 'XAU',
        GOLD: 'GOLD',
        JPN225: 'JPN225'
      },
      AdminRoleStatus: {
        NORMAL: '正常',
        LOCKED: '锁定',
        DISABLED: '禁用'
      },
      CompanyStatus: {
        ACTIVE: '激活',
        DISABLED: '禁用'
      },
      ConvertOperation: {
        MUL: 'MUL',
        DIV: 'DIV',
        MUL_MUL: 'MUL_MUL',
        MUL_DIV: 'MUL_DIV',
        DIV_MUL: 'DIV_MUL',
        DIV_DIV: 'DIV_DIV'
      },
      AdminPrivilegeType: {
        MENU: '菜单',
        API: 'API'
      },
      AdminOperatorType: {
        ROOT: 'ROOT',
        ADMIN: 'ADMIN',
        MANAGER: 'MANAGER'
      },
      AdminOperatorStatus: {
        NORMAL: 'NORMAL',
        LOCKED: 'LOCKED',
        DISABLED: 'DISABLED'
      },
      AdminPasswordExpirePolicy: {
        NEVER: 'NEVER',
        ALWAYS: 'ALWAYS'
      },
      AdminNoticeLevel: {
        INFO: 'INFO',
        WARN: 'WARN',
        ERROR: 'ERROR'
      },
      HoldingTriggerProperty: {
        ORDER_TYPE: 'ORDER_TYPE',
        ORDER_DELAY_TIME: 'ORDER_DELAY_TIME',
        ORDER_MAX_VOLUME: 'ORDER_MAX_VOLUME'
      },
      AdminNoticeStatus: {
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        DELETED: 'DELETED'
      },
      AdminOperatorNoticeStatus: {
        PENDING: 'PENDING',
        ACCEPTED: 'ACCEPTED'
      },
      ItType: {
        BINANCE: 'BINANCE',
        HUOBI: 'HUOBI',
        OKX: 'OKX',
        CHAINUP: 'CHAINUP',
        ZB: 'ZB',
        CHAOEX: 'CHAOEX',
        AMPEX: 'AMPEX',
        MXC: 'MXC',
        BKEX: 'BKEX',
        AEX: 'AEX',
        CEX: 'CEX',
        QBTC: 'QBTC',
        PARAMOUNTDAX: 'PARAMOUNTDAX',
        ZT: 'ZT',
        COWEX: 'COWEX',
        FTX: 'FTX',
        GATE: 'GATE',
        BIKI: 'BIKI',
        BTSE: 'BTSE',
        KRYPTOX: 'KRYPTOX',
        HOTCOINEX: 'HOTCOINEX',
        ASCENDEX: 'ASCENDEX',
        B2C2: 'B2C2',
        COINMARK: 'COINMARK',
        BPM: 'BPM',
        PARALLELCAPITAL: 'PARALLELCAPITAL',
        BITMART: 'BITMART',
        TOKENOMY: 'TOKENOMY',
        WHITEBIT: 'WHITEBIT',
        LBANK: 'LBANK'
      },
      AdminNoticeDomain: {
        SYSTEM: 'SYSTEM',
        PRICING: 'PRICING',
        TRADING: 'TRADING'
      },
      AdminNoticeType: {
        INSERT_TRADING_EXECUTION: 'INSERT_TRADING_EXECUTION',
        DELETE_TRADING_EXECUTION: 'DELETE_TRADING_EXECUTION',
        MODIFY_TRADING_EXECUTION: 'MODIFY_TRADING_EXECUTION'
      },
      MonitorLevel: {
        INFO: 'INFO',
        WARN: 'WARN',
        ERROR: 'ERROR'
      },
      MonitorPolicy: {
        WARN: 'WARN',
        ERROR: 'ERROR',
        COUNT_HWM: 'COUNT_HWM',
        LATENCY_HWM: 'LATENCY_HWM'
      },
      MonitorFilterPolicy: {
        WARN: 'WARN',
        ERROR: 'ERROR',
        COUNT_HWM: 'COUNT_HWM',
        LATENCY_HWM: 'LATENCY_HWM'
      },
      PriceAdjustType: {
        VALUE: 'VALUE',
        PERCENTAGE: 'PERCENTAGE'
      },
      MakingTickType: {
        TOB: 'TOB',
        MID: 'MID'
      },
      HaStatus: {
        SLAVE: 'SLAVE',
        MASTER: 'MASTER',
        GIVING_UP: 'GIVING_UP',
        TAKING_OVER: 'TAKING_OVER'
      },
      CustomerProfileStatus: {
        ACTIVE: 'ACTIVE',
        CLOSED: 'CLOSED'
      },
      CustomerAccountStatus: {
        ACTIVE: 'ACTIVE',
        CLOSED: 'CLOSED'
      },
      CustomerConfigType: {
        MIDAPI: 'MIDAPI',
        ADVANCE: 'ADVANCE',
        EXPRESS: 'EXPRESS',
        TRADING: 'TRADING',
        PRICING: 'PRICING'
      },
      CustomerMessageType: {
        LOSSCUT: 'LOSSCUT',
        MARGIN_CALL: 'MARGIN_CALL',
        TRIGGER_ORDER: 'TRIGGER_ORDER'
      },
      CustomerMailType: {
        LOSSCUT: 'LOSSCUT',
        OVERDRAFT: 'OVERDRAFT',
        MARGIN_CALL: 'MARGIN_CALL',
        EXPIRE_ORDER: 'EXPIRE_ORDER',
        EXECUTE_ORDER: 'EXECUTE_ORDER'
      },
      CustomerDeviceStatus: {
        ACTIVE: 'ACTIVE',
        PAUSED: 'PAUSED',
        DISABLED: 'DISABLED'
      },
      IssueStatus: {
        PENDING: 'PENDING',
        ISSUING: 'ISSUING',
        SUCCESS: 'SUCCESS',
        FAILURE: 'FAILURE'
      },
      ClientType: {
        WEB: 'WEB',
        ADMIN: 'ADMIN',
        ADMAPI: 'ADMAPI',
        MGRAPI: 'MGRAPI',
        MIDAPI: 'MIDAPI'
      },
      MobileType: {
        IOS: 'IOS',
        ANDROID: 'ANDROID'
      },
      CurrencyType: {
        FIAT: 'FIAT',
        CRYPTO: 'CRYPTO'
      },
      LoginRestriction: {
        NORMAL: 'NORMAL',
        OFF: 'OFF'
      },
      MarginRestriction: {
        NORMAL: 'NORMAL',
        LOSSCUT_ONLY: 'LOSSCUT_ONLY',
        RISKCUT_ONLY: 'RISKCUT_ONLY',
        OFF: 'OFF'
      },
      PlacingRestriction: {
        NORMAL: 'NORMAL',
        OPEN_SHORT_OFF: 'OPEN_SHORT_OFF',
        OPEN_LONG_OFF: 'OPEN_LONG_OFF',
        OPEN_OFF: 'OPEN_OFF',
        OFF: 'OFF'
      },
      TransferRestriction: {
        NORMAL: 'NORMAL',
        DEPOSIT_ONLY: 'DEPOSIT_ONLY',
        WITHDRAW_ONLY: 'WITHDRAW_ONLY',
        OFF: 'OFF'
      },
      GroupStatus: {
        ACTIVE: 'ACTIVE',
        DISABLED: 'DISABLED'
      },
      HoldingPolicy: {
        NORMAL: 'NORMAL',
        DISABLED: 'DISABLED'
      },
      LosscutPolicy: {
        FULL: 'FULL',
        INCREMENTAL: 'INCREMENTAL'
      },
      MarginPolicy: {
        NORMAL: 'NORMAL',
        UNLIMITED: 'UNLIMITED'
      },
      FreeMarginPolicy: {
        REALIZED: 'REALIZED',
        UNREALIZED: 'UNREALIZED',
        UNREALIZED_LOSS: 'UNREALIZED_LOSS',
        UNREALIZED_PROFIT: 'UNREALIZED_PROFIT'
      },
      RequiredMarginPolicy: {
        NORMAL: 'NORMAL',
        NET_MARGIN: 'NET_MARGIN',
        MAX_MARGIN: 'MAX_MARGIN'
      },
      TradeDomain: {
        SPOT: 'SPOT',
        MARGIN: 'MARGIN'
      },
      SymbolStatus: {
        ACTIVE: 'ACTIVE',
        HIDDEN: 'HIDDEN',
        DISABLED: 'DISABLED'
      },
      CommissionType: {
        MONEY: 'MONEY',
        POINT: 'POINT',
        PERCENTAGE: 'PERCENTAGE'
      },
      SwapPolicy: {
        DEFAULT: 'DEFAULT',
        PRODUCT: 'PRODUCT'
      },
      CommissionUnit: {
        PER_LOT: 'PER_LOT',
        PER_DEAL: 'PER_DEAL'
      },
      MarginFormulaType: {
        FX: 'FX - Lots / Leverage * ContractSize * MarginPercentage',
        CFD: 'CFD - Lots * Price * ContractSize * MarginPercentage',
        CFD_INDEX: 'CFD_INDEX - Lots * Price * ContractSize * TickPrice / TickSize * MarginPercentage',
        CFD_LEVERAGE: 'CFD_LEVERAGE - Lots * Price / Leverage * ContractSize * MarginPercentage'
      },
      MobileReleaseStatus: {
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        DISABLED: 'DISABLED'
      },
      ChartType: {
        M1: 'M1',
        M5: 'M5',
        M10: 'M10',
        M15: 'M15',
        M30: 'M30',
        H1: 'H1',
        H2: 'H2',
        H4: 'H4',
        D1: 'D1',
        W1: 'W1',
        MN1: 'MN1'
      },
      TradingRestriction: {
        NORMAL: 'NORMAL',
        SHORT_OFF: 'SHORT OFF',
        LONG_OFF: 'LONG OFF',
        OFF: 'OFF'
      },
      AuditLevel: {
        INFO: 'INFO',
        WARN: 'WARN',
        ERROR: 'ERROR'
      },
      TriggerStrategy: {
        NPL: 'NPL'
      },
      PricingRestriction: {
        NORMAL: 'NORMAL',
        OFF: 'OFF'
      },
      TradingAccountType: {
        TRADER: 'TRADER',
        FEEDER: 'FEEDER'
      },
      CompanyConfigType: {
        URL_HOMEPAGE: 'HOMEPAGE'
      },
      BrokerStatus: {
        ACTIVE: 'ACTIVE',
        DISABLED: 'DISABLED'
      },
      PricingQuoteType: {
        SNAPSHOT: 'SNAPSHOT',
        INCREMENTAL: 'INCREMENTAL'
      },
      DealingSessionStatus: {
        PENDING: 'PENDING',
        CONNECTED: 'CONNECTED',
        CONNECTING: 'CONNECTING',
        DISCONNECTING: 'DISCONNECTING'
      },
      DealingOrderType: {
        STOP: 'STOP',
        LIMIT: 'LIMIT',
        MARKET: 'MARKET',
        INSTANT: 'INSTANT',
        SLIPPAGE: 'SLIPPAGE'
      },
      DepthType: {
        NORMAL: 'NORMAL',
        COMBINE: 'COMBINE'
      },
      Side: {
        BUY: 'BUY',
        SELL: 'SELL'
      },
      HedgingCopType: {
        WHOLE: 'WHOLE',
        SEPARATE: 'SEPARATE'
      },
      DealingOrderStatus: {
        PENDING: 'PENDING',
        EXECUTING: 'EXECUTING',
        EXECUTED: 'EXECUTED',
        EXPIRED: 'EXPIRED',
        REJECTED: 'REJECTED',
        CANCELLING: 'CANCELLING',
        CANCELLED: 'CANCELLED',
        UNKNOWN: 'UNKNOWN',
        UNCONFIRMED: 'UNCONFIRMED',
        INTERRUPTED: 'INTERRUPTED'
      },
      ExecuteReportMode: {
        NORM: 'NORM',
        SYNC: 'SYNC',
        TERM: 'TERM',
        FIX: 'FIX'
      },
      LpType: {
        LIQUIDITY_PROVIDER: 'LIQUIDITY_PROVIDER',
        LIQUIDITY_DEMANDER: 'LIQUIDITY_DEMANDER'
      },
      TimeInForce: {
        IOC: 'IOC',
        GTC: 'GTC'
      },
      DealingPlaceType: {
        DEAL: 'DEAL',
        TRADE: 'TRADE',
        AUTO_HEDGE: 'AUTO HEDGE',
        MANUAL_HEDGE: 'MANUAL HEDGE',
        TRADE_REFLECT: 'TRADE REFLECT',
        INPUT: 'INPUT'
      },
      MakingOrderType: {
        SOURCE_LIQUIDITY: 'SOURCE_LIQUIDITY',
        TARGET_PRICE: 'TARGET_PRICE',
        SYNTHETIC_INDEX: 'SYNTHETIC_INDEX'
      },
      AlertType: {
        BLANCE: 'BLANCE',
        BUGRATE: 'BUGRATE',
        TRADE: 'TRADE',
        POSITION: 'POSITION'
      },
      VmsStatus: {
        CONNECTED: 'CONNECTED',
        LOST: 'LOST',
        REJECT: 'REJECT',
        ERROR: 'ERROR'
      },
      Level: {
        D1: 'D1',
        H4: 'H4',
        M30: 'M30',
        M5: 'M5',
        M1: 'M1'
      },
      MakingDiffType: {
        MID: 'MID',
        BOB: 'BOB'
      },
      MakingRestriction: {
        OFF: 'OFF',
        NORMAL: 'NORMAL'
      },
      LiquidityType: {
        DIRECT: 'DIRECT',
        CROSSED: 'CROSSED',
        PARALLEL: 'PARALLEL'
      },
      AllocateRestriction: {
        NORMAL: 'NORMAL',
        OFF: 'OFF'
      },
      DealingLadderType: {
        L1_TOB: 'L1 TOB',
        L2_TOB: 'L2 TOB',
        L1_ORDER: 'L1 ORDER',
        L2_ORDER: 'L2 ORDER',
        TRADE_ORDER: 'TRADE ORDER',
        TRADE: 'TRADE'
      },
      InstrumentType: {
        SPOT: 'SPOT',
        SWAP: 'SWAP',
        MARGIN: 'MARGIN'
      },
      AlertRestriction: {
        NORMAL: 'NORMAL',
        OFF: 'OFF'
      },
      SimulateType: {
        DEFAULT: 'DEFAULT',
        ITO: 'ITO'
      }
    },
    // =====================================
    // label
    // =====================================
    label: {
      common: {
        ms: 'ms',
        id: 'ID',
        lp: '交易对手',
        to: '结束时间',
        pt: 'pt',
        no: 'No',
        all: '全部',
        sec: 's',
        ask: '卖价',
        bid: '买价',
        pip: 'pip',
        alp: 'ALP',
        tob: 'TOB',
        wap: 'WAP',
        key: '密匙',
        sum: '合计',
        base: '基础',
        name: '名称',
        type: '类型',
        date: '日期',
        side: '方向',
        meta: 'Meta',
        book: '账本',
        open: 'Open',
        other: '其他',
        apply: '应用',
        from: '开始时间',
        time: '时间',
        none: '无',
        alert: '报警',
        count: '计数',
        group: '组别',
        value: '数值',
        ratio: '占比',
        total: '合计',
        long: '买',
        short: '卖',
        trigger: '触发',
        restate: '统计重置',
        launch: '执行',
        executing: '执行',
        trade: '交易',
        price: '价格',
        maxPrice: '最高价',
        minPrice: '最低价',
        avgPrice: '均价',
        quote: '报价',
        reset: '重置',
        forceReset: '强制重置',
        forceStartPrice: '强制使用开始价格',
        basic: '基本',
        books: '账本',
        shade: 'Shade',
        login: '登陆',
        image: '图片',
        charge: '花费',
        domain: '域',
        status: '状态',
        placeType: '下单类型',
        margin: '保证金',
        notice: '通知',
        profit: '盈利',
        verbose: '调试',
        active: '有效',
        detail: '详情',
        simulate: '模拟',
        frequency: '频率',
        Level: '级别',
        timeFrom: '开始时间',
        timeTo: '结束时间',
        ladder: '梯度',
        action: '操作',
        quotes: '报价',
        config: '配置',
        flurry: 'Flurry',
        broker: '做市商',
        points: '点数',
        result: '结果',
        spread: '点差',
        uptime: '运行时间',
        weight: '权重',
        symbol: '币对',
        volume: '交易量',
        risk: '风险',
        amount: '交易金额',
        allAmount: '总交易金额',
        balance: '资产',
        comment: '备注',
        dynamic: '动态',
        realized: '已实现',
        unrealized: '未实现',
        avgCost: '平均成本',
        allAvgCost: '总平均成本',
        floatingPL: '浮动损益',
        netVolume: 'Net数量',
        netPL: 'Net损益',
        hedgeVolume: '对冲数量',
        hedgePL: '对冲损益',
        today: '当日',
        m2m: '盯市',
        pl: '损益',
        history: '历史',
        summary: '概要',
        configs: '配置',
        version: '版本',
        message: '信息',
        contact: '联系',
        placing: '发单',
        pricing: '定价',
        dealing: '成交',
        trading: '交易',
        execute: '执行',
        currency: '货币',
        transfer: '转移',
        condition: '条件',
        leverage: '杠杆',
        LpAccount: '对冲CP账户',
        realtime: '实时',
        accounts: '账户',
        strategy: '策略',
        matching: '匹配',
        sourceId: '源ID',
        orderType: '订单类型',
        operator: '管理员',
        brokerId: '做市商',
        contextId: '上下文ID',
        accountId: '账户ID',
        spreadFix: '固定点差',
        spreadVar: '浮动点差',
        subscribe: '订阅',
        threshold: '阈值',
        reliefThreshold: '恢复阈值',
        minInterval: '最小间隔',
        maxTimes: '最大次数',
        execution: '执行',
        customerId: '客户ID',
        allocation: '分配',
        adjustment: '调整',
        startTime: '开始时间',
        endTime: '结束时间',
        lastUpdate: '最后更新',
        prevDate: '前交易日',
        tradingDate: '交易日期',
        restriction: '限制',
        institution: '机构',
        unsubscribe: '取消订阅',
        priceLadder: '报价梯度',
        remoteAddress: '远程地址',
        abbreviation: '缩写',
        longVolume: '买量',
        longAmount: '买金额',
        longTrades: '买交易次数',
        shortVolume: '卖量',
        shortAmount: '卖金额',
        shortTrades: '卖交易次数',
        baseCurrency: '基础货币',
        volumeLadder: '交易量梯度',
        spreadDynamic: '动态点差',
        subscriptions: '订阅',
        modal_detail: '{{name}} {{type}}',
        updateOperator: '更新管理员',
        updateDatetime: '最后更新',
        changePassword: '修改密码',
        profitCurrency: '报价货币',
        position: '头寸',
        netPosition: '净头寸',
        liquidityProvider: '流动性提供商',
        commission: '手续费',
        cost: '成本',
        bidPrice: '买价',
        askPrice: '卖价',
        bidVolume: '买量',
        askVolume: '卖量',
        making_config: '做市配置',
        changes: '变化',
        changing: '差额',
        high: '高',
        hedging: '对冲',
        low: '低',
        dow: {
          1: '星期一',
          2: '星期二',
          3: '星期三',
          4: '星期四',
          5: '星期五',
          6: '星期六',
          7: '星期天'
        },
        btn: {
          on: '开',
          off: '关',
          add: '添加',
          csv: 'CSV',
          buy: '买',
          sell: '卖',
          mid: '中值',
          ba: '买/卖',
          back: '返回',
          edit: '编辑',
          copy: '复制',
          lock: '锁定',
          save: '保存',
          sort: '排序',
          bind: '绑定',
          more: '更多',
          flush: '刷新',
          abort: '中止',
          retry: '重试',
          apply: '应用',
          clear: '清理',
          books: '账本',
          debug: '调试',
          steps: '步骤',
          close: '关闭',
          closePosition: '平仓',
          closeAllPosition: '全部平仓',
          reset: '重置',
          forceReset: '强制重置',
          roles: '角色',
          stop: '停止',
          pause: '暂停',
          start: '开始',
          aggressive: '主动停止',
          buying: '买',
          selling: '卖',
          unlock: '解锁',
          resume: '恢复',
          submit: '提交',
          logout: '退出',
          config: '配置',
          quotes: '报价',
          cancel: '取消',
          detail: '详情',
          devices: '设备',
          search: '搜索',
          remove: '移除',
          brokers: '做市商',
          configs: '配置',
          symbols: '币对',
          currencys: '货币',
          columns: '列',
          restart: '重启',
          refresh: '刷新',
          preview: '预览',
          history: '历史',
          loading: '正在装载...',
          connect: '连接',
          setting: '设置',
          realtime: '实时',
          holidays: '假期',
          template: '模版',
          continue: '继续',
          revision: '修订版',
          accounts: '账号',
          sessions: '会话',
          schedule: '安排',
          transfer: '转移',
          execution: '执行',
          searching: '搜索',
          quickEdit: '快速编辑',
          subscribe: '订阅',
          privileges: '权限',
          disconnect: '断开',
          refreshing: '正在刷新...',
          unsubscribe: '取消订阅',
          conversions: '转换',
          subscribing: '正在订阅...',
          downloading: '正在下载...',
          tradingPanel: '交易面板',
          unsubscribing: '正在取消订阅...'
        },
        time_unit: {
          year: '年',
          month: '月',
          day: '日'
        },
        language: {
          EN: '英文',
          JA: '日文',
          ZH: '中文'
        },
        no_data_found: '无法找到数据.',
        appTitle: 'Market Making Admin',
        appName: '做市管理后台'
      },
      dialog: {
        action: {
          default: '默认',
          add: '添加 {{name}}',
          edit: '编辑 {{name}}',
          stop: '停止确认',
          start: '开始确认',
          pause: '暂停确认',
          aggressive: '主动停止确认',
          detail: '{{name}} 详情',
          save: '保存确认',
          abort: '中止确认',
          reset: '重置确认',
          forceReset: '强制重置确认',
          retry: '重试确认',
          apply: '应用确认',
          order: '命令确认',
          flush: '刷新确认',
          cancel: '取消确认',
          resume: '恢复确认',
          logout: '退出确认',
          remove: '移除确认',
          connect: '连接确认',
          schedule: '日程确认',
          disconnect: '断开确认'
        },
        content: {
          save: '确定保存?',
          abort: '确定中止?',
          apply: '确定应用?',
          retry: '确定重试?',
          reset: '确定重置?',
          forceReset: '确定强制重置?',
          stop: '确定停止?',
          start: '确定开始?',
          emptyReason: '拒绝原因: 空',
          pause: '确定暂停?',
          aggressive: '确定主动停止?',
          flush: '确定刷新?',
          logout: '确定退出?',
          resume: '确定恢复?',
          remove: '确定移除此项内容 {{target}} ?',
          cancel: '确定取消?',
          connect: '确定连接?',
          schedule: '确定日程?',
          disconnect: '确定断开?'
        }
      },
      notification: {
        added: '{{name}} 添加成功',
        saved: '{{name}} 保存成功',
        reset: '{{name}} 重置成功',
        forceReset: '{{name}} 强制重置成功',
        start: '{{name}} 开始成功',
        abort: '{{name}} 中止成功',
        logout: '{{name}} 退出成功',
        stop: '{{name}} 停止成功',
        pause: '{{name}} 暂停成功',
        aggressive: '{{name}} 主动停止成功',
        rebuild: '{{name}} 重建成功',
        removed: '{{name}} 移除成功',
        applied: '{{name}} 应用成功',
        resumed: '{{name}} 恢复成功',
        connect: '{{name}} 连接成功',
        modified: '{{name}} 改动成功',
        executed: '{{name}} 执行成功',
        restarted: '{{name}} 重启成功',
        cancelled: '{{name}} 取消成功',
        scheduled: '{{name}} 计划成功',
        flushed: '{{name}} 刷新成功',
        queried: '{{name}} 查询成功',
        debugEmpty: '无调试 {{name}}',
        disconnect: '{{name}} 断开成功',
        subscribed: '订阅成功',
        unsubscribed: '取消订阅成功',
        passwordChanged: '密码成功修改',
        sorted: '{{name}} 排序显示成功保存',
        restated: '重置成功',
        ordered: '下单成功'
      },
      making: {
        making_config: {
          list: {
            l1OrderActive: 'L1 订单',
            l2OrderActive: 'L2 订单',
            tradeActive: '成交',
            l1Type: 'L1配置类型',
            l1Config: 'L1配置',
            l1Version: 'L1版本'
          },
          tab: {
            basic: '基本',
            level1: '1级',
            level2: '2级',
            trades: '交易'
          },
          basic: {
            frequency: '发送频率/分',
            l1OrderActive: 'L1 发送订单',
            l2OrderActive: 'L2 发送订单',
            tradeActive: '发送成交',
            tradeStrict: '订单成交躲避',
            expiration: '过期',
            tickSize: '最小价格单位',
            volumeMinScale: '交易量最小精度',
            volumeMaxScale: '交易量最大精度',
            volumeRoundRatio: '交易量舍入概率'
          },
          level1: {
            spreadType: '点差类型',
            configType: '配置类型',
            config: '配置',
            ladderDepth: '深度',
            stepHeight: '步长',
            spreadValue: '最小点差',
            spreadPercentage: '最小点差百分比',
            maxDeviation: '价格限制'
          },
          level2: {
            benchmark: "基准",
            benchmarkType: '基准类型',
            differenceValue: '差价',
            differencePercentage: '差价百分比',
            thresholdValue: '阈值',
            thresholdPercentage: '阈值百分比',
            l2MinDifference: '价差',
            ladderDepth: '深度',
            maxDeviation: '价格限制',
            minVolume: '最小交易数量',
            maxVolume: '最大交易数量',
            minThreshold: '阈值',
            l2DiffType: '比较基准',
            l2BidMinVolume: 'BID最小交易数量',
            l2BidMaxVolume: 'BID最大交易数量',
            l2AskMinVolume: 'ASK最小交易数量',
            l2AskMaxVolume: 'ASK最大交易数量'
          },
          trades: {
            tradeType: '成交类型',
            tradePriceType: '成交价格类型',
            tradeSendRatio: '成交概率',
            tradeVolumeDeduct: '成交数量扣除',
            minTradeVolumeRatio: '最小成交比例',
            maxTradeVolumeRatio: '最大成交比例',
            tradeMidDeviation: '中值偏离',
            tradeRefSpread: '成交参照点差',
            tradeVolumeLowerLimit: '最小交易量',
            tradeVolumeUpperLimit: '最大交易量',
            volumeBoundaryDeviation: '量界偏离',
            tradeVolumeMultiplier: '交易量乘数',
            tradeAnnexableSize: '强制成交阀值'
          }
        },
        filter_config: {
          deviation: '偏离量',
          refLps: '参考对手',
          active: '有效'
        },
        mass_order_history: {
          orderPrice: '订单价',
          orderVolume: '订单量',
          execAmount: '成交额',
          execVolume: '成交量',
          orderTime: '下单时间',
          execTime: '成交时间',
          orderType: '订单类型',
          lpOrderId: '交易所订单ID',
          ladderType: '挂单类型',
          placedTime: '接受时间',
          cancelledTime: '取消时间'
        },
        source_liquidity_config: {
          refLps: '流动性来源',
          baseLp: '基础LP',
          source_quote_expiration: '报价来源到期时间',
          bid_volume_adjust_ratio: 'Bid数量调整比例',
          ask_volume_adjust_ratio: 'Ask数量调整比例',
          max_volume: '最大数量',
          max_volume_ratio: '最大数量比例',
          price_adjust_type: '价格调整类型',
          bid_price_adjust_ratio: 'Bid价格调整比例',
          ask_price_adjust_ratio: 'Ask价格调整比例',
          bid_price_adjust_value: 'Bid价格调整值',
          ask_price_adjust_value: 'Ask价格调整值',
          basic: '基本',
          detail: '详情',
          bidMaxVolume: 'Bid最大数量',
          askMaxVolume: 'Ask最大数量'
        },
        target_price_config: {
          startTime: '开始时间',
          startPrice: '开始价格',
          targetTime: '目标时间',
          targetPrice: '目标价格',
          volatility: '波动/秒',
          fluctuation: '波动系数',
          maxSlope: '最大坡度',
          maxDeviation: '价格限制',
          minVolume: '最小数量',
          maxVolume: '最大数量',
          simulator: '模拟器',
          bidMinVolume: 'Bid最小数量',
          bidMaxVolume: 'Bid最大数量',
          askMinVolume: 'Ask最小数量',
          askMaxVolume: 'Ask最大数量',
          pressureVolume: '压力数量',
          concessionRatio: '退让比例',
          releasePrice: '释放价格',
          simulateType: '模拟类型'
        },
        making_config_revision: {
          making_config_id: '配置ID'
        },
        source_liquidity_config_revision: {
          source_liquidity_config_id: '配置ID'
        },
        target_price_config_revision: {
          target_price_config_id: '配置ID'
        },
        dealing_making_statement_history: {
          id: 'ID'
        },
        dealing_making_statement: {
          id: 'ID',
          startTime: '开始时间',
          endTime: '结束时间',
          configVersion: '做市配置版本',
          configRevisions: '做市配置修订履历',
          longThreshold: '多头阈值',
          shortThreshold: '空头阈值',
          matchingVolume: '做市成交量',
          matchingAmount: '做市成交金额',
          matchingCount: '做市成交件数',
          matchingPrice: '做市成交平均价格',
          longVolume: '买成交量',
          longAmount: '买成交金额',
          longCount: '买成交件数',
          longMaxPrice: '买最大价格',
          longMinPrice: '买最小价格',
          longAvgPrice: '买平均价格',
          shortVolume: '卖成交量',
          shortAmount: '卖成交金额',
          shortCount: '卖成交件数',
          shortMaxPrice: '卖最大价格',
          shortMinPrice: '卖最小价格',
          shortAvgPrice: '卖平均价格'
        }
      },
      market: {
        broker: {
          abbreviation: '缩写',
          baseCurrency: '基础货币',
          abbrevation: '缩写',
          loginRestriction: '登录限制',
          tradingRestriction: '交易限制',
          pricingRestriction: '报价限制',
          makingRestriction: '做市限制',
          triggerRestriction: '触发限制',
          ratefilterRestriction: '报价过滤限制',
          allocateRestriction: '分配限制',
          alertRestriction: '报警限制',
          vmsKey: 'VMS公钥',
          vmsSecret: 'VMS私钥',
          vmsNumber: 'VMS语音号码',
          balanceAlertTts: '余额报警模版',
          balanceAlertTel: '余额报警电话',
          balanceAlertActive: '余额报警开启',
          positionAlertTts: '持仓报警模版',
          positionAlertTel: '持仓报警电话',
          positionAlertActive: '持仓报警开启',
          tradeAlertTts: '做市成交报警模版',
          tradeAlertTel: '做市成交报警电话',
          tradeAlertActive: '做市成交报警开启',
          alertBackupList: '备用报警电话',
          bugrateAlertTts: '错价报警模板',
          bugrateAlertTel: '错价报警电话',
          bugrateAlertActive: '错价报警开启',
          symbol: {
            profitCurrency: '报价货币',
            pricingFrequency: '报价频率',
            tradingPriceScale: '价格小数精度',
            tradingVolumeScale: '交易量小数精度',
            tradingMinVolume: '最小交易量',
            tradingMaxVolume: '最大交易量'
          }
        },
        market: {
          self: '市场',
          time: '当前时间',
          sodTime: '开盘时间',
          eodTime: '收盘时间',
          tradingDate: '交易日期',
          prevSodTime: '上一个开盘时间',
          prevEodTime: '上一个收盘时间',
          restriction: '限制',
          tradingRestriction: '交易限制',
          pricingRestriction: '报价限制',
          loginRestriction: '登录限制',
          marginRestriction: '保证金限制',
          placingRestriction: '下单限制',
          transferRestriction: '转移限制',
          prevTradingDate: '上一个交易日',
          market_session: {
            type: '类型',
            startTime: '开始时间',
            endTime: '结束时间'
          }
        },
        market_session: {
          self: '连接',
          to: '结束时间',
          type: '连接类型',
          from: '开始时间',
          endTime: '结束时间',
          startTime: '开始时间',
          daylightSavings: '夏令时',
          daylightSavingType: '夏令时类型'
        },
        symbol: {
          id: 'ID',
          name: '名称',
          type: '类型',
          image: '图片',
          scale: '小数精度',
          displayOrder: '显示顺序',
          baseCurrency: '基础货币',
          profitCurrency: '报价货币',
          version: '版本',
          insertOperator: '插入操作管理员',
          updateOperator: '更新操作管理员',
          updateDatetime: '最后更新时间'
        },
        currency: {
          type: '类型',
          unit: '单位',
          name: '名称',
          scale: '小数精度',
          image: '图片'
        },
        institution: {
          self: '交易所',
          id: 'ID',
          name: '名称',
          status: '状态',
          version: '版本',
          abbreviation: '缩写',
          category: '种类',
          pricingUrl: '报价Url',
          quotingKey: '订阅公钥',
          quotingSecret: '订阅私钥',
          pricingEnabled: '报价开启',
          pricingVerbose: '报价调试',
          instrumentType: '交易所类型',
          symbol: {
            institution: '机构',
            hedging: '对冲',
            quoteCode: '报价代码',
            tradeCode: '交易代码',
            displayOrder: '显示顺序',
            subscriptionType: '订阅类型',
            subscriptionDepth: '订阅深度',
            quoteSubscription: '报价订阅',
            persistDepth: '存储深度',
            hedgingMinVolume: '最小交易量',
            hedgingMaxVolume: '最大交易量',
            hedgingMinAmount: '最小交易额',
            hedgingVolumeScale: '交易量小数精度',
            hedgingPriceScale: '报价小数精度',
            quoteFrequency: '报价频率',
            persistTick: '存储市场交易',
            crossed: '交叉汇率',
            baseSymbol: '基础币对',
            profitSymbol: '损益币对',
            liquidityType: '流动性类型',
            askPriceRatio: '卖价格比率',
            bidPriceRatio: '买价格比率'
          }
        },
        lp: {
          self: '流动性提供商',
          id: 'ID',
          itId: '交易所',
          lpType: 'LP类型',
          abbreviation: '缩写',
          brokerId: '做市商ID',
          tradingEnabled: '交易开启',
          tradingVerbose: '交易调试',
          balanceCheck: '资金检查',
          tradingUrl: '交易Url',
          tradingKey: '交易公钥',
          tradingSecret: '交易私钥',
          tradingPassphrase: '密码',
          tradingDefaultAccount: '默认账户',
          tradingExternalAccounts: '外部账户',
          tradingRetryTimes: '重试次数',
          tradingRetryInterval: ' 重试间隔',
          symbol: {
            base: {
              self: '基本',
              name: '名称',
              code: '代码',
              displayOrder: '显示顺序'
            },
            pricing: {
              self: '报价',
              subscriptionType: '订阅类型',
              subscriptionDepth: '订阅深度',
              quoteExpiration: '报价过期',
              quoteSubscription: '报价订阅',
              pricingRestriction: '报价限制'
            },
            trading: {
              self: '交易',
              hedgingMinVolume: '对冲最小交易量',
              hedgingMaxVolume: '对冲最大交易量',
              hedgingMinAmount: '对冲最小交易额',
              hedgingLpAccount: '对冲CP账户',
              hedgingVolumeScale: '对冲交易量小数精度',
              tradingRestriction: '交易限制',
              persistMassOrder: '存储做市订单',
              persistMassExecution: '存储做市成交',
              hedgingLevel: '对冲杠杆',
              hedgingCopType: '对冲保证金类型'
            }
          },
          account: {
            tradingAccount: '交易账户',
            accountAlias: '账户名称',
            accountId: '账户ID',
            tradingKey: '交易公钥',
            tradingSecret: '交易私钥',
            tradingPassphrase: '密码'
          }
        }
      },
      operator: {
        role: {
          menu: '目录',
          self: '角色权限',
          name: '名称',
          status: '状态',
          broker: '做市商',
          comment: '备注',
          privileges: {
            self: '角色权限',
            menu: '目录',
            api: 'API',
            allow: '允许'
          }
        },
        privilege: {
          self: '权限',
          id: 'ID',
          type: '类型',
          name: '名称',
          read: '读',
          write: '写',
          comment: '备注',
          displayOrder: '显示顺序',
          updateOperator: '更新管理员',
          updateDatetime: '最后更新'
        },
        menu: {
          self: '目录',
          id: 'ID',
          url: 'URL',
          name: '名称',
          type: '类型',
          icon: '图标',
          view: '查看',
          parent: '父目录',
          displayOrder: '显示顺序',
          readPrivilege: '查看权限',
          writePrivilege: '修改权限'
        },
        access: {
          level: '级别',
          action: '操作',
          domain: '域',
          target: '目标',
          resource: '资源',
          parameter: '参数',
          parameters: '参数',
          accessTime: '访问时间',
          clientType: '客户端类型',
          clientVersion: '客户端版本',
          clientAddress: '客户IP地址'
        },
        operator: {
          self: '管理员',
          id: 'ID',
          name: '名称',
          type: '类型',
          status: '状态',
          broker: '做市商',
          loginId: '登录ID',
          password: '密码',
          newPw: '新密码',
          newPw1: '新密码1',
          newPw2: '新密码2',
          lastName: '姓',
          firstName: '名',
          expireTime: '到期时间',
          currentPw: '旧密码',
          confirmPw: '确认密码',
          expirePolicy: '到期规则',
          passwordExpireTime: '密码到期时间',
          passwordExpirePolicy: '密码到期规则'
        }
      },
      system: {
        digest: {
          self: '摘要',
          host: '主机',
          name: '名称',
          appId: '应用ID',
          domain: '域',
          status: '状态',
          appName: '应用名称',
          processId: '处理ID',
          updateDatetime: '最后更新'
        },
        alert: {
          self: '警告',
          id: 'ID',
          to: '结束时间',
          from: '开始时间',
          time: '时间',
          value: '值',
          app: '应用',
          appId: '应用ID',
          comment: '备注',
          policy: '规则',
          property: '属性',
          appName: '应用名称',
          timestamp: '时间戳',
          monitorKey: '监控主键',
          monitorName: '监控名称',
          monitorType: '监控类型',
          monitorLevel: '监控级别',
          monitorPolicy: '监控规则'
        },
        alert_guard: {
          self: '设置',
          id: 'ID',
          enabled: '开启',
          appName: '应用名称',
          threshold: '阈值',
          monitorKey: '监控主键',
          monitorName: '监控名称',
          monitorLevel: '监控级别',
          monitorPolicy: '监控规则',
          comment: '备注',
          version: '版本'
        },
        batch: {
          self: '批处理任务',
          jobName: '任务名称',
          jobParameters: '任务参数',
          status: '状态',
          lastUpdated: '最后更新'
        },
        batch_history: {
          self: '批处理历史',
          createTime: '创建时间',
          endTime: '结束时间',
          exitCode: '退出代码',
          exitMessage: '退出信息',
          jobExecutionId: '任务执行ID',
          jobInstanceId: '任务实例ID',
          startTime: '开始时间',
          status: '状态',
          stepExecutionId: '步骤执行ID',
          stepName: '步骤名称',
          lastUpdated: '最后更新'
        },
        config: {
          self: '配置',
          id: 'ID',
          domain: '域',
          dynamic: '动态',
          configType: '配置类型',
          configKey: '配置key',
          configValue: '配置数值',
          version: '版本',
          updateDatetime: '最后更新'
        },
        debug: {
          self: '调试',
          application: {
            self: '应用'
          },
          operator: {
            self: '管理员'
          },
          broker: {
            self: '做市商'
          },
          symbol: {
            self: '币对'
          },
          bkSymbol: {
            self: '客户币对'
          },
          lpSymbol: {
            self: '交易对手币对'
          },
          itSymbol: {
            self: '交易所币对'
          }
        },
        application: {
          self: '应用',
          appId: '应用ID',
          uptime: '运行时间',
          appName: '应用名称',
          appHost: '应用主机',
          appHome: '应用主页',
          processId: '处理ID',
          ha: '高可用',
          updateDatetime: '最后更新'
        }
      },
      pricing: {
        quotation_history: {
          self: '报价历史',
          lp: '交易对手',
          price: '报价',
          change: '变动',
          change_percentage: '变动百分比',
          volume: '交易量',
          high: '高',
          low: "低",
          updateDatetime: '最后更新'
        },
        dealing_panel: {
          self: '做市面板',
          setting: '配置设置',
          orderType: '订单类型',
          orderPrice: '订单价',
          orderVolume: '订单交易量',
          slippage: '滑点',
          suffixLabel: 'ing',
          volume: '头寸',
          amount: '成本',
          allAmount: '总成本',
          avgCost: '平均成本',
          allAvgCost: '总平均成本'
        }
      },
      trades: {
        hedge_order_history: {
          placeType: '下单类型',
          orderType: '订单类型',
          orderPrice: '订单价',
          avgPrice: '均价',
          orderVolume: '订单量',
          hedgePrice: '对冲价格',
          execAmount: '成交额',
          execPrice: '成交价',
          execVolume: '成交量',
          execCommission: '交易手续费',
          orderTime: '下单时间',
          execTime: '成交时间',
          lpOrderId: '交易所订单ID',
          lpExcutionId: '交易所成交ID',
          emptyRejectReason: '拒绝原因: 空',
          query: '同步',
          cancel: '强制取消',
          execution: {
            button: '成交',
            executeTime: '成交时间',
            executePrice: '成交价',
            executeReportMode: '成交方式',
            executeAmount: '成交额',
            executeVolume: '成交量',
            commission: '手续费'
          }
        },
        hedge_execution_history: {
          orderId: '订单ID',
          lpOrderId: '交易所订单ID',
          lpExcutionId: '交易所成交ID',
          executeTime: '成交时间',
          orderTime: '下单时间',
          executePrice: '成交价',
          pl: '损益',
          executeReportMode: '成交方式',
          executeAmount: '成交额',
          executeVolume: '成交量',
          commission: '手续费',
          executeComment: '成交备注',
          selfMatching: '自成交',
          fetchTime: '查询时间',
          recieveTime: '接受时间'
        },
        order_history: {
          lpOrderId: '交易所订单ID',
          orderType: '订单类型',
          orderPrice: '订单价',
          placeType: '下单类型',
          orderVolume: '订单量',
          execAmount: '成交额',
          commission: '手续费',
          execVolume: '成交量',
          orderTime: '下单时间',
          executeTime: '成交时间',
          order: {
            button: '子订单',
            execution: {
              button: '成交',
              lpOrderId: '交易所订单ID',
              lpExcutionId: '交易所成交ID',
              executePrice: '成交价',
              executeReportMode: '成交方式',
              executeVolume: '成交量',
              executeAmount: '成交额',
              commission: '手续费',
              executeTime: '成交时间'
            }
          }
        },
        cp_order: {
          from: '开始时间',
          to: '结束时间'
        }
      },
      risk: {
        book: {},
        allocator: {
          self: '分配规则',
          minVolume: '最小量',
          maxVolume: '最大量',
          priority: '优先级',
          placeType: '下单类型',
          timeInForce: '订单有效类型',
          orderType: '订单类型',
          books: '账本'
        },
        trigger: {
          self: '触发',
          slippage: '滑点',
          longEnabled: '多头',
          shortEnabled: '空头',
          longVolume: '多头量',
          shortVolume: '空头量',
          longRatio: '多头持仓比例',
          shortRatio: '空头持仓比例',
          minAmount: '最小交易金额',
          maxAmount: '最大交易金额',
          minVolume: '最小交易数量',
          maxVolume: '最大交易数量',
          tpThreshold: '止盈阈值',
          slThreshold: '止损阈值',
          profitEnabled: '止盈',
          lossEnabled: '止损',
          minSpread: '最小点差',
          maxSpread: '最大点差',
          priceLimit: '价格限制',
          tab: {
            basic: '基本',
            strategy: '策略'
          }
        },
        router: {
          self: '路由',
          allocators: '分配规则'
        },
        risk: {
          self: '风控',
          openVolume: '净头寸',
          openAmount: '金额',
          costRate: '成本价',
          floatingProfit: '浮动损益',
          closeVolume: '平仓量',
          realizedProfit: '实现损益',
          netVolume: 'Net 量',
          netPl: 'Net 损益',
          hedgeVolume: '对冲量',
          hedgePl: '对冲损益',
          longVolume: '买量',
          longAmount: '买金额',
          shortVolume: '卖量',
          shortAmount: '卖金额',
          unrealized: '未实现',
          realized: '已实现'
        },
        cp_statement_history: {
          openAmount: '金额',
          openVolume: '净头寸',
          mtmPl: '盯市损益',
          mtmQuote: '盯市价',
          closePl: '关仓损益',
          closeVolume: '关仓数量'
        },
        statement_history: {
          amount: '成本',
          volume: '净头寸',
          hedgePl: '对冲损益',
          hedgeVolume: '对冲量',
          netPl: 'Net 损益',
          netVolume: 'Net 量',
          mtmPl: '盯市损益',
          mtmQuote: '盯市价'
        },
        cp_balance: {
          free: '可用',
          frozen: '冻结',
          borrowed: '已借',
          sum: '合计'
        },
        cp_position: {
          marginType: '保证金类型',
          markPrice: '标价',
          liquidationPrice: '清算价格',
          avgPrice: '均价',
          unrealizedPl: '未实现损益'
        },
        position_alertor: {
          nop: '持仓量',
          increment: '增量'
        },
        balance_alertor: {},
        trade_alertor: {
          longThreshold: '买阈值',
          shortThreshold: '卖阈值',
          longReliefThreshold: '买恢复阈值',
          shortReliefThreshold: '卖恢复阈值'
        },
        bugrate_alertor: {
          threshold: '阈值',
          minInterval: '最小间隔',
          maxTimes: '最大次数'
        },
        alertor_history: {
          time: 'Time',
          alertType: '报警类型',
          tel: '呼叫号码',
          vmsStatus: '发送状态',
          alertComment: '警告信息',
          vmsComment: '最终状态'
        }
      },
      report: {
        self: '报表',
        holding_daily_pl: {},
        holding_daily_trade: {
          trade_times: '次数',
          trade_amount: '交易金额',
          trade_amount_ratio: '金额占比',
          hedge_amount: '对冲金额',
          hedge_ratio: '对冲占比',
          buy_volume: '买入量',
          sell_volume: '卖出量',
          net_buy_volume: '净买量'
        },
        holding_balance_turnover: {
          turnover_ratio: '资产周转率'
        },
        holding_deposit_withdraw: {
          commission: '手续费'
        }
      }
    },
    // =====================================
    // error
    // =====================================
    error: {
      invalid: '无效',
      overlap: '重叠',
      expired: '过期',
      overflow: '溢出',
      required: '必填',
      readonly: '只读',
      underflow: '低于下限',
      overlength: '长度超过限制',
      unknown: '未知.',
      duplicated: '重复',
      unauthorized: '未授权',
      incompatible: '不兼容',
      type_mismatch: '类型不匹配.',
      not_found: '不存在.',
      invalid_account: '无效账号.',
      invalid_execution: '无效执行',
      resource_not_found: '资源不存在.',
      must_choose_master: '必选一个主LP',
      total_share_invalid: '总数必须等于1',
      invalid_company_cas_url: '无效公司CAS URL.',
      timeout: '请求超时，请稍后重试。',
      server_busy: '服务器繁忙，请稍后重试。',
      service_timeout: '请求超时，请稍后重试。',
      stale_version: '过期数据，请重新加载页面并重试。',
      rejected: '请求被拒绝，请联系管理员。',
      restricted: '请求被限制，请联系管理员。',
      service_unavailable: '请求不可用，请稍后重试。',
      service_rejected: '请求被拒绝，请联系管理员。',
      service_restricted: '请求被限制，请联系管理员。',
      common: {
        invalid: '{{name}} 无效 ',
        notEmpty: '{{name}} 不能为空',
        duplicated: '{{name}} 重复',
        number: {
          less_than: '应少于 {{value}}',
          more_than: '应多余 {{value}}',
          between: '范围应在 {{from}} 与 {{to}} 之间'
        }
      },
      date: {
        out_of_range: '时间超出 {{days}} {{unit}}'
      },
      password: {
        mismatch: '密码不匹配.',
        incorrect: '无效登录名或密码。',
        invalid_length: '密码长度无效 [6-16]',
        almost_expire: '密码将在 {0} 天后失效.'
      },
      privileges: {
        empty: '无权限'
      },
      broker: {
        absent: '做市商不存在',
        mismatch: '做市商ID不匹配',
        restriction: '做市商限制',
        making: {
          restriction: {
            off: '已关闭做市商做市限制',
            normal: '已开启做市商做市限制'
          }
        }
      },
      lp: {
        miss: '交易对手未选择',
        absent: '交易对手不存在',
        length: '交易对手不能为空',
        liquidity: '交易对手类型不能是liquidity',
        type: {
          error: '类型错误'
        },
        making: {
          restriction: {
            off: '已关闭做市商做市限制',
            normal: '已开启做市商做市限制'
          }
        }
      },
      config: {
        absent: '配置不存在'
      },
      dialog: {
        TIMEOUT: '{{name}} 超时',
        REJECTED: '{{name}} 拒绝',
        RESTRICTED: '{{name}} 限制',
        STALE_VERSION: '{{name}} 过期版本',
        INVALID_POSITION: '{{name}} 无效位',
        INVALID_ORDER: '{{name}} 无效订单',
        INSUFFICIENT_LIQUIDITY: '{{name}} 流动性不足',
        INSUFFICIENT_MARGIN: '{{name}} 余额不足',
        NO_AVAILABLE_LP: '{{name}} 对手不可用',
        INTERNAL_ERROR: '{{name}} 内部错误',
        INVALID_REQUEST: '{{name}} 无效请求'
      },
      dealingPanel: {
        INVALID: '无效',
        EXPIRED: '过期',
        OVERFLOW: '溢出',
        READONLY: '只读',
        REQUIRED: '必填',
        UNDERFLOW: '下溢',
        DUPLICATED: '重复',
        RESTRICTED: '限制',
        OVERLENGTH: '长度超过限制',
        field: {
          brokerId: '做市商 {{error}}',
          lpSymbolId: '交易对手或币对 {{error}}',
          bkSymbolId: '做市商或币对 {{error}}'
        },
        common: '下单失败,选项: {{key}} 原因:{{value}}'
      },
      hedgeOrder: {
        EMPTY: '{{name}} 是空值 !'
      },
      allocators: {
        restricted: ' 路由 {{name}} 已使用此分配规则 !'
      },
      editable: {
        required: '这一项必填',
        number_required: '需有数字'
      },
      navtab: {
        overflow: '已达上限 {{max}} 页'
      },
      typeMismatch: {
        byte: '无效数字',
        short: '无效数字',
        int: '无效数字',
        long: '无效数字',
        float: '无效数字',
        double: '无效数字',
        java: {
          lang: {
            Byte: '无效数字',
            Long: '无效数字',
            Short: '无效数字',
            Float: '无效数字',
            Double: '无效数字',
            Integer: '无效数字'
          },
          math: {
            BigDecimal: '无效数字'
          }
        }
      }
    },
    // =====================================
    // help
    // =====================================
    help: {
      market: {
        lp: {
          tradingExternalAccounts: "特定的交易对手,可以使用外部的账户,判断交易是否进入持仓管理.设置一个以上的情况使用分号分割."
        },
        institution: {
          institution_currency: {
            name: "交易所和做市系统对同一货币的命名不同的场景,在此进行映射."
          },
          symbol: {
            quoteCode: "订阅报价时使用的标识.",
            tradeCode: "发送订单请求时使用的标识.",
            persistDepth: "-1代表不存储,0代表只保存tob,大于等于1意味着,除了保存tob,还会保存具体的n层流动性.",
            liquidityType: "DIRECT意味着直接导入上游相同品种的流动性。CROSSED意味通过上游的两个交叉币对的流动性生成新的流动性,PARALLEL指的是通过设定一个比例,将一个币对的流动性,转化为一个新币对的流动性."
          }
        }
      },

      making: {
        making_config: {
          tradeStrict: "做市时尽量避免本次做市单和上次做市单成交.",
          volumeBoundaryDeviation: "市单的数量超过设定的最小值或最大值的时候,使用最小值向上偏离或者最大值向下偏离的值,量界偏离设定了最大的偏离幅度.",
          volumeRoundRatio: "做市单的量并不是一个固定的精度,设定一个概率,在交易量最小精度和交易量最大精度之间随机",
          maxDeviation: "做市对手的下单价格限制,距离中值偏离幅度",
          l2DiffType: "基于哪个基准来判断是否更新Level2做市单,BOB基于Level2最顶层Level1的最底层的差,MID基于Level2最顶层和中值得差.",
          l2MinDifference: "生成Level2时,Level2 tob距离MID或者BOB的差.",
          minThreshold: "基于比较基准获得一个差值,差值小于最小阈值,则更新Level2.若当前Level2 tob的价格和当前MID或者BOB的差值小于阈值,则更新Level2.",
          tradeSendRatio: "多大几率,在发送做市单的同时发送成交单.",
          tradeMidDeviation: "成交单的价格在mid值附近,偏离spread长度的百分比.",
          tradeVolumeMultiplier: "成交单的量基于价格的变动的幅度进行计算,然后基于不同币对的实际属性,乘上不同的交易量乘数进行修正.",
          tradeAnnexableSize: "做市时成交单需求当前盘口中spread大于两个点,否则不能生成成交单的价格.但是当盘口的买一或者卖一的量小于该设定值,则发出一笔订单和其成交."
        },

        source_liquidity_config: {
          baseLp: "使用该交易对手的流动性的中值做市做市单生成的中值.若不设定基础LP,使用所有关联的流动性的中值."
        },

        target_price_config: {
          pressureVolume: "当在订阅层数内的盘口中存在卖单的数量超过该设定,触发压单躲避机制.",
          concessionRatio: "触发压单躲避时,目标价格调整为压单价格*(1-退让比例),若存在最小值,最小值也相应调整.",
          releasePrice: "若压单价格小于等于释放价格,则不尽兴躲避.",
          startPrice: "若不存在当前价格,则使用开始价格作为起点进行做市,若选择了强制使用开始价格,即使存在当前价格,依然使用开始价格作为起点进行做市.",
          startTime: "设定未来时间,则做市将在指定的时间开始."
        },

        trigger: {
          priceLimit: "若流动性更新后,tob价格偏离mid值超过该限制则不进行触发.",
          longRatio: "多头仓位触发后,保留的比例,该比例基于多头量的设定而不是实际的仓位数量.",
          shortRatio: "空头仓位触发后,保留的比例,该比例基于空头量的设定而不是实际的仓位数量."
        }
      }
    }
  };
});
define('frodo/pods/components/common/field-multiple-mask-select/component', ['exports', 'frodo/pods/components/common/field-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    lookUp: null,
    options: null,
    onSelect: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('lookUp')) {
        Ember.observer('lookUp', function () {
          Ember.run.once(this, 'lookUpChange');
        });
      }
    },
    lookUpChange: function lookUpChange() {
      this.set('values', []);
    },


    values: Ember.computed('masked', 'options.@each.value', 'value.@each', {
      set: function set(key, value) {
        return value;
      },
      get: function get() /*key*/{
        var value = this.get('value');
        var masked = this.get('masked');
        if (!value) return [];
        if (!masked) {
          var options = this.get('options');
          var filter = _.filter(options, function (o) {
            return value.includes(o.value);
          });
          if (filter) {
            return _.map(filter, function (o) {
              return o.value;
            });
          } else {
            return [];
          }
        } else {
          var r = [];
          if (value === 0) {
            return r;
          }
          var _options = this.get('options');
          _options.forEach(function (o) {
            if (parseInt(value & o.value) !== 0) {
              r.push(o.value);
            }
          });
          return r;
        }
      }
    }),

    validations: Ember.computed('error', function () {
      var error = this.get('error');
      return error ? error.validation : [];
    }),

    isInvalid: Ember.computed('validations.[]', function () {
      return !Ember.isEmpty(this.get('validations'));
    }),

    actions: {
      onSelect: function onSelect(selected, preSelected) {
        if (this.get('readonly')) return false;
        if (this.get('masked')) {
          this.set('value', _.sumBy(selected, function (o) {
            return o.value;
          }));
        }
        var selectAction = this.get('onSelect');
        if (selectAction) {
          selectAction(selected, preSelected);
        }
      }
    }
  });
});
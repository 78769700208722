define('frodo/pods/components/index/making/modal-filter-config/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/base-model', 'frodo/pods/index/making/filter-config/model', 'frodo/models/enums'], function (exports, _component, _baseModel, _model, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    lpTypeProvider: _enums.LpType.LIQUIDITY_PROVIDER,

    init: function init() {
      this._super.apply(this, arguments);
      this.selectBroker();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      //
      var refLps = this.get('model.refLps');var r = Ember.A();
      if (refLps && refLps.values) refLps.values.forEach(function (lp) {
        r.push(lp.v1);
      });
      this.set("refLpsValues", r);

      //
      if (refLps && refLps.values) {
        var lps = this.get('bootstrap').getSelection('broker.lp');
        this.set('refLpsRows', Ember.A());
        refLps.values.forEach(function (lp) {
          var found = _.find(lps, function (item) {
            return item.value === lp.v1;
          });
          _this.get('refLpsRows').pushObject(_baseModel.default.create({
            lpId: lp.v1,
            lp: found ? found.name : "-",
            weight: lp.v2
          }));
        });
      }
    },


    refLpsOptions: Ember.computed('model', 'changeset.brokerId', function () {
      var brokerId = this.get('changeset.brokerId');if (!brokerId) return "";
      var lps = this.get('bootstrap').getSelection('broker.lp');
      var lpType = this.lpTypeProvider;
      return _.filter(lps, function (o) {
        return brokerId == o.extra.$group && o.extra.$group2 == lpType;
      });
    }),

    isDisabled: Ember.computed('readonly', 'preview', function () {
      return this.get('readonly') || this.get('preview');
    }),

    actions: {
      onSelectBroker: function onSelectBroker() {
        this.send('brokerSelected');
        this.removeAllRefLpsRows();
      },
      check: function check(validation) {
        if (Ember.isEmpty(validation)) {
          var model = this.get('model');
          var changeset = this.get('changeset');
          changeset.set('refLps', this.toJson(this.refLpsRows));
          return (0, _model.check)(this, this.copy(model, changeset));
        }
      },
      submit: function submit() {
        var model = this.get('model');
        var changeset = this.get('changeset');
        changeset.set('refLps', this.toJson(this.refLpsRows));
        var obj = this.copy(model, changeset);
        if (this.get('isCreate')) {
          return (0, _model.add)(this, obj);
        } else {
          return (0, _model.edit)(this, obj);
        }
      },
      onSelectLps: function onSelectLps(selected) {
        this.addRefLpsRows(selected);
      },
      removeRefLpsRows: function removeRefLpsRows(selected) {
        var _this2 = this;

        if (this.get('isDisabled')) return;
        var found = this.get('refLpsRows').find(function (p) {
          return p.lpId === selected.lpId;
        });
        if (found) this.get('refLpsRows').removeObject(found);
        //
        this.set("refLpsValues", Ember.A());
        this.get('refLpsRows').forEach(function (lp) {
          _this2.get("refLpsValues").push(lp.lpId);
        });
      }
    },
    toJson: function toJson(rows) {
      var r = Ember.A();
      if (rows) {
        rows.forEach(function (row) {
          r.push(_baseModel.default.create({ "v1": parseInt(row.lpId), "v2": parseInt(row.weight) }));
        });
      }
      return JSON.stringify({ values: r });
    },
    addRefLpsRows: function addRefLpsRows(refLps) {
      var _this3 = this;

      this.set("refLpsRows", Ember.A());
      refLps.forEach(function (item) {
        _this3.get('refLpsRows').pushObject(_baseModel.default.create({
          lpId: item.value,
          lp: item.name,
          weight: ""
        }));
      });
    },
    removeAllRefLpsRows: function removeAllRefLpsRows() {
      this.set("refLpsRows", Ember.A());
    }
  });
});
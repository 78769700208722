define('frodo/pods/index/making/target-price-config/model', ['exports', 'frodo/pods/index/making/making-model', 'frodo/pods/index/base-model'], function (exports, _makingModel, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.one = exports.simulate = exports.del = exports.edit = exports.add = exports.check = exports.fetch = undefined;
  exports.default = _makingModel.default.extend({});


  function fetch(owner, p) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/targetPriceConfigs', { data: p });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/targetPriceConfig/validate', { data: data });
  }

  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _makingModel.PARENT + '/targetPriceConfig', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/targetPriceConfig/' + data.id, { data: data });
  }

  function one(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/targetPriceConfig/get', { data: data });
  }

  function del(owner, data) {
    return owner.get('ajax').delete(_baseModel.ROOT + '/' + _makingModel.PARENT + '/targetPriceConfig/' + data.id + '/' + data.version);
  }

  function simulate(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/targetPriceConfig/simulate', { data: data });
  }

  exports.fetch = fetch;
  exports.check = check;
  exports.add = add;
  exports.edit = edit;
  exports.del = del;
  exports.simulate = simulate;
  exports.one = one;
});
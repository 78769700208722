define('frodo/pods/components/common/field-datetime/component', ['exports', 'frodo/pods/components/common/field-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    datePickable: true,
    timePickable: true,
    timePattern: 'HH:mm:ss',
    datePattern: 'YYYY/MM/DD',
    hasHelp: false,
    helpContent: ""
  });
});
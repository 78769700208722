define('frodo/pods/components/index/pricing/modal-dealing-setting/component', ['exports', 'frodo/pods/components/index/pricing/modal-dealing-setting/template', 'frodo/pods/components/common/modal-base/component', 'frodo/mixins/service-bus'], function (exports, _template, _component, _serviceBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_serviceBus.default, {
    classNames: ['modal', 'modal-dealing-panel-setting'],
    layout: _template.default,
    titleKey: 'pricing.dealing_panel.setting',
    actions: {
      back: function back() {},
      submit: function submit() {
        var _this = this;

        var model = this.get('model');
        this.get('dialogManager').saveSetting(model);
        // this.get('parent').trigger('settingsChange');
        Ember.run.later(this, function () {
          _this.send('close');
        }, 200);
      }
    }
  });
});
define('frodo/pods/index/market/symbol/route', ['exports', 'frodo/pods/index/market/market-route'], function (exports, _marketRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketRoute.default.extend({
    preload: true,
    ignoreResumeModel: true,
    ignoreResumeParam: true
  });
});
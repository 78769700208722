define("frodo/pods/index/market/it-session/route", ["exports", "frodo/pods/index/market/market-route"], function (exports, _marketRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketRoute.default.extend({
    ignoreResumeModel: true,
    ignoreResumeParam: true
  });
});
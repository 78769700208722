define('frodo/helpers/capitalize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.capitalize = capitalize;
  function capitalize(params /*, hash*/) {
    var v = params[0];
    return v ? v.capitalize() : '-';
  }

  exports.default = Ember.Helper.helper(capitalize);
});
define('frodo/pods/components/index/pricing/modal-dealing-panel/component', ['exports', 'frodo/pods/components/common/dialog-base/component', 'frodo/pods/components/index/pricing/modal-dealing-panel/template', 'frodo/models/enums', 'frodo/mixins/draggable', 'frodo/mixins/popup-window', 'frodo/config/environment'], function (exports, _component, _template, _enums, _draggable, _popupWindow, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_draggable.default, _popupWindow.default, {

    classNames: ['modal-dealing-panel'],

    layout: _template.default,

    menuKey: 'Dealing Order',

    customizeTitle: 'pricing.dealing_panel.self',

    payload: Ember.computed.alias('model'), // for save state

    settings: null,

    state: _enums.PanelState.OPEN,

    popupUrl: Ember.computed(function () {
      return _environment.default.rootURL + '#/popup/dealing_panel';
    }),

    popHeight: 650,

    panelType: Ember.computed('model.panelType', function () {
      return this.get('model.panelType') ? this.get('model.panelType') : _enums.PanelType.DEALING;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().on('resizeHeight', function (e, height) {
        this.set('popHeight', height + 30);
      }.bind(this));
    },
    remove: function remove() {},
    hidenAction: function hidenAction() {
      this.set('state', _enums.PanelState.CLOSE);
    },


    actions: {
      openPopup: function openPopup() {
        var _this = this;

        var context = this.get('dialogManager').getDialogState(this);
        var params = JSON.stringify(context);
        var popHeight = this.get('popHeight');

        this.popupWindow({
          queryPath: this.get('popupUrl'),
          windowParams: params,
          width: 600,
          height: popHeight // innerHeight
        }).then(function () {
          Ember.run.schedule('render', function () {
            _this.send('close');
          });
        });
      }
    }

  });
});
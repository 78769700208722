define('frodo/helpers/amount', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.amount = amount;
  function amount(params, hash) {
    var v = params[0];
    var colorful = hash && hash.colorful ? true : false;
    var displayHyphen = hash && hash.displayHyphen ? true : false;
    var displayZero = hash && hash.displayZero ? true : false;
    var plain = hash && hash.plain ? true : false;
    var scale = hash && hash.scale ? hash.scale : 12;

    if (isNaN(v)) {
      return '-';
    }
    var n = parseFloat(v);
    if (n === 0) {
      if (displayZero) {
        return n;
      }
      return displayHyphen ? '-' : n;
    }

    if (plain) {
      var r = new BigNumber(n).toFixed(scale, BigNumber.ROUND_DOWN).toString();
      var pointIndex = r.indexOf('.');
      var a = [];
      if (pointIndex > 0) {
        var t = false;
        for (var i = r.length - 1; i >= 0; i--) {
          var c = r.charAt(i);
          if (t) {
            a.push(c);
          } else {
            if (c === '.') {
              t = true;
              a.push(c);
            } else if (c !== '0') {
              t = true;
              a.push(c);
            }
          }
        }
      }
      return _.join(a.reverse(), '');
    }

    if (colorful && n < 0) {
      n = Ember.String.htmlSafe('<span class="text-danger">' + n + '</span>');
    }

    return n;
  }

  exports.default = Ember.Helper.helper(amount);
});
define('frodo/pods/index/making/mass-order-history/controller', ['exports', 'frodo/pods/index/making/making-controller', 'frodo/mixins/paginatable', 'frodo/pods/index/making/mass-order-history/model', 'frodo/models/enums'], function (exports, _makingController, _paginatable, _model, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingController.default.extend(_paginatable.default, {
    lpType: _enums.LpType.LIQUIDITY_DEMANDER,
    actions: {
      cancel: function cancel(o) {
        var _this = this;

        var self = this;
        var id = o.get('id'),
            i18n = this.get('i18n'),
            flash = this.get('flashMessages');
        (0, _model.cancel)(this, { id: id }).then(function (r) {
          if (_this.isData(r)) {
            var remote = _model.default.create(r.data);
            self.copyProperties(o, remote);
          } else if (_this.isSuccess(r)) {
            flash.success(i18n.t('label.notification.cancelled', 'Hedge Order'));
          } else {
            if (r && r.key) {
              flash.danger(i18n.t('' + r.key));
            }
          }
        });
      },
      query: function query(o) {
        var _this2 = this;

        var self = this;
        var i18n = this.get('i18n');
        var flash = this.get('flashMessages');
        var id = o.get('id');
        o.set('hasVerified', true);
        (0, _model.check)(this, { id: id }).then(function (r) {
          if (_this2.isData(r)) {
            var remote = _model.default.create(r.data);
            self.copyProperties(o, remote);
            o.set('hasVerified', false);
          } else if (_this2.isSuccess(r)) {
            flash.success(i18n.t('label.notification.queried', 'Hedge Order'));
          } else {
            if (r && r.key) {
              flash.danger(i18n.t('' + r.key));
            }
          }
        });
      }
    },
    doSearch: function doSearch(params) {
      var _this3 = this;

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = r.data.total;
        var records = _.map(r.data.records, function (parent) {
          return _model.default.create(parent);
        });
        if (_this3.hasRecords(r)) {
          _this3.set('total', total);
          _this3.set('records', records);
        }
        _this3.updateNavtab(_this3.get('currentRouteName'), { total: total, records: records }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('brokerId')) p.brokerId = this.get('brokerId');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('bkSymbolId')) p.bkSymbolId = this.get('bkSymbolId');
      if (this.get('status')) p.status = this.get('status');
      if (this.get('type')) p.type = this.get('type');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('to')) p.to = this.get('to');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
      this.set('status', p.hasOwnProperty('status') ? p.status : 0);
      this.set('type', p.hasOwnProperty('type') ? p.type : 0);
      this.set('symbolId', p.hasOwnProperty('symbolId') ? p.symbolId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    }
  });
});
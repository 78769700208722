define("frodo/pods/index/making/making-config/controller", ["exports", "frodo/pods/index/making/making-controller", "frodo/pods/index/making/making-config/validator", "frodo/pods/index/making/making-config/model", "frodo/models/enums", "frodo/mixins/downloadable"], function (exports, _makingController, _validator, _model, _enums, _downloadable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingController.default.extend(_downloadable.default, {
    Validator: _validator.default,
    record: null,
    isSearching: true,
    lpType: _enums.LpType.LIQUIDITY_DEMANDER,
    actions: {
      reload: function reload() {
        var _this = this;

        var p = this.build({});
        this.set('csvParams', Ember.assign({}, p));
        (0, _model.fetch)(this, p).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              s.restate = false;
              return _model.default.create(s);
            });
            _this.set('records', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, p);
        });
      },
      reset: function reset(record, force) {
        var lpSymbolId = record.id;
        return (0, _model.reset)(this, { lpSymbolId: lpSymbolId, force: force });
      },
      start: function start(record) {
        var lpSymbolId = record.id;
        return (0, _model.start)(this, { lpSymbolId: lpSymbolId });
      },
      pause: function pause(record) {
        var lpSymbolId = record.id;
        return (0, _model.pause)(this, { lpSymbolId: lpSymbolId });
      },
      stop: function stop(record, aggressive) {
        var lpSymbolId = record.id;
        return (0, _model.stop)(this, { lpSymbolId: lpSymbolId, aggressive: aggressive });
      },
      openAdd: function openAdd() {
        this.set('record', _model.default.create({}));
      },
      remove: function remove(o) {
        return (0, _model.del)(this, o);
      }
    },

    downloadUrl: function downloadUrl() {
      return (0, _model.csv)(this.get('csvParams'));
    },
    build: function build(p) {
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('bkSymbolId')) p.bkSymbolId = this.get('bkSymbolId');
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
      this.set('bkSymbolId', p.hasOwnProperty('bkSymbolId') ? p.bkSymbolId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('records', model);
    }
  });
});
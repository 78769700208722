define('frodo/pods/components/nav/nav-header/component', ['exports', 'frodo/mixins/result', 'frodo/models/enums'], function (exports, _result, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_result.default, {
    i18n: Ember.inject.service(),
    utility: Ember.inject.service(),
    bootstrap: Ember.inject.service(),
    tagName: 'ul',
    classNames: ['navbar-nav', 'ml-auto'],

    currentLabel: Ember.computed('bootstrap.profile', function () {
      var language = this.get('bootstrap.profile').language;
      return this.get('i18n').t('label.common.language.' + (0, _enums.parse)(_enums.Language_Translation, language));
    }),

    currentValue: Ember.computed('bootstrap.profile', function () {
      return this.get('bootstrap.profile').language;
    }),

    languages: Ember.computed('bootstrap.enums.Language', function () {
      var ss = this.get('bootstrap.enums.Language');
      var filtered = _.filter(ss, function (e) {
        return e.name !== 'enums.Language.JA';
      });

      return _.map(filtered, function (e) {
        var key = 'label.common.language.' + (0, _enums.parse)(_enums.Language_Translation, e.value);
        return { name: key, value: e.value };
      });
    }),

    actions: {
      toggleNavMenu: function toggleNavMenu(e) {
        e.preventDefault();
        Ember.$("body").toggleClass("sidenav-toggled");
        Ember.$(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
        Ember.$(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("show");
      },
      changeLang: function changeLang(v) {
        var self = this;
        this.get('utility').changeLanguage(v).then(function (r) {
          if (self.isSuccess(r)) {
            self.get('utility').closeWindows();
            location.reload();
          }
        });
      }
    }
  });
});
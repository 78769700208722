define('frodo/pods/index/trades/hedge-execution-history/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/trades/trades-model'], function (exports, _baseModel, _tradesModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.csv = exports.edit = exports.add = exports.check = exports.fetch = undefined;
  exports.default = _tradesModel.default.extend({});


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_execution_history', { data: data });
  }

  function check(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_execution_history/validate', { data: data });
  }

  function add(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_execution_add', { data: data });
  }

  function edit(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_execution_history/' + data.id, { data: data });
  }

  function csv(p) {
    return _baseModel.NAMESPACE + '/' + _baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_execution_history/csv?' + $.param(p);
  }

  exports.fetch = fetch;
  exports.check = check;
  exports.add = add;
  exports.edit = edit;
  exports.csv = csv;
});
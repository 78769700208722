define('frodo/pods/index/making/dealing-making-statement/route', ['exports', 'frodo/pods/index/making/making-route'], function (exports, _makingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingRoute.default.extend({
    lpWithAllOption: false,
    lpOptionSwitchFirst: true,
    profitCurrencyWithAllOption: false,
    profitCurrencyOptionSwitchFirst: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('isResumed')) {//
      }
    },
    resetController: function resetController(controller, isExisting) {
      this._super.apply(this, arguments);
      if (this.taskId) {
        this.get('scheduler').stop(this.taskId);
      }
      if (isExisting) {
        controller.resetStatements();
      }
    },
    beforePreload: function beforePreload(controller /* , model */) {
      if (!controller.get('isResumed')) {
        controller._reactSingleLp();
        controller._reactProfitCurrency();
      }
    }
  });
});
define('frodo/pods/index/market/lp/controller', ['exports', 'frodo/pods/index/market/lp/validator', 'frodo/pods/index/market/lp/model', 'frodo/pods/index/market/market-controller'], function (exports, _validator, _model, _marketController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _marketController.default.extend({
    sortable: true,
    Validator: _validator.default,
    actions: {
      reload: function reload() {
        var _this = this;

        var p = { brokerId: this.get('brokerId') };
        (0, _model.fetch)(this, p).then(function (r) {
          if (_this.isData(r)) {
            var model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('lps', model);
            _this.updateNavtab(_this.get('currentRouteName'), model, p);
          }
        });
      },
      openAdd: function openAdd() {
        this.set('record', _model.default.create({}));
      },
      symbols: function symbols(lp) {
        this.get('router').transitionTo('index.market.lp_symbol', lp.id);
      },
      accounts: function accounts(lp) {
        this.get('router').transitionTo('index.market.lp_account', lp.id);
      }
    },
    resumeParam: function resumeParam(p) {
      this.set('brokerId', p.brokerId);
    },
    resumeModel: function resumeModel(model) {
      this.set('lps', model);
    }
  });
});
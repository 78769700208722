define('frodo/pods/index/making/mass-execution-history/route', ['exports', 'frodo/pods/index/making/making-route'], function (exports, _makingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _makingRoute.default.extend({
    preload: false,
    lpWithAllOption: false,
    lpOptionSwitchFirst: true,
    symbolWithAllOption: true,
    symbolOptionSwitchFirst: false,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('isResumed')) {
        controller.send('brokerSelected');
      }
    }
  });
});
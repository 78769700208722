define('frodo/pods/components/nav/nav-menu/model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NavMenu = Ember.Object.extend({
    name: null,
    isShow: false,
    isRoutable: true,

    hash: Ember.computed('name', function () {
      return '#' + this.get('name');
    }),

    canRead: Ember.computed('readable', function () {
      return this.get('readable');
    }),

    canWrite: Ember.computed('readable', 'writeable', function () {
      return this.get('readable') && this.get('writeable');
    })
  });

  NavMenu.reopenClass({
    parse: function parse(raw, registeredRoutes) {
      var root = Ember.A();

      raw.forEach(function (r) {
        var subs = Ember.A();
        var parent = NavMenu.create(r);
        r.subs.forEach(function (sub) {
          if (_.includes(registeredRoutes, 'index.' + sub.name)) {
            subs.pushObject(NavMenu.create(sub));
          }
        });

        parent.subs = subs;
        root.pushObject(parent);
      });

      return root;
    }
  });

  exports.default = NavMenu;
});
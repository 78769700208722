define('frodo/pods/index/system/alert-guard/controller', ['exports', 'frodo/pods/index/system/alert-guard/model', 'frodo/pods/index/system/system-controller', 'frodo/pods/index/system/alert-guard/validator'], function (exports, _model, _systemController, _validator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemController.default.extend({
    Validator: _validator.default,
    parentRoot: "system.alert",

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          if (_this.isData(r)) {
            _this.set('model', _.map(r.data, function (s) {
              return _model.default.create(s);
            }));
          }
        });
      },
      remove: function remove(o) {
        return (0, _model.del)(this, o);
      }
    }
  });
});
define('frodo/pods/index/report/holding-balance-flow/controller', ['exports', 'frodo/pods/index/report/holding-balance-flow/model', 'frodo/pods/index/report/report-controller', 'frodo/mixins/paginatable'], function (exports, _model, _reportController, _paginatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportController.default.extend(_paginatable.default, {
    paginatable: false,

    doSearch: function doSearch() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var title = void 0,
            records = void 0,
            cls = void 0;
        if (_this.isData(r)) {
          cls = r.data.currency;
          records = _.map(r.data.balanceInfo, function (b) {
            return _model.default.create(b);
          });
          title = _this.getTitle();
          _this.set('title', title);
          _this.set('columns', cls);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { records: records, columns: cls, title: title }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('to')) p.to = this.get('to');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      return p;
    },
    getTitle: function getTitle() {
      var lpId = this.get('lpId');
      return this.get('bootstrap').getSelectionNameDash('broker.lp', lpId);
    },
    resumeParam: function resumeParam(p) {
      this.set('to', p.to);
      this.set('from', p.from);
      this.set('lpId', p.lpId);
      this.set('brokerId', p.brokerId);
    },
    resumeModel: function resumeModel(model) {
      this.set('title', model.title);
      this.set('records', model.records);
      this.set('columns', model.columns);
    }
  });
});
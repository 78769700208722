define('frodo/pods/components/common/field-json-simple-values/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    bootstrap: Ember.inject.service(),

    tagName: 'div',
    classNameBindings: ['hasFormGroup:form-group', 'inline:row'],

    inline: true,
    preview: false,
    readonly: false,
    withLabel: true,
    editDisable: false,
    hasFormGroup: true,
    keepBlank: false,
    trimZero: false,

    unit: null,
    model: null,
    maxlength: null,
    fieldName: null,
    fieldLabel: null,
    valueAlign: 'left',
    labelAlign: 'right',
    labelOffset: 1,
    contentOffset: 0,
    labelWidthRatio: 4,
    contentWidthRatio: 7,

    placeholder: Ember.computed('placeholderTranslation', function () {
      return this.get('placeholderTranslation') ? this.get('i18n').t(this.get('placeholderTranslation')) : '';
    }),

    didInsertElement: function didInsertElement() {
      if (!this.get('keepBlank')) {
        if (this.get('trimZero')) {
          var t = this.get('value');
          console.log('ssss:' + t + ' , fieldname:' + this.get('fieldName'));

          if (!isNaN(t)) {
            this.set('value', t.toString().replace(/(?:\.0*|(\.\d+?)0+)$/, '$1'));
          }
        }
      }
    },


    isEdit: Ember.computed('model', function () {
      return this.get('model.id') > 0;
    }),

    isCreate: Ember.computed('model', function () {
      return !this.get('isEdit');
    }),

    isDisabled: Ember.computed('readonly', 'isEdit', 'preview', 'editDisable', function () {
      if (this.get('readonly') || this.get('preview')) return true;
      return this.get('isEdit') && this.get('editDisable');
    }),

    labelWidthClass: Ember.computed('labelWidthRatio', function () {
      var ratio = this.get('labelWidthRatio');
      return ratio ? 'col-sm-' + ratio : '';
    }),

    labelOffsetClass: Ember.computed('labelOffset', function () {
      var offset = this.get('labelOffset');
      return offset ? 'offset-sm-' + offset : '';
    }),

    contentWidthClass: Ember.computed('contentWidthRatio', function () {
      var ratio = this.get('contentWidthRatio');
      return ratio ? 'col-sm-' + ratio : '';
    }),

    contentOffsetClass: Ember.computed('contentOffset', function () {
      var offset = this.get('contentOffset');
      return offset ? 'offset-sm-' + offset : '';
    }),

    valueAlignClass: Ember.computed('valueAlign', function () {
      return this._align(this.get('valueAlign'));
    }),

    labelAlignClass: Ember.computed('labelAlign', function () {
      return this._align(this.get('labelAlign'));
    }),

    focusOut: function focusOut() /*e*/{
      if (!this.get('parseToNum')) {
        return;
      }
      var val = this.get('value');
      if (isNaN(val) || isNaN(parseInt(val))) {
        return;
      }
      var real = parseFloat(val);
      this.set('value', real);
      this.$('input').val(real);
    },
    _align: function _align(direction) {
      switch (direction) {
        case 'left':
          return 'text-left';
        case 'right':
          return 'text-right';
        case 'center':
          return 'text-center';
        default:
          return 'text-center';
      }
    }
  });
});
define('frodo/pods/components/common/trading-date-picker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FROM = 1970;
  var TO = 2100;

  exports.default = Ember.Component.extend({

    value: '19700101',
    disabled: false,
    dateFormat: 'YYYY-MM-DD',
    valueFormat: 'YYYYMMDD',

    classNames: ['input-group', 'date'],

    bootstrap: Ember.inject.service(),
    hasError: Ember.computed.notEmpty('error'),

    didUpdateAttrs: function didUpdateAttrs() {

      var disabled = this.get('disabled');

      if (disabled) {
        this._disable();
      } else {
        this._enable();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var value = this.get('value');
      var disabled = this.get('disabled');
      var $dtp = this.$().datetimepicker(this._options(value)).on('dp.change', this.onChange.bind(this));

      this.set('$dtp', $dtp);

      if (Ember.isNone(value)) {
        $dtp.data('DateTimePicker').date(null);
      }

      if (disabled) {
        this._disable();
      }
    },
    _disable: function _disable() {
      this.$().find('input').attr('readonly', false);
      this.get('$dtp').data('DateTimePicker').disable();
    },
    _enable: function _enable() {
      this.$().find('input').attr('readonly', true);
      this.get('$dtp').data('DateTimePicker').enable();
    },
    onChange: function onChange(e) {
      var pattern = this.get('valueFormat');
      var bootstrap = this.get('bootstrap');
      if (e.date) {
        this.set('value', moment.unix(e.date.unix()).utcOffset(bootstrap.getLocalTimezone()).format(pattern));
      }
    },
    _options: function _options(value) {

      var keyBinds = null;
      var icons = this._icons();
      var ignoreReadonly = true;
      var showTodayButton = true;
      var format = this.get('dateFormat');
      // let daysOfWeekDisabled = [0, 6];
      var disabledDates = this._disabledDates(FROM, TO);

      var r = {
        icons: icons,
        format: format,
        keyBinds: keyBinds,
        disabledDates: disabledDates,
        ignoreReadonly: ignoreReadonly,
        showTodayButton: showTodayButton
        // daysOfWeekDisabled
      };

      if (value) {
        var valueFormat = this.get('valueFormat');
        r.defaultDate = moment(value, valueFormat).format(format);
      }

      return r;
    },
    _icons: function _icons() {
      return {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        time: 'far fa-clock',
        date: 'fas fa-calendar-alt',
        clear: 'fa fa-trash',
        close: 'fa fa-remove',
        today: 'fa fa-arrows-alt'
      };
    },
    _disabledDates: function _disabledDates(start, end) {
      return _.map(_.range(start, end), function (y) {
        return moment('01/01/' + y, 'MM/DD/YYYY');
      });
    }
  });
});
define('frodo/pods/index/system/applications/controller', ['exports', 'frodo/pods/index/system/applications/model', 'frodo/pods/index/system/system-controller'], function (exports, _model, _systemController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemController.default.extend({
    records: null,

    actions: {
      reload: function reload() {
        (0, _model.fetch)(this).then(this.parse.bind(this));
      }
    },

    parse: function parse(r) {
      var total = void 0;
      var model = void 0;
      if (this.isData(r)) {
        total = r.data.length;
        model = _.map(r.data, function (d) {
          return _model.default.create(d);
        });
        this.set('total', total);
        this.set('model', model);
      }
      this.updateNavtab(this.get('currentRouteName'), { total: total, model: model }, {});
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('model', model.model);
    }
  });
});
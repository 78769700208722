define('frodo/pods/index/risk/risk/controller', ['exports', 'frodo/pods/index/risk/risk/model', 'frodo/pods/index/risk/risk/lp-model', 'frodo/pods/index/risk/holding-controller', 'frodo/mixins/popup-window', 'frodo/config/environment', 'frodo/models/enums'], function (exports, _model, _lpModel, _holdingController, _popupWindow, _environment, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.HEDGE_EXECUTION = undefined;
  var HEDGE_EXECUTION = exports.HEDGE_EXECUTION = 'trades.hedge_execution_history';

  exports.default = _holdingController.default.extend(Ember.Evented, _popupWindow.default, {
    popWidth: 650,
    popHeight: 650,
    weightFee: true,
    expandAll: false,

    modalTitle: Ember.computed('menu', function () {
      return this.get('i18n').t('menu.index.trades.hedge_execution_history');
    }),

    weightFeeEnabled: Ember.computed('weightFee', function () {
      return this.get('weightFee');
    }),

    popupUrl: Ember.computed(function () {
      return _environment.default.rootURL + '#/popup/dealing_panel';
    }),

    hasHedgeExecutionRw: Ember.computed(function () {
      return this.get('bootstrap').hasWritePrivilege(HEDGE_EXECUTION);
    }),

    subscription: Ember.computed('brokerId', function () {
      return { broker: this.get('brokerId') };
    }),

    actions: {
      selectPositionSymbol: function selectPositionSymbol(p) {
        var param = {
          brokerId: this.brokerId,
          lpId: 0,
          symbolId: p.symbolId,
          orderPrice: 0,
          volumeScale: 0,
          priceScale: 0,
          orderVolume: 0,
          side: _enums.Side.BUY,
          orderType: _enums.DealingOrderType.LIMIT,
          placeType: _enums.DealingPlaceType.MANUAL_HEDGE
        };
        this.openPopupWindows(param);
      },
      selectLpSymbol: function selectLpSymbol(p) {
        var param = {
          brokerId: this.brokerId,
          lpId: p.lpId,
          symbolId: p.symbolId,
          orderPrice: 0,
          volumeScale: 0,
          priceScale: 0,
          orderVolume: 0,
          side: _enums.Side.BUY,
          orderType: _enums.DealingOrderType.LIMIT,
          placeType: _enums.DealingPlaceType.MANUAL_HEDGE
        };
        this.openPopupWindows(param);
      },
      updatePositions: function updatePositions(response, lps) {
        var self = this;
        if (this.isData(response)) {
          _.forEach(response.data.dealingPosition, function (dp) {
            var rows = self.get('positions');
            var row = rows.findBy('symbolId', dp.symbolId);
            if (row) self.send('settingPositionInfo', row, dp);else self.set('positions', _.concat(rows, self.convert(dp, lps)));
          });
          var interval = response.data.refreshInterval;
          if (interval) {
            self.set('refreshError', false);
            self.set('refreshInterval', interval);
          }
          self.refresh();
        } else {
          self.set('refreshError', true);
        }
      },
      initPositions: function initPositions(response, lps) {
        var self = this;
        if (self.isData(response)) {
          var _self = this;
          var model = _.map(response.data.dealingPosition, function (dp) {
            return _self.convert(dp, lps);
          });
          _self.set('positions', model);

          var interval = response.data.refreshInterval;
          if (interval) {
            _self.set('refreshError', false);
            _self.set('refreshInterval', interval);
          }
          if (this.get('isSubscribed')) {
            this.send('unsubscribe', this.get('prev_subscription'));
          }
          this.send('subscribe', this.get('subscription'));
          this.set('isSubscribed', true);
          _self.refresh();
        } else {
          self.set('refreshError', true);
        }
      },
      settingPositionInfo: function settingPositionInfo(row, data) {
        var self = this;
        if (row) {
          data = this.calculation(data);
          row.set("volume", data.volume);
          row.set("amount", data.amount);
          row.set("avgCost", data.avgCost);
          row.set("netVolume", data.netVolume);
          row.set("netPl", data.netPl);
          row.set("hedgeVolume", data.hedgeVolume);
          row.set("hedgePl", data.hedgePl);
          row.set("dailyVolume", data.dailyVolume);
          row.set("dailyAmount", data.dailyAmount);
          row.set("dailyAvgCost", data.dailyAvgCost);
          row.set("grossAmount", data.grossAmount);
          row.set("grossAvgCost", data.grossAvgCost);
          row.set("mtmAmount", data.mtmAmount);
          row.set("mtmPl", data.mtmPl);
        } else {
          self.set('refreshError', true);
        }
      },
      reload: function reload() {
        var _this = this;

        var posRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var p = this.build(posRefresh);
        if (!(p.brokerId && p.bookId && p.currencyId)) {
          console.warn('brokerId or bookId or currencyId is empty');
          return;
        }
        var lps = _.filter(this.bootstrap.getSelection('broker.lp'), function (o) {
          return p.brokerId == o.extra.$group;
        });
        if (p.refresh) {
          return (0, _model.fetch)(this, p).then(function (r) {
            _this.send('updatePositions', r, lps);
          });
        } else {
          return (0, _model.fetch)(this, p).then(function (r) {
            _this.send('initPositions', r, lps);
            _this.updateNavtab(_this.get('currentRouteName'), {}, p);
          });
        }
      },
      subscribe: function subscribe(payload) {
        var realtime = this.get('realtime');
        if (payload) {
          realtime.subscribe(_enums.WebSocketEvent.LP_QUOTE, payload);
          realtime.on(_enums.WebSocketEvent.LP_QUOTE, this.onLpQuote.bind(this));
          realtime.on(_enums.WebSocketEvent.LP_QUOTE_SUBSCRIBE_RESPONSE, this.onLpQuoteSubscribeResponse.bind(this));
        }
      },
      unsubscribe: function unsubscribe(payload) {
        var realtime = this.get('realtime');
        if (payload) {
          realtime.unsubscribe(_enums.WebSocketEvent.LP_QUOTE, payload);
          realtime.off(_enums.WebSocketEvent.LP_QUOTE);
          realtime.off(_enums.WebSocketEvent.LP_QUOTE_SUBSCRIBE_RESPONSE);
        }
      }
    },
    isNumber: function isNumber(value) {
      var r = false;
      var v = _.toNumber(value);
      if (!isNaN(v) && typeof v === 'number') r = true;
      return r;
    },
    avgCost: function avgCost(amount, volume, scale) {
      if (!this.isNumber(amount) || !this.isNumber(volume) || !this.isNumber(scale)) {
        console.error("must be number,amount: " + amount + " volume:" + volume + " scale:" + scale);
        return "0";
      }
      var round = BigNumber.ROUND_CEIL;
      if (volume < 0) {
        round = BigNumber.ROUND_FLOOR;
      }
      return !new BigNumber(volume).isZero() ? new BigNumber(amount).dividedBy(new BigNumber(volume).multipliedBy(new BigNumber(-1))).toFixed(scale, round) : "-";
    },
    openPopupWindows: function openPopupWindows(v) {
      var _this2 = this;

      var bookId = this.get('bookId');
      var lpIds = v.lpId === 0 ? [] : [v.lpId];
      var brokers = this.get('bootstrap').getSelection("broker");
      var books = this.get('bootstrap').getSelection("broker.book");
      var filter_broker = _.find(brokers, function (s) {
        return s.value === v.brokerId && s.extra.$status == _enums.BrokerStatus.ACTIVE;
      });
      if (!filter_broker) return;
      var filter_book = _.find(books, function (s) {
        return s.value === bookId;
      });
      if (!filter_book) return;
      var params = {
        bookId: bookId,
        bookWithAllOption: false,
        brokerId: v.brokerId,
        lpIds: lpIds,
        symbolId: v.symbolId,
        volumeScale: v.volumeScale,
        priceScale: v.priceScale,
        orderVolume: v.orderVolume,
        hasRW: this.get('hasRW'),
        hasR0: this.get('hasR0'),
        orderType: v.orderType,
        side: v.side,
        placeType: v.placeType,
        timeInForce: _enums.TimeInForce.IOC,
        panelType: _enums.PanelType.DEALING,
        coord: { x: 0, y: 30 }
      };
      this.popupWindow({
        windowParams: params,
        width: this.get('popWidth'),
        height: this.get('popHeight'),
        queryPath: this.get('popupUrl')
      }).then(function (r) {
        _this2.get('dialogManager').pushWindow(r);
      });
    },
    build: function build(posRefresh) {
      var p = { refresh: posRefresh };
      if (this.get('bookId')) p.bookId = this.get('bookId');
      if (this.get('profitCurrencyId')) p.currencyId = this.get('profitCurrencyId');
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('bookId', p.bookId);
      this.set('brokerId', p.brokerId);
      this.set('profitCurrencyId', p.currencyId);
    },
    onLpQuote: function onLpQuote(quotes) {
      var self = this;
      _.forEach(quotes, function (quote) {
        var foundPos = _.find(self.get('positions'), function (position) {
          return position.get('symbolId') === quote.symbolId;
        });
        if (foundPos) {
          var scale = self.getSymbolProfitCurrencyScale(foundPos.get('symbolId'));
          if (isNaN(scale)) {
            console.warn('this is invalid scale ' + scale);
          }
          var foundLp = _.find(foundPos.get('lpPositions'), function (lp) {
            return lp.lpId === quote.lpId;
          });
          if (foundLp) {
            foundLp.set('quoteId', quote.id);
            foundLp.set('priceScale', scale);
            foundLp.set('updateDatetime', quote.time);
            if (self.get('weightFeeEnabled')) {
              if (quote.askPrice) foundLp.set('askPrice', self.addWeightFee(quote.askPrice, quote.weight, _enums.Side.BUY, scale));
              if (quote.bidPrice) foundLp.set('bidPrice', self.addWeightFee(quote.bidPrice, quote.weight, _enums.Side.SELL, scale));
            } else {
              if (quote.askPrice) foundLp.set('askPrice', new BigNumber(quote.askPrice));
              if (quote.bidPrice) foundLp.set('bidPrice', new BigNumber(quote.bidPrice));
            }
          }
        }
      });
    },
    onLpQuoteSubscribeResponse: function onLpQuoteSubscribeResponse() /*response*/{},
    onSwitchBroker: function onSwitchBroker(owner, next, prev) {
      var t = Object.assign({}, owner.get('subscription'), { broker: prev });
      owner.resetPosition();
      owner.set('prev_subscription', t);
    },
    addWeightFee: function addWeightFee(price, weight, side, scale) {
      if (side === _enums.Side.BUY) {
        var raw_price = new BigNumber(price).toFixed(scale, BigNumber.ROUND_UP);
        var weightPrice = new BigNumber(raw_price).dividedBy(new BigNumber(1).minus(weight)).toFixed(scale, BigNumber.ROUND_UP);
        return weightPrice;
      } else if (side === _enums.Side.SELL) {
        var _raw_price = new BigNumber(price).toFixed(scale, BigNumber.ROUND_DOWN);
        var _weightPrice = new BigNumber(_raw_price).multipliedBy(new BigNumber(1).minus(weight)).toFixed(scale, BigNumber.ROUND_DOWN);
        return _weightPrice;
      } else {
        return undefined;
      }
    },
    resetPosition: function resetPosition() {
      this.set('positions', Ember.A());
    },
    convert: function convert(dp, lps) {
      var self = this;
      var lpPositions = _.map(lps, function (lpPosition) {
        lpPosition.lpId = lpPosition.value;
        lpPosition.askPrice = '';
        lpPosition.bidPrice = '';
        return _lpModel.default.create(lpPosition);
      });
      dp.lpPositions = lpPositions;
      dp = this.calculation(dp);
      dp.weightFeeEnabled = self.get('weightFeeEnabled');
      dp.updateDatetime = '';
      return _model.default.create(dp);
    },
    calculation: function calculation(data) {
      var self = this;
      var priceScale = 12,
          volumeScale = 12,
          plPriceScale = 12;
      var bkSymbols = self.get('bootstrap').getSelection('broker.symbol');
      var bkSymbol = self.getBkSymbol(bkSymbols, data.brokerId, data.symbolId);
      if (bkSymbol) {
        priceScale = bkSymbol.extra.tradingPriceScale;
        volumeScale = bkSymbol.extra.tradingVolumeScale;
      }
      var scale = self.getSymbolProfitCurrencyScale(data.symbolId);
      if (scale) {
        plPriceScale = scale;
      }
      data.avgCost = this.avgCost(data.amount, data.volume, priceScale);
      data.dailyAvgCost = this.avgCost(data.dailyAmount, data.dailyVolume, priceScale);
      data.grossAvgCost = this.avgCost(data.grossAmount, data.volume, priceScale);
      data.priceScale = priceScale;
      data.volumeScale = volumeScale;
      data.plPriceScale = plPriceScale;
      return data;
    }
  });
});
define('frodo/mixins/paginatable', ['exports', 'frodo/mixins/result'], function (exports, _result) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_result.default, {

    total: 0,
    pageNo: 1,
    pageSize: 25,
    orderBy: 'ID',
    asc: false,

    searched: false,
    isSearching: false,
    timeDisable: false,
    paginatable: true,

    isEmpty: Ember.computed.empty('records'),
    isNotEmpty: Ember.computed.not('isEmpty'),

    foundRecord: Ember.computed.and('searched', 'isNotEmpty'),
    notFoundRecord: Ember.computed.and('searched', 'isEmpty'),

    onResetPaginate: Ember.on('resetPaginate', function () {
      this.reset();
    }),

    actions: {
      flip: function flip() {
        this.send('search', false);
      },
      search: function search() {
        var _this = this;

        var reset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        if (reset && this.get('paginatable')) {
          this.set('pageNo', 1);
        }
        this.set('records', []);
        this.set('searched', false);
        this.set('isSearching', true);

        this.doSearch(this._buildSearchParams()).then(function (r) {
          _this.set('searched', true);
          _this.set('isSearching', false);
          if (_this.isValidation(r) || _this.isError(r)) {
            var flash = _this.get('flashMessages');
            flash.danger(_this.get('i18n').t(r.key));
          }
        });
      },
      reload: function reload() {
        this.send("search");
      }
    },

    hasRecords: function hasRecords(_ref) {
      var _ref$data = _ref.data,
          data = _ref$data === undefined ? {} : _ref$data;

      return data.total > 0;
    },
    reset: function reset() {
      if (this.get('paginatable')) {
        this.set('total', 0);
        this.set('pageNo', 1);
        this.set('pageSize', 25);
      }
      this.set('records', []);
      this.set('searched', false);
      this.set('isSearching', false);
    },
    _buildSearchParams: function _buildSearchParams() {
      var p = {};
      if (this.get('paginatable')) {
        p.asc = this.get('asc');
        p.orderBy = this.get('orderBy');
        p.pageNo = this.get('pageNo');
        p.pageSize = this.get('pageSize');
      }
      if (!this.get('timeDisable')) {
        p.to = this.get('to');
        p.from = this.get('from');
      }
      return p;
    },
    resumePageableParam: function resumePageableParam(param) {
      if (this.get('paginatable')) {
        this.set('asc', param.asc);
        this.set('pageNo', param.pageNo);
        this.set('orderBy', param.orderBy);
        this.set('pageSize', param.pageSize);
      }
      this.set('searched', true);
      this.set('isSearching', false);
      if (!this.get('timeDisable')) {
        this.set('to', param.to);
        this.set('from', param.from);
      }
    }
  });
});
define('frodo/services/dialog-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KEY__DIALOG_STACK = 'dialog_stack';

  exports.default = Ember.Service.extend({
    cookie: Ember.inject.service(),
    storage: Ember.inject.service(),
    dialogStack: Ember.A(),
    windowStack: Ember.A(),
    startZIndex: 1900,
    settingDefault: Ember.computed(function () {
      return { weightFeeEnabled: true };
    }),

    /**
     * window: object
     */
    pushWindow: function pushWindow(win) {
      var stack = this.get('windowStack');
      stack.pushObject(win);
      return true;
    },
    destroyWindows: function destroyWindows() {
      var windowStack = this.get('windowStack');
      _.each(windowStack, function (w) {
        if (w) w.close();
      });
      windowStack.clear();
    },


    /**
     * dialog: object
     */
    pushDialog: function pushDialog(dialog) {
      var stack = this.get('dialogStack');
      if (stack.length >= this.get('maxDialogCount')) {
        return false;
      }
      var uid = new Date().getTime();
      dialog.uid = uid;
      stack.pushObject(dialog);
      return true;
    },
    popDialog: function popDialog(dialog) {
      var dialogStack = this.get('dialogStack');
      dialogStack.removeObject(dialog);
    },
    clearDialogs: function clearDialogs() {
      var dialogStack = this.get('dialogStack');
      for (var i = dialogStack.length - 1; i >= 0; i--) {
        var dialog = dialogStack[i];
        dialog.send('close');
      }
    },
    makeDialogTop: function makeDialogTop(dialog) {
      var dialogStack = this.get('dialogStack');
      var last = dialogStack.get('lastObject');
      if (last === dialog) return;

      dialogStack.removeObject(dialog);
      dialogStack.pushObject(dialog);
      // change zIndex
      var startZIndex = this.get('startZIndex');
      dialogStack.forEach(function (frame, i) {
        frame.$().css('z-index', startZIndex + i).removeClass('active');
      });
      dialog.$().addClass('active');
    },
    getNewDialogOffset: function getNewDialogOffset() {
      var dialogStack = this.get('dialogStack');
      var found = dialogStack.filterBy('isDragged', false);
      var step = 10;
      return step * found.length;
    },


    // handleStateChange: observer('dialogStack.@each.coord', 'dialogStack.@each.payload', function() {
    //   console.log('dialogStack change');
    //   run.debounce(this, 'saveDialogState', 100, false);
    // }),

    // saveDialogState() {
    //   let storage = this.get('storage');
    //   let dialogStack = this.get('dialogStack');
    //   // coord && settings , so layout need to rename
    //   let list = dialogStack.map((dialog) => {
    //     return dialog.getProperties(['coord', 'payload','uid']);
    //   });
    //
    //   let val = {
    //     list,
    //     referSize: {
    //       width: window.outerWidth,
    //       height: window.outerHeight
    //     },
    //   };
    //
    //   // val.settings = this.get('settings');
    //   storage.setItem(KEY__DIALOG_STACK, val,1);
    //   Ember.debug('dialog-manager: saveDialogState():' + JSON.stringify(val));
    // },

    saveSetting: function saveSetting() {
      var s = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var storage = this.get('storage');
      var list = this.get('dialogStack').map(function (dialog) {
        return dialog.getProperties(['coord', 'payload', 'uid']);
      });
      var val = {
        list: list,
        referSize: {
          width: window.outerWidth,
          height: window.outerHeight
        },
        settings: s
      };
      storage.setItem(KEY__DIALOG_STACK, val, 1);
      Ember.debug('dialog-manager: saveDialogState():' + JSON.stringify(val));
    },
    getSettings: function getSettings() {
      var storage = this.get('storage');
      var item = storage.getItem(KEY__DIALOG_STACK, 1);
      if (!item) {
        item = { referSize: { width: window.outerWidth, height: window.outerHeight }, settings: this.get('settingDefault') };
        storage.setItem(KEY__DIALOG_STACK, item, 1);
        return item.settings;
      }
      if (!item.settings) {
        item.settings = this.get('settingDefault');
        storage.setItem(KEY__DIALOG_STACK, item, 1);
        return item.settings;
      }
      return item.settings;
    },


    //
    // restoreDialogConfig( /*todo:type*/ ) {
    //   return this.isLocked().then((isLocked) => {
    //     if (isLocked) return new Promise.resolve([]);
    //     this.lockDialogConfigs();
    //     let storage = this.get('storage');
    //     let val = storage.getItem(KEY__DIALOG_STACK);
    //     if (!val) return new Promise.resolve([]);
    //
    //     let { list, referSize } = val;
    //     // push dialogStack ?
    //     let [scalex, scaley] = [
    //       (window.outerWidth / referSize.width),
    //       (window.outerHeight / referSize.height),
    //     ];
    //     list = list.map(dialogCfg => {
    //       let coord = dialogCfg.coord;
    //       dialogCfg.coord = {
    //         x: Math.round(coord.x * scalex),
    //         y: Math.round(coord.y * scaley)
    //       };
    //       return dialogCfg;
    //     }).slice(0, this.get('maxDialogCount'));
    //     return new Promise.resolve(list);
    //   });
    //
    // },

    getDialogState: function getDialogState(ctx) {
      var dialogStack = this.get('dialogStack');
      var dialog = _.find(dialogStack, function (dialog) {
        return dialog === ctx;
      });
      if (dialog) {
        return dialog.getProperties(['coord', 'payload', 'uid']);
      }
      return null;
    },
    init: function init() {
      this._super.apply(this, arguments);
    },


    /**
     * lock panel config ,only first window need load
     */
    lockDialogConfigs: function lockDialogConfigs() /*todo type*/{
      var cookie = this.get('cookie');
      var duration = 500; // ms
      function lock() {
        var now = new Date().getTime();
        cookie.setCookie('DEALINGPANEL_LOCK', 1, {
          expires: now + 1000
        });
      }
      lock();
      setInterval(lock, duration);
      this.lockDialogConfigs = function () {
        (false && Ember.warn('dialogManager:lockDialogConfigs() only allow invoke once!'));
      };
    },


    /**
     * after 1s
     */
    isLocked: function isLocked() /*todo type*/{
      var cookie = this.get('cookie');
      var locked = !!cookie.getCookie('DEALINGPANEL_LOCK');
      if (locked) return new Ember.RSVP.Promise.resolve(true);
      return new Ember.RSVP.Promise(function (resolve) {
        var loopCount = 3;
        var i = 0;
        var timer = setInterval(function () {
          var locked = !!cookie.getCookie('DEALINGPANEL_LOCK');
          if (locked || i++ >= loopCount) {
            clearInterval(timer);
            resolve(locked);
          }
        }, 400);
      });
    }
  });
});
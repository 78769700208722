define('frodo/pods/components/common/base-spinner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    disabled: false,
    max: Infinity,
    min: -Infinity,
    value: null,
    step: 1,
    delayUtilRepat: 300,
    repeatInterval: 50,
    enableKeyboard: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('enableKeyboard')) {
        this._registerKeyboardEvent();
      }
    },
    bindUpAction: function bindUpAction($btn) {
      this._repeatButton($btn, 'up');
    },
    bindDownAction: function bindDownAction($btn) {
      this._repeatButton($btn, 'down');
    },
    _registerKeyboardEvent: function _registerKeyboardEvent() {
      var _this = this;

      var $txt = this.$('input[type=text]');
      var KEY_UP = 38,
          KEY_DOWN = 40;
      $txt.on('keydown', function (e) {
        if (e.keyCode === KEY_UP || e.keyCode === KEY_DOWN) {
          // up /down
          e.preventDefault();
          if (e.keyCode === KEY_UP) {
            _this.send('up');
          } else {
            _this.send('down');
          }
        }
      });
    },
    _repeatButton: function _repeatButton($btn, event) {
      var self = this;
      var timer = void 0,
          interval = void 0;
      var isStop = true;
      var updated = false;
      var delayUtilRepat = this.get('delayUtilRepat');
      var repeatInterval = this.get('repeatInterval');
      var startFn = function startFn() {
        if (self.get('disabled')) {
          return;
        }
        isStop = false;
        timer = setTimeout(function () {
          interval = setInterval(function () {
            self.send(event);
            updated = true;
          }, repeatInterval);
        }, delayUtilRepat);
      };

      var endFn = function endFn(e) {
        if (self.get('disabled')) {
          return;
        }
        if (isStop) return;
        isStop = true;
        clearTimeout(timer);
        clearInterval(interval);
        if ((e.type === 'mouseup' || e.type === 'mouseleave') && !updated) {
          self.send(event);
        }
        timer = 0;
        interval = 0;
        updated = false;
      };
      $btn.on('mousedown.spinner', startFn).on('mouseup.spinner mouseleave.spinner', endFn);
    },


    actions: {
      up: function up() {
        //console.log('keyup step:'+this.get('step'));
        var value = parseFloat(this.get('value'));
        var max = this.get('max');
        if (isNaN(value)) {
          value = max;
        } else {
          var step = this.get('step');
          if (value + step > max) {
            value = max;
          } else {
            value += step;
          }
        }
        var real = parseFloat(value).toFixed(this.get('scale'));
        var final = parseFloat(real);
        this.updateValue(final);
        // this.set('value', isNaN(value) ? 0 : parseFloat(value));
      },

      down: function down() {
        //console.log('key down step:'+this.get('step'));
        var value = parseFloat(this.get('value'));
        var min = this.get('min');
        if (isNaN(value)) {
          value = min;
        } else {
          var step = this.get('step');
          if (value - step < min) {
            value = min;
          } else {
            value -= step;
          }
        }
        var real = parseFloat(value).toFixed(this.get('scale'));
        var final = parseFloat(real);
        this.updateValue(final);
        // this.set('value', isNaN(value) ? 0 : parseFloat(value));
      }
    }
  });
});
define('frodo/pods/components/index/pricing/modal-dealing-container/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/pricing/pricing-model'], function (exports, _baseModel, _pricingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.place = exports.fetch = undefined;
  exports.default = _pricingModel.default.extend({});


  function fetch(owner, data) {
    if (data.refresh) {
      return owner.get('ajax').request(_baseModel.ROOT + '/' + _pricingModel.PARENT + '/panel/position/refresh', { data: data });
    } else {
      return owner.get('ajax').request(_baseModel.ROOT + '/' + _pricingModel.PARENT + '/panel/position', { data: data });
    }
  }

  function place(owner, data) {
    return owner.get('ajax').put(_baseModel.ROOT + '/' + _pricingModel.PARENT + '/panel/order', { data: data });
  }

  exports.fetch = fetch;
  exports.place = place;
});
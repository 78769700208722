define('frodo/pods/index/report/holding-balance-daily-change/controller', ['exports', 'frodo/pods/index/report/holding-balance-daily-change/model', 'frodo/pods/index/report/report-controller', 'frodo/mixins/paginatable'], function (exports, _model, _reportController, _paginatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportController.default.extend(_paginatable.default, {
    max_days: 30,
    default_days: 7,
    paginatable: false,
    alertMessage: Ember.computed(function () {
      var i18n = this.get('i18n');
      var value = this.get('max_days');
      var unit = i18n.t('label.common.time_unit.day');
      return i18n.t('error.date.out_of_range', { days: value, unit: unit });
    }),

    doSearch: function doSearch() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      //
      if (this.outOfDays(this.get('from'), this.get('to'))) {
        this.set('invalid', true);
        return new Ember.RSVP.Promise(function (resolve) {
          resolve('');
        });
      }
      //
      this.set('invalid', false);
      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var contents = Ember.A();
        var labels = void 0,
            content = void 0;
        if (_this.isData(r)) {
          labels = _.map(r.data, function (e) {
            return e.date;
          });
          content = _.map(r.data, function (e) {
            return e.amount;
          });
          contents.push({ label: '', data: content });
          _this.set('labels', labels);
          _this.set('records', r.data);
          _this.set('contents', contents);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { records: r.data, labels: labels, contents: contents }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('to')) p.to = this.get('to');
      if (this.get('from')) p.from = this.get('from');
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('currencyId')) p.currencyId = this.get('currencyId');
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      return p;
    },
    getTitle: function getTitle() {
      var lpId = this.get('lpId');
      return this.get('bootstrap').getSelectionNameDash('broker.lp', lpId);
    },
    resumeParam: function resumeParam(p) {
      this.set('to', p.to);
      this.set('from', p.from);
      this.set('brokerId', p.brokerId);
      this.set('currencyId', p.currencyId);
      this.set('lpId', p.hasOwnProperty('lpId') ? p.lpId : 0);
    },
    resumeModel: function resumeModel(model) {
      this.set('labels', model.labels);
      this.set('records', model.records);
      this.set('contents', model.contents);
    },
    outOfDays: function outOfDays(from, to) {
      var t = this.get('max_days') * 86400000;
      return to - from > t;
    }
  });
});
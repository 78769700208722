define('frodo/pods/index/system/alert-guard/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    monitorPolicy: [(0, _validators.validatePresence)(true)],
    appName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 16 })],
    threshold: [(0, _validators.validateLength)({ max: 128 })],
    monitorKey: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 64 })],
    monitorName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 64 })],
    monitorLevel: [(0, _validators.validatePresence)(true)]
  };
});
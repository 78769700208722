define('frodo/pods/components/common/field-multiselect/component', ['exports', 'frodo/pods/components/common/field-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({

    type: 'bitMask',
    onSelect: null,
    invalidBtnClz: 'multiselect-btn-danger',

    validations: Ember.computed('error', function () {
      var error = this.get('error');
      this._setBtnClz(error);
      return error ? error.validation : [];
    }),

    selectedMasks: Ember.computed('value', function () {
      var value = this.get('value');
      var options = this.get('options');
      return this._getSelectedMasks(value, options);
    }),

    actions: {
      select: function select(isSelected, selectedValue) {
        // if the component type is 'bit mask'
        var value = this.get('value');
        var options = this.get('options');
        var masks = this._getSelectedMasks(value, options);

        var sv = parseInt(selectedValue);
        if (isSelected) {
          masks.push(sv);
        } else {
          _.remove(masks, function (v) {
            return v === sv;
          });
        }

        masks = _.compact(masks);
        this.set('value', this._setSelectedValue(masks));

        //
        var onSelect = this.get('onSelect');
        if (onSelect) {
          onSelect(isSelected, value);
        }
      }
    },

    _setSelectedValue: function _setSelectedValue(masks) {
      var r = 0;
      masks.forEach(function (v) {
        r += v;
      });
      return r;
    },
    _getSelectedMasks: function _getSelectedMasks(value, options) {
      var r = [];
      options.forEach(function (o) {
        if (parseInt(value & o.value) !== 0) {
          r.push(o.value);
        }
      });
      return r;
    },
    _setBtnClz: function _setBtnClz(error) {
      var clz = this.get('invalidBtnClz');
      var target = this.$().find('button');
      if (Ember.isEmpty(error) && target.hasClass(clz)) {
        target.removeClass(clz);
      } else {
        target.addClass(clz);
      }
    }
  });
});
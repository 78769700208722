define('frodo/mixins/result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DATA = exports.DATA = 'DATA';
  var ERROR = exports.ERROR = 'ERROR';
  var MESSAGE = exports.MESSAGE = 'MESSAGE';
  var SUCCESS = exports.SUCCESS = 'success';
  var VALIDATION = exports.VALIDATION = 'VALIDATION';

  var keys = Object.keys;
  exports.default = Ember.Mixin.create({
    i18n: Ember.inject.service(),

    isData: function isData(r) {
      return !Ember.isNone(r) && r.hasOwnProperty('data') && r.type === DATA;
    },
    isError: function isError(r) {
      return !Ember.isNone(r) && r.hasOwnProperty('key') && r.level === ERROR;
    },
    isMessage: function isMessage(r) {
      return !Ember.isNone(r) && r.hasOwnProperty('key') && r.type === MESSAGE;
    },
    isSuccess: function isSuccess(r) {
      return !Ember.isNone(r) && r.hasOwnProperty('key') && r.key === SUCCESS;
    },
    isValidation: function isValidation(r) {
      return !Ember.isNone(r) && r.hasOwnProperty('data') && r.type === VALIDATION;
    },
    merge: function merge(result) {
      var _this = this;

      if (result) {
        var r = {};
        var error = result.data;
        keys(error).forEach(function (k) {
          r[k] = { validation: [_this.i18n.t(error[k].key)] };
        });
        return r;
      }
    }
  });
});
define('frodo/pods/index/report/holding-deposit-withdraw/controller', ['exports', 'frodo/pods/index/report/holding-deposit-withdraw/model', 'frodo/pods/index/report/report-controller', 'frodo/mixins/paginatable'], function (exports, _model, _reportController, _paginatable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reportController.default.extend(_paginatable.default, {
    doSearch: function doSearch() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var p = this.build(params);
      return (0, _model.fetch)(this, p).then(function (r) {
        var total = void 0,
            records = void 0;
        if (_this.hasRecords(r)) {
          total = r.data.total;
          records = _.map(r.data.records, function (b) {
            return _model.default.create(b);
          });
          _this.set('total', total);
          _this.set('records', records);
        }
        _this.updateNavtab(_this.get('currentRouteName'), { total: total, records: records }, p);
        return r;
      });
    },
    build: function build(p) {
      if (this.get('lpId')) p.lpId = this.get('lpId');
      if (this.get('currencyId')) p.currencyId = this.get('currencyId');
      if (this.get('authorisedBrokerId')) p.brokerId = this.get('authorisedBrokerId');
      return p;
    },
    resumeParam: function resumeParam(p) {
      this.set('lpId', p.lpId);
      this.set('brokerId', p.brokerId);
      this.set('currencyId', p.currencyId);
    },
    resumeModel: function resumeModel(model) {
      this.set('total', model.total);
      this.set('records', model.records);
    },

    actions: {
      openAdd: function openAdd() {
        this.set('record', _model.default.create({}));
      }
    }
  });
});
define('frodo/pods/index/system/config/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/system/system-model'], function (exports, _baseModel, _systemModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.check = exports.edit = exports.reset = exports.fetch = undefined;
  exports.default = _systemModel.default.extend({});


  function fetch(owner, domain) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/config/' + domain);
  }

  function reset(owner, domain) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/config/' + domain + '/reset');
  }

  function edit(owner, data) {
    var p = { value: data.configValue, version: data.version };
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/config/' + data.domain + '/' + data.id, { data: p });
  }

  function check() {
    return Promise.resolve({ key: 'success' });
  }

  exports.fetch = fetch;
  exports.reset = reset;
  exports.edit = edit;
  exports.check = check;
});
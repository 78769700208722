define('frodo/pods/components/common/select-institution/component', ['exports', 'frodo/pods/components/common/select-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({

    type: 'broker.institution',
    itId: null,
    isManager: false,
    emptyOption: false,
    allowClear: false

  });
});
define('frodo/pods/index/risk/cp-balance/route', ['exports', 'frodo/pods/index/risk/holding-route'], function (exports, _holdingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _holdingRoute.default.extend({
    preload: true,
    lpSelectAll: true,
    lpMultiSelect: true,
    lpWithAllOption: false,
    //
    currencyWithAllOption: false,
    beforePreload: function beforePreload(controller /* , model */) {
      if (!controller.get('isResumed')) {
        var brokerId = this.get('brokerId');
        controller.onSelectBroker(brokerId, true, true, true);
        controller._reactMultiCurrency();
      }
    }
  });
});
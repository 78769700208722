define('frodo/helpers/number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.number = number;
  function number(params, hash) {

    var unit = void 0;
    var v = params[0];
    var pattern = '0,0';
    var replacement = '-';
    if (hash && hash.unit) {
      unit = hash.unit;
    }

    if (hash && hash.pattern) {
      pattern = hash.pattern;
    }

    if (Ember.isNone(v) || isNaN(v) || +v === 0) {
      return replacement;
    }

    if (!(hash && hash.force) && !Ember.isEmpty(v)) {
      v = v.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
      var idx = String(v).indexOf('.');
      if (idx > 0) {
        var x = idx + 1,
            y = String(v).length - x;
        if (y > 0) {
          pattern += '.';for (var i = 0; i < y; i++) {
            pattern += '0';
          }
        }
      }
    }
    var str = numeral(v).format(pattern);
    return unit ? Ember.String.htmlSafe(str + ' <span>' + unit + '</span>') : str;
  }

  exports.default = Ember.Helper.helper(number);
});
define('frodo/pods/index/operator/operator/controller', ['exports', 'frodo/pods/index/operator/operator/validator', 'frodo/pods/index/operator/operator/model', 'frodo/pods/index/operator/role/model', 'frodo/pods/index/operator/operator-controller'], function (exports, _validator, _model, _model2, _operatorController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _operatorController.default.extend({
    Validator: _validator.default,
    actions: {
      reload: function reload() {
        var _this = this;

        var p = void 0;
        if (this.get('isManager')) {
          p = { brokerId: this.get('bootstrap.brokerId') };
        }
        (0, _model.fetch)(this, p).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            model = _.map(r.data, function (s) {
              return _model.default.create(s);
            });
            _this.set('operators', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, p);
        });
      },
      openAdd: function openAdd() {
        var t = this.get('operatorType');
        this.set('record', _model.default.create({ type: t }));
      },
      openEditRole: function openEditRole(o) {
        var _this2 = this;

        (0, _model.roles)(this, o).then(function (r) {
          if (_this2.isData(r)) {
            _this2.set('record', o);
            _this2.set('roles', _.map(r.data, function (s) {
              return _model2.default.create(s);
            }));
            if (Ember.isEmpty(_this2.get('roles'))) {
              _this2.set('isShowEditRoleModal', false);
            }
          }
        });
      },
      resumeParam: function resumeParam(p) {
        if (this.get('isManager')) this.set('brokerId', p.brokerId);
      },
      resumeModel: function resumeModel(model) {
        this.set('operators', model);
      }
    }
  });
});
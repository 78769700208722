define('frodo/pods/components/common/multiple-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: null,
    options: null,
    onSelect: null,
    searchEnable: false,
    readonly: false,
    isInvalid: false,
    maxDisplayed: 2,
    hasSelectAll: true,
    closeOnSelect: false,
    triggerComponent: 'common/multiple-select/display-selected',
    beforeOptionsComponent: 'common/multiple-select/first-option',
    triggerClass: Ember.computed('isInvalid', 'readonly', function () {
      return this.get('isInvalid') ? 'power-multiple-select-invalid' : this.get('readonly') ? 'power-multiple-select-readonly' : '';
    }),

    dropdownClass: Ember.computed('isInvalid', 'readonly', function () {
      return this.get('isInvalid') ? 'power-multiple-select-invalid-dropdown' : this.get('readonly') ? 'power-multiple-select-readonly-dropdown' : '';
    }),

    hasOptions: Ember.computed('options', function () {
      return !Ember.isEmpty(this.get('options'));
    }),

    selected: Ember.computed('value', 'options.@each.value', function () {
      var value = Ember.A(this.get('value'));
      var options = Ember.A(this.get('options'));
      return options.filter(function (option) {
        return value.includes(option.value);
      });
    }),

    actions: {
      onchange: function onchange(selected) {
        if (this.get('readonly')) return false;
        var pre_value = this.get('value');
        this.set('value', selected.getEach('value'));
        var selectAction = this.get('onSelect');
        if (selectAction) {
          selectAction(selected, pre_value);
        }
      }
    }

  });
});
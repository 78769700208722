define('frodo/pods/index/making/mass-order-history/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/making/making-model'], function (exports, _baseModel, _makingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cancel = exports.check = exports.fetch = undefined;
  exports.default = _makingModel.default.extend({
    hasRejectReason: Ember.computed('rejectReason', function () {
      return !Ember.isEmpty(this.get('rejectReason'));
    })
  });


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/massOrderHistory', { data: data });
  }
  function check(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/massOrderHistory/check', { data: data });
  }
  function cancel(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/massOrderHistory/cancel', { data: data });
  }
  exports.fetch = fetch;
  exports.check = check;
  exports.cancel = cancel;
});
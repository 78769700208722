define('frodo/services/clock', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    now: 0,
    bootstrap: Ember.inject.service(),
    scheduler: Ember.inject.service(),
    timePattern: 'HH:mm:ss',
    datePattern: 'YYYY/MM/DD',
    tzOffset: Ember.computed.alias('bootstrap.systemConfig.timeZoneOffset'),

    init: function init() {
      this._super.apply(this, arguments);
      this.taskId = this.get('scheduler').add({
        action: this._tick.bind(this),
        interval: 1000
      });
      this._tick();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      if (this.taskId) {
        this.get('scheduler').stop(this.taskId);
      }
    },
    date: function date() {
      var _getProperties = this.getProperties('now', 'tzOffset', 'datePattern'),
          time = _getProperties.time,
          offset = _getProperties.offset,
          pattern = _getProperties.pattern;

      return moment.unix(time / 1000).utcOffset(offset / 1000 / 3600).format(pattern);
    },
    time: function time() {
      var _getProperties2 = this.getProperties('now', 'tzOffset', 'timePattern'),
          time = _getProperties2.time,
          offset = _getProperties2.offset,
          pattern = _getProperties2.pattern;

      return moment.unix(time / 1000).utcOffset(offset / 1000 / 3600).format(pattern);
    },
    convertToMarketTime: function convertToMarketTime(time) {
      var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'HH:mm:ss';

      var offset = this.get('tzOffset');
      return moment.unix(time / 1000).utcOffset(offset / 1000 / 3600)._format(fmt);
    },
    _tick: function _tick() {
      var _delta = this.get('_delta');
      var localtime = +new Date();
      this.set('now', localtime - _delta);
    },
    _format: function _format(time) {
      var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'HH:mm:ss';

      return moment(time, fmt).format(fmt);
    },


    _delta: Ember.computed('bootstrap.market.time', function () {
      var server = this.get('bootstrap.market.time');

      if (server) {
        server = parseInt(server);
        var local = +new Date();
        return local - server;
      } else {
        return 0;
      }
    })

  });
});
define('frodo/pods/index/operator/operator-model', ['exports', 'frodo/pods/index/base-model'], function (exports, _baseModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PARENT = undefined;


  /**
   *
   */
  var PARENT = exports.PARENT = 'operator';

  var BaseOperatorModel = _baseModel.default.extend({});

  BaseOperatorModel.reopenClass({});

  exports.default = BaseOperatorModel;
});
define('frodo/pods/components/common/help-popover/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    tagName: 'i',
    size: null,
    content: "",
    container: 'body',
    actionName: 'popover',
    popoverClass: '',
    classNameBindings: ['iconSizeClass'],
    attributeBindings: ['actionName:data-toggle'],
    classNames: ['far', 'fa-question-circle', 'text-primary', 'clickable', 'help-popover'],

    iconSizeClass: Ember.computed('size', function () {
      var size = this.get('size');
      return size ? 'fa-' + size : '';
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var content = this.get('content');
      this.$().popover({
        title: '',
        placement: 'auto',
        trigger: 'click',
        html: true,
        content: content ? "" + this.get('i18n').t(content) : "",
        container: this.get('container')
      });

      // this.$().on('hide.bs.popover', function () {
      //   $('div[id*="popover"]').each(function (index,e) {
      //     //e.remove();
      //     console.log(e);
      //   });
      // }.bind(this));

      // hide popover when click outside
      $('body').on('click', function (e) {
        $('[data-toggle=popover]').each(function () {
          // hide any open popovers when the anywhere else in the body is clicked
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
          }
        });
      });
    }
  });
});
define('frodo/pods/components/common/page-navigation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    total: 0,
    pageNo: 1,
    isTop: false,
    pageSize: 25,
    position: 'auto',
    hiddenPageNo: false,
    classNames: ['row'],
    classNameBindings: ['isTop:pagination-top:pagination-bottom'],

    hidden: Ember.computed('totalPage', function () {
      return this.get('totalPage') <= 1;
    }),

    sizerPosition: Ember.computed('isTop', function () {
      return this.get('isTop') ? 'auto' : 'above';
    }),

    totalPage: Ember.computed('pageSize', 'total', function () {
      if (this.get('total') === 0) return 0;
      var total = this.get('total'),
          pageSize = this.get('pageSize'),
          remain = total % pageSize;
      return (total - remain) / pageSize + (remain === 0 ? 0 : 1);
    }),

    startIndex: Ember.computed('pageNo', 'pageSize', 'total', function () {
      var pageNo = this.get('pageNo'),
          pageSize = this.get('pageSize'),
          total = this.get('total');
      return total === 0 ? 0 : (pageNo - 1) * pageSize + 1;
    }),

    endIndex: Ember.computed('pageNo', 'pageSize', 'total', function () {
      var pageNo = this.get('pageNo'),
          pageSize = this.get('pageSize'),
          total = this.get('total'),
          endIndex = pageNo * pageSize;
      return endIndex > total ? total : endIndex;
    })
  });
});
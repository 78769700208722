define('frodo/pods/index/making/source-liquidity-config/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    brokerId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],

    lpId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],

    symbolId: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],

    bidVolumeAdjustRatio: [(0, _validators.validatePresence)(true)],

    askVolumeAdjustRatio: [(0, _validators.validatePresence)(true)],

    bidMaxVolume: [(0, _validators.validatePresence)(true)],

    askMaxVolume: [(0, _validators.validatePresence)(true)],

    priceAdjustType: [(0, _validators.validatePresence)(true)]
  };
});
define('frodo/pods/components/common/field-param/component', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/components/common/field-input/component'], function (exports, _baseModel, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.ranges = this.get('value');

      Ember.run.later(function () {
        _this.set('value', JSON.stringify(_this.get('value')));
      }, 0);
    },

    rangesObserver: Ember.observer('ranges.@each.key', 'ranges.@each.value', function () {
      var r = [],
          ranges = this.get('ranges');
      _.each(ranges, function (o) {
        r.push({ key: o.key, value: o.value });
      });

      this.set('value', JSON.stringify(r));
    }),

    lastIndex: Ember.computed('ranges.[]', function () {
      return this.get('ranges').length - 1;
    }),

    actions: {
      add: function add() {
        var ranges = this.get('ranges');
        ranges.pushObject(_baseModel.default.create({ key: '', value: '' }));
      },
      remove: function remove(range) {
        var ranges = this.get('ranges');
        ranges.removeObject(range);
      }
    }
  });
});
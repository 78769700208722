define('frodo/pods/components/common/modal-dialog/component', ['exports', 'frodo/pods/components/common/modal-base/component', 'frodo/mixins/draggable', 'frodo/mixins/popup-window', 'frodo/config/environment', 'frodo/mixins/result', 'frodo/mixins/service-bus'], function (exports, _component, _draggable, _popupWindow, _environment, _result, _serviceBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_draggable.default, _popupWindow.default, _serviceBus.default, _result.default, {

    classNames: ['modal-dealing-panel'],

    customizeTitle: null,

    payload: Ember.computed.alias('model'), // for save state

    settings: null,

    popupUrl: Ember.computed(function () {
      return _environment.default.baseURL + '#/popup/dealing-panel';
    }),

    popHeight: 630,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().on('resizeHeight', function (e, height) {
        this.set('popHeight', height + 30);
      }.bind(this));
    },


    actions: {
      openPopup: function openPopup() {
        var _this = this;

        var state = this.get('dialogManager').getDialogState(this);
        var params = JSON.stringify(state);
        var popHeight = this.get('popHeight');
        this.popupWindow({
          queryPath: this.get('popupUrl'),
          windowParams: params,
          width: 400,
          height: popHeight // innerHeight
        }).then(function () {
          Ember.run.schedule('render', function () {
            _this.send('close');
          });
        });
      }
    }

  });
});
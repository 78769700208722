define('frodo/pods/components/index/operator/modal-role-privilege/component', ['exports', 'frodo/pods/components/index/operator/modal-role-privilege/model', 'frodo/pods/components/common/modal-base/component', 'frodo/pods/index/operator/role/model', 'frodo/models/enums'], function (exports, _model, _component, _model2, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({

    menuTree: Ember.computed('menus', function () {
      var _this = this;

      return this.get('menus').map(function (menu) {

        var parent = _model.default.create(menu);
        var subs = menu.subs.map(function (sub) {
          var sm = _model.default.create(sub);
          sm.set('parentName', parent.get('name'));
          return sm;
        });

        parent.set('subs', subs);
        parent.set('isParent', true);
        parent.set('isExpanding', false);
        parent.set('readCheckAll', Ember.isNone(_this._find(subs, 'readChecked')));
        parent.set('writeCheckAll', Ember.isNone(_this._find(subs, 'writeChecked')));
        parent.set('readAllDisabled', !Ember.isNone(_this._find(subs, 'readable')) || _this.get('isDisabled'));
        parent.set('writeAllDisabled', !Ember.isNone(_this._find(subs, 'writable')) || _this.get('isDisabled'));

        // determinate
        var size = menu.subs.length;
        var readUnchecked = subs.filter(function (sub) {
          return !sub.get('readChecked');
        });
        var writeUnchecked = subs.filter(function (sub) {
          return !sub.get('writeChecked');
        });

        parent.set('readDeterminate', !Ember.isEmpty(readUnchecked) && size !== readUnchecked.length);
        parent.set('writeDeterminate', !Ember.isEmpty(writeUnchecked) && size !== writeUnchecked.length);

        return parent;
      });
    }),

    apiList: Ember.computed('apis', function () {
      return this.get('apis').map(function (a) {
        return _model.default.create(a);
      });
    }),

    _find: function _find(submenus, field) {
      return _.find(submenus, function (sub) {
        return !sub.get(field);
      });
    },


    statusChange: Ember.observer('model.status', function () {
      Ember.run.once(this, 'triggerChange');
    }),

    triggerChange: function triggerChange() {
      if (this.get('model.status') != _enums.AdminRoleStatus.NORMAL) {
        this.set('readonly', true);
      }
    },


    isDisabled: Ember.computed('readonly', 'isEdit', 'preview', 'editDisable', function () {
      if (this.get('readonly') || this.get('preview')) return true;
      return this.get('isEdit') && this.get('editDisable');
    }),

    actions: {
      apiSelect: function apiSelect(a) {
        Ember.run.later(this, function () {
          a.toggleProperty('allow');
        }, 50);
      },
      selectRead: function selectRead(m) {
        Ember.run.later(this, function () {
          m.toggleProperty('readChecked');
        }, 50);
      },
      selectWrite: function selectWrite(m) {
        Ember.run.later(this, function () {
          m.toggleProperty('writeChecked');
        }, 50);
      },
      selectReadAll: function selectReadAll(o) {
        var _this2 = this;

        Ember.run.later(this, function () {
          var menusProxy = _this2.get('menuTree');
          _.each(menusProxy, function (menu) {
            if (menu.get('name') === o.get('name')) {
              menu.toggleProperty('readCheckAll');
              menu.set('readDeterminate', false);
              var isChecked = menu.get('readCheckAll');
              if (!isChecked) menu.set('writeCheckAll', false);
              _.each(menu.subs, function (sub) {
                sub.set('readChecked', isChecked);
                if (!isChecked) sub.set('writeChecked', false);
              });
            }
          });
        }, 50);
      },
      selectWriteAll: function selectWriteAll(o) {
        var _this3 = this;

        Ember.run.later(this, function () {
          var menusProxy = _this3.get('menuTree');
          _.each(menusProxy, function (menu) {
            if (menu.get('name') === o.get('name')) {
              menu.toggleProperty('writeCheckAll');
              menu.set('readDeterminate', false);
              menu.set('writeDeterminate', false);
              var isChecked = menu.get('writeCheckAll');
              if (isChecked) {
                menu.set('readCheckAll', isChecked);
              }
              _.each(menu.subs, function (sub) {
                if (isChecked) {
                  sub.set('readChecked', isChecked);
                  sub.set('writeChecked', isChecked);
                } else {
                  sub.set('writeChecked', isChecked);
                }
              });
            }
          });
        }, 50);
      },
      check: function check() {
        return { "args": [], "key": "success", "level": "INFO", "type": "SUCCESS" };
      },
      submit: function submit() {
        var model = this.get('model');
        var privileges = [],
            menus = this.get('menuTree'),
            apis = this.get('apiList');
        _.each(menus, function (menu) {
          _.each(menu.subs, function (sub) {
            if (sub.get('readChecked')) {
              privileges.push(sub.get('readPrivilege'));
            }

            if (sub.get('writeChecked')) {
              privileges.push(sub.get('writePrivilege'));
            }
          });
        });
        _.each(apis, function (api) {
          if (api.get('allow')) {
            privileges.push(api.get('id'));
          }
        });
        return (0, _model2.grant)(this, model, privileges);
      }
    }
  });
});
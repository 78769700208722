define('frodo/helpers/dow', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    i18n: Ember.inject.service(),

    compute: function compute(params /*, hash*/) {
      var v = params[0];
      if (!v) return '-';
      return this.get('i18n').t('label.common.dow.' + v);
    }
  });
});
define('frodo/pods/index/system/digest/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/system/system-model'], function (exports, _baseModel, _systemModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.detail = exports.reset = exports.fetch = undefined;
  exports.default = _systemModel.default.extend({});


  function reset(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _systemModel.PARENT + '/digest/' + data.domain, { data: { name: data.name } });
  }

  function fetch(owner, domain) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/digest/' + domain);
  }

  function detail(owner, domain, name) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/digest/' + domain + '/' + name);
  }

  exports.fetch = fetch;
  exports.reset = reset;
  exports.detail = detail;
});
define('frodo/pods/components/common/bread-crumb/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'b',
    router: Ember.inject.service(),
    topLevelRoute: null
  });
});
define('frodo/pods/index/system/debug/controller', ['exports', 'frodo/pods/index/system/debug/model', 'frodo/pods/index/system/system-controller', 'frodo/mixins/service-bus'], function (exports, _model, _systemController, _serviceBus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _systemController.default.extend(_serviceBus.default, {
    type: 'application',

    actions: {
      reload: function reload() {
        var _this = this;

        (0, _model.fetch)(this).then(function (r) {
          var model = void 0;
          if (_this.isData(r)) {
            _.forEach(r.data, function (v, k) {
              r.data[k] = _.map(v, function (o) {
                return _model.default.create(o);
              });
            });
            model = r.data;
            _this.set('model', model);
          }
          _this.updateNavtab(_this.get('currentRouteName'), model, {});
        });
      },

      add: function add(id) {
        var type = this.get('type');
        return (0, _model.set)(this, type, { id: id, verbose: true }).then(this._resolve.bind(this), this._reject.bind(this));
      },
      remove: function remove(o) {
        var type = this.get('type');
        if (type === 'broker') return (0, _model.set)(this, type, { id: o.id, verbose: false });
        if (type === 'symbol') return (0, _model.set)(this, type, { id: o.id, verbose: false });
        if (type === 'bkSymbol') return (0, _model.set)(this, type, { id: o.id, verbose: false });
        if (type === 'lpSymbol') return (0, _model.set)(this, type, { id: o.id, verbose: false });
        if (type === 'itSymbol') return (0, _model.set)(this, type, { id: o.id, verbose: false });
        if (type === 'operator') return (0, _model.set)(this, type, { id: o.loginId, verbose: false });
        if (type === 'application') return (0, _model.set)(this, type, { id: o.appId, verbose: false });
        return (0, _model.set)(this, type, undefined);
      },
      tab: function tab(type) {
        this.set('type', type);
      }
    },

    _resolve: function _resolve(r) {
      var i18n = this.get('i18n');
      var flash = this.get('flashMessages');
      if (this.isSuccess(r)) {
        var type = this.get('type');
        flash.info(i18n.t('label.notification.added', { name: i18n.t('label.system.debug.' + type + '.self') }));
      } else {
        flash.danger(i18n.t(r.key));
      }
    },
    _reject: function _reject(r) {
      var i18n = this.get('i18n');
      this.get('flashMessages').danger(i18n.t(r.key));
    },
    resumeModel: function resumeModel(model) {
      this.set('model', model);
    }
  });
});
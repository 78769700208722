define('frodo/pods/index/trades/order-history/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/trades/trades-model'], function (exports, _baseModel, _tradesModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.findExecution = exports.findOrder = exports.fetch = undefined;
  exports.default = _tradesModel.default.extend({

    isSor: Ember.computed('sourceId', 'lpId', function () {
      return this.get('sourceId') === '0' && this.get('lpId') === 0;
    })

  });


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/order_history', { data: data });
  }
  function findOrder(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/order', { data: data });
  }
  function findExecution(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _tradesModel.PARENT + '/hedge_execution', { data: data });
  }
  exports.fetch = fetch;
  exports.findOrder = findOrder;
  exports.findExecution = findExecution;
});
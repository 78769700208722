define('frodo/pods/components/index/system/batch-history-steps/component', ['exports', 'frodo/pods/components/common/modal-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    title: Ember.computed('model', function () {
      var _$head = _.head(this.get("model")),
          jobExecutionId = _$head.jobExecutionId;

      return 'Job Execution ID: ' + jobExecutionId;
    })
  });
});
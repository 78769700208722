define('frodo/pods/index/system/applications/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/system/system-model'], function (exports, _baseModel, _systemModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = undefined;
  exports.default = _systemModel.default.extend({});


  function fetch(owner) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _systemModel.PARENT + '/applications');
  }

  exports.fetch = fetch;
});
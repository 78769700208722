define('frodo/pods/index/market/symbol/validator', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ max: 32 })],

    type: [(0, _validators.validatePresence)(true)],

    baseCurrency: [(0, _validators.validatePresence)(true)],

    profitCurrency: [(0, _validators.validatePresence)(true)]
  };
});
define('frodo/pods/components/common/tooltip-validator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    tagName: 'div',
    tooltipClass: 'tooltip-warning',
    message: Ember.computed('source', function () {
      if (Ember.isEmpty(this.get('source'))) {
        return this.get('i18n').t(this.get('emptyLabel'));
      }
      return this.get('source');
    }),
    actions: {}
  });
});
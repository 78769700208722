define('frodo/helpers/menu-name', ['exports', 'frodo/models/enums'], function (exports, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    i18n: Ember.inject.service(),

    compute: function compute(params, hash) {
      var v = params[0];
      var parent = params[1] ? params[1] : false;
      var hs = this.get('i18n').t('menu.index.' + v + (parent ? '.self' : '')) || v;
      var length = hash && hash.length ? hash.length : undefined;
      if (length) {
        var source = hs.string;
        var locale = this.get('i18n.locale');
        var source_length = source.length;
        if (_enums.Language_i18n[3] === locale) {
          //
          length = Math.floor(length / 2);
        }
        if (source_length <= length) return source;else return source.substring(0, length) + '...';
      } else {
        return hs;
      }
    }
  });
});
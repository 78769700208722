define('frodo/pods/index/making/dealing-making-statement/model', ['exports', 'frodo/pods/index/base-model', 'frodo/pods/index/making/making-model'], function (exports, _baseModel, _makingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.update = exports.refresh = exports.fetch = undefined;
  exports.default = _makingModel.default.extend({});


  function fetch(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/dealing_making_statement', { data: data });
  }
  function refresh(owner, data) {
    return owner.get('ajax').request(_baseModel.ROOT + '/' + _makingModel.PARENT + '/dealing_making_statement/refresh', { data: data });
  }
  function update(owner, data) {
    return owner.get('ajax').post(_baseModel.ROOT + '/' + _makingModel.PARENT + '/dealing_making_statement/update', { data: data });
  }

  exports.fetch = fetch;
  exports.refresh = refresh;
  exports.update = update;
});